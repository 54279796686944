import { Paiement } from "./paiement"

export enum StockOrderType {
  Pool = "POOL",
  Movie = "MOVIE",
}

export default interface CardStockOrder {
  id: number
  adultQuantity: number
  childQuantity: number
  type: StockOrderType
  orderedAt: string
  createdAt: string
  payment?: Paiement
  amount?: number
  deposit?: number
  totalWithCaution?: number
}

export function getStockOrderTypeLabel(type: StockOrderType | string) {
  switch (type) {
    case StockOrderType.Pool:
      return "Piscine"
    case StockOrderType.Movie:
      return "Cinéma"
    default:
      return type
  }
}
