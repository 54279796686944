import { useEffect, useState } from "react"
import { FloatingLabel, Form, Modal } from "react-bootstrap"
import styled from "styled-components"
import colors from "../../utils/style/colors"
import Toastify from "toastify-js"
import { ResponseApiType } from "../../models/responseApiType"
import ButtonPrimary from "../ButtonPrimary"
import ButtonSecondary from "../ButtonSecondary"
import { DocumentAdmin } from "../../models/document"
import { usePostPersonalDocumentMutation } from "../../features/userSlice"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons"

interface ModalProps {
  show: boolean
  handleClose: () => void
}

const StyledModal = styled(Modal)`
  font-size: 0.9rem;
  .modal-title {
    font-size: 1rem !important;
    font-weight: 300 !important;
    padding-left: 0.5rem;

    @media (min-width: 768px) {
      font-size: 1.2rem !important;
    }
  }

  .modal-header {
    background-color: ${colors.violet};
    color: white;
  }
`

const StyledSelect = styled.div`
  font-size: 0.9rem;
  input,
  select {
    font-size: 1rem;
  }
`

function PersonalDocumentModal(modalProps: ModalProps) {
  const [validated, setValidated] = useState(false)
  const [msgError, setMsgError] = useState<string>()
  const [infos, setInfos] = useState<DocumentAdmin>()
  const [errors, setErrors] = useState({} as any)

  const [postDocument, { isLoading: isUpdateLoading }] =
    usePostPersonalDocumentMutation()

  const handleDocumentChange = ({ target: { name, value } }: any) => {
    setInfos((prev: any) => ({ ...prev, [name]: value.trim() }))
  }

  const handleSubmit = (event: any) => {
    const form = event.currentTarget
    event.preventDefault()
    event.stopPropagation()
    if (form.checkValidity() === false) {
      setValidated(true)
    } else {
      postDocument(infos)
        .unwrap()
        .then(() => {
          setValidated(false)
          Toastify({
            text: "Le document a bien été ajouté",
            duration: 8000,
            newWindow: true,
            close: true,
            gravity: "top", // `top` or `bottom`
            position: "right", // `left`, `center` or `right`
            stopOnFocus: true, // Prevents dismissing of toast on hover
            style: {
              background: "linear-gradient(to right, #29aac4, #bd79e7)",
            },
            onClick: function () {}, // Callback after click
          }).showToast()
          modalProps.handleClose()
        })
        .catch((error: any) => {
          console.error(error)
          if (error.data?.type === ResponseApiType.CustomError) {
            setMsgError(error.data?.detail)
            setErrors(error.data?.detail)
            setValidated(true)
          } else if (error.data?.type === ResponseApiType.FormError) {
            setMsgError("Veuillez vérifier les données")
            setErrors(error.data?.detail)
            setValidated(true)
          } else {
            setMsgError("Une erreur interne s'est produite.")
          }
        })
    }
  }

  const convertToBase64 = (file: any): Promise<string> => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader()
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result as string)
      }
      fileReader.onerror = (error) => {
        reject(error)
      }
    })
  }

  const handleFileUpload = async (e: any) => {
    const file = e.target.files[0]
    await convertToBase64(file)
      .then((result) => {
        setInfos((prev: any) => ({
          ...prev,
          data: result,
        }))
      })
      .catch((error) => console.error(error))
  }

  useEffect(() => {
    if (!modalProps.show) {
      setMsgError("")
      setErrors(null)
      setValidated(false)
      setInfos(undefined)
    }
  }, [modalProps.show])

  return (
    <StyledModal
      show={modalProps.show}
      onHide={modalProps.handleClose}
      backdrop="static"
      keyboard={false}
      dialogClassName="modal-width"
      centered
    >
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title>Ajouter un document personnel</Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-4">
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <StyledSelect className="d-flex flex-column mb-2 gap-2">
            <div className="my-2">
              <FontAwesomeIcon icon={faCircleInfo} color="#72a4ff" />
              &nbsp; Une fois le document ajouté vous ne pourrez pas le modifier
              ou le supprimer. Pour cela veuillez nous en faire la demande via
              le formulaire de contact.
            </div>
            <FloatingLabel label="Titre du document *">
              <Form.Control
                className="mb-2"
                name="title"
                required
                placeholder="Titre"
                onChange={handleDocumentChange}
                isInvalid={validated && errors && errors.title}
              />
              <Form.Control.Feedback type="invalid">
                Ce champ est requis.
              </Form.Control.Feedback>
            </FloatingLabel>
            <Form.Group controlId="formFileLg" className="mb-3">
              <Form.Label>
                Importer le fichier{" "}
                <span
                  style={{
                    color: "grey",
                    fontSize: "0.8rem",
                    fontStyle: "italic",
                  }}
                >
                  (Taille max : 10M)
                </span>
              </Form.Label>
              <Form.Control
                className="mb-2"
                name="file"
                placeholder="Fichier"
                required
                type="file"
                onChange={handleFileUpload}
                isInvalid={validated && errors && errors.file}
              />
              <Form.Control.Feedback type="invalid">
                {errors && errors.file}
              </Form.Control.Feedback>
            </Form.Group>
          </StyledSelect>
          <Form.Group className="my-3 text-danger">{msgError}</Form.Group>
          <div className="d-flex justify-content-end gap-3 flex-wrap">
            <ButtonPrimary
              label="ajouter"
              type="submit"
              loading={isUpdateLoading}
            />
            <ButtonSecondary onClick={modalProps.handleClose} label="Annuler" />
          </div>
        </Form>
      </Modal.Body>
    </StyledModal>
  )
}

export default PersonalDocumentModal
