import WaveHeader from "../../../components/WaveHeader"
import bgImage from "../../../assets/refund-bg.jpg"
import guitareImg from "../../../assets/guitare.jpg"
import sportImg from "../../../assets/sport.jpg"
import { Box, ButtonBase, styled as muiStyled, Typography } from "@mui/material"
import colors from "../../../utils/style/colors"
import { useNavigate } from "react-router-dom"

const images = [
  {
    url: sportImg,
    title: "Activités sportives et scolaires",
    width: "30%",
    path: "/remboursements/sport",
  },
  {
    url: guitareImg,
    title: "Activités culturelles",
    width: "30%",
    path: "/remboursements/culture",
  },
]

const ImageButton = muiStyled(ButtonBase)(({ theme }) => ({
  position: "relative",
  height: 300,
  [theme.breakpoints.down("sm")]: {
    width: "100% !important", // Overrides inline-style
    height: 200,
  },
  "&:hover, &.Mui-focusVisible": {
    zIndex: 1,
    "& .MuiImageBackdrop-root": {
      opacity: 0.4,
    },
    "& .MuiImageMarked-root": {
      opacity: 0,
    },
    "& .MuiTypography-root": {
      border: "2px solid currentColor",
      borderRadius: "4px",
    },
  },
}))

const ImageSrc = muiStyled("span")({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundSize: "cover",
  backgroundPosition: "center 40%",
})

const Image = muiStyled("span")(({ theme }) => ({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: theme.palette.common.white,
}))

const ImageBackdrop = muiStyled("span")(({ theme }) => ({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundColor: colors.darkViolet,
  opacity: 0.7,
  transition: theme.transitions.create("opacity"),
}))

const ImageMarked = muiStyled("span")(({ theme }) => ({
  height: 3,
  width: 18,
  backgroundColor: theme.palette.common.white,
  position: "absolute",
  bottom: -2,
  left: "calc(50% - 9px)",
  transition: theme.transitions.create("opacity"),
}))

function Refund() {
  const navigate = useNavigate()
  return (
    <div>
      <WaveHeader
        style={{ backgroundPosition: "left, left center" }}
        title="Mes remboursements"
        image={bgImage}
      />
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          minWidth: 300,
          width: "100%",
          justifyContent: "center",
          paddingTop: "3rem",
          paddingBottom: "3rem",
        }}
      >
        {images.map((image) => (
          <ImageButton
            focusRipple
            key={image.title}
            style={{
              width: image.width,
            }}
            onClick={() => navigate(image.path)}
          >
            <ImageSrc style={{ backgroundImage: `url(${image.url})` }} />
            <ImageBackdrop className="MuiImageBackdrop-root" />
            <Image>
              <Typography
                component="span"
                paragraph
                color="inherit"
                sx={{
                  position: "relative",
                  fontSize: "1.3rem",
                  p: 4,
                  pt: 2,
                  pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
                }}
              >
                {image.title}
                <ImageMarked className="MuiImageMarked-root" />
              </Typography>
            </Image>
          </ImageButton>
        ))}
      </Box>
    </div>
  )
}

export default Refund
