import {
  faCakeCandles,
  faCircleCheck,
  faCrown,
  faDownload,
  faXmarkCircle,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Breadcrumbs, Divider, Typography } from "@mui/material"
import { Fragment } from "react"
import { Col, Container, Row } from "react-bootstrap"
import { Link, useParams } from "react-router-dom"
import styled from "styled-components"
import ButtonPrimary from "../../../components/ButtonPrimary"
import InternError from "../../../components/InternError"
import { useGetUserProfileQuery } from "../../../features/userSlice"
import { Document } from "../../../models/document"
import Member, { getRelationshipLabel } from "../../../models/member"
import { AdminUser } from "../../../models/personalInfos"
import {
  ResponseApiException,
  ResponseApiType,
} from "../../../models/responseApiType"
import { API } from "../../../utils/API"
import { Loader } from "../../../utils/Atoms"
import colors from "../../../utils/style/colors"
import { formatStrToDate, getAge } from "../../../utils/utils"
import Toastify from "toastify-js"

const LinkStyled = styled(Link)`
  text-decoration: none;
  color: #929292;
  &:hover {
    text-decoration: underline;
    color: ${colors.violet};
  }
`

const StyledDiv = styled(Container)`
  padding: 2rem 0.5rem 2rem 2.6rem;

  @media all and (min-width: 768px) {
    padding: 2rem 0.5rem 2rem 3.5rem;
  }

  h1 {
    font-size: 1.6rem;
    color: ${colors.violet};
    text-align: center;
    margin-bottom: 2rem;
  }
`
const NoneSpan = styled.span`
  color: grey;
  font-style: italic;
`

const CardDiv = styled.div`
  @media all and (min-width: 992px) {
    padding: 0 10rem;
  }

  @media all and (min-width: 1200px) {
    padding: 0 16rem;
  }
`

const StyledCol = styled(Col)`
  font-weight: bold;
  font-size: 1rem;
  color: ${colors.darkViolet};
`

function UserProfile() {
  let { userId } = useParams()

  const {
    data: user,
    isLoading,
    isSuccess,
    isError,
    error,
  }: {
    data: AdminUser
    isLoading: boolean
    isSuccess: boolean
    isError: boolean
    error: ResponseApiException
  } = useGetUserProfileQuery(userId)

  const handleDownload = (id: number, fileName: string) => {
    API.get(`documents/${id}/download`, {
      responseType: "blob",
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement("a")
        link.href = url
        link.setAttribute("download", fileName)
        document.body.appendChild(link)
        link.click()
      })
      .catch((error) => {
        Toastify({
          text: "Une erreur s'est produite lors du téléchargement",
          duration: 8000,
          newWindow: true,
          close: true,
          gravity: "top", // `top` or `bottom`
          position: "right", // `left`, `center` or `right`
          stopOnFocus: true, // Prevents dismissing of toast on hover
          style: {
            background: "linear-gradient(to right, #29aac4, #bd79e7)",
          },
          onClick: function () {}, // Callback after click
        }).showToast()
      })
  }

  let content
  if (isLoading) {
    content = <Loader />
  } else if (isError) {
    if (error.data?.type === ResponseApiType.CustomError) {
      content = error.data?.detail
    } else {
      content = <InternError />
    }
  } else if (isSuccess) {
    content = (
      <CardDiv className="gap-2 justify-content-center">
        <Row className="justify-content-center my-2">
          <StyledCol sm={6} md={5}>
            Compte actif :
          </StyledCol>
          <Col sm={6} md={7}>
            {user.isActive ? (
              <FontAwesomeIcon icon={faCircleCheck} color="green" />
            ) : (
              <FontAwesomeIcon icon={faXmarkCircle} color="darkred" />
            )}
          </Col>
        </Row>
        <Divider />
        <Row className="justify-content-center my-2">
          <StyledCol sm={6} md={5}>
            Nom :
          </StyledCol>
          <Col sm={6} md={7}>
            {user.lastname}
          </Col>
        </Row>
        <Divider />
        <Row className="justify-content-center my-2">
          <StyledCol sm={6} md={5}>
            Prénom :
          </StyledCol>
          <Col sm={6} md={7}>
            {user.firstName}
          </Col>
        </Row>
        <Divider />
        <Row className="justify-content-center my-2">
          <StyledCol sm={6} md={5}>
            Dernière connexion
          </StyledCol>
          <Col sm={6} md={7}>
            {user.lastConnection
              ? formatStrToDate(user.lastConnection, "dd/MM/yyyy à HH:mm")
              : ""}
          </Col>
        </Row>
        <Divider />
        <Row className="justify-content-center my-2">
          <StyledCol sm={6} md={5}>
            Email :
          </StyledCol>
          <Col sm={6} md={7}>
            <a
              href={`mailto:${user.email}`}
              style={{ textDecoration: "underline", color: colors.violet }}
            >
              {user.email}
            </a>
          </Col>
        </Row>
        <Divider />

        <Row className="justify-content-center my-2">
          <StyledCol sm={6} md={5}>
            Adresse :
          </StyledCol>
          <Col sm={6} md={7}>
            {user.address ? (
              <Fragment>
                {user.address.number} {user.address.street}
                <br />
                {user.address.additionalAddress && (
                  <Fragment>
                    <span>{user.address.additionalAddress}</span>
                    <br />
                  </Fragment>
                )}
                {user.address.postalCode} {user.address.city}
              </Fragment>
            ) : (
              <NoneSpan>Aucune adresse enregistrée</NoneSpan>
            )}
          </Col>
        </Row>
        <Divider />
        <Row className="justify-content-start my-2">
          <StyledCol xs={12} className="mb-1">
            Membres de la famille :
          </StyledCol>
          <Col xs={12} /*sm={6} md={7}*/>
            {user.familyMember && user.familyMember.length > 0 ? (
              <ul>
                {user.familyMember.map((member: Member) => (
                  <li key={member.id} className="mb-2">
                    {member.firstname} {member.lastname}(
                    {getRelationshipLabel(member.relationship)}) -&nbsp;
                    {getAge(member.birthDate)} ans
                    <span className="d-flex align-items-baseline gap-2">
                      <FontAwesomeIcon
                        icon={faCakeCandles}
                        color="sandybrown"
                      />
                      {formatStrToDate(member.birthDate)}
                    </span>
                  </li>
                ))}
              </ul>
            ) : (
              <NoneSpan>Aucun membre enregistré</NoneSpan>
            )}
          </Col>
        </Row>
        <Divider />
        <Row className="justify-content-center my-2">
          <StyledCol xs={12} className="mb-1">
            Documents :
          </StyledCol>
          <Col xs={12}>
            {user.personalDocuments && user.personalDocuments.length > 0 ? (
              <div className="d-flex flex-wrap gap-4 mt-1 justify-content-start">
                {user.personalDocuments.map((doc: Document) => (
                  <div
                    className="d-flex flex-column align-items-start align-items-sm-center"
                    key={doc.id}
                  >
                    <span
                      style={{ color: colors.violet, fontSize: "0.9rem" }}
                      className="mb-1"
                    >
                      {doc.title}
                    </span>
                    <ButtonPrimary
                      size="small"
                      icon={faDownload}
                      label="Télécharger"
                      onClick={() => handleDownload(doc.id, doc.path)}
                      type="button"
                    />
                  </div>
                ))}
              </div>
            ) : (
              <NoneSpan>Aucun document enregistré</NoneSpan>
            )}
          </Col>
        </Row>
      </CardDiv>
    )
  }
  return (
    <StyledDiv fluid>
      <Breadcrumbs aria-label="breadcrumb" className="mb-4">
        <LinkStyled color="gray" to="/admin/directory">
          Répertoire
        </LinkStyled>
        <Typography color="text.primary">{user?.email}</Typography>
      </Breadcrumbs>
      <h1>
        <span>
          {(user?.roles?.includes("ROLE_ADMIN") ||
            user?.roles?.includes("ROLE_SUPER_ADMIN")) && (
            <FontAwesomeIcon
              size="xs"
              icon={faCrown}
              color="#e6c619"
              className="me-2"
            />
          )}
          Fiche utilisateur
        </span>
      </h1>
      <div className="d-flex justify-content-center">{content}</div>
    </StyledDiv>
  )
}

export default UserProfile
