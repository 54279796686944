import {
  faCircleExclamation,
  faCircleInfo,
  faExclamationTriangle,
  faFilm,
  faPencil,
  faPersonSwimming,
  faPlusCircle,
  faSwimmer,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useMemo, useState } from "react"
import { Container } from "react-bootstrap"
import DataTable from "react-data-table-component"
import styled from "styled-components"
import ButtonPrimary from "../../../components/ButtonPrimary"
import CardPriceModal from "../../../components/CardPriceModal"
import miniShape from "../../../assets/mini-shapes.png"
import {
  useGetCardStockOrderWithPaginationQuery,
  useGetCurrentCardsPricesQuery,
  useGetCurrentStockQuery,
  useGetFutureCardsPricesQuery,
} from "../../../features/orderSlice"
import CardPrice from "../../../models/cardPrice"
import CardStockOrder, { StockOrderType } from "../../../models/cardStockOrder"
import { Loader } from "../../../utils/Atoms"
import colors from "../../../utils/style/colors"
import { formatPrice, formatStrToDate } from "../../../utils/utils"
import { EscalatorWarning, Man } from "@mui/icons-material"
import CardStockOrderModal from "../../../components/CardStockOrderModal"
import { getPaiementTypeLabel, PaiementType } from "../../../models/paiement"

const StyledDiv = styled(Container)`
  padding: 2rem 0.5rem 2rem 2rem;

  h1 {
    font-size: 1.4rem;
    color: ${colors.violet};
    text-align: center;
    margin-bottom: 2rem;
  }

  h2 {
    font-size: 1.3rem;
    font-weight: 500;
    text-decoration: underline;
    margin-left: 0.7rem;
  }

  @media all and (min-width: 575px) {
    padding: 2rem 0.5rem 2rem 2.5rem;
  }

  @media all and (min-width: 768px) {
    padding: 2rem 0.5rem 2rem 3.2rem;
    h1 {
      font-size: 1.7rem;
    }

    h2 {
      font-size: 1.5rem;
    }
  }
`
const StyledSpan = styled.span`
  font-size: 0.8rem;
  color: gray;
  font-weight: 400;
  font-style: italic;
`

const StyledChequeNumber = styled.span`
  font-size: 0.8rem;
  color: ${colors.violet};
  font-weight: bold;
`
const StyledIcon = styled.div`
  text-align: center;
  background-color: white;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 6.5rem;
  width: 6.5rem;
  box-shadow: 3px 3px 8px grey;

  span {
    font-size: 1.4rem;
    font-weight: 100;
  }

  .svg-inline--fa {
    font-size: 20px;
  }

  @media all and (min-width: 768px) {
    height: 8rem;
    width: 8rem;
    span {
      font-size: 1.9rem;
    }

    .svg-inline--fa {
      font-size: 1.8rem;
    }
  }
`
const PriceDiv = styled.div`
  margin-top: 1rem;
  overflow-x: auto;
  table {
    width: 100%;
    font-size: 0.8rem;

    th {
      background-color: #4492e157;

      &[scope="row"] {
        max-width: 7rem;
      }
    }

    tr {
      border-bottom: 1px solid #ddd;

      &:not(:first-child):hover {
        background-color: #e5e5e5;
      }
    }
    td,
    th {
      padding: 0.5rem 0.5rem;
    }

    @media all and (min-width: 768px) {
      font-size: 0.9rem;
    }
  }
`
function Stock() {
  const columns: any = [
    {
      name: "Date commande",
      selector: (row: CardStockOrder) =>
        formatStrToDate(row.orderedAt, "dd/MM/yyyy"),
      sortable: true,
      sortField: "orderedAt",
    },
    {
      name: "Type",
      selector: (row: CardStockOrder) => row.type,
      cell: (row: CardStockOrder) =>
        row.type === StockOrderType.Pool ? (
          <FontAwesomeIcon icon={faSwimmer} color={colors.blue} size="lg" />
        ) : row.type === StockOrderType.Movie ? (
          <FontAwesomeIcon icon={faFilm} color={"#920000"} size="lg" />
        ) : (
          row.type
        ),
    },
    {
      name: "Nb Adultes",
      selector: (row: CardStockOrder) => row.adultQuantity,
    },
    {
      name: "Nb Enfants",
      selector: (row: CardStockOrder) => row.childQuantity,
    },
    {
      name: "Montant",
      selector: (row: CardStockOrder) => row.payment?.amount,
      format: (row: CardStockOrder) =>
        row.payment?.amount ? formatPrice(row.payment.amount) : "",
    },
    {
      name: "Paiement",
      selector: (row: CardStockOrder) => row.payment?.type,
      center: true,
      cell: (row: CardStockOrder) =>
        row.payment?.type && (
          <div className="d-flex flex-column justify-content-center text-center">
            <span>{getPaiementTypeLabel(row.payment?.type)}</span>
            {(row.payment?.type === PaiementType.Cheque ||
              row.payment?.type === PaiementType.Deux) &&
              row.payment?.chequeNumber && (
                <StyledChequeNumber>
                  {row.payment?.chequeNumber}
                </StyledChequeNumber>
              )}
          </div>
        ),
    },
  ]

  const [order, setOrder] = useState({
    orderBy: "orderedAt",
    direction: "DESC",
  })

  const paginationComponentOptions = {
    rowsPerPageText: "Commandes par page",
    rangeSeparatorText: "de",
  }
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(10)
  const [showCardPriceDialog, setShowCardPriceDialog] = useState(false)
  const [showCardStockOrderDialog, setShowCardStockOrderDialog] =
    useState(false)
  const [dialogType, setDialogType] = useState<StockOrderType>(
    StockOrderType.Pool
  )

  const [stockOrderId, setStockOrderId] = useState<number | undefined>()

  const {
    data: orderPaginate = [],
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetCardStockOrderWithPaginationQuery({
    limit: perPage,
    page: page,
    orderBy: order?.orderBy,
    direction: order?.direction,
  })

  const {
    data: currentCardsPrices = [],
    isLoading: isCardPriceLoading,
    isError: isCardPriceError,
  } = useGetCurrentCardsPricesQuery()

  const { data: futureCardsPrices = [], isSuccess: isCardFutureSuccess } =
    useGetFutureCardsPricesQuery()

  const {
    data: currentStock = [],
    isSuccess: isStockSuccess,
    isLoading: isStockLoading,
  } = useGetCurrentStockQuery()

  const handleSort = (selectedColumn: any, sortDirection: any) => {
    setOrder({
      orderBy: selectedColumn.sortField,
      direction: sortDirection,
    })
  }

  const handlePageChange = (page: number) => {
    setPage(page)
  }

  const handlePerRowsChange = (newPerPage: number) => {
    setPerPage(newPerPage)
  }

  const handleShowPriceModal = (type: StockOrderType) => {
    setDialogType(type)
    setShowCardPriceDialog(true)
  }

  const handleCloseCardPrice = () => setShowCardPriceDialog(false)

  const handleRowClicked = (id: number) => {
    setStockOrderId(id)
    setShowCardStockOrderDialog(true)
  }

  const cardsPrices = useMemo(() => {
    let cards: any = []

    if (currentCardsPrices) {
      let poolCards = currentCardsPrices.find(
        (cardPrice: CardPrice) => cardPrice.type === StockOrderType.Pool
      )
      let movieCards = currentCardsPrices.find(
        (cardPrice: CardPrice) => cardPrice.type === StockOrderType.Movie
      )

      cards[StockOrderType.Pool] = poolCards
      cards[StockOrderType.Movie] = movieCards
    }
    return cards
  }, [currentCardsPrices])

  const cardsFuturePrices = useMemo(() => {
    let cards: any = []
    if (futureCardsPrices) {
      let poolCards = futureCardsPrices.find(
        (cardPrice: CardPrice) => cardPrice.type === StockOrderType.Pool
      )
      let movieCards = futureCardsPrices.find(
        (cardPrice: CardPrice) => cardPrice.type === StockOrderType.Movie
      )

      cards[StockOrderType.Pool] = poolCards
      cards[StockOrderType.Movie] = movieCards
    }
    return cards
  }, [futureCardsPrices])

  const adultPoolStock = useMemo(() => {
    return currentStock.find(
      (setting: any) => setting.name === "AdultPoolCardStock"
    )?.value
  }, [currentStock])

  const childPoolStock = useMemo(() => {
    return currentStock.find(
      (setting: any) => setting.name === "ChildPoolCardStock"
    )?.value
  }, [currentStock])

  const adultMovieStock = useMemo(() => {
    return currentStock.find(
      (setting: any) => setting.name === "AdultMovieCardStock"
    )?.value
  }, [currentStock])

  const childMovieStock = useMemo(() => {
    return currentStock.find(
      (setting: any) => setting.name === "ChildMovieCardStock"
    )?.value
  }, [currentStock])

  const adultMovieOrders = useMemo(() => {
    return currentStock.find(
      (setting: any) => setting.name === "AdultMovieOrders"
    )?.value
  }, [currentStock])

  const childMovieOrders = useMemo(() => {
    return currentStock.find(
      (setting: any) => setting.name === "ChildMovieOrders"
    )?.value
  }, [currentStock])

  const poolOrders = useMemo(() => {
    return currentStock.find((setting: any) => setting.name === "PoolOrders")
      ?.value
  }, [currentStock])

  return (
    <StyledDiv fluid>
      <h1>Gestion des stocks</h1>
      <div className="d-flex align-content-center mb-3">
        <img
          src={miniShape}
          alt=""
          style={{ width: "2rem", objectFit: "contain" }}
        />
        <h2>Stocks actuels :</h2>
      </div>
      <div className="d-flex flex-wrap gap-4 justify-content-center">
        <StyledIcon>
          <FontAwesomeIcon icon={faPersonSwimming} color={colors.blue} />
          <span>
            {isStockLoading ? <Loader /> : isStockSuccess ? adultPoolStock : ""}
          </span>
          <Man sx={{ color: colors.violet, fontSize: "25px" }} />
        </StyledIcon>
        {/*  <StyledIcon>
          <FontAwesomeIcon icon={faPersonSwimming} color={colors.blue} />
          <span>
            {isStockLoading ? <Loader /> : isStockSuccess ? childPoolStock : ""}
          </span>
          <EscalatorWarning sx={{ color: "rosybrown", fontSize: "25px" }} />
        </StyledIcon> */}
        <StyledIcon>
          <FontAwesomeIcon icon={faFilm} color="#920000" />
          <span>
            {isStockLoading ? (
              <Loader />
            ) : isStockSuccess ? (
              adultMovieStock
            ) : (
              ""
            )}
          </span>
          <Man sx={{ color: colors.violet, fontSize: "25px" }} />
        </StyledIcon>
        <StyledIcon>
          <FontAwesomeIcon icon={faFilm} color="#920000" />
          <span>
            {isStockLoading ? (
              <Loader />
            ) : isStockSuccess ? (
              childMovieStock
            ) : (
              ""
            )}
          </span>
          <EscalatorWarning sx={{ color: "rosybrown", fontSize: "25px" }} />
        </StyledIcon>
      </div>
      {(+adultPoolStock + +childPoolStock <= 10 ||
        +adultMovieStock + +childMovieStock <= 10) && (
        <div
          className="d-flex text-center mt-4 align-items-end"
          style={{
            width: "fit-content",
            padding: "1rem 2rem",
            margin: "0 auto",
          }}
        >
          <FontAwesomeIcon
            icon={faExclamationTriangle}
            size="2x"
            color="darkorange"
          ></FontAwesomeIcon>
          <div className="text-center ms-2">Attention le stock est bas</div>
        </div>
      )}
      <br />
      <br />
      <div className="d-flex align-content-center mb-3">
        <img
          src={miniShape}
          alt=""
          style={{ width: "2rem", objectFit: "contain" }}
        />
        <h2>Commandes en cours :</h2>
      </div>
      <div className="d-flex flex-wrap gap-4 justify-content-center">
        <StyledIcon>
          <FontAwesomeIcon icon={faPersonSwimming} color={colors.blue} />
          <span>
            {isStockLoading ? (
              <Loader />
            ) : isStockSuccess ? (
              poolOrders ?? 0
            ) : (
              ""
            )}
          </span>
          <Man sx={{ color: colors.violet, fontSize: "25px" }} />
        </StyledIcon>
        <StyledIcon>
          <FontAwesomeIcon icon={faFilm} color="#920000" />
          <span>
            {isStockLoading ? (
              <Loader />
            ) : isStockSuccess ? (
              adultMovieOrders ?? 0
            ) : (
              ""
            )}
          </span>
          <Man sx={{ color: colors.violet, fontSize: "25px" }} />
        </StyledIcon>
        <StyledIcon>
          <FontAwesomeIcon icon={faFilm} color="#920000" />
          <span>
            {isStockLoading ? (
              <Loader />
            ) : isStockSuccess ? (
              childMovieOrders ?? 0
            ) : (
              ""
            )}
          </span>
          <EscalatorWarning sx={{ color: "rosybrown", fontSize: "25px" }} />
        </StyledIcon>
      </div>
      {(childMovieOrders >= childMovieStock ||
        adultMovieOrders >= adultMovieStock ||
        poolOrders >= adultPoolStock) && (
        <>
          <div
            className="d-flex flex-column text-center gap-1 justify-content-center my-3"
            style={{ color: "red" }}
          >
            <div>
              <FontAwesomeIcon
                icon={faCircleExclamation}
                color="red"
                size="lg"
              />
            </div>
            {childMovieOrders >= childMovieStock && (
              <div>Il faut commander des places de cinéma enfant</div>
            )}
            {adultMovieOrders >= adultMovieStock && (
              <div>Il faut commander des places de cinéma adulte</div>
            )}
            {poolOrders >= adultPoolStock && (
              <div>Il faut commander des cartes de piscine</div>
            )}
          </div>
        </>
      )}
      <div className="d-flex align-content-center mb-3">
        <img
          src={miniShape}
          alt=""
          style={{ width: "2rem", objectFit: "contain" }}
        />
        <h2>Tarifs :</h2>
      </div>
      {isCardPriceLoading ? (
        <Loader />
      ) : currentCardsPrices.length > 0 ? (
        <PriceDiv>
          <table>
            <tbody>
              <tr>
                <td></td>
                <th scope="col">Prix coûtant Adulte </th>
                <th scope="col">Prix coûtant Enfant</th>
                <th scope="col">Prix Adulte</th>
                <th scope="col">Prix Enfant</th>
              </tr>
              <tr>
                <th scope="row">Piscine</th>
                <td>
                  {formatPrice(
                    cardsPrices[StockOrderType.Pool]?.adultCostPrice
                  )}
                </td>
                <td>
                  {/*  {formatPrice(
                    cardsPrices[StockOrderType.Pool]?.childCostPrice
                  )} */}
                  NA
                </td>
                <td>
                  {formatPrice(cardsPrices[StockOrderType.Pool]?.adultPrice)}
                </td>
                <td>
                  {/*  {formatPrice(cardsPrices[StockOrderType.Pool]?.childPrice)} */}
                  NA
                </td>
              </tr>
              <tr>
                <th scope="row">Cinéma</th>
                <td>
                  {formatPrice(
                    cardsPrices[StockOrderType.Movie]?.adultCostPrice
                  )}
                </td>
                <td>
                  {formatPrice(
                    cardsPrices[StockOrderType.Movie]?.childCostPrice
                  )}
                </td>
                <td>
                  {formatPrice(cardsPrices[StockOrderType.Movie]?.adultPrice)}
                </td>
                <td>
                  {formatPrice(cardsPrices[StockOrderType.Movie]?.childPrice)}
                </td>
              </tr>
            </tbody>
          </table>
        </PriceDiv>
      ) : (
        <div>
          Aucun tarif indiqué. Merci d'en ajouter afin que les employé(e)s
          puissent faire des commandes.
        </div>
      )}
      {isCardFutureSuccess && futureCardsPrices.length > 0 && (
        <div className="mt-5 mb-4">
          <p>
            <FontAwesomeIcon
              icon={faCircleInfo}
              color="#203477"
              size="1x"
              className="me-2"
            />
            De nouveaux tarifs vont s'appliquer :<br />
          </p>
          <PriceDiv>
            <table>
              <tbody>
                <tr>
                  <td></td>
                  <th scope="col">Prix coûtant Adulte </th>
                  <th scope="col">Prix coûtant Enfant</th>
                  <th scope="col">Prix Adulte</th>
                  <th scope="col">Prix Enfant</th>
                </tr>
                <tr>
                  <th scope="row">
                    Piscine <br />
                    <StyledSpan>
                      (
                      {!cardsFuturePrices[StockOrderType.Pool]
                        ? "Même tarif"
                        : `À partir du ${formatStrToDate(
                            cardsFuturePrices[StockOrderType.Pool].applyFrom,
                            "dd-MM-yyyy"
                          )}`}
                      )
                    </StyledSpan>
                  </th>
                  <td>
                    {cardsFuturePrices[StockOrderType.Pool]
                      ? formatPrice(
                          cardsFuturePrices[StockOrderType.Pool]?.adultCostPrice
                        )
                      : formatPrice(
                          cardsPrices[StockOrderType.Pool]?.adultCostPrice
                        )}
                  </td>
                  <td>
                    {/* {cardsFuturePrices[StockOrderType.Pool]
                      ? formatPrice(
                          cardsFuturePrices[StockOrderType.Pool]?.childCostPrice
                        )
                      : formatPrice(
                          cardsPrices[StockOrderType.Pool]?.childCostPrice
                        )} */}
                    NA
                  </td>
                  <td>
                    {cardsFuturePrices[StockOrderType.Pool]
                      ? formatPrice(
                          cardsFuturePrices[StockOrderType.Pool]?.adultPrice
                        )
                      : formatPrice(
                          cardsPrices[StockOrderType.Pool]?.adultPrice
                        )}
                  </td>
                  <td>
                    {/*  {cardsFuturePrices[StockOrderType.Pool]
                      ? formatPrice(
                          cardsFuturePrices[StockOrderType.Pool]?.childPrice
                        )
                      : formatPrice(
                          cardsPrices[StockOrderType.Pool]?.childPrice
                        )} */}
                    NA
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    Cinéma <br />
                    <StyledSpan>
                      (
                      {!cardsFuturePrices[StockOrderType.Movie]
                        ? "Même tarif"
                        : `À partir du ${formatStrToDate(
                            cardsFuturePrices[StockOrderType.Movie].applyFrom,
                            "dd-MM-yyyy"
                          )}`}
                      )
                    </StyledSpan>
                  </th>
                  <td>
                    {cardsFuturePrices[StockOrderType.Movie]
                      ? formatPrice(
                          cardsFuturePrices[StockOrderType.Movie]
                            ?.adultCostPrice
                        )
                      : formatPrice(
                          cardsPrices[StockOrderType.Movie]?.adultCostPrice
                        )}
                  </td>
                  <td>
                    {cardsFuturePrices[StockOrderType.Movie]
                      ? formatPrice(
                          cardsFuturePrices[StockOrderType.Movie]
                            ?.childCostPrice
                        )
                      : formatPrice(
                          cardsPrices[StockOrderType.Movie]?.childCostPrice
                        )}
                  </td>
                  <td>
                    {cardsFuturePrices[StockOrderType.Movie]
                      ? formatPrice(
                          cardsFuturePrices[StockOrderType.Movie]?.adultPrice
                        )
                      : formatPrice(
                          cardsPrices[StockOrderType.Movie]?.adultPrice
                        )}
                  </td>
                  <td>
                    {cardsFuturePrices[StockOrderType.Movie]
                      ? formatPrice(
                          cardsFuturePrices[StockOrderType.Movie]?.childPrice
                        )
                      : formatPrice(
                          cardsPrices[StockOrderType.Movie]?.childPrice
                        )}
                  </td>
                </tr>
              </tbody>
            </table>
          </PriceDiv>
        </div>
      )}
      <div className="my-2 mt-3 mb-4 d-flex flex-wrap gap-2">
        <ButtonPrimary
          label="Modifier les tarifs piscine"
          icon={cardsPrices.length > 0 ? faPencil : faPlusCircle}
          onClick={() => handleShowPriceModal(StockOrderType.Pool)}
        ></ButtonPrimary>
        <ButtonPrimary
          label="Modifier les tarifs cinéma"
          icon={cardsPrices.length > 0 ? faPencil : faPlusCircle}
          onClick={() => handleShowPriceModal(StockOrderType.Movie)}
        ></ButtonPrimary>
        <CardPriceModal
          show={showCardPriceDialog}
          handleClose={handleCloseCardPrice}
          isUpdate={false}
          type={dialogType}
        />
      </div>
      <br />
      <div className="d-flex align-content-center mb-3">
        <img
          src={miniShape}
          alt=""
          style={{ width: "2rem", objectFit: "contain" }}
        />
        <h2>Commandes de cartes :</h2>
      </div>
      <div className="mb-2">
        <ButtonPrimary
          label="ajouter une commande"
          type="button"
          icon={faPlusCircle}
          onClick={() => {
            setStockOrderId(undefined)
            setShowCardStockOrderDialog(true)
          }}
        />
      </div>
      <DataTable
        columns={columns}
        data={orderPaginate.items}
        progressPending={isLoading}
        pagination
        paginationServer
        paginationTotalRows={orderPaginate.totalItems}
        onChangeRowsPerPage={handlePerRowsChange}
        paginationPerPage={perPage}
        onChangePage={handlePageChange}
        onRowClicked={(row: CardStockOrder) => handleRowClicked(row.id)}
        onSort={handleSort}
        sortServer
        defaultSortFieldId={1}
        defaultSortAsc={false}
        responsive
        highlightOnHover
        pointerOnHover
        noDataComponent="Aucune commande"
        progressComponent={<Loader></Loader>}
        paginationComponentOptions={paginationComponentOptions}
      ></DataTable>
      <CardStockOrderModal
        show={showCardStockOrderDialog}
        handleClose={() => {
          setShowCardStockOrderDialog(false)
        }}
        stockOrderId={stockOrderId}
        isUpdate={stockOrderId !== undefined}
      />
    </StyledDiv>
  )
}

export default Stock
