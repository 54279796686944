import { useState } from "react"
import { FloatingLabel, Form } from "react-bootstrap"
import styled from "styled-components"
import ButtonPrimary from "../../../../components/ButtonPrimary"
import { API } from "../../../../utils/API"
import Toastify from "toastify-js"
import { DocumentAdmin } from "../../../../models/document"
import { array } from "prop-types"

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`

const StyledDiv = styled.div`
  margin-top: 3rem;
  background-color: white;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 90vw;
  align-self: center;
  border-radius: 15px;
  box-shadow: grey 2px 2px 15px;
  @media (min-width: 576px) {
    padding: 2rem;
    width: 30rem;
  }
`

function PoolOrder() {
  const [msg, setMsg] = useState<string[]>([])
  const [infos, setInfos] = useState<DocumentAdmin>()
  const [isLoading, setIsLoading] = useState(false)

  const canSubmit = !isLoading

  const handleSubmit = (event: any) => {
    event.preventDefault()
    event.stopPropagation()
    setIsLoading(true)
    //API call
    API.post(`superadmin/import/poolorders`, infos)
      .then((response: any) => {
        Toastify({
          text: "Process réalisé avec succès",
          duration: 8000,
          newWindow: true,
          close: true,
          gravity: "top", // `top` or `bottom`
          position: "right", // `left`, `center` or `right`
          stopOnFocus: true, // Prevents dismissing of toast on hover
          style: {
            background: "linear-gradient(to right, #29aac4, #bd79e7)",
          },
          onClick: function () {}, // Callback after click
        }).showToast()
        console.log(response.data)
        setMsg(response.data)
      })
      .catch((error: any) => {
        setMsg(error?.response?.data?.detail)
        console.error(error.response)
        Toastify({
          text: "Une erreur s'est produite lors du processus d'import",
          duration: 8000,
          newWindow: true,
          close: true,
          gravity: "top", // `top` or `bottom`
          position: "right", // `left`, `center` or `right`
          stopOnFocus: true, // Prevents dismissing of toast on hover
          style: {
            background: "linear-gradient(to right, #29aac4, #bd79e7)",
          },
          onClick: function () {}, // Callback after click
        }).showToast()
      })
      .finally(() => setIsLoading(false))
  }

  const convertToBase64 = (file: any): Promise<string> => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader()
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result as string)
      }
      fileReader.onerror = (error) => {
        reject(error)
      }
    })
  }

  const handleFileUpload = async (e: any) => {
    const file = e.target.files[0]
    await convertToBase64(file)
      .then((result) => {
        setInfos((prev: any) => ({
          ...prev,
          data: result,
        }))
      })
      .catch((error) => console.error(error))
  }

  return (
    <StyledContainer>
      <StyledDiv>
        <h2 className="text-center pb-4 fs-4 violet">
          Import des commandes de piscine
        </h2>
        <Form onSubmit={(e) => handleSubmit(e)}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Control
              className="mb-2"
              name="file"
              placeholder="Fichier"
              required
              accept=".xlsx"
              type="file"
              onChange={handleFileUpload}
            />
          </Form.Group>

          <div className="d-flex justify-content-center mt-4 w-100">
            <ButtonPrimary
              label="Valider"
              type="submit"
              loading={isLoading}
              disabled={!canSubmit}
            />
          </div>
        </Form>
      </StyledDiv>
      <div className="pt-3 my-3 ps-5 ms-2">
        {msg && Array.isArray(msg)
          ? msg.map((txt: string) => <div>{txt}</div>)
          : msg}
      </div>
    </StyledContainer>
  )
}

export default PoolOrder
