import WaveHeader from "../../../components/WaveHeader"
import fragImage from "../../../assets/other-bg.jpg"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Breadcrumbs, Typography } from "@mui/material"
import colors from "../../../utils/style/colors"
import styled from "styled-components"
import { Col, Container, Row } from "react-bootstrap"
import { Link } from "react-router-dom"
import {
  getOrderStatusLabel,
  getStatusColor,
  OrderStatusType,
  OtherOrderDto,
} from "../../../models/order"
import { Fragment, useMemo, useState } from "react"
import { Loader } from "../../../utils/Atoms"
import InternError from "../../../components/InternError"
import ButtonPrimary from "../../../components/ButtonPrimary"
import otherImg from "../../../assets/other-order.jpg"
import OtherOrderModal from "../../../components/OtherOrderModal"
import { useGetOtherOrdersQuery } from "../../../features/orderSlice"
import { cutDocTitle, formatPrice, formatStrToDate } from "../../../utils/utils"
import { API } from "../../../utils/API"
import Toastify from "toastify-js"
import { faBox } from "@fortawesome/free-solid-svg-icons"

const ContainerStyled = styled(Container)`
  h2 {
    font-size: 1.5rem;
    color: ${colors.violet};
  }
  h3 {
    font-size: 1.4rem;
    color: ${colors.darkViolet};
    font-weight: 500;
  }
`

const LinkStyled = styled(Link)`
  text-decoration: none;
  color: #929292;
  &:hover {
    text-decoration: underline;
    color: ${colors.violet};
  }
`

const StyledSpan = styled.span<{ status: OrderStatusType }>`
  font-size: 0.8rem;
  padding: 0.2rem 0.5rem;
  border-radius: 2rem;
  align-self: center;
  text-align: center;
  white-space: nowrap;
  background-color: ${(prop) =>
    prop.status ? getStatusColor(prop.status) : "white"};
  color: white;
  margin-left: 0.5rem;
`
const StyledRow = styled(Row)`
  background-color: white;
  font-size: 1rem;
  box-shadow: 3px 3px 10px lightgray;
  h3 {
    font-size: 1.1rem;
    color: ${colors.darkViolet};
  }
`

const StyledButton = styled.button`
  background-color: transparent;
  border: none;
  font-size: 0.9rem;
  color: ${colors.violet};
  padding: 0;

  &:hover {
    color: ${colors.blue};
  }
`

const Title = styled.span`
  font-weight: bold;
`

function CardOrder({ order }: { order: OtherOrderDto }) {
  const handleDownload = (id: number, fileName: string) => {
    API.get(`documents/${id}/download`, {
      responseType: "blob",
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement("a")
        link.href = url
        link.setAttribute("download", fileName)
        document.body.appendChild(link)
        link.click()
      })
      .catch((error) => {
        Toastify({
          text: "Une erreur s'est produite lors du téléchargement",
          duration: 8000,
          newWindow: true,
          close: true,
          gravity: "top", // `top` or `bottom`
          position: "right", // `left`, `center` or `right`
          stopOnFocus: true, // Prevents dismissing of toast on hover
          style: {
            background: "linear-gradient(to right, #29aac4, #bd79e7)",
          },
          onClick: function () {}, // Callback after click
        }).showToast()
      })
  }
  return (
    <StyledRow className="px-2 py-3 m-1 my-3">
      <div className="d-flex flex-wrap gap-md-1 justify-content-between">
        <div className="d-flex gap-2">
          <FontAwesomeIcon icon={faBox} color={colors.violet} />
          <h3>Commande du {formatStrToDate(order.orderDate)} : </h3>
        </div>
        <StyledSpan status={order.status} className="d-none d-md-block">
          {getOrderStatusLabel(order.status, true)}
        </StyledSpan>
      </div>
      <div className="mb-1">
        <Title>Numéro de commande :</Title> {order.number}
      </div>
      <div className="mb-1">
        <Title>Titre :</Title> {order.title}
      </div>
      <div className="mb-1">
        <Title>Détail :</Title>
        <div
          style={{
            backgroundColor: "#e9e9e9",
            borderRadius: "5px",
            padding: "0.8rem",
            fontSize: "0.9rem",
            whiteSpace: "pre-line",
          }}
          className="mt-1"
        >
          {order.employeeMessage}
        </div>
      </div>
      {order?.documents && order.documents.length > 0 && (
        <div className="d-flex flex-wrap gap-3 mt-2">
          <Title>Pièce(s)-jointe(s):</Title>
          <div className="d-flex flex-wrap gap-3">
            {order.documents.map((doc) => (
              <StyledButton
                onClick={() => handleDownload(doc.id, doc.path)}
                key={doc.id}
              >
                {cutDocTitle(doc.title)}
              </StyledButton>
            ))}
          </div>
        </div>
      )}
      <div className="d-flex flex-wrap justify-content-between mt-2">
        <div style={{ fontWeight: "bold" }}>
          Montant total : {formatPrice(order.amount)}
        </div>
        <StyledSpan status={order.status} className="d-md-none">
          {getOrderStatusLabel(order.status, true)}
        </StyledSpan>
      </div>
    </StyledRow>
  )
}

function Orders() {
  const {
    data: orders = [],
    isLoading: isOrderLoading,
    isSuccess: isOrderSuccess,
    isError: isOrderError,
  } = useGetOtherOrdersQuery()

  const pendingOrdersData = useMemo(() => {
    let datas = orders.filter(
      (order: OtherOrderDto) => order.status === OrderStatusType.ToHandle
    )

    return datas
  }, [orders])

  const pastOrdersData = useMemo(() => {
    let datas = orders.filter(
      (order: OtherOrderDto) => order.status !== OrderStatusType.ToHandle
    )
    return datas
  }, [orders])

  let pendingOrder
  let pastOrder
  if (isOrderError) {
    pendingOrder = <InternError />
    pastOrder = <InternError />
  } else if (isOrderSuccess) {
    pendingOrder = (
      <Fragment>
        {pendingOrdersData &&
          pendingOrdersData.map((order: OtherOrderDto) => (
            <CardOrder order={order} key={order.id}></CardOrder>
          ))}
        {pendingOrdersData &&
          pendingOrdersData.length === 0 &&
          "Aucune commande"}
      </Fragment>
    )

    pastOrder = (
      <Fragment>
        {pastOrdersData &&
          pastOrdersData.map((order: OtherOrderDto) => (
            <CardOrder order={order} key={order.id}></CardOrder>
          ))}
        {pastOrdersData && pastOrdersData.length === 0 && "Aucune commande"}
      </Fragment>
    )
  } else if (isOrderLoading) {
    pendingOrder = <Loader />
    pastOrder = <Loader />
  }

  return (
    <Fragment>
      <h2>Vos commandes en cours : </h2>
      <div className="container-fluid py-3 px-0 mb-3 mb-lg-5">
        <Col sm={10} lg={7}>
          {pendingOrder}
        </Col>
      </div>
      <h2>Vos commandes passées cette année : </h2>
      <div className="container-fluid py-3 px-0 mb-3 mb-lg-5">
        <Col sm={10} lg={7}>
          {pastOrder}
        </Col>
      </div>
    </Fragment>
  )
}

function Other() {
  const [showOrder, setShowOrder] = useState(false)
  return (
    <div>
      <WaveHeader
        style={{ backgroundPosition: "left, left" }}
        title="Autre"
        image={fragImage}
      />
      <ContainerStyled>
        <Breadcrumbs aria-label="breadcrumb" className="mb-4">
          <LinkStyled color="gray" to="/commandes">
            Mes commandes
          </LinkStyled>
          <Typography color="text.primary">Autre</Typography>
        </Breadcrumbs>
        <h2>Vous souhaitez profiter de nos offres sur les parfums ?</h2>
        <div className="d-flex flex-wrap flex-lg-nowrap gap-4 gap-md-5 py-3 mb-3 mb-lg-5 justify-content-center">
          <div className="align-self-center">
            <img
              src={otherImg}
              alt="Pop corn"
              className="img-fluid"
              style={{
                width: "100%",
                maxWidth: "35rem",
                borderRadius: "4rem",
                boxShadow: "4px 4px 10px grey",
              }}
            />
          </div>
          <div className="mt-md-4">
            <h3>
              Pour commander des produits issus des catalogues, c'est par ici !
            </h3>
            <br />
            <div>
              Vous pouvez consulter les produits en stock sur le site de notre
              partenaire{" "}
              <a
                href="https://www.laparfumerie.eu"
                target="_blank"
                rel="noreferrer"
                style={{
                  textDecoration: "none",
                  fontWeight: "bold",
                  color: colors.violet,
                }}
              >
                www.laparfumerie.eu
              </a>{" "}
              avec les identifiants suivants :
              <ul className="mt-1">
                <li>
                  Login :{" "}
                  <span style={{ fontWeight: "bold", color: colors.violet }}>
                    laparfumerie
                  </span>
                </li>
                <li>
                  Mot de passe :{" "}
                  <span style={{ fontWeight: "bold", color: colors.violet }}>
                    123456
                  </span>
                </li>
              </ul>
            </div>
            <div className="text-center text-md-start">
              <ButtonPrimary
                label="Commander"
                type="button"
                onClick={() => setShowOrder(true)}
              />
            </div>
          </div>
        </div>
        <Orders />
        <OtherOrderModal
          show={showOrder}
          handleClose={() => setShowOrder(false)}
        />
      </ContainerStyled>
    </div>
  )
}

export default Other
