import WaveHeader from "../../components/WaveHeader"
import bgImage from "../../assets/news.jpg"
import { Carousel, Col, Container, Row } from "react-bootstrap"
import { Link, useParams } from "react-router-dom"
import { useGetArticleQuery } from "../../features/articleSlice"
import { Loader } from "../../utils/Atoms"
import InternError from "../../components/InternError"
import { Paper } from "@mui/material"
import Article from "../../models/article"
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos"
import styled from "styled-components"
import colors from "../../utils/style/colors"
import Image from "../../models/image"
import { formatStrToDate } from "../../utils/utils"

const StyledLink = styled(Link)`
  padding: 1rem;
  text-decoration: none;
  color: ${colors.violet};
  font-size: 1.3rem;
  margin-bottom: 1rem;

  &:hover {
    color: ${colors.blue};
  }
`

const StyledCarousel = styled(Carousel)`
  background-color: #532c6a2e;
  img {
    max-height: 30rem;
    object-fit: contain;
    width: 100%;

    @media all and (min-width: 992px) {
      max-height: 32rem;
    }

    @media all and (min-width: 1600px) {
      max-height: 40rem;
    }
  }
`

const StyledDate = styled.div`
  margin-top: 2rem;
  color: grey;
  text-align: right;
  font-size: 0.9rem;
`

function NewsItemSection() {
  let { newsId } = useParams()
  const {
    data: article = {} as Article,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetArticleQuery(newsId)

  let content

  if (isLoading) {
    content = <Loader />
  } else if (isSuccess) {
    content = (
      <Col md={10} lg={8}>
        <Paper elevation={3} className="px-md-4 py-3">
          <h2 className="violet text-center fs-2 px-2 py-3">{article.title}</h2>
          <div className="px-md-2">
            <StyledCarousel
              variant="dark"
              controls={article.images && article.images.length > 1}
              indicators={article.images && article.images.length > 1}
            >
              {article.images && article.images.length > 0 ? (
                article.images.map((image: Image, index: number) => (
                  <Carousel.Item key={index}>
                    <img
                      className="d-block"
                      src={`${process.env.REACT_APP_API_ARTICLE_URL}/${image.name}`}
                      alt={image.alt}
                    />
                  </Carousel.Item>
                ))
              ) : (
                <Carousel.Item>
                  <img
                    className="d-block"
                    src={`${process.env.REACT_APP_API_ARTICLE_URL}/${article.thumbnail.name}`}
                    alt={article.thumbnail.alt}
                  />
                </Carousel.Item>
              )}
            </StyledCarousel>
          </div>
          <div className="mt-3 py-3 px-2">{article.description}</div>
          <StyledDate className="px-2">
            Mise à jour le {formatStrToDate(article.updatedAt, "PPP") ?? ""}
          </StyledDate>
        </Paper>
      </Col>
    )
  } else if (isError) {
    content = <InternError />
  }

  return (
    <div>
      <WaveHeader title="Actualité" image={bgImage} />
      <section className="mb-5">
        <Container>
          <Row>
            <StyledLink to="/news">
              <ArrowBackIosIcon fontSize="small"></ArrowBackIosIcon>Toutes les
              actualités
            </StyledLink>
          </Row>
          <Row className="justify-content-center">{content}</Row>
        </Container>
      </section>
    </div>
  )
}

export default NewsItemSection
