import { Modal } from "react-bootstrap"
import styled from "styled-components"
import colors from "../../utils/style/colors"
import ButtonPrimary from "../ButtonPrimary"
import ButtonSecondary from "../ButtonSecondary"

interface ModalProps {
  show: boolean
  handleClose: () => void
  handleConfirm: () => void
  modalMessage: string
  modalTitle?: string
  isUpdate?: boolean
  loading?: boolean
  //children?: React.ReactNode
}

const StyledModal = styled(Modal)`
  font-size: 0.9rem;
  .modal-title {
    font-size: 1rem !important;
    font-weight: 300 !important;
    padding-left: 0.5rem;

    @media (min-width: 768px) {
      font-size: 1.2rem !important;
    }
  }

  .modal-header {
    background-color: ${colors.violet};
    color: white;
  }
`

function ValidateModal(modalProps: ModalProps) {
  return (
    <>
      <StyledModal
        show={modalProps.show}
        onHide={modalProps.handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton closeVariant="white">
          <Modal.Title>{modalProps.modalTitle ?? "Confirmation"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-center text-center">
            {modalProps.modalMessage}
          </div>
          <br />
          <div className="d-flex justify-content-center gap-3 flex-wrap">
            <ButtonPrimary
              label="Confirmer"
              onClick={modalProps.handleConfirm}
              loading={modalProps.loading}
            />
            <ButtonSecondary onClick={modalProps.handleClose} label="Annuler" />
          </div>
        </Modal.Body>
      </StyledModal>
    </>
  )
}

export default ValidateModal
