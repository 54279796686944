import { AdminCardOrder } from "../models/adminCardOrder"
import { AdminOtherOrder } from "../models/adminOtherOrder"
import CardOrder from "../models/cardOrder"
import CardPrice from "../models/cardPrice"
import CardStockOrder from "../models/cardStockOrder"
import CardStockOrderPagination from "../models/cardStockOrderPagination"
import { CardOrderDto, OrderType, OtherOrderDto } from "../models/order"
import {
  CardOrderAdmin,
  OtherOrderAdmin,
  ParentOrder,
} from "../models/orderAdmin"
import OrderPagination from "../models/orderPagination"
import OtherOrder from "../models/otherOrder"
import { Paiement } from "../models/paiement"
import { apiSlice } from "./api/apiSlice"

export const extendedApiSlice: any = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCardStockOrderWithPagination: builder.query<
      CardStockOrderPagination,
      { limit: number; page: number; orderBy: string; direction: string }
    >({
      query: (param) => {
        return { url: "admin/card-stock-orders", params: param }
      },
      providesTags: (result) =>
        result && result.items
          ? [
              ...result.items.map(({ id }) => ({
                type: "CardStockOrder" as const,
                id,
              })),
              { type: "CardStockOrder", id: "LIST" },
            ]
          : [{ type: "CardStockOrder", id: "LIST" }],
    }),
    addCardOrder: builder.mutation<Partial<CardOrder>, any>({
      query: (body) => ({
        url: `card-order`,
        method: "POST",
        body,
      }),
      invalidatesTags: [
        { type: "CardOrder", id: "LIST" },
        { type: "AdminCardOrder", id: "LIST" },
      ],
    }),
    getCurrentCardsPrices: builder.query<CardPrice[], void>({
      query: () => {
        return { url: "cards-prices/current" }
      },
      providesTags: (result) => [{ type: "CardPrice" as const, id: "LIST" }],
    }),
    getFutureCardsPrices: builder.query<CardPrice[], void>({
      query: () => {
        return { url: "admin/cards-prices/future" }
      },
      providesTags: (result) => [{ type: "CardPrice" as const, id: "LIST" }],
    }),

    getCurrentStock: builder.query<any, void>({
      query: () => {
        return { url: "admin/cards-stock" }
      },
      providesTags: (result) => [{ type: "CardStock" as const, id: "LIST" }],
    }),
    addCardPrice: builder.mutation<CardPrice, any>({
      query: (body) => ({
        url: `admin/cards-prices`,
        method: "POST",
        body,
      }),
      invalidatesTags: [{ type: "CardPrice", id: "LIST" }],
    }),
    addCardStockOrder: builder.mutation<Partial<CardStockOrder>, any>({
      query: (body) => ({
        url: `admin/card-stock-orders`,
        method: "POST",
        body,
      }),
      invalidatesTags: [
        { type: "CardStockOrder", id: "LIST" },
        { type: "CardStock", id: "LIST" },
        { type: "Operation", id: "LIST" },
      ],
    }),
    getCardOrder: builder.query<CardOrderDto, { type: string }>({
      query: (param) => {
        return { url: "card-order", params: param }
      },
      providesTags: (result) => [{ type: "CardOrder" as const, id: "LIST" }],
    }),
    addOtherOrder: builder.mutation<OtherOrder, any>({
      query: (body) => ({
        url: `other-order`,
        method: "POST",
        body,
      }),
      invalidatesTags: [
        { type: "OtherOrder", id: "LIST" },
        { type: "AdminOtherOrder", id: "LIST" },
      ],
    }),
    getOtherOrders: builder.query<OtherOrderDto, any>({
      query: () => {
        return { url: "other-orders" }
      },
      providesTags: (result) => [{ type: "OtherOrder" as const, id: "LIST" }],
    }),
    getOrdersWithPagination: builder.query<
      OrderPagination,
      {
        from: string
        to: string
        limit: number
        page: number
        orderBy: string
        direction: string
        type: string
        status: string
        search: string
      }
    >({
      query: (param) => {
        return { url: "admin/orders/cards", params: param }
      },
      providesTags: (result) =>
        result && result.items
          ? [
              ...result.items.map(({ id }) => ({
                type: "AdminCardOrder" as const,
                id,
              })),
              { type: "AdminCardOrder", id: "LIST" },
            ]
          : [{ type: "AdminCardOrder", id: "LIST" }],
    }),
    getAdminOrder: builder.query<CardOrderAdmin | OtherOrderAdmin, string>({
      query: (number) => `admin/orders/${number}`,
      providesTags: (result, error, id) =>
        result && result.parentOrder?.type === OrderType.Other
          ? [{ type: "AdminOtherOrder", id: result?.id }]
          : [{ type: "AdminCardOrder", id: result?.id }],
    }),
    editCardOrder: builder.mutation<any, { id: number; body: AdminCardOrder }>({
      query: ({ id, body }) => ({
        url: `admin/orders/cards/${id}`,
        method: "POST",
        body,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "AdminCardOrder", id: "LIST" },
        { type: "CardOrder", id: "LIST" },
        { type: "AdminCardOrder", id: arg.id },
        { type: "AdminOrder", id: "LIST" },
      ],
    }),
    editOtherOrder: builder.mutation<
      any,
      { id: number; body: AdminOtherOrder }
    >({
      query: ({ id, body }) => ({
        url: `admin/orders/others/${id}`,
        method: "POST",
        body,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "AdminOtherOrder", id: "LIST" },
        { type: "OtherOrder", id: "LIST" },
        { type: "AdminOtherOrder", id: arg.id },
        { type: "AdminOrder", id: "LIST" },
      ],
    }),
    getOtherOrdersTotal: builder.mutation<any, { body: any }>({
      query: ({ body }) => ({
        url: `admin/orders-others/total`,
        method: "POST",
        body,
      }),
    }),
    getOtherOrdersWithPagination: builder.query<
      OrderPagination,
      {
        from: string
        to: string
        limit: number
        page: number
        orderBy: string
        direction: string
        status: string
        search: string
      }
    >({
      query: (param) => {
        return { url: "admin/orders/others", params: param }
      },
      providesTags: (result) =>
        result && result.items
          ? [
              ...result.items.map(({ id }) => ({
                type: "AdminOtherOrder" as const,
                id,
              })),
              { type: "AdminOtherOrder", id: "LIST" },
            ]
          : [{ type: "AdminOtherOrder", id: "LIST" }],
    }),
    getOtherOrdersByStatus: builder.query<OtherOrderAdmin[], string>({
      query: (statusStr) => {
        return { url: `admin/orders/others/status/${statusStr}` }
      },
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: "AdminOtherOrder" as const,
                id,
              })),
              { type: "AdminOtherOrder", id: "LIST" },
            ]
          : [{ type: "AdminOtherOrder", id: "LIST" }],
    }),
    getOrdersByStatus: builder.query<ParentOrder[], string>({
      query: (statusStr) => {
        return { url: `admin/orders/status/${statusStr}` }
      },
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: "AdminOrder" as const,
                id,
              })),
              { type: "AdminOrder", id: "LIST" },
            ]
          : [{ type: "AdminOrder", id: "LIST" }],
    }),
    postAdminOtherOrder: builder.mutation<
      any,
      {
        body: {
          ids: []
          adminPayment: Paiement
          detail: string
          orderDate: string
        }
      }
    >({
      query: ({ body }) => ({
        url: `admin/orders/admin-others/ordered`,
        method: "POST",
        body,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "AdminOtherOrder", id: "LIST" },
        { type: "AdminOtherOrder", id: result?.id },
        { type: "Operation", id: "LIST" },
      ],
    }),
    postReceiptAdminOtherOrder: builder.mutation<
      any,
      {
        body: {
          ids: []
          receivedAt: string
        }
      }
    >({
      query: ({ body }) => ({
        url: `admin/orders/admin-others/received`,
        method: "POST",
        body,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "AdminOtherOrder", id: "LIST" },
        { type: "AdminOtherOrder", id: result?.id },
        { type: "Operation", id: "LIST" },
      ],
    }),
    getCardStockOrder: builder.query<CardStockOrder, number>({
      query: (id) => `admin/card-stock-orders/${id}`,
      providesTags: (result, error, id) => [{ type: "CardStockOrder", id }],
    }),
    editCardStockOrder: builder.mutation<
      Document,
      { id: number; body: CardStockOrder }
    >({
      query: ({ id, body }) => ({
        url: `admin/card-stock-orders/${id}`,
        method: "POST",
        body,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "CardStockOrder", id: "LIST" },
        { type: "CardStockOrder", id: arg.id },
        { type: "CardStock", id: "LIST" },
        { type: "Operation", id: "LIST" },
      ],
    }),
  }),
})

export const {
  useGetCardStockOrderWithPaginationQuery,
  useGetCurrentCardsPricesQuery,
  useAddCardPriceMutation,
  useGetFutureCardsPricesQuery,
  useGetCurrentStockQuery,
  useAddCardOrderMutation,
  useGetCardOrderQuery,
  useAddOtherOrderMutation,
  useGetOtherOrdersQuery,
  useGetOrdersWithPaginationQuery,
  useGetAdminOrderQuery,
  useEditCardOrderMutation,
  useGetOtherOrdersWithPaginationQuery,
  useEditOtherOrderMutation,
  useGetOtherOrdersByStatusQuery,
  useGetOrdersByStatusQuery,
  useGetOtherOrdersTotalMutation,
  usePostAdminOtherOrderMutation,
  usePostReceiptAdminOtherOrderMutation,
  useAddCardStockOrderMutation,
  useGetCardStockOrderQuery,
  useEditCardStockOrderMutation,
} = extendedApiSlice
