import { Col, FloatingLabel, Form, Modal, Row } from "react-bootstrap"
import ButtonPrimary from "../ButtonPrimary"
import styled from "styled-components"
import colors from "../../utils/style/colors"
import ButtonSecondary from "../ButtonSecondary"
import { useEffect, useRef, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faBasketball,
  faMusic,
  faWarning,
} from "@fortawesome/free-solid-svg-icons"
import {
  calculateRefund,
  calculateSportRefund,
  formatPrice,
} from "../../utils/utils"
import { getRefundTypeLabel, Refund, RefundType } from "../../models/refund"
import File from "../../models/file"
import { Button } from "@mui/material"
import { ResponseApiType } from "../../models/responseApiType"
import { useAddRefundMutation } from "../../features/refundSlice"
import Toastify from "toastify-js"
import { useGetPersonalInfosQuery } from "../../features/userSlice"
import PersonalInfos from "../../models/personalInfos"
import InternError from "../InternError"
import Member, { RelationshipType } from "../../models/member"
import { API } from "../../utils/API"
import { Loader } from "../../utils/Atoms"

interface ModalProps {
  show: boolean
  handleClose: () => void
  type: RefundType
  isUpdate?: boolean
}

const StyledSpan = styled.span`
  color: grey;
  font-size: 0.8rem;
  font-style: italic;
`

const Span = styled.span`
  font-weight: bold;
  color: ${colors.darkViolet};
`

const StyledModal = styled(Modal)`
  font-size: 0.9rem;
  h2 {
    font-size: 1rem;
    font-weight: 300;
    padding-left: 0.5rem;

    @media (min-width: 768px) {
      font-size: 1.2rem;
    }
  }

  .prices span,
  .total span {
    font-weight: bold;
  }

  .modal-header {
    background-color: ${colors.violet};
    color: white;
  }
`

function RefundModal(modalProps: ModalProps) {
  const [validated, setValidated] = useState(false)
  const [refund, setRefund] = useState<Partial<Refund>>({
    type: modalProps.type,
  })
  const [total, setTotal] = useState<number | null>(0)
  const [errors, setErrors] = useState({} as any)
  const [msgError, setMsgError] = useState<string>()
  const [addRefund, { isLoading: isPostLoading }] = useAddRefundMutation()
  const [postDocuments, setPostDocuments] = useState([] as File[])
  const [documentsErrorArray, setDocumentsErrorArray] = useState([] as any[])
  const [recipients, setRecipients] = useState([] as Member[])
  const [isRecipientsSuccess, setIsRecipientsSuccess] = useState(false)
  const [isRecipientsLoading, setIsRecipientsLoading] = useState(false)
  const [isRecipientsError, setIsRecipientsError] = useState(false)

  const inputRef = useRef<any>(null)

  const handleRefundChange = ({
    target: { name, value },
  }: React.ChangeEvent<HTMLInputElement> | any) => {
    let newValue: any = value
    if (name === "price") newValue = parseFloat(value)
    if (name === "recipientId") newValue = parseInt(value)
    setRefund((prev) => ({ ...prev, [name]: newValue }))
  }

  const handleAddRefund = (event: any) => {
    const form = event.currentTarget
    event.preventDefault()
    event.stopPropagation()
    if (form.checkValidity() === false) {
      setValidated(true)
    } else {
      console.log(refund)
      addRefund(refund)
        .unwrap()
        .then(() => {
          Toastify({
            text: "Votre demande a bien été créée",
            duration: 8000,
            newWindow: true,
            close: true,
            gravity: "top", // `top` or `bottom`
            position: "right", // `left`, `center` or `right`
            stopOnFocus: true, // Prevents dismissing of toast on hover
            style: {
              background: "linear-gradient(to right, #29aac4, #bd79e7)",
            },
            onClick: function () {}, // Callback after click
          }).showToast()
          modalProps.handleClose()
        })
        .catch((error: any) => {
          if (error.data?.type === ResponseApiType.FormError) {
            setMsgError("Veuillez vérifier les données")
            setErrors(error.data?.detail)
            setValidated(true)
          } else if (error.data?.type === ResponseApiType.CustomError) {
            setMsgError(error.data?.detail)
          } else {
            setMsgError("Une erreur interne s'est produite.")
          }
        })
    }
  }

  const convertToBase64 = (file: any): Promise<string> => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader()
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result as string)
      }
      fileReader.onerror = (error) => {
        reject(error)
      }
    })
  }

  const handleDocUpload = (e: any) => {
    setPostDocuments([])
    const files = e.target.files
    Object.entries(files).forEach(
      async ([key, file]: [key: any, file: any]) => {
        await convertToBase64(file)
          .then((result) => {
            setPostDocuments((docArr) => [
              ...docArr,
              {
                data: result,
                filename: file.name,
                alt: "",
                fileBlob: file,
                id: key,
              } as File,
            ])
          })
          .catch((error) => console.error(error))
      }
    )
  }

  const removeDoc = (doc: File) => {
    const newPostDocs = postDocuments.filter(
      (filePrev: File) => filePrev.fileBlob !== doc.fileBlob
    )

    if (inputRef?.current) {
      inputRef.current.value = null
    }
    setPostDocuments(newPostDocs)
  }

  useEffect(() => {
    if (modalProps.type === RefundType.Cultural) {
      let newAmount = calculateRefund(refund?.price ?? 0, modalProps.type)
      setTotal(newAmount)
    } else {
      let url
      if (refund.recipientId) {
        url = `refunds-total/member/${refund.recipientId}`
      } else {
        url = `refunds-total`
      }
      API.get(url)
        .then((response) => {
          let newAmount = calculateSportRefund(
            refund?.price ?? 0,
            response.data
          )
          setTotal(newAmount)
        })
        .catch((error) => {
          setTotal(0)
        })
    }
  }, [refund, modalProps.type])

  useEffect(() => {
    if (modalProps.show) {
      setValidated(false)
      setPostDocuments([])
      setRefund({
        type: modalProps.type,
        recipientId: 0,
      })
      setMsgError("")
      setErrors({})
      setDocumentsErrorArray([])
      setTotal(0)

      if (modalProps.type !== RefundType.Cultural) {
        setIsRecipientsLoading(true)
        API.get(`recipients/sport`)
          .then((response) => {
            setRecipients(response.data)
            setIsRecipientsSuccess(true)
            setRefund((prev) => ({
              ...prev,
              recipientId: response.data ? response.data[0]?.value : 0,
            }))
          })
          .catch((error) => {
            setIsRecipientsSuccess(false)
            setIsRecipientsError(true)
          })
          .finally(() => setIsRecipientsLoading(false))
      }
    }
  }, [modalProps.show])

  useEffect(() => {
    let imagesErrors: any[] = []
    postDocuments.forEach((postDoc, index) => {
      let msg = errors[`documents[${index}].file`]
      if (msg) {
        imagesErrors.push(`Fichier ${index + 1}: ${msg[0]}`)
      }
    })

    if (imagesErrors.length > 0) setDocumentsErrorArray(imagesErrors)
  }, [errors])

  useEffect(() => {
    setRefund((prev: any) => ({ ...prev, documents: postDocuments }))
  }, [postDocuments])

  return (
    <>
      <StyledModal
        show={modalProps.show}
        onHide={modalProps.handleClose}
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-width p-0"
        centered
      >
        <Modal.Header closeButton closeVariant="white">
          <Modal.Title className="d-flex gap-2 pt-1 align-items-center align-items-sm-start">
            <FontAwesomeIcon
              icon={
                modalProps.type === RefundType.Cultural ? faMusic : faBasketball
              }
              color="white"
            />
            <h2>{getRefundTypeLabel(modalProps.type)}</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-4">
          <Form noValidate validated={validated} onSubmit={handleAddRefund}>
            <Row className="mb-3 ps-2">
              Veuillez renseigner les champs ci-dessous :
            </Row>
            <div className="mb-4" style={{ color: "gray" }}>
              <FontAwesomeIcon icon={faWarning} color="darkred" />
              &nbsp; Veillez bien à vérifier les informations avant de valider.
              Vous ne pourrez pas les modifer après validation.
            </div>
            {isRecipientsLoading && <Loader />}
            {isRecipientsSuccess && (
              <FloatingLabel className="mb-3" label="Bénéficiaire">
                <Form.Select
                  aria-label="Bénéficiaire"
                  style={{ minWidth: "10rem" }}
                  name="recipientId"
                  onChange={handleRefundChange}
                >
                  {recipients?.map((member: any) => (
                    <option value={member.value} key={member.value}>
                      {member.label}
                    </option>
                  ))}
                </Form.Select>
              </FloatingLabel>
            )}
            {isRecipientsError && <InternError />}
            <Form.Group className="mb-3" controlId="formTitle">
              <FloatingLabel controlId="title" label="Intitulé *">
                <Form.Control
                  type="text"
                  placeholder="Titre"
                  autoFocus
                  required
                  name="title"
                  onChange={handleRefundChange}
                  isInvalid={validated && errors.title}
                />
                <Form.Control.Feedback type="invalid">
                  Le titre est requis
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>

            <Form.Group controlId="formDocuments" className="mb-3">
              <Form.Label>
                Justificatif(s) *{" "}
                <StyledSpan>(Taille max par document : 2M)</StyledSpan>
              </Form.Label>
              <Form.Control
                type="file"
                onChange={handleDocUpload}
                multiple
                ref={inputRef}
                isInvalid={
                  validated &&
                  (documentsErrorArray.length > 0 || errors?.documents)
                }
              />
              <Form.Control.Feedback type="invalid">
                {errors?.documents && errors?.documents[0]}
                {documentsErrorArray &&
                  documentsErrorArray.map((txtError, index) => (
                    <div key={index}>{txtError}</div>
                  ))}
              </Form.Control.Feedback>
              <div className="d-flex flex-column gap-2 mt-1">
                {postDocuments &&
                  postDocuments.map((doc, index) => (
                    <div key={index}>
                      <span className="fst-italic">{doc.filename}</span>
                      <div>
                        <Button
                          onClick={() => removeDoc(doc)}
                          variant="text"
                          color="error"
                        >
                          Supprimer
                        </Button>
                      </div>
                    </div>
                  ))}
              </div>
            </Form.Group>
            <Form.Group className="mb-4" controlId="formMessage">
              <FloatingLabel controlId="message" label="Remarques">
                <Form.Control
                  placeholder="Message"
                  aria-label="Message"
                  name="employeeMsg"
                  as="textarea"
                  style={{ height: "10rem" }}
                  onChange={handleRefundChange}
                  isInvalid={validated && errors?.employeeMsg}
                />
                {errors && errors.employeeMsg && (
                  <Form.Control.Feedback
                    type="invalid"
                    style={{ display: "block" }}
                  >
                    {errors &&
                      errors.employeeMsg?.map((error: any, index: number) => (
                        <div key={index}>{error}</div>
                      ))}
                  </Form.Control.Feedback>
                )}
              </FloatingLabel>
            </Form.Group>
            <Form.Group className="mb-3 total" as={Col} controlId="formTotal">
              <FloatingLabel
                controlId="amount"
                label="Prix total de l'activité (€) *"
              >
                <Form.Control
                  placeholder="Montant total"
                  aria-label="Montant total"
                  name="price"
                  required
                  type="number"
                  step={0.01}
                  min={0}
                  //value={otherOrder?.amount ?? ""}
                  onChange={handleRefundChange}
                  isInvalid={validated && errors?.amount}
                />
                {errors && errors.amount ? (
                  <Form.Control.Feedback
                    type="invalid"
                    style={{ display: "block" }}
                  >
                    {errors &&
                      errors.amount?.map((error: any, index: number) => (
                        <div key={index}>{error}</div>
                      ))}
                  </Form.Control.Feedback>
                ) : (
                  <Form.Control.Feedback type="invalid">
                    Format incorrect
                  </Form.Control.Feedback>
                )}
              </FloatingLabel>
            </Form.Group>
            <Form.Group className="mb-3">
              <Span className="d-flex flex-wrap">
                Montant remboursé : {formatPrice(total ?? 0)}
              </Span>
            </Form.Group>
            <Form.Group className="my-3 text-danger">{msgError}</Form.Group>
            <div className="d-flex justify-content-end gap-3 flex-wrap">
              <ButtonPrimary
                label="Valider"
                type="submit"
                loading={isPostLoading}
                disabled={!((total ?? 0) > 0)}
              />
              <ButtonSecondary
                onClick={modalProps.handleClose}
                label="Annuler"
                type="button"
              />
            </div>
          </Form>
        </Modal.Body>
      </StyledModal>
    </>
  )
}

export default RefundModal
