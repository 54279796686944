import { Document } from "./document"

export enum OrderStatusType {
  ToHandle = "ToHandle",
  Ordered = "Ordered",
  PendingPayment = "PendingPayment",
  Payed = "Payed",
  Cashed = "Cashed",
  Canceled = "Canceled",
}

export function getCardOrderStatus() {
  return [
    OrderStatusType.ToHandle,
    OrderStatusType.Payed,
    OrderStatusType.Cashed,
    OrderStatusType.Canceled,
  ]
}

export function getOtherOrderStatus() {
  return [
    OrderStatusType.ToHandle,
    OrderStatusType.Ordered,
    OrderStatusType.PendingPayment,
    OrderStatusType.Payed,
    OrderStatusType.Cashed,
    OrderStatusType.Canceled,
  ]
}

export function getOrderStatusLabel(
  status: OrderStatusType | string,
  isEmployee: boolean = false
) {
  switch (status) {
    case OrderStatusType.ToHandle:
      return "À traiter"
    case OrderStatusType.Ordered:
      return "Commandée"
    case OrderStatusType.PendingPayment:
      return "En attente de paiement"
    case OrderStatusType.Payed:
      return "Payée"
    case OrderStatusType.Cashed:
      return isEmployee ? "Payée" : "Encaissée"
    case OrderStatusType.Canceled:
      return "Annulée"
    default:
      return status
  }
}

export function getStatusColor(
  status: OrderStatusType,
  isEmployee: boolean = false
) {
  switch (status) {
    case OrderStatusType.ToHandle:
      return "grey"
    case OrderStatusType.Ordered:
      return "#cfc703"
    case OrderStatusType.PendingPayment:
      return "orange"
    case OrderStatusType.Payed:
      return "green"
    case OrderStatusType.Cashed:
      return isEmployee ? "green" : "pink"
    case OrderStatusType.Canceled:
      return "darkred"
    default:
      return "white"
  }
}

export enum OrderType {
  Pool = "POOL",
  Movie = "MOVIE",
  Other = "OTHER",
}

export function getOrderTypeLabel(type: OrderType | string) {
  switch (type) {
    case OrderType.Pool:
      return "Piscine"
    case OrderType.Movie:
      return "Cinéma"
    case OrderType.Other:
      return "Autre"
    default:
      return type
  }
}

export interface Order {
  orderDate: string
  number: string
  status: OrderStatusType
  note?: string
  createdAt: string
  updatedAt?: string
  amount: number
  type: OrderType
  paidAt?: string
  cashedAt?: string
  canceledAt?: string
  title: string
}

export interface CardOrderDto extends Order {
  id: number
  adultQuantity: number
  childQuantity: number
}

export interface OtherOrderDto extends Order {
  id: number
  employeeMessage: string
  orderedAt?: string
  receivedAt?: string
  documents?: Document[]
}
