import WaveHeader from "../../../components/WaveHeader"
import poolImage from "../../../assets/piscine.jpg"
import {
  useGetCardOrderQuery,
  useGetCurrentCardsPricesQuery,
} from "../../../features/orderSlice"
import { Fragment, useMemo, useState } from "react"
import CardPrice from "../../../models/cardPrice"
import { StockOrderType } from "../../../models/cardStockOrder"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import ButtonPrimary from "../../../components/ButtonPrimary"
import {
  faCircleInfo,
  faFaceFrown,
  faWarning,
} from "@fortawesome/free-solid-svg-icons"
import { Loader } from "../../../utils/Atoms"
import { Col, Container, Row } from "react-bootstrap"
import { formatPrice } from "../../../utils/utils"
import InternError from "../../../components/InternError"
import { Breadcrumbs, Typography } from "@mui/material"
import styled from "styled-components"
import colors from "../../../utils/style/colors"
import { Link } from "react-router-dom"
import CardModal from "../../../components/CardModal"
import swimImg from "../../../assets/pool.jpg"
import { CardOrderDto, OrderStatusType, OrderType } from "../../../models/order"
import CardOrder from "../../../components/CardOrder"

const ContainerStyled = styled(Container)`
  h2 {
    font-size: 1.5rem;
    color: ${colors.violet};
  }
  h3 {
    font-size: 1.3rem;
    color: ${colors.darkViolet};
  }
`

const LinkStyled = styled(Link)`
  text-decoration: none;
  color: #929292;
  &:hover {
    text-decoration: underline;
    color: ${colors.violet};
  }
`

function Orders({ type }: { type: OrderType }) {
  const {
    data: orders = [],
    isLoading: isOrderLoading,
    isSuccess: isOrderSuccess,
    isError: isOrderError,
  } = useGetCardOrderQuery({ type: type })

  const pendingOrdersData = useMemo(() => {
    let datas = orders.filter(
      (cardOrder: CardOrderDto) => cardOrder.status === OrderStatusType.ToHandle
    )

    return datas
  }, [orders])

  const pastOrdersData = useMemo(() => {
    let datas = orders.filter(
      (cardOrder: CardOrderDto) => cardOrder.status !== OrderStatusType.ToHandle
    )

    return datas
  }, [orders])

  let pendingOrder
  let pastOrder
  if (isOrderError) {
    pendingOrder = <InternError />
    pastOrder = <InternError />
  } else if (isOrderSuccess) {
    pendingOrder = (
      <Fragment>
        {pendingOrdersData &&
          pendingOrdersData.map((order: CardOrderDto) => (
            <CardOrder order={order} key={order.id}></CardOrder>
          ))}
        {pendingOrdersData &&
          pendingOrdersData.length === 0 &&
          "Aucune commande"}
      </Fragment>
    )

    pastOrder = (
      <Fragment>
        {pastOrdersData &&
          pastOrdersData.map((order: CardOrderDto) => (
            <CardOrder order={order} key={order.id}></CardOrder>
          ))}
        {pastOrdersData && pastOrdersData.length === 0 && "Aucune commande"}
      </Fragment>
    )
  } else if (isOrderLoading) {
    pendingOrder = <Loader />
    pastOrder = <Loader />
  }

  return (
    <Fragment>
      <h2>Vos commandes en cours : </h2>
      <div className="container-fluid py-3 mb-3 mb-lg-5">
        <Col sm={10} lg={7}>
          {pendingOrder}
        </Col>
      </div>
      <h2>Vos commandes passées cette année : </h2>
      <div className="container-fluid py-3 mb-3 mb-lg-5">
        <Col sm={10} lg={7}>
          {pastOrder}
        </Col>
      </div>
    </Fragment>
  )
}

function Pool() {
  const {
    data: currentCardsPrices = [],
    isLoading: isCardPriceLoading,
    isSuccess: isCardPriceSuccess,
  } = useGetCurrentCardsPricesQuery()

  const [showOrderCard, setShowOrderCard] = useState(false)

  const cardsPrices = useMemo(() => {
    let cards: any = {}

    if (currentCardsPrices) {
      cards = currentCardsPrices.find(
        (cardPrice: CardPrice) => cardPrice.type === StockOrderType.Pool
      )
    }
    return cards
  }, [currentCardsPrices])

  let poolPriceContent = isCardPriceLoading ? (
    <Loader />
  ) : isCardPriceSuccess ? (
    <div className="my-2">
      <Row>
        <Col xs={"auto"}>Prix d'une carte :</Col>
        <Col style={{ fontWeight: "600" }}>
          {formatPrice(cardsPrices?.adultPrice)}
        </Col>
      </Row>
      {/* <Row>
        <Col xs={"auto"}>Prix d'une carte enfant :</Col>
        <Col style={{ fontWeight: "600" }}>
          {formatPrice(cardsPrices?.childPrice)}
        </Col>
      </Row> */}
      <Row className="mt-3">
        <Col xs={"auto"}>
          <FontAwesomeIcon icon={faCircleInfo} color={colors.violet} />
          &nbsp; Une carte de piscine comprend 10 places valides 1 an après la
          première utilisation de la carte. Lorsque celle-ci est vide, merci de
          bien vouloir nous la restituer.
        </Col>
      </Row>
      <Row className="mt-3">
        <Col xs={"auto"}>
          <FontAwesomeIcon icon={faWarning} color="darkred" />
          &nbsp; Merci de vous présenter muni de votre moyen de paiement pour
          réceptionner vos cartes. Dans le cas contraire, celles-ci ne pourront
          pas vous être délivrées.
        </Col>
      </Row>
      <Row className="mt-3">
        <Col xs={"auto"}>
          <FontAwesomeIcon icon={faWarning} color="darkred" />
          &nbsp; En cas de dépassement de la validité, les cartes ne sont ni
          reprises, ni échangées, ni prorogées.
        </Col>
      </Row>
    </div>
  ) : (
    <InternError />
  )
  return (
    <div>
      <WaveHeader title="Cartes de piscine" image={poolImage} />
      <ContainerStyled>
        <Breadcrumbs aria-label="breadcrumb" className="mb-4">
          <LinkStyled color="gray" to="/commandes">
            Mes commandes
          </LinkStyled>
          <Typography color="text.primary">Cartes de piscine</Typography>
        </Breadcrumbs>
        <h2>Envie de piquer une tête ? </h2>
        <div className="d-flex flex-wrap flex-lg-nowrap gap-4 gap-md-5 py-3 mb-3 mb-lg-5">
          <div>
            <img
              src={swimImg}
              alt="Un homme qui nage"
              className="img-fluid"
              style={{
                borderRadius: "4rem",
                boxShadow: "4px 4px 10px grey",
              }}
            />
          </div>
          <div className="mt-md-4">
            <h3>Profitez des tarifs réduits du CSE ! </h3>
            {isCardPriceSuccess && !cardsPrices && (
              <div style={{ color: "darkred" }}>
                <FontAwesomeIcon icon={faFaceFrown} color="darkred" /> Aucun
                prix n'a été paramétré pour le moment... Veuillez nous contacter
                si le problème persiste.
              </div>
            )}
            {poolPriceContent}
            <br />
            <div className="text-center text-md-start">
              <ButtonPrimary
                label="Commander des cartes"
                type="button"
                disabled={isCardPriceSuccess && !cardsPrices}
                onClick={() => setShowOrderCard(true)}
              />
            </div>
          </div>
        </div>
        <Orders type={OrderType.Pool} />
        <CardModal
          type={StockOrderType.Pool}
          show={showOrderCard}
          handleClose={() => setShowOrderCard(false)}
        />
      </ContainerStyled>
    </div>
  )
}

export default Pool
