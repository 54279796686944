import styled from "styled-components"
import waveImg from "../../assets/vagues-bg-under.svg"
import logo from "../../assets/logo.svg"
import shapesImg from "../../assets/shapes.png"
import colors from "../../utils/style/colors"
import { Link } from "react-router-dom"
import { ChevronRight } from "react-bootstrap-icons"
import { Row } from "react-bootstrap"

const StyledFooter = styled.footer`
  background-color: ${colors.darkViolet};
  background-size: cover;
  background-position: top center;
  color: white;
  position: absolute;
  bottom: 0;
  gap: 3rem;
  padding: 7rem 1rem 1rem 1rem;

  &::before {
    background-image: url(${waveImg});
    background-size: 100% 100%;
    background-position: center;
    top: -2px;
    content: "";
    height: 5rem;
    left: 0;
    position: absolute;
    width: 100%;
  }
`

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 1rem 1.5rem;

  span {
    text-decoration: underline;
    font-size: 1.1rem;
  }
`

const StyledLink = styled(Link)`
  text-decoration: none;
  color: white;
  padding: 0.2rem;

  &:hover {
    color: ${colors.blue};
  }
`

const StyledRealLink = styled.a`
  text-decoration: none;
  color: white;
  padding: 0.2rem;

  &:hover {
    color: ${colors.blue};
  }
`
const StyledLogo = styled.img`
  width: 5rem;
  object-fit: contain;

  @media (min-width: 992px) {
    width: 10rem;
  }
`
function Footer() {
  return (
    <StyledFooter className="container-fluid">
      <Row className="text-center">
        <div className="col-md-3 pb-2">
          <div className="d-flex justify-content-center justify-content-md-start">
            <StyledLogo src={shapesImg} alt="" />
            <img src={logo} alt="Logo TAC" style={{ width: "10rem" }} />
          </div>
        </div>
        <StyledDiv className="col-md-3 offset-md-1">
          <span>LE CSE & vous</span>
          <StyledLink to="/presentation">
            <ChevronRight></ChevronRight>&nbsp;Découvrir le CSE
          </StyledLink>
          <StyledLink to="/news">
            <ChevronRight></ChevronRight>&nbsp;Actualités
          </StyledLink>
          <StyledLink to="/documents">
            <ChevronRight></ChevronRight>&nbsp;Documents
          </StyledLink>
          <StyledLink to="/contact">
            <ChevronRight></ChevronRight>&nbsp;Nous contacter
          </StyledLink>
        </StyledDiv>
        <StyledDiv className="col-md-4">
          <span>Mon profil</span>
          <StyledLink to="/commandes">
            <ChevronRight></ChevronRight>&nbsp;Mes commandes
          </StyledLink>
          <StyledLink to="/remboursements">
            <ChevronRight></ChevronRight>&nbsp;Mes demandes de remboursement
          </StyledLink>
          <StyledLink to="/infos-personnelles">
            <ChevronRight></ChevronRight>&nbsp;Mes informations personnelles
          </StyledLink>
        </StyledDiv>
      </Row>
      <hr />
      <Row className="text-center pt-2">
        <div>
          <StyledLink to="/mentions-legales">Mentions Légales</StyledLink> -{" "}
          <StyledLink to="/politique-confidentialite">
            Politique de confidentialité
          </StyledLink>
        </div>
      </Row>
      <Row className="text-center">
        <div>
          Réalisé par{" "}
          <StyledRealLink
            target="_blank"
            href="https://comsea.fr"
            rel="noreferrer"
          >
            COMSEA
          </StyledRealLink>
        </div>
      </Row>
    </StyledFooter>
  )
}

export default Footer
