import { Col, FloatingLabel, Form, Modal, Row } from "react-bootstrap"
import frLocale from "date-fns/locale/fr"
import ButtonPrimary from "../ButtonPrimary"
import styled from "styled-components"
import colors from "../../utils/style/colors"
import ButtonSecondary from "../ButtonSecondary"
import { TextField } from "@mui/material"
import { useEffect, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faFilm,
  faInfoCircle,
  faPersonSwimming,
} from "@fortawesome/free-solid-svg-icons"
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { addDays, format } from "date-fns"
import CardPrice from "../../models/cardPrice"
import { StockOrderType } from "../../models/cardStockOrder"
import { useAddCardPriceMutation } from "../../features/orderSlice"

interface ModalProps {
  show: boolean
  handleClose: () => void
  isUpdate?: boolean
  type: StockOrderType
}

const StyledModal = styled(Modal)`
  font-size: 0.9rem;
  h2 {
    font-size: 1.3rem;
    font-weight: 500;
    color: ${colors.darkViolet};
  }
  .modal-title {
    font-size: 1rem !important;
    font-weight: 300 !important;
    padding-left: 0.5rem;

    @media (min-width: 768px) {
      font-size: 1.2rem !important;
    }
  }

  .modal-header {
    background-color: ${colors.violet};
    color: white;
  }
`

function CardPriceModal(modalProps: ModalProps) {
  const initCardPrice: Partial<CardPrice> = {
    type: modalProps.type,
  }

  const [validated, setValidated] = useState(false)
  const [value, setValue] = useState<Date | null>()
  const [cardPrice, setCardPrice] = useState(initCardPrice)
  const [errors, setErrors] = useState({} as any)
  const [msgError, setMsgError] = useState<string>()

  const [addCardPrice, { isLoading: isPostLoading }] = useAddCardPriceMutation()

  const handleCardPriceChange = ({
    target: { name, value },
  }: React.ChangeEvent<HTMLInputElement>) =>
    setCardPrice((prev) => ({ ...prev, [name]: value }))

  const handleAddOrEditCardPrice = (event: any) => {
    const form = event.currentTarget
    event.preventDefault()
    event.stopPropagation()
    if (form.checkValidity() === false) {
      setValidated(true)
    } else {
      let newCardPrice = {
        adultCostPrice: +(cardPrice.adultCostPrice ?? 0),
        childCostPrice: +(cardPrice.childCostPrice ?? 0),
        adultPrice: +(cardPrice.adultPrice ?? 0),
        childPrice: +(cardPrice.childPrice ?? 0),
        type: modalProps.type,
        applyFrom: value ? format(value, "yyyy-MM-dd HH:mm:ss") : null,
      } as Partial<CardPrice>
      if (modalProps.isUpdate) {
      } else {
        addCardPrice(newCardPrice)
          .unwrap()
          .then(() => modalProps.handleClose())
          .catch((error: any) => {
            setMsgError("Veuillez vérifier les données")
            setValidated(true)
            setErrors(error.data?.detail)

            console.error(errors)
          })
      }
    }
  }

  useEffect(() => {
    let tomorrow = addDays(new Date(), 1)
    tomorrow.setHours(0)
    tomorrow.setMinutes(0)
    tomorrow.setSeconds(0)
    setValue(tomorrow)
    setValidated(false)
  }, [modalProps.show])

  return (
    <>
      <StyledModal
        show={modalProps.show}
        onHide={modalProps.handleClose}
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-width"
      >
        <Modal.Header closeButton closeVariant="white">
          <Modal.Title>
            {modalProps.isUpdate ? "Modifier un tarif" : "Ajouter un tarif"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-4">
          <Form
            noValidate
            validated={validated}
            onSubmit={handleAddOrEditCardPrice}
          >
            <Form.Group className="mb-4">
              <Row className="mb-2">
                {modalProps.type === StockOrderType.Pool ? (
                  <h2>
                    <FontAwesomeIcon
                      icon={faPersonSwimming}
                      color={colors.blue}
                    />
                    &nbsp;&nbsp;Cartes de piscine
                  </h2>
                ) : (
                  <h2>
                    <FontAwesomeIcon icon={faFilm} color="#920000" />
                    &nbsp;&nbsp;Places de cinéma
                  </h2>
                )}
              </Row>
              <div className="mb-4">
                <FontAwesomeIcon icon={faInfoCircle} color="cornflowerblue" />{" "}
                <span className="fst-italic" style={{ color: "grey" }}>
                  Si vous avez déjà défini des prix à une date ultérieure à
                  celle d'aujourd'hui, ceux-ci seront remplacés par ceux que
                  vous allez définir ci-dessous. Les commandes passées à partir
                  de la date que vous aurez indiquée seront automatiquement
                  calculées avec les nouveaux prix.
                </span>
              </div>
              <Row className="mb-3">
                <Form.Group as={Col} controlId="formAdultCostPrice">
                  <FloatingLabel
                    controlId="floatingAdultCostPrice"
                    label="Prix coûtant Adulte (€)"
                  >
                    <Form.Control
                      placeholder="Prix coûtant Adulte"
                      aria-label="Prix coûtant Adulte"
                      name="adultCostPrice"
                      required
                      type="number"
                      step={0.01}
                      min={1}
                      onChange={handleCardPriceChange}
                      isInvalid={validated && errors.adultCostPrice}
                    />
                  </FloatingLabel>
                  {errors && errors.adultCostPrice && (
                    <Form.Control.Feedback
                      type="invalid"
                      style={{ display: "block" }}
                    >
                      {errors &&
                        errors.adultCostPrice?.map(
                          (error: any, index: number) => (
                            <div key={index}>{error}</div>
                          )
                        )}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
                <Form.Group as={Col} controlId="formChildCostPrice">
                  <FloatingLabel
                    controlId="floatingChildCostPrice"
                    label="Prix coûtant Enfant (€)"
                  >
                    <Form.Control
                      placeholder="Prix coûtant Enfant"
                      aria-label="Prix coûtant Enfant"
                      name="childCostPrice"
                      disabled={modalProps.type === StockOrderType.Pool}
                      required={modalProps.type === StockOrderType.Movie}
                      type="number"
                      step={0.01}
                      min={
                        modalProps.type === StockOrderType.Movie ? 1 : undefined
                      }
                      onChange={handleCardPriceChange}
                      isInvalid={validated && errors.childCostPrice?.length > 0}
                    />
                  </FloatingLabel>
                  {errors && errors.childCostPrice && (
                    <Form.Control.Feedback
                      type="invalid"
                      style={{ display: "block" }}
                    >
                      {errors &&
                        errors.childCostPrice?.map(
                          (error: any, index: number) => (
                            <div key={index}>{error}</div>
                          )
                        )}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} controlId="formAdultPrice">
                  <FloatingLabel
                    controlId="floatingAdultPrice"
                    label="Prix Adulte (€)"
                  >
                    <Form.Control
                      placeholder="Prix Adulte"
                      aria-label="Prix Adulte"
                      name="adultPrice"
                      required
                      type="number"
                      step={0.01}
                      min={1}
                      onChange={handleCardPriceChange}
                      isInvalid={validated && errors.adultPrice?.length > 0}
                    />
                  </FloatingLabel>
                  {errors && errors.adultPrice && (
                    <Form.Control.Feedback
                      type="invalid"
                      style={{ display: "block" }}
                    >
                      {errors &&
                        errors.adultPrice?.map((error: any, index: number) => (
                          <div key={index}>{error}</div>
                        ))}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
                <Form.Group as={Col} controlId="formChildPrice">
                  <FloatingLabel
                    controlId="floatingChildPrice"
                    label="Prix Enfant (€)"
                  >
                    <Form.Control
                      placeholder="Prix Enfant"
                      aria-label="Prix Enfant"
                      name="childPrice"
                      disabled={modalProps.type === StockOrderType.Pool}
                      required={modalProps.type === StockOrderType.Movie}
                      min={
                        modalProps.type === StockOrderType.Movie ? 1 : undefined
                      }
                      type="number"
                      step={0.01}
                      onChange={handleCardPriceChange}
                      isInvalid={validated && errors.childPrice}
                    />
                  </FloatingLabel>
                  {errors && errors.childPrice && (
                    <Form.Control.Feedback
                      type="invalid"
                      style={{ display: "block" }}
                    >
                      {errors &&
                        errors.childPrice?.map((error: any, index: number) => (
                          <div key={index}>{error}</div>
                        ))}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Row>
            </Form.Group>
            <Form.Group>
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={frLocale}
              >
                <DatePicker
                  label="Appliqué à partir du"
                  value={value}
                  onChange={(newValue) => {
                    setValue(newValue)
                  }}
                  className="datePicker"
                  minDate={addDays(new Date(), 1)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: "jj/mm/aaaa",
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
              {errors && errors.applyFrom && (
                <Form.Control.Feedback
                  type="invalid"
                  style={{ display: "block" }}
                >
                  {errors &&
                    errors.applyFrom?.map((error: any, index: number) => (
                      <div key={index}>{error}</div>
                    ))}
                </Form.Control.Feedback>
              )}
            </Form.Group>

            <Form.Group className="my-3 text-danger">{msgError}</Form.Group>
            <div className="d-flex justify-content-end gap-3 flex-wrap">
              <ButtonPrimary label="sauvegarder" type="submit" />
              <ButtonSecondary
                onClick={modalProps.handleClose}
                label="Annuler"
              />
            </div>
          </Form>
        </Modal.Body>
      </StyledModal>
    </>
  )
}

export default CardPriceModal
