import { createGlobalStyle } from "styled-components"

const StyledGlobalStyle = createGlobalStyle`

    body {
        font-family: 'Roboto', Helvetica, sans-serif;
        font-size: 100%;
        background-color: #F7F7F7;
    }

    nav {
        z-index:100
    }
`

function GlobalStyle() {
  return <StyledGlobalStyle />
}

export default GlobalStyle
