import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Spinner } from "react-bootstrap"
import styled from "styled-components"
import colors from "../../utils/style/colors"

interface ButtonProps {
  label?: string
  className?: string
  type?: "button" | "submit" | "reset" | undefined
  onClick?: (e: any | null) => void
  icon?: IconProp
  position?: "left" | "right"
  loading?: boolean
  disabled?: boolean
  style?: any
  size?: "small" | "normal"
}

const StyledButton = styled.button<ButtonProps>`
  box-shadow: 0px 3px 10px #0000001c;
  border-radius: 22px;
  background: linear-gradient(
    91deg,
    #58b4e5 0%,
    #5687ce 23%,
    #9060b1 64%,
    #ab75d0 100%
  );
  color: white;
  text-transform: uppercase;
  border: 1px solid white;
  width: auto;
  transition: border 0.5s ease;
  font-size: ${(buttonProps: ButtonProps) =>
    buttonProps.size === "small" ? "0.8rem" : `0.9rem`};

  padding: ${(buttonProps: ButtonProps) =>
    buttonProps.label
      ? buttonProps.size === "small"
        ? "0.2rem 1.5rem"
        : "0.3rem 1.8rem"
      : buttonProps.size === "small"
      ? "0.4rem 0.8rem"
      : `0.5rem 1rem`};

  border-radius: ${(buttonProps: ButtonProps) =>
    buttonProps.label ? "22px" : `16px`};

  &:hover {
    border: 1px ${colors.violet} solid;
  }

  &:disabled {
    opacity: 0.5 !important;
  }
`

function ButtonPrimary(buttonProps: ButtonProps) {
  return (
    <StyledButton
      className={buttonProps.className}
      type={buttonProps.type ?? "button"}
      onClick={buttonProps.onClick}
      disabled={buttonProps.loading || buttonProps.disabled}
      style={buttonProps.style}
      size={buttonProps.size}
    >
      {buttonProps.loading && (
        <Spinner animation="border" size="sm" className="me-1"></Spinner>
      )}
      {!buttonProps.loading &&
        buttonProps.icon &&
        (buttonProps.position === "left" || !buttonProps.position) && (
          <FontAwesomeIcon
            icon={buttonProps.icon}
            size={buttonProps.size === "small" ? "sm" : "lg"}
            className={buttonProps.label && "pe-2"}
          ></FontAwesomeIcon>
        )}
      {buttonProps.label}
      {!buttonProps.loading &&
        buttonProps.icon &&
        buttonProps.position === "right" && (
          <FontAwesomeIcon
            icon={buttonProps.icon}
            size={buttonProps.size === "small" ? "sm" : "lg"}
            className={buttonProps.label && "pe-2"}
          ></FontAwesomeIcon>
        )}
    </StyledButton>
  )
}

export default ButtonPrimary
