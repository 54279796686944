import {
  Divider,
  FormControl,
  IconButton,
  InputBase,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material"
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { Row } from "react-bootstrap"
import styled from "styled-components"
import colors from "../../utils/style/colors"
import frLocale from "date-fns/locale/fr"
import { useEffect, useState } from "react"
import SearchIcon from "@mui/icons-material/Search"
import DataTable from "react-data-table-component"
import { Loader } from "../../utils/Atoms"
import Toastify from "toastify-js"

import {
  formatPrice,
  formatStrToDate,
  getFirstDayOfCurrentYear,
  getLastDayOfCurrentYear,
} from "../../utils/utils"
import {
  getOrderStatusLabel,
  getOtherOrderStatus,
  getStatusColor,
  OrderStatusType,
} from "../../models/order"
import { useGetOtherOrdersWithPaginationQuery } from "../../features/orderSlice"
import { format } from "date-fns"
import InternError from "../InternError"
import { useNavigate } from "react-router-dom"
import { OtherOrderAdmin } from "../../models/orderAdmin"
import ButtonPrimary from "../ButtonPrimary"
import OrderedModal from "../OrderedModal"
import OrdersReceivedModal from "../OrdersReceivedModal"
import { API } from "../../utils/API"
import AdminCSEOtherOrder from "../AdminCSEOtherOrder"

const StyledRow = styled(Row)`
  font-size: 1rem;
  h3 {
    font-size: 1.1rem !important;
    color: ${colors.darkViolet};
    margin-bottom: 0 !important ;
  }
`

const StyledSpan = styled.span<{ status: OrderStatusType }>`
  font-size: 0.8rem;
  padding: 0.2rem 0.5rem;
  border-radius: 2rem;
  align-self: center;
  text-align: center;
  white-space: nowrap;
  background-color: ${(prop) =>
    prop.status ? getStatusColor(prop.status) : "white"};
  color: white;
  /*margin-left: 0.5rem;*/
`

function AdminOtherOrder() {
  const [from, setFrom] = useState<Date | null>(getFirstDayOfCurrentYear())
  const [showOrderedModal, setShowOrderedModal] = useState(false)
  const [showReceivedModal, setShowReceivedModal] = useState(false)
  const [to, setTo] = useState<Date | null>(getLastDayOfCurrentYear())
  const [fromDate, setFromDate] = useState<string | null>()
  const [toDate, setToDate] = useState<string | null>()
  const [page, setPage] = useState(1)
  const [status, setStatus] = useState<string[]>([])
  const [perPage, setPerPage] = useState(10)
  const [searchTerm, setSearchTerm] = useState("")
  const [skip, setSkip] = useState(false)
  const [order, setOrder] = useState({
    orderBy: "orderDate",
    direction: "DESC",
  })

  const navigate = useNavigate()

  const paginationComponentOptions = {
    rowsPerPageText: "Commandes par page",
    rangeSeparatorText: "de",
  }

  const columns = [
    {
      name: "Date commande",
      selector: (row: OtherOrderAdmin) => row.parentOrder?.orderDate,
      sortable: true,
      sortField: "orderDate",
      format: (row: OtherOrderAdmin) =>
        formatStrToDate(row.parentOrder?.orderDate, "dd/MM/yyyy HH:mm"),
    },
    {
      name: "Numéro",
      selector: (row: OtherOrderAdmin) => row.parentOrder?.number,
    },
    {
      name: "Salarié",
      selector: (row: OtherOrderAdmin) => row.parentOrder?.owner,
      format: (row: OtherOrderAdmin) => {
        if (
          !row.parentOrder?.owner.firstName &&
          !row.parentOrder?.owner.lastname
        )
          return row.parentOrder?.owner.email
        else
          return `${row.parentOrder?.owner.firstName} ${row.parentOrder?.owner.lastname}`
      },
    },
    {
      name: "Libellé",
      selector: (row: OtherOrderAdmin) => row.parentOrder?.title,
    },
    {
      name: "Montant",
      selector: (row: OtherOrderAdmin) => row.parentOrder?.amount,
      format: (row: OtherOrderAdmin) => formatPrice(row.parentOrder?.amount),
    },
    {
      name: "Montant payé",
      selector: (row: OtherOrderAdmin) => row.parentOrder?.payment?.amount,
      format: (row: OtherOrderAdmin) =>
        formatPrice(row.parentOrder?.payment?.amount),
    },
    {
      name: "Statut",
      selector: (row: OtherOrderAdmin) => row.parentOrder?.status,
      minWidth: "12rem",
      cell: (row: OtherOrderAdmin) => (
        <StyledSpan status={row.parentOrder?.status}>
          {getOrderStatusLabel(row.parentOrder?.status)}
        </StyledSpan>
      ),
    },
  ] as any

  const {
    data: ordersPaginate = [],
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetOtherOrdersWithPaginationQuery(
    {
      limit: perPage,
      page: page,
      orderBy: order.orderBy,
      direction: order.direction,
      status: status?.join("-"),
      from: fromDate,
      to: toDate,
      search: searchTerm,
    },
    { skip: !fromDate || !toDate || skip }
  )

  const handlePageChange = (page: number) => {
    setPage(page)
  }

  const handlePerRowsChange = (newPerPage: number) => {
    setPerPage(newPerPage)
  }

  const handleSort = (selectedColumn: any, sortDirection: any) => {
    setOrder({
      orderBy: selectedColumn.sortField,
      direction: sortDirection,
    })
  }

  const handleRowClicked = (orderNumber: string) => {
    //Go to order page
    navigate(`/admin/orders/${orderNumber}`)
  }

  useEffect(() => {
    try {
      let date1 = from ? format(from, "yyyy-MM-dd HH:mm:ss") : null
      let date2 = to ? format(to, "yyyy-MM-dd HH:mm:ss") : null
      setFromDate(date1)
      setToDate(date2)
    } catch (error) {
      console.error(error)
    }
  }, [from, to])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setSkip(false)
    }, 1000)

    return () => clearTimeout(delayDebounceFn)
  }, [searchTerm])

  const handleStatusChange = (event: SelectChangeEvent<typeof status>) => {
    const {
      target: { value },
    } = event

    let newValue = typeof value === "string" ? value.split(",") : value
    console.log("before", newValue)
    if (newValue.includes("") && newValue[newValue.length - 1] === "") {
      newValue = [""]
    } else {
      //remove "All filter if another selected"
      newValue = newValue.filter((str) => {
        return str !== ""
      })
    }
    console.log(newValue)

    setStatus(
      // On autofill we get a stringified value.
      newValue
    )
  }
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={frLocale}>
      <StyledRow className="py-3 px-0 mx-0 my-3">
        <div className="d-flex flex-wrap gap-5 justify-content-center">
          <DatePicker
            label="Du"
            value={from}
            onChange={(newValue) => {
              setFrom(newValue)
            }}
            className="datePicker"
            renderInput={(params) => (
              <TextField
                {...params}
                inputProps={{
                  ...params.inputProps,
                  placeholder: "jj/mm/aaaa",
                }}
              />
            )}
          />
          <DatePicker
            label="au"
            value={to}
            onChange={(newValue) => {
              setTo(newValue)
            }}
            className="datePicker"
            minDate={from}
            renderInput={(params) => (
              <TextField
                {...params}
                inputProps={{
                  ...params.inputProps,
                  placeholder: "jj/mm/aaaa",
                }}
              />
            )}
          />
        </div>
        <div className="mt-4 mb-3 d-flex flex-wrap gap-1 ">
          <FormControl sx={{ m: 1, minWidth: 150 }} size="small">
            <InputLabel id="label-select-status">Statut</InputLabel>
            <Select
              labelId="label-select-status"
              id="select-status"
              value={status}
              label="Statut"
              multiple
              onChange={handleStatusChange}
            >
              <MenuItem value="">
                <em>Tous</em>
              </MenuItem>
              {getOtherOrderStatus().map((key, index) => (
                <MenuItem key={index} value={key}>
                  {getOrderStatusLabel(key)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <ButtonPrimary
            style={{
              padding: "0.5rem 0.8rem",
              borderRadius: "8px",
              alignSelf: "center",
              background: "#cfc703",
            }}
            label="Indiquer une commande"
            onClick={() => setShowOrderedModal(true)}
          />
          <ButtonPrimary
            style={{
              padding: "0.5rem 0.8rem",
              borderRadius: "8px",
              alignSelf: "center",
              background: "orange",
            }}
            label="Indiquer une réception"
            onClick={() => setShowReceivedModal(true)}
          />

          <Paper
            component="form"
            sx={{
              p: "2px 4px",
              display: "flex",
              alignItems: "center",
              width: 400,
            }}
            className="ms-auto"
            onSubmit={(e: any) => {
              e.preventDefault()
              setSkip(false)
            }}
          >
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Rechercher"
              inputProps={{ "aria-label": "rechercher" }}
              onChange={(e) => {
                setSearchTerm(e.target.value)
                setSkip(true)
              }}
            />
            <IconButton
              type="submit"
              sx={{ p: "10px" }}
              aria-label="recherche"
              onClick={() => setSkip(false)}
            >
              <SearchIcon />
            </IconButton>
          </Paper>
        </div>
        {isSuccess && (
          <DataTable
            columns={columns}
            data={ordersPaginate.items}
            progressPending={isLoading}
            pagination
            striped
            paginationServer
            paginationTotalRows={ordersPaginate.totalItems}
            onChangeRowsPerPage={handlePerRowsChange}
            paginationPerPage={perPage}
            onChangePage={handlePageChange}
            onRowClicked={(row: OtherOrderAdmin) =>
              handleRowClicked(row.parentOrder?.number)
            }
            onSort={handleSort}
            sortServer
            defaultSortFieldId={1}
            defaultSortAsc={false}
            responsive
            highlightOnHover
            pointerOnHover
            noDataComponent="Aucune commande"
            progressComponent={<Loader></Loader>}
            paginationComponentOptions={paginationComponentOptions}
          ></DataTable>
        )}
        {isError && <InternError />}
      </StyledRow>
      <Divider />
      <AdminCSEOtherOrder />
      <OrderedModal
        show={showOrderedModal}
        handleClose={() => setShowOrderedModal(false)}
      />
      <OrdersReceivedModal
        show={showReceivedModal}
        handleClose={() => setShowReceivedModal(false)}
      />
    </LocalizationProvider>
  )
}

export default AdminOtherOrder
