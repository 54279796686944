import { useLocation } from "react-router-dom"
import { FullScreenLoader } from "../../components/Loader"
import { useAuth } from "../hooks/useAuth"
import { Navigate } from "../Navigate"

export function PrivateRoute({ children }: { children: JSX.Element }) {
  const {
    loggedUser: { token, user, isLoading, isError },
  } = useAuth()
  const location = useLocation()

  if (isLoading) {
    return <FullScreenLoader />
  } else if (!isError && token /*&& user?.email*/) {
    return children
  } else {
    return <Navigate to="/login" replace state={{ from: location }} />
  }
}
