// Import the RTK Query methods from the React-specific entry point
import {
  isRejectedWithValue,
  Middleware,
  MiddlewareAPI,
} from "@reduxjs/toolkit"
import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react"
import User from "../../models/user"
import { RootState } from "../../utils/store"
import Toastify from "toastify-js"
import { getMessageFromType } from "../../utils/utils"
import { Mutex } from "async-mutex"
import { logout } from "../../features/auth/authSlice"
import { ResponseApiType } from "../../models/responseApiType"

export interface UserResponse {
  token: string
  userid: string
}
export interface LoginRequest {
  username: string
  password: string
}

export const TAG_USER = "User"
export const TAG_CARD_STOCK_ORDER = "CardStockOrder"

// Create our baseQuery instance
const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_URL + "/api/",
  prepareHeaders: (headers, { getState }) => {
    headers.set("Content-Type", "application/json")
    const token = (getState() as RootState).auth.token
    if (token) {
      headers.set("Authorization", `Bearer ${token}`)
    }
    return headers
  },
  mode: "cors",
})
//const baseQueryWithRetry = retry(baseQuery, { maxRetries: 1 })
const mutex = new Mutex()
const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  // wait until the mutex is available without locking it
  await mutex.waitForUnlock()
  let result: any = await baseQuery(args, api, extraOptions)
  if (
    result.error &&
    result.error.data?.type ===
      ResponseApiType.TokenExpired /*result.error.status === 401*/
  ) {
    // checking whether the mutex is locked
    if (!mutex.isLocked()) {
      const release = await mutex.acquire()
      try {
        // try to get a new token
        const refreshResult = await baseQuery(
          "/refreshToken",
          api,
          extraOptions
        )
        if (refreshResult.data) {
          // store the new token
          // api.dispatch(tokenReceived(refreshResult.data))
          // retry the initial query
          result = await baseQuery(args, api, extraOptions)
        } else {
          api.dispatch(logout())
        }
      } finally {
        // release must be called once the mutex should be released again.
        release()
      }
    } else {
      // wait until the mutex is available without locking it
      await mutex.waitForUnlock()
      result = await baseQuery(args, api, extraOptions)
    }
  }
  return result
}

// Define our single API slice object
export const apiSlice = createApi({
  // The cache reducer expects to be added at `state.api` (already default - this is optional)
  reducerPath: "api",
  // All of our requests will have URLs starting with '/api'
  baseQuery: baseQueryWithReauth,
  tagTypes: [
    "Article",
    "Document",
    "DocumentAdmin",
    "ArticleAdmin",
    TAG_USER,
    TAG_CARD_STOCK_ORDER,
    "CardPrice",
    "CardStock",
    "CardOrder",
    "OtherOrder",
    "AdminCardOrder",
    "AdminOtherOrder",
    "AdminOrder",
    "PersonalInfos",
    "Refund",
    "AdminRefund",
    "UserAdmin",
    "BankDeposit",
    "Operation",
    "BankAccount",
  ],
  //refetchOnMountOrArgChange: 30,
  // The "endpoints" represent operations and requests for this server
  endpoints: (build) => ({
    login: build.mutation<UserResponse, LoginRequest>({
      query: (credentials) => ({
        url: "/login_check",
        method: "POST",
        body: credentials,
        /*validateStatus: (response, result) =>
          response.status === 200 && result.token,*/
      }),
    }),
    getUser: build.query<{ user: User }, string>({
      query: (id) => ({ url: `user/${id}` }),
      providesTags: [TAG_USER],
    }),
  }),
})

export const { useLoginMutation, useGetUserQuery } = apiSlice

export const rtkQueryErrorLogger: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
    if (isRejectedWithValue(action)) {
      console.warn("We got a rejected action!", action)
      let msg = getMessageFromType(action.payload)
      if (msg) {
        Toastify({
          text: msg,
          duration: 8000,
          newWindow: true,
          close: true,
          gravity: "top", // `top` or `bottom`
          position: "right", // `left`, `center` or `right`
          stopOnFocus: true, // Prevents dismissing of toast on hover
          style: {
            background: "linear-gradient(to right, #29aac4, #bd79e7)",
          },
          onClick: function () {}, // Callback after click
        }).showToast()
      }
    }

    return next(action)
  }
