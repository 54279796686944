import {
  FormControl,
  IconButton,
  InputBase,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material"
import Toastify from "toastify-js"
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { Row } from "react-bootstrap"
import styled from "styled-components"
import colors from "../../utils/style/colors"
import frLocale from "date-fns/locale/fr"
import { useEffect, useState } from "react"
import SearchIcon from "@mui/icons-material/Search"
import DataTable from "react-data-table-component"
import { Loader } from "../../utils/Atoms"
import {
  formatPrice,
  formatStrToDate,
  getFirstDayOfCurrentYear,
  getLastDayOfCurrentYear,
} from "../../utils/utils"
import { format } from "date-fns"
import InternError from "../InternError"
import { useNavigate } from "react-router-dom"
import {
  getRefundStatus,
  getRefundStatusLabel,
  getStatusColor,
  Refund,
  RefundStatusType,
  RefundType,
} from "../../models/refund"
import { useGetRefundsWithPaginationQuery } from "../../features/refundSlice"
import { getRelationshipLabel } from "../../models/member"
import ButtonPrimary from "../ButtonPrimary"
import RepayedModal from "../RepayedModal"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons"
import { API } from "../../utils/API"

const StyledRow = styled(Row)`
  font-size: 1rem;
  h3 {
    font-size: 1.1rem !important;
    color: ${colors.darkViolet};
    margin-bottom: 0 !important ;
  }
`

const StyledSpan = styled.span<{ status: RefundStatusType }>`
  font-size: 0.8rem;
  padding: 0.2rem 0.5rem;
  border-radius: 2rem;
  text-align: center;
  align-self: center;
  background-color: ${(prop) =>
    prop.status ? getStatusColor(prop.status) : "white"};
  color: white;
  white-space: nowrap;
  margin-left: 0.5rem;
`

function AdminSportRefund() {
  const [from, setFrom] = useState<Date | null>(getFirstDayOfCurrentYear())
  const [to, setTo] = useState<Date | null>(getLastDayOfCurrentYear())
  const [fromDate, setFromDate] = useState<string | null>()
  const [toDate, setToDate] = useState<string | null>()
  const [page, setPage] = useState(1)
  const [status, setStatus] = useState<string>("")
  const [perPage, setPerPage] = useState(10)
  const [searchTerm, setSearchTerm] = useState("")
  const [skip, setSkip] = useState(false)
  const [order, setOrder] = useState({
    orderBy: "requestDate",
    direction: "DESC",
  })

  const [showRepayedModal, setShowRepayedModal] = useState(false)

  const navigate = useNavigate()

  const paginationComponentOptions = {
    rowsPerPageText: "Demandes par page",
    rangeSeparatorText: "de",
  }

  const columns = [
    {
      name: "Date demande",
      selector: (row: Refund) => row.requestDate,
      sortable: true,
      sortField: "requestDate",
      format: (row: Refund) =>
        formatStrToDate(row.requestDate, "dd/MM/yyyy HH:mm"),
    },
    {
      name: "Numéro",
      selector: (row: Refund) => row.number,
    },
    {
      name: "Salarié",
      selector: (row: Refund) => row.owner,
      format: (row: Refund) => {
        if (!row.owner?.firstName && !row.owner?.lastname)
          return row.owner?.email
        else return `${row.owner?.firstName} ${row.owner?.lastname}`
      },
    },
    {
      name: "Bénéficiaire",
      selector: (row: Refund) => row.recipient,
      cell: (row: Refund) => (
        <div className="text-cente">
          {row.recipient
            ? `${row.recipient?.firstname} ${
                row.recipient?.lastname
              } (${getRelationshipLabel(row.recipient?.relationship)})`
            : !row.owner?.firstName && !row.owner?.lastname
            ? row.owner?.email
            : `${row.owner?.firstName} ${row.owner?.lastname}`}
        </div>
      ),
    },
    {
      name: "Intitulé",
      selector: (row: Refund) => row.title,
      sortable: true,
      sortField: "title",
    },
    {
      name: "Prix",
      selector: (row: Refund) => row.price,
      format: (row: Refund) => formatPrice(row.price),
      sortable: true,
      sortField: "price",
    },
    {
      name: "À rembourser",
      selector: (row: Refund) => row.repaymentAmount,
      format: (row: Refund) => formatPrice(row.repaymentAmount),
      sortable: true,
      sortField: "repaymentAmount",
    },
    {
      name: "Statut",
      selector: (row: Refund) => row.status,
      cell: (row: Refund) => (
        <StyledSpan status={row.status}>
          {getRefundStatusLabel(row.status)}
        </StyledSpan>
      ),
    },
  ] as any

  const {
    data: refundsPaginate = [],
    isLoading,
    isSuccess,
    isError,
  } = useGetRefundsWithPaginationQuery(
    {
      limit: perPage,
      page: page,
      orderBy: order.orderBy,
      direction: order.direction,
      type: RefundType.Sport,
      status: status,
      from: fromDate,
      to: toDate,
      search: searchTerm,
    },
    { skip: !fromDate || !toDate || skip }
  )

  const handlePageChange = (page: number) => {
    setPage(page)
  }

  const handlePerRowsChange = (newPerPage: number) => {
    setPerPage(newPerPage)
  }

  const handleSort = (selectedColumn: any, sortDirection: any) => {
    setOrder({
      orderBy: selectedColumn.sortField,
      direction: sortDirection,
    })
  }

  const handleRowClicked = (orderNumber: string) => {
    //Go to order page
    navigate(`/admin/refunds/${orderNumber}`)
  }

  useEffect(() => {
    try {
      let date1 = from ? format(from, "yyyy-MM-dd HH:mm:ss") : null
      let date2 = to ? format(to, "yyyy-MM-dd HH:mm:ss") : null
      setFromDate(date1)
      setToDate(date2)
    } catch (error) {
      console.error(error)
    }
  }, [from, to])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setSkip(false)
    }, 1000)

    return () => clearTimeout(delayDebounceFn)
  }, [searchTerm])

  const handleExport = () => {
    if (from && to) {
      API.get(`admin/refund/export`, {
        responseType: "blob",
        params: {
          from: fromDate,
          to: toDate,
          status: status,
          type: RefundType.Sport,
        },
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement("a")
          link.href = url
          link.setAttribute(
            "download",
            `activites-sportives_${format(from, "dd-MM-yyyy")}_${format(
              to,
              "dd-MM-yyyy"
            )}.xlsx`
          )
          document.body.appendChild(link)
          link.click()
        })
        .catch((error) => {
          Toastify({
            text: "Une erreur s'est produite lors du téléchargement",
            duration: 8000,
            newWindow: true,
            close: true,
            gravity: "top", // `top` or `bottom`
            position: "right", // `left`, `center` or `right`
            stopOnFocus: true, // Prevents dismissing of toast on hover
            style: {
              background: "linear-gradient(to right, #29aac4, #bd79e7)",
            },
            onClick: function () {}, // Callback after click
          }).showToast()
        })
    }
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={frLocale}>
      <StyledRow className="px-lg-2 py-3 m-lg-1 my-3">
        <div className="d-flex flex-wrap gap-5 justify-content-center">
          <DatePicker
            label="Du"
            value={from}
            onChange={(newValue) => {
              setFrom(newValue)
            }}
            className="datePicker"
            renderInput={(params) => (
              <TextField
                {...params}
                inputProps={{
                  ...params.inputProps,
                  placeholder: "jj/mm/aaaa",
                }}
              />
            )}
          />
          <DatePicker
            label="au"
            value={to}
            onChange={(newValue) => {
              setTo(newValue)
            }}
            className="datePicker"
            minDate={from}
            renderInput={(params) => (
              <TextField
                {...params}
                inputProps={{
                  ...params.inputProps,
                  placeholder: "jj/mm/aaaa",
                }}
              />
            )}
          />
        </div>
        <div className="mt-4 mb-0 ">
          <FontAwesomeIcon icon={faInfoCircle} color="cornflowerblue" />
          &nbsp;
          <span
            className="fst-italic"
            style={{ color: "grey", fontSize: "0.9rem" }}
          >
            Si vous avez remboursé plusieurs demandes via un seul paiement vous
            pouvez l'indiquer via le bouton "Indiquer un remboursement". Ainsi
            celui ci sera bien considéré comme une seule opération dans le
            budget du CSE.
          </span>
        </div>
        <div className="mt-3 mb-3 d-flex flex-wrap">
          <FormControl sx={{ m: 1, minWidth: 150 }} size="small">
            <InputLabel id="label-select-status">Statut</InputLabel>
            <Select
              labelId="label-select-status"
              id="select-status"
              value={status}
              label="Statut"
              onChange={(event: SelectChangeEvent) => {
                setStatus(event.target.value)
              }}
            >
              <MenuItem value="">
                <em>Tous</em>
              </MenuItem>
              {getRefundStatus().map((key, index) => (
                <MenuItem key={index} value={key}>
                  {getRefundStatusLabel(key)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <ButtonPrimary
            style={{
              padding: "0.5rem 0.8rem",
              borderRadius: "8px",
              alignSelf: "center",
              background: "#cfc703",
            }}
            label="Indiquer un remboursement"
            onClick={() => setShowRepayedModal(true)}
          />
          <ButtonPrimary
            style={{
              padding: "0.5rem 0.8rem",
              borderRadius: "8px",
              alignSelf: "center",
              background: colors.violet,
            }}
            label="Exporter"
            onClick={() => handleExport()}
          />
          <Paper
            component="form"
            sx={{
              p: "2px 4px",
              display: "flex",
              alignItems: "center",
              width: 400,
            }}
            className="ms-auto"
            onSubmit={(e: any) => {
              e.preventDefault()
              setSkip(false)
            }}
          >
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Rechercher"
              inputProps={{ "aria-label": "rechercher" }}
              onChange={(e) => {
                setSearchTerm(e.target.value)
                setSkip(true)
              }}
            />
            <IconButton
              type="submit"
              sx={{ p: "10px" }}
              aria-label="recherche"
              onClick={() => setSkip(false)}
            >
              <SearchIcon />
            </IconButton>
          </Paper>
        </div>
        {isSuccess && (
          <DataTable
            columns={columns}
            data={refundsPaginate.items}
            progressPending={isLoading}
            striped
            pagination
            paginationServer
            paginationTotalRows={refundsPaginate.totalItems}
            onChangeRowsPerPage={handlePerRowsChange}
            paginationPerPage={perPage}
            onChangePage={handlePageChange}
            onRowClicked={(row: Refund) => handleRowClicked(row.number)}
            onSort={handleSort}
            sortServer
            defaultSortFieldId={1}
            defaultSortAsc={false}
            responsive
            highlightOnHover
            pointerOnHover
            noDataComponent="Aucune demande"
            progressComponent={<Loader></Loader>}
            paginationComponentOptions={paginationComponentOptions}
          ></DataTable>
        )}
        {isError && <InternError />}
      </StyledRow>
      <RepayedModal
        show={showRepayedModal}
        handleClose={() => setShowRepayedModal(false)}
      />
    </LocalizationProvider>
  )
}

export default AdminSportRefund
