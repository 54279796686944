import {
  FormControl,
  IconButton,
  InputBase,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material"
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { Row } from "react-bootstrap"
import styled from "styled-components"
import colors from "../../utils/style/colors"
import frLocale from "date-fns/locale/fr"
import { useEffect, useState } from "react"
import SearchIcon from "@mui/icons-material/Search"
import { faFilm, faPersonSwimming } from "@fortawesome/free-solid-svg-icons"
import DataTable from "react-data-table-component"
import { Loader } from "../../utils/Atoms"
import Toastify from "toastify-js"

import {
  formatPrice,
  formatStrToDate,
  getFirstDayOfCurrentYear,
  getLastDayOfCurrentYear,
} from "../../utils/utils"
import {
  getCardOrderStatus,
  getOrderStatusLabel,
  getStatusColor,
  OrderStatusType,
  OrderType,
} from "../../models/order"
import { useGetOrdersWithPaginationQuery } from "../../features/orderSlice"
import { format } from "date-fns"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { EscalatorWarning, Man } from "@mui/icons-material"
import InternError from "../InternError"
import { useNavigate } from "react-router-dom"
import { CardOrderAdmin } from "../../models/orderAdmin"
import ButtonPrimary from "../ButtonPrimary"
import { API } from "../../utils/API"

const StyledRow = styled(Row)`
  font-size: 1rem;
  h3 {
    font-size: 1.1rem !important;
    color: ${colors.darkViolet};
    margin-bottom: 0 !important ;
  }
`

const StyledSpan = styled.span<{ status: OrderStatusType }>`
  font-size: 0.8rem;
  padding: 0.2rem 0.5rem;
  border-radius: 2rem;
  text-align: center;
  align-self: center;
  white-space: nowrap;
  background-color: ${(prop) =>
    prop.status ? getStatusColor(prop.status) : "white"};
  color: white;
  margin-left: 0.5rem;
`

function AdminCardOrder() {
  const [from, setFrom] = useState<Date | null>(getFirstDayOfCurrentYear())
  const [to, setTo] = useState<Date | null>(getLastDayOfCurrentYear())
  const [fromDate, setFromDate] = useState<string | null>()
  const [toDate, setToDate] = useState<string | null>()
  const [page, setPage] = useState(1)
  const [type, setType] = useState<string>("")
  const [status, setStatus] = useState<string[]>([])
  const [perPage, setPerPage] = useState(10)
  const [searchTerm, setSearchTerm] = useState("")
  const [skip, setSkip] = useState(false)
  const [order, setOrder] = useState({
    orderBy: "orderDate",
    direction: "DESC",
  })

  const navigate = useNavigate()

  const paginationComponentOptions = {
    rowsPerPageText: "Commandes par page",
    rangeSeparatorText: "de",
  }

  const columns = [
    {
      name: "Date commande",
      selector: (row: CardOrderAdmin) => row.parentOrder?.orderDate,
      sortable: true,
      sortField: "orderDate",
      format: (row: CardOrderAdmin) =>
        formatStrToDate(row.parentOrder?.orderDate, "dd/MM/yyyy HH:mm"),
    },
    {
      name: "Numéro",
      selector: (row: CardOrderAdmin) => row.parentOrder?.number,
    },
    {
      name: "Salarié",
      selector: (row: CardOrderAdmin) => row.parentOrder?.owner,
      format: (row: CardOrderAdmin) => {
        if (
          !row.parentOrder?.owner.firstName &&
          !row.parentOrder?.owner.lastname
        )
          return row.parentOrder?.owner.email
        else
          return `${row.parentOrder?.owner.firstName} ${row.parentOrder?.owner.lastname}`
      },
    },
    {
      name: "Type",
      selector: (row: CardOrderAdmin) => row.parentOrder?.type,
      sortable: true,
      sortField: "type",
      cell: (row: CardOrderAdmin) =>
        row.parentOrder?.type === OrderType.Pool ? (
          <div data-tag="allowRowEvents">
            <FontAwesomeIcon icon={faPersonSwimming} color={colors.blue} />
            &nbsp; Piscine
          </div>
        ) : (
          <div data-tag="allowRowEvents">
            <FontAwesomeIcon icon={faFilm} color="#920000" />
            &nbsp; Cinéma
          </div>
        ),
    },
    {
      name: "Nb cartes",
      cell: (row: CardOrderAdmin) => (
        <div className="d-flex flex-column gap-1">
          {row.parentOrder?.type === OrderType.Movie ? (
            <div className="d-flex gap-1 align-items-end">
              <Man sx={{ color: colors.orange, fontSize: "20px" }} />{" "}
              {row.adultQuantity} adulte{row.adultQuantity > 1 && "s"}
            </div>
          ) : (
            <div className="d-flex gap-1 align-items-end">
              <Man sx={{ color: colors.orange, fontSize: "20px" }} />{" "}
              {row.adultQuantity} carte{row.adultQuantity > 1 && "s"}
            </div>
          )}
          {row.parentOrder?.type === OrderType.Movie && (
            <div className="d-flex gap-1 align-items-end">
              <EscalatorWarning sx={{ color: colors.blue, fontSize: "20px" }} />{" "}
              {row.childQuantity} enfant{row.childQuantity > 1 && "s"}
            </div>
          )}
        </div>
      ),
    },
    {
      name: "Montant",
      selector: (row: CardOrderAdmin) => row.parentOrder?.amount,
      format: (row: CardOrderAdmin) => formatPrice(row.parentOrder?.amount),
    },
    {
      name: "Montant payé",
      selector: (row: CardOrderAdmin) => row.parentOrder?.payment?.amount,
      format: (row: CardOrderAdmin) =>
        formatPrice(row.parentOrder?.payment?.amount),
    },
    {
      name: "Statut",
      selector: (row: CardOrderAdmin) => row.parentOrder?.status,
      cell: (row: CardOrderAdmin) => (
        <StyledSpan status={row.parentOrder?.status}>
          {getOrderStatusLabel(row.parentOrder?.status)}
        </StyledSpan>
      ),
    },
  ] as any

  const {
    data: ordersPaginate = [],
    isLoading,
    isSuccess,
    isError,
  } = useGetOrdersWithPaginationQuery(
    {
      limit: perPage,
      page: page,
      orderBy: order.orderBy,
      direction: order.direction,
      type: type,
      status: status?.join("-"),
      from: fromDate,
      to: toDate,
      search: searchTerm,
    },
    { skip: !fromDate || !toDate || skip }
  )

  const handlePageChange = (page: number) => {
    setPage(page)
  }

  const handlePerRowsChange = (newPerPage: number) => {
    setPerPage(newPerPage)
  }

  const handleSort = (selectedColumn: any, sortDirection: any) => {
    setOrder({
      orderBy: selectedColumn.sortField,
      direction: sortDirection,
    })
  }

  const handleRowClicked = (orderNumber: string) => {
    //Go to order page
    navigate(`/admin/orders/${orderNumber}`)
  }

  useEffect(() => {
    try {
      let date1 = from ? format(from, "yyyy-MM-dd HH:mm:ss") : null
      let date2 = to ? format(to, "yyyy-MM-dd HH:mm:ss") : null
      setFromDate(date1)
      setToDate(date2)
    } catch (error) {
      console.error(error)
    }
  }, [from, to])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setSkip(false)
    }, 1000)

    return () => clearTimeout(delayDebounceFn)
  }, [searchTerm])

  const handleExport = () => {
    if (from && to) {
      API.get(`admin/card-order/export`, {
        responseType: "blob",
        params: {
          type: type,
          from: fromDate,
          to: toDate,
          status: status?.join("-"),
        },
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement("a")
          link.href = url
          link.setAttribute(
            "download",
            `commandes_${format(from, "dd-MM-yyyy")}_${format(
              to,
              "dd-MM-yyyy"
            )}.xlsx`
          )
          document.body.appendChild(link)
          link.click()
        })
        .catch((error) => {
          Toastify({
            text: "Une erreur s'est produite lors du téléchargement",
            duration: 8000,
            newWindow: true,
            close: true,
            gravity: "top", // `top` or `bottom`
            position: "right", // `left`, `center` or `right`
            stopOnFocus: true, // Prevents dismissing of toast on hover
            style: {
              background: "linear-gradient(to right, #29aac4, #bd79e7)",
            },
            onClick: function () {}, // Callback after click
          }).showToast()
        })
    }
  }

  const handleStatusChange = (event: SelectChangeEvent<typeof status>) => {
    const {
      target: { value },
    } = event

    let newValue = typeof value === "string" ? value.split(",") : value
    console.log("before", newValue)
    if (newValue.includes("") && newValue[newValue.length - 1] === "") {
      newValue = [""]
    } else {
      //remove "All filter if another selected"
      newValue = newValue.filter((str) => {
        return str !== ""
      })
    }
    console.log(newValue)

    setStatus(
      // On autofill we get a stringified value.
      newValue
    )
  }
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={frLocale}>
      <StyledRow className="px-0 px-lg-2 py-3 m-lg-1 my-3">
        <div className="d-flex flex-wrap gap-5 justify-content-center">
          <DatePicker
            label="Du"
            value={from}
            onChange={(newValue) => {
              setFrom(newValue)
            }}
            className="datePicker"
            renderInput={(params) => (
              <TextField
                {...params}
                inputProps={{
                  ...params.inputProps,
                  placeholder: "jj/mm/aaaa",
                }}
              />
            )}
          />
          <DatePicker
            label="au"
            value={to}
            onChange={(newValue) => {
              setTo(newValue)
            }}
            className="datePicker"
            minDate={from}
            renderInput={(params) => (
              <TextField
                {...params}
                inputProps={{
                  ...params.inputProps,
                  placeholder: "jj/mm/aaaa",
                }}
              />
            )}
          />
        </div>
        <div className="mt-4 mb-3 d-flex flex-wrap">
          <FormControl sx={{ m: 1, minWidth: 150 }} size="small">
            <InputLabel id="label-select-status">Statut</InputLabel>
            <Select
              labelId="label-select-status"
              id="select-status"
              value={status}
              label="Statut"
              multiple
              onChange={handleStatusChange}
            >
              <MenuItem value="">
                <em>Tous</em>
              </MenuItem>
              {getCardOrderStatus().map((key, index) => (
                <MenuItem key={index} value={key}>
                  {getOrderStatusLabel(key)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl sx={{ m: 1, minWidth: 150 }} size="small">
            <InputLabel id="label-select-type">Type</InputLabel>
            <Select
              labelId="label-select-type"
              id="select-type"
              value={type}
              label="Type"
              onChange={(event: SelectChangeEvent) => {
                setType(event.target.value)
              }}
            >
              <MenuItem value="">
                <em>Tous</em>
              </MenuItem>
              <MenuItem value={OrderType.Movie}>Cinéma</MenuItem>
              <MenuItem value={OrderType.Pool}>Piscine</MenuItem>
            </Select>
          </FormControl>
          <ButtonPrimary
            style={{
              padding: "0.5rem 0.8rem",
              borderRadius: "8px",
              alignSelf: "center",
              background: colors.violet,
            }}
            label="Exporter"
            onClick={() => handleExport()}
          />
          <Paper
            component="form"
            sx={{
              p: "2px 4px",
              display: "flex",
              alignItems: "center",
              width: 400,
            }}
            className="ms-auto"
            onSubmit={(e: any) => {
              e.preventDefault()
              setSkip(false)
            }}
          >
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Rechercher"
              inputProps={{ "aria-label": "rechercher" }}
              onChange={(e) => {
                setSearchTerm(e.target.value)
                setSkip(true)
              }}
            />
            <IconButton
              type="submit"
              sx={{ p: "10px" }}
              aria-label="recherche"
              onClick={() => setSkip(false)}
            >
              <SearchIcon />
            </IconButton>
          </Paper>
        </div>
        {isSuccess && (
          <DataTable
            columns={columns}
            data={ordersPaginate.items}
            progressPending={isLoading}
            pagination
            paginationServer
            striped
            paginationTotalRows={ordersPaginate.totalItems}
            onChangeRowsPerPage={handlePerRowsChange}
            paginationPerPage={perPage}
            onChangePage={handlePageChange}
            onRowClicked={(row: CardOrderAdmin) =>
              handleRowClicked(row.parentOrder?.number)
            }
            onSort={handleSort}
            sortServer
            defaultSortFieldId={1}
            defaultSortAsc={false}
            responsive
            highlightOnHover
            pointerOnHover
            noDataComponent="Aucune commande"
            progressComponent={<Loader></Loader>}
            paginationComponentOptions={paginationComponentOptions}
          ></DataTable>
        )}
        {isError && <InternError />}
      </StyledRow>
    </LocalizationProvider>
  )
}

export default AdminCardOrder
