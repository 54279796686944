import WaveHeader from "../../components/WaveHeader"
import bgImage from "../../assets/mentions-bg.jpg"
import { Row } from "react-bootstrap"
import styled from "styled-components"
import colors from "../../utils/style/colors"

const StyledDiv = styled.div`
  padding: 1rem;
  @media (min-width: 768px) {
    background-size: 100%, 40rem, 18rem;
    background-position: left, top 3rem left 1rem, bottom 0 right 1rem;
  }

  a {
    color: ${colors.violet};
  }

  h2 {
    font-size: 1.5rem;
  }

  p {
    color: black;
  }
`

function MentionsLegales() {
  return (
    <div>
      <WaveHeader title="Mentions légales" image={bgImage} />
      <StyledDiv className="container-fluid">
        <Row className="justify-content-start violet fs-6 fs-md-5 pb-5 m-md-5 mt-md-3">
          <p>
            Conformément aux dispositions de la loi n° 2004-575 du 21 juin 2004
            pour la confiance en l’économie numérique, il est précisé aux
            utilisateurs du site <a href="https://cse-ctcm.fr">cse-ctcm.fr</a>{" "}
            l’identité des différents intervenants dans le cadre de sa
            réalisation et de son suivi.
          </p>
          <h2>Propriétaire</h2>
          <p>
            CTCM – SIRET 528943996.00033 - Rue Jean Baptiste Lefort Zac Du Val
            De Vence 08000 Charleville-Mézières
          </p>
          <h2>Conception et réalisation </h2>
          <p>
            Agence de Communication COMSEA - Site Internet :{" "}
            <a href="https://www.comsea.fr" target="_blank" rel="noreferrer">
              www.comsea.fr
            </a>{" "}
            - Email : <a href="mailto:contact@comsea.fr">contact@comsea.fr</a>
          </p>
          <h2>Responsable de publication</h2>
          <p>
            M. Dominique Blanc –{" "}
            <a href="mailto:cse.ctcm@free.fr">cse.ctcm@free.fr</a>
          </p>
          <h2>Hébergeur</h2>
          <p>Le site cse-ctcm.fr est hébergé par la société :</p>
          <p>OVH, 2 RUE KELLERMANN 59100 ROUBAIX</p>
          <h2>Propriété Intellectuelle</h2>
          <p>
            Le présent site Internet pris dans sa globalité et chacun des
            éléments qui le composent pris indépendamment, notamment les
            programmes et développements spécifiques et les contenus incluant
            des données, textes, images fixes ou animées, logotypes, sons,
            graphiques, fichiers, sont la propriété exclusive du responsable de
            la publication, du créateur du site Internet ou de tiers qui lui ont
            concédé une licence. Toute représentation totale ou partielle du
            site ou de l’un des éléments qui le composent sans l’autorisation
            expresse du titulaire des droits de propriété intellectuelle est
            interdite et constituerait une contrefaçon sanctionnée par les
            articles L.335-2 et suivants du Code de la propriété intellectuelle.
          </p>
          <h2>Responsabilité de l’Utilisateur</h2>
          <p>
            L’Utilisateur est responsable des risques liés à l’utilisation de
            son identifiant de connexion et de son mot de passe. Le mot de passe
            de l’Utilisateur doit rester secret. En cas de divulgation de mot de
            passe, l’Éditeur décline toute responsabilité. L’Utilisateur assume
            l’entière responsabilité de l’utilisation qu’il fait des
            informations et contenus présents sur le site cse-ctcm.fr. Tout
            usage du service par l’Utilisateur ayant directement ou
            indirectement pour conséquence des dommages doit faire l’objet d’une
            indemnisation au profit du site.
          </p>
          <h2>Nous contacter</h2>
          <p>
            Par email : <a href="mailto:cse.ctcm@free.fr">cse.ctcm@free.fr</a>
          </p>
        </Row>
      </StyledDiv>
    </div>
  )
}

export default MentionsLegales
