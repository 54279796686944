import { Form } from "react-bootstrap"
import styled from "styled-components"
import ButtonPrimary from "../../components/ButtonPrimary"
import bgImage from "../../assets/bus.jpg"
import { useEffect, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons"
import colors from "../../utils/style/colors"
import { Link, useParams } from "react-router-dom"
import { API } from "../../utils/API"
import Toastify from "toastify-js"

const StyledDiv = styled.div`
  background-color: white;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 90vw;
  align-self: center;
  border-radius: 15px;
  box-shadow: grey 2px 2px 15px;
  @media (min-width: 576px) {
    padding: 2rem;
    width: 30rem;
  }
`

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.6),
      rgba(83, 44, 110, 0.5)
    ),
    url(${bgImage});
  background-size: cover;
  background-position: center;
  min-height: 100vh;
`

function ResetPasswordToken() {
  const [validated, setValidated] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [match, setMatch] = useState(false)
  const [msg, setMsg] = useState("")
  const [formState, setFormState] = useState<any>({
    plainPassword: {
      first: "",
      second: "",
    },
  })

  let { token } = useParams()

  const canSubmit = !isLoading

  const handleChange = ({
    target: { name, value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setFormState((prev: any) => ({
      ...prev,
      plainPassword: { ...prev.plainPassword, [name]: value },
    }))
  }
  useEffect(() => {
    setMatch(formState.plainPassword?.first === formState.plainPassword?.second)
  }, [formState])

  const handleSubmit = (event: any) => {
    event.preventDefault()
    event.stopPropagation()
    const form = event.currentTarget
    if (form.checkValidity() === false) {
      setValidated(true)
      return
    } else {
      setIsLoading(true)
      //reset
      console.log(formState)
      API.post(`reset-password/reset/${token}`, formState)
        .then((response: any) => {
          if (response.data) {
            API.post(`reset-password/reset`, {
              form: formState,
              id: response.data?.session_id,
            })
              .then((response: any) => {
                console.log(response)
                setMsg(response.data)
              })
              .catch((error: any) => {
                Toastify({
                  text: "Une erreur s'est produite lors de la rénitialisation",
                  duration: 8000,
                  newWindow: true,
                  close: true,
                  gravity: "top", // `top` or `bottom`
                  position: "right", // `left`, `center` or `right`
                  stopOnFocus: true, // Prevents dismissing of toast on hover
                  style: {
                    background: "linear-gradient(to right, #29aac4, #bd79e7)",
                  },
                  onClick: function () {}, // Callback after click
                }).showToast()
              })
              .finally(() => {
                setIsLoading(false)
              })
          }
        })
        .catch((error: any) => {
          Toastify({
            text: "Une erreur s'est produite lors de la rénitialisation",
            duration: 8000,
            newWindow: true,
            close: true,
            gravity: "top", // `top` or `bottom`
            position: "right", // `left`, `center` or `right`
            stopOnFocus: true, // Prevents dismissing of toast on hover
            style: {
              background: "linear-gradient(to right, #29aac4, #bd79e7)",
            },
            onClick: function () {}, // Callback after click
          }).showToast()
          setIsLoading(false)
        })
    }
  }

  return (
    <StyledContainer>
      <h1 className="text-center p-4 pb-5" style={{ color: "white" }}>
        CSE RDAM
      </h1>
      <StyledDiv>
        <h2 className="text-center pb-4 fs-4 violet">
          Réinitialisation du mot de passe
        </h2>
        <Form
          onSubmit={(e) => handleSubmit(e)}
          validated={validated}
          noValidate
        >
          {!msg && (
            <div className="mb-3">
              Veuillez indiquer votre nouveau mot de passe (min. 8 caractères)
            </div>
          )}
          {msg && (
            <div className="mb-3 fst-italic text-center">
              <FontAwesomeIcon icon={faCheckCircle} color="green" /> {msg}{" "}
              <br />
              <Link to="/login" style={{ color: colors.violet }}>
                Se connecter
              </Link>
            </div>
          )}
          <Form.Group className="mb-3" controlId="formBasicPlainPassword">
            <Form.Control
              required
              minLength={8}
              type="password"
              placeholder="Entrez votre mot de passe"
              onChange={handleChange}
              name="first"
              isInvalid={
                validated && formState.plainPassword?.first?.length < 8
              }
            />
            <Form.Control.Feedback type="invalid">
              Le mot de passe doit faire au moins 8 caractères
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicPlainPassword">
            <Form.Control
              required
              minLength={8}
              type="password"
              placeholder="Répétez votre mot de passe"
              onChange={handleChange}
              name="second"
              isInvalid={validated && !match}
            />
            <Form.Control.Feedback type="invalid">
              Les mots de passe ne correspondent pas
            </Form.Control.Feedback>
          </Form.Group>

          <div className="d-flex justify-content-center mt-4 w-100">
            <ButtonPrimary
              label="Valider"
              type="submit"
              loading={isLoading}
              disabled={!canSubmit}
            />
          </div>
        </Form>
        <div className="mt-4 text-end">
          <Link
            to="/login"
            style={{ color: colors.violet, fontSize: "0.9rem" }}
          >
            Retour à la page de connexion
          </Link>
        </div>
      </StyledDiv>
    </StyledContainer>
  )
}

export default ResetPasswordToken
