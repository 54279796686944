import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Spinner } from "react-bootstrap"
import styled from "styled-components"

interface ButtonProps {
  label?: string
  className?: string
  type?: "button" | "submit" | "reset"
  onClick?: (e: any | null) => void
  icon?: IconProp
  position?: "left" | "right"
  loading?: boolean
  disabled?: boolean
}

const StyledButton = styled.button<ButtonProps>`
  box-shadow: 0px 3px 10px #0000001c;
  border-radius: 22px;
  border: 1px solid grey;
  background: rgba(grey, 0.3);
  color: grey;
  text-transform: uppercase;
  width: auto;
  transition: ease 0.2s;
  font-size: 0.8rem;

  padding: ${(buttonProps: ButtonProps) =>
    buttonProps.label ? "0.3rem 1.8rem" : `0.5rem 1rem`};

  border-radius: ${(buttonProps: ButtonProps) =>
    buttonProps.label ? "22px" : `16px`};

  &:hover {
    color: white;
    background-color: grey;
  }

  &:disabled {
    opacity: 0.5 !important;
  }
`

function ButtonSecondary(buttonProps: ButtonProps) {
  return (
    <StyledButton
      className={buttonProps.className}
      type={buttonProps.type ?? "button"}
      onClick={buttonProps.onClick}
      disabled={buttonProps.loading || buttonProps.disabled}
    >
      {buttonProps.loading && (
        <Spinner animation="border" size="sm" className="me-1"></Spinner>
      )}
      {!buttonProps.loading &&
        buttonProps.icon &&
        (buttonProps.position === "left" || !buttonProps.position) && (
          <FontAwesomeIcon
            icon={buttonProps.icon}
            size="lg"
            className={buttonProps.label && "pe-2"}
          ></FontAwesomeIcon>
        )}
      {buttonProps.label}
      {!buttonProps.loading &&
        buttonProps.icon &&
        buttonProps.position === "right" && (
          <FontAwesomeIcon
            icon={buttonProps.icon}
            size="lg"
            className={buttonProps.label && "pe-2"}
          ></FontAwesomeIcon>
        )}
    </StyledButton>
  )
}

export default ButtonSecondary
