import { useMemo, useState } from "react"
import News from "../../models/article"
import { Loader } from "../../utils/Atoms"
import WaveHeader from "../../components/WaveHeader"
import bgImage from "../../assets/news.jpg"
import Card from "../../components/Card"
import { Container, Row } from "react-bootstrap"
import { cutText, formatStrToDate } from "../../utils/utils"
import { useGetArticlesWithPaginationQuery } from "../../features/articleSlice"
import InternError from "../../components/InternError"
import Pagination from "@mui/material/Pagination"
import { useNavigate } from "react-router-dom"

function NewsSection() {
  let navigate = useNavigate()
  const goToArticle = (path: string) => {
    navigate(path)
  }

  const [page, setPage] = useState(1)
  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value)
    document.getElementById("articles")?.scrollIntoView()
  }
  const {
    data: articlesPaginate = [],
    isLoading,
    isSuccess,
    isError,
    error,
    refetch,
  } = useGetArticlesWithPaginationQuery({ limit: 12, page: page })

  const sortedArticles = useMemo(() => {
    const articles = articlesPaginate.items ?? []
    const sortedArticles = articles.slice()
    // Sort posts in descending chronological order
    sortedArticles.sort((a: News, b: News) =>
      b.updatedAt.localeCompare(a.updatedAt)
    )
    return sortedArticles
  }, [articlesPaginate])

  /*useEffect(() => {
    refetch()
  }, [])*/

  let content
  let paginate

  if (isLoading) {
    content = <Loader />
  } else if (isSuccess) {
    if (sortedArticles.length > 0) {
      content = sortedArticles.map((newsItem: News) => (
        <Card
          className="col-md-5 col-lg-3"
          key={newsItem.id}
          title={newsItem.title}
          content={cutText(newsItem.description)}
          image={{
            path: `${process.env.REACT_APP_API_ARTICLE_URL}/${newsItem.thumbnail?.name}`,
            alt: newsItem.thumbnail?.alt,
            name: newsItem.thumbnail?.name,
          }}
          labelChips={formatStrToDate(newsItem.updatedAt, "PPP") ?? ""}
          labelButton="Lire plus"
          buttonOnClick={() => goToArticle(`/news/${newsItem.id}`)}
        />
      ))

      if (articlesPaginate.totalPages > 1) {
        paginate = (
          <Row className="justify-content-center">
            <Pagination
              count={articlesPaginate.totalPages}
              color="secondary"
              page={page}
              onChange={handlePageChange}
            />
          </Row>
        )
      }
    } else {
      content = "Aucun article"
    }
  } else if (isError) {
    content = <InternError />
  }
  return (
    <div>
      <WaveHeader title="Actualités" image={bgImage} />
      <section id="articles" className="px-2">
        <Container>
          <Row
            style={{ gap: "1rem", paddingTop: "4rem" }}
            className="justify-content-center"
          >
            {content}
          </Row>
          {paginate}
        </Container>
      </section>
    </div>
  )
}

export default NewsSection
