import styled from "styled-components"
import waveSvg from "../../assets/vagues-bg.svg"

interface WaveHeaderProps {
  title: string
  image: string
  style?: any
}

const StyledDiv = styled.header<WaveHeaderProps>`
  background-image: ${(waveHeaderProp: WaveHeaderProps) =>
    waveHeaderProp.image &&
    `linear-gradient(
      90deg,
      rgba(83, 44, 110, 0.7),
      rgba(83, 44, 110, 0.7)
    ),
    url(${waveHeaderProp.image});`};
  background-size: cover;
  background-position: center, center center;
  padding-top: 10rem;
  height: 25rem;
  color: white;
  position: relative;
  text-align: center;

  &::after {
    background-image: url(${waveSvg});
    background-size: 100% 100%;
    background-position: center;
    bottom: -1px;
    content: "";
    height: 5rem;
    left: 0;
    position: absolute;
    width: 100%;
  }

  h1 {
    text-transform: uppercase;
    font-size: 1.6rem;
  }
`

function WaveHeader(waveHeaderProps: WaveHeaderProps) {
  return (
    <StyledDiv
      style={waveHeaderProps.style}
      title={waveHeaderProps.title}
      image={waveHeaderProps.image}
    >
      <h1>{waveHeaderProps.title}</h1>
    </StyledDiv>
  )
}

export default WaveHeader
