import { useEffect, useState } from "react"
import { Form, Modal } from "react-bootstrap"
import {
  useAddArticleMutation,
  useEditArticleMutation,
  useGetArticleQuery,
} from "../../features/articleSlice"
import ArticleAdmin from "../../models/articleAdmin"
import ButtonPrimary from "../ButtonPrimary"
import File from "../../models/file"
import styled from "styled-components"
import colors from "../../utils/style/colors"
import ButtonSecondary from "../ButtonSecondary"
import Article from "../../models/article"
import { IconButton } from "@mui/material"
import DeleteIcon from "@mui/icons-material/Delete"
import {
  ResponseApiException,
  ResponseApiType,
} from "../../models/responseApiType"

interface ModalProps {
  show: boolean
  handleClose: () => void
  articleId?: number
  isUpdate?: boolean
}

interface ImagePreview {
  id?: number
  path: string
  file?: Blob
}
const StyledModal = styled(Modal)`
  font-size: 0.9rem;
  .modal-title {
    font-size: 1rem !important;
    font-weight: 300 !important;
    padding-left: 0.5rem;

    @media (min-width: 768px) {
      font-size: 1.2rem !important;
    }
  }

  .modal-header {
    background-color: ${colors.violet};
    color: white;
  }
`

const StyledCheck = styled(Form.Check)`
  input:checked {
    background-color: ${colors.violet}!important;
    border-color: ${colors.violet};
  }
`

const StyledSpan = styled.span`
  color: grey;
  font-size: 0.8rem;
  font-style: italic;
`

function ArticleModal(modalProps: ModalProps) {
  const initialArticleValue: ArticleAdmin = {
    visible: true,
    description: "",
    title: "",
  }

  const initialFileValue: File = {
    alt: "",
    filename: "",
    id: undefined,
    data: "",
  }

  const [addArticle, { isLoading: isPostLoading }] = useAddArticleMutation()
  const [editArticle, { isLoading: isEditLoading }] = useEditArticleMutation()
  const [postImage, setPostImage] = useState(initialFileValue)
  const [postImages, setPostImages] = useState([] as File[])
  const [postArticle, setPostArticle] = useState(initialArticleValue)
  const [thumbnailPreview, setThumbnailPreview] = useState("")
  const [imagesPreview, setImagesPreview] = useState([] as ImagePreview[])
  const [validated, setValidated] = useState(false)
  const [errors, setErrors] = useState({} as any)
  const [imagesErrorArray, setImagesErrorArray] = useState([] as any[])
  const [skip, setSkip] = useState(true)
  const [msgError, setMsgError] = useState<string>()
  const [imagesToRemove, setImagesToRemove] = useState([] as number[])
  const { data: article, isSuccess }: { data: Article; isSuccess: boolean } =
    useGetArticleQuery(modalProps.articleId, { skip })
  //Init Article if Edit

  useEffect(() => {
    let imagesErrors: any[] = []
    postImages.forEach((postImg, index) => {
      let msg = errors[`images[${index}].file`]
      if (msg) {
        imagesErrors.push(msg[0])
      }
    })

    if (imagesErrors.length > 0) setImagesErrorArray(imagesErrors)
  }, [errors])

  useEffect(() => {
    if (modalProps.isUpdate) {
      setSkip(false)
      if (isSuccess) {
        setPostArticle((prev) => ({
          ...prev,
          visible: article?.visible ?? false,
          description: article?.description ?? "",
          title: article?.title ?? "",
        }))

        setPostImage({
          id: article?.thumbnail.id,
          data: "",
          filename: article?.thumbnail?.name,
          alt: article?.thumbnail?.alt,
        })
        setThumbnailPreview(
          `${process.env.REACT_APP_API_ARTICLE_URL}/${article?.thumbnail?.name}`
        )
        setPostImages([])
        setImagesPreview([])
        setImagesToRemove([])
        article?.images.forEach((img) => {
          /*setPostImages((imgArr) => [
            ...imgArr,
            { data: "", filename: img.name, alt: img.alt, id: img.id } as File,
          ])*/
          setImagesPreview((imgArr) => [
            ...imgArr,
            {
              path: `${process.env.REACT_APP_API_ARTICLE_URL}/${img.name}`,
              id: img.id,
            },
          ])
        })
      }
    } else {
    }
  }, [modalProps.articleId, article, modalProps.isUpdate, isSuccess])

  const handleAddOrEditArticle = (event: any) => {
    const form = event.currentTarget
    event.preventDefault()
    event.stopPropagation()
    if (form.checkValidity() === false) {
      setValidated(true)
    } else {
      let article = {
        title: postArticle.title,
        description: postArticle.description,
        visible: postArticle.visible,
        thumbnail: postImage,
        images: postImages,
      } as ArticleAdmin

      if (modalProps.isUpdate) {
        editArticle({
          id: modalProps.articleId,
          body: { articleAdminApi: article, imgsIdRemove: imagesToRemove },
        })
          .unwrap()
          .then(() => modalProps.handleClose())
          .catch((error: ResponseApiException) => {
            console.error(error.data)
            if (error.data?.type === ResponseApiType.FormError) {
              setMsgError("Veuillez vérifier les données")
              setErrors(error.data?.detail)
              setValidated(true)
            } else {
              setMsgError("Une erreur interne s'est produite.")
            }
          })
      } else {
        addArticle(article)
          .unwrap()
          .then(() => modalProps.handleClose())
          .catch((error: any) => {
            setMsgError("Veuillez vérifier les données")
            setErrors(error.data?.detail)
            setValidated(true)
          })
      }
    }
  }

  const convertToBase64 = (file: any): Promise<string> => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader()
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result as string)
      }
      fileReader.onerror = (error) => {
        reject(error)
      }
    })
  }
  const handleFileUpload = async (e: any) => {
    const file = e.target.files[0]
    await convertToBase64(file)
      .then((result) => {
        setPostImage({
          data: result,
          filename: file.name,
          alt: "",
          id: 0,
        })
        setThumbnailPreview(URL.createObjectURL(file))
      })
      .catch((error) => console.error(error))
  }

  const handleImagesUpload = (e: any) => {
    const files = e.target.files
    Object.entries(files).forEach(async ([key, file]) => {
      await convertToBase64(file)
        .then((result) => {
          setPostImages((imgArr) => [
            ...imgArr,
            {
              data: result,
              filename: "",
              alt: "",
              id: 0,
              fileBlob: file,
            } as File,
          ])
          setImagesPreview((imgArr) => [
            ...imgArr,
            {
              path: URL.createObjectURL(e.target.files[key]),
              file: e.target.files[key],
            },
          ])
        })
        .catch((error) => console.error(error))
    })
  }

  const toggleVisible = (e: any) => {
    setPostArticle((prev) => ({
      ...prev,
      visible: !prev.visible,
    }))
  }

  const handleArticleChange = ({
    target: { name, value },
  }: React.ChangeEvent<HTMLInputElement>) =>
    setPostArticle((prev) => ({ ...prev, [name]: value }))

  const removeImg = (imgPrev: ImagePreview) => {
    if (imgPrev.id && imgPrev.id > 0) {
      setImagesToRemove([...imagesToRemove, imgPrev.id])
    }
    //remove from postImages
    const newPostImages = postImages.filter(
      (filePrev: File) => filePrev.fileBlob !== imgPrev.file
    )

    setPostImages(newPostImages)

    removeImgPreview(imgPrev)
  }

  const removeImgPreview = (imgPrevToRm: ImagePreview) => {
    const newImgPreview = imagesPreview.filter(
      (imgPrev: ImagePreview) => imgPrev.path !== imgPrevToRm.path
    )
    setImagesPreview(newImgPreview)
  }

  return (
    <>
      <StyledModal
        show={modalProps.show}
        onHide={modalProps.handleClose}
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-width"
      >
        <Modal.Header closeButton closeVariant="white">
          <Modal.Title>
            {modalProps.isUpdate
              ? "Modification d'une actualité"
              : "Ajout d'une actualité"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            noValidate
            validated={validated}
            onSubmit={handleAddOrEditArticle}
          >
            <Form.Group className="mb-3" controlId="formTitle">
              <Form.Label>Titre *</Form.Label>
              <Form.Control
                type="text"
                autoFocus
                required
                value={postArticle.title}
                name="title"
                onChange={handleArticleChange}
                isInvalid={validated && errors.title}
              />
              <Form.Control.Feedback type="invalid">
                Le titre est requis
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="formThumb" className="mb-3">
              <Form.Label>
                Image miniature <StyledSpan>(Taille max : 2M)</StyledSpan>
              </Form.Label>
              <Form.Control
                type="file"
                onChange={handleFileUpload}
                accept="image/*"
                isInvalid={validated && errors["thumbnail.file"]}
              />
              <Form.Control.Feedback type="invalid">
                {errors["thumbnail.file"]}
              </Form.Control.Feedback>
              {thumbnailPreview && (
                <div className="col-md-4">
                  <img
                    src={thumbnailPreview}
                    alt=""
                    className="img-thumbnail"
                  />
                </div>
              )}
            </Form.Group>
            <Form.Group controlId="formImages" className="mb-3">
              <Form.Label>
                Images <StyledSpan>(Taille max par image : 2M)</StyledSpan>
              </Form.Label>
              <Form.Control
                type="file"
                onChange={handleImagesUpload}
                multiple
                accept="image/*"
                isInvalid={validated && imagesErrorArray.length > 0}
              />
              <Form.Control.Feedback type="invalid">
                {imagesErrorArray &&
                  imagesErrorArray.map((txtError) => <div>{txtError}</div>)}
              </Form.Control.Feedback>
              <div className="row ps-2">
                {imagesPreview &&
                  imagesPreview.map((imgPrev: ImagePreview, index: number) => (
                    <div className="col-md-3 p-0" key={index}>
                      <img
                        src={imgPrev.path}
                        alt=""
                        className="img-thumbnail"
                      />
                      <div>
                        <IconButton
                          onClick={() => removeImg(imgPrev)}
                          aria-label="supprimer"
                          color="error"
                        >
                          <DeleteIcon />
                        </IconButton>
                      </div>
                    </div>
                  ))}
              </div>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formDescription">
              <Form.Label>Description *</Form.Label>
              <Form.Control
                as="textarea"
                required
                rows={10}
                onChange={handleArticleChange}
                name="description"
                value={postArticle.description}
              />
              <Form.Control.Feedback type="invalid">
                Une description est requise
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3 " controlId="formVisible">
              <Form.Label className="me-2">Visible</Form.Label>
              <StyledCheck
                inline
                type="switch"
                checked={postArticle.visible}
                onChange={toggleVisible}
              />
            </Form.Group>
            <Form.Group className="mb-3 text-danger">{msgError}</Form.Group>
            <div className="d-flex justify-content-end gap-3 flex-wrap">
              <ButtonPrimary
                label="sauvegarder"
                type="submit"
                disabled={isEditLoading || isPostLoading}
                loading={isEditLoading || isPostLoading}
              />
              <ButtonSecondary
                onClick={modalProps.handleClose}
                label="Annuler"
                disabled={isEditLoading || isPostLoading}
              />
            </div>
          </Form>
        </Modal.Body>
      </StyledModal>
    </>
  )
}

export default ArticleModal
