import WaveHeader from "../../../components/WaveHeader"
import guitareImg from "../../../assets/guitare.jpg"
import { Fragment, useMemo, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import ButtonPrimary from "../../../components/ButtonPrimary"
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons"
import { Loader } from "../../../utils/Atoms"
import { Col, Container } from "react-bootstrap"
import { formatPrice } from "../../../utils/utils"
import InternError from "../../../components/InternError"
import { Breadcrumbs, Typography } from "@mui/material"
import styled from "styled-components"
import colors from "../../../utils/style/colors"
import { Link } from "react-router-dom"
import parcImg from "../../../assets/parc-attraction.jpg"
import { API } from "../../../utils/API"
import { Refund, RefundStatusType, RefundType } from "../../../models/refund"
import { useGetRefundsQuery } from "../../../features/refundSlice"
import CardRefund from "../../../components/CardRefund"
import RefundModal from "../../../components/RefundModal"

const ContainerStyled = styled(Container)`
  h2 {
    font-size: 1.4rem;
    color: ${colors.violet};
  }
  h3 {
    font-size: 1.3rem;
    color: ${colors.darkViolet};
  }
`

const LinkStyled = styled(Link)`
  text-decoration: none;
  color: #929292;
  &:hover {
    text-decoration: underline;
    color: ${colors.violet};
  }
`

function Refunds({ type }: { type: RefundType }) {
  const {
    data: refunds = [],
    isLoading: isRefundLoading,
    isSuccess: isRefundSuccess,
    isError: isRefundError,
  } = useGetRefundsQuery(type)

  const pendingRefundsData = useMemo(() => {
    let datas = refunds.filter(
      (refund: Refund) => refund.status === RefundStatusType.ToHandle
    )

    return datas
  }, [refunds])

  const pastRefundsData = useMemo(() => {
    let datas = refunds.filter(
      (refund: Refund) => refund.status !== RefundStatusType.ToHandle
    )

    return datas
  }, [refunds])

  let pendingRefundsContent
  let pastRefundsContent
  if (isRefundError) {
    pendingRefundsContent = <InternError />
    pastRefundsContent = <InternError />
  } else if (isRefundSuccess) {
    pendingRefundsContent = (
      <Fragment>
        {pendingRefundsData &&
          pendingRefundsData.map((refund: Refund) => (
            <CardRefund refund={refund} key={refund.id} />
          ))}
        {pendingRefundsData &&
          pendingRefundsData.length === 0 &&
          "Aucune demande"}
      </Fragment>
    )

    pastRefundsContent = (
      <Fragment>
        {pastRefundsData &&
          pastRefundsData.map((refund: Refund) => (
            <CardRefund refund={refund} key={refund.id} />
          ))}
        {pastRefundsData && pastRefundsData.length === 0 && "Aucune demande"}
      </Fragment>
    )
  } else if (isRefundLoading) {
    pendingRefundsContent = <Loader />
    pastRefundsContent = <Loader />
  }

  return (
    <Fragment>
      <h2>Vos demandes en cours : </h2>
      <div className="container-fluid px-0 py-3 mb-3 mb-lg-5">
        <Col sm={10} lg={7}>
          {pendingRefundsContent}
        </Col>
      </div>
      <h2>Vos demandes cette année : </h2>
      <div className="container-fluid px-0 py-3 mb-3 mb-lg-5">
        <Col sm={10} lg={7}>
          {pastRefundsContent}
        </Col>
      </div>
    </Fragment>
  )
}

function Culture() {
  const [showRefundModal, setShowRefundModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [isError, setIsError] = useState(false)
  const [total, setTotal] = useState()

  API.get(`refunds/cultural/total`)
    .then((response) => {
      setTotal(response.data)
      setIsSuccess(true)
    })
    .catch((error) => {
      setIsSuccess(false)
      setIsError(true)
    })
    .finally(() => setIsLoading(false))

  let content = isLoading ? (
    <Loader />
  ) : isSuccess ? (
    <div className="my-2 mt-4" style={{ fontStyle: "italic" }}>
      <FontAwesomeIcon icon={faCircleInfo} color={colors.violet} />
      &nbsp;
      {total && total > 0 ? (
        <Fragment>
          Vous avez été remboursé de {formatPrice(total)} cette année. Vous ne
          pouvez plus faire de demandes.
        </Fragment>
      ) : (
        <Fragment>Vous n'avez pas encore fait de demande cette année</Fragment>
      )}
    </div>
  ) : isError ? (
    <InternError />
  ) : (
    ""
  )
  return (
    <div>
      <WaveHeader title="Activités culturelles" image={guitareImg} />
      <ContainerStyled>
        <Breadcrumbs aria-label="breadcrumb" className="mb-4">
          <LinkStyled color="gray" to="/remboursements">
            Mes remboursements
          </LinkStyled>
          <Typography color="text.primary">Activités culturelles</Typography>
        </Breadcrumbs>
        <h2>
          Vous prévoyez d'aller dans un parc d'attraction ? À un concert ?
        </h2>
        <div className="d-flex flex-wrap flex-lg-nowrap gap-4 gap-md-5 py-3 mb-3 mb-lg-5">
          <Col xs={12} lg={6}>
            <img
              src={parcImg}
              alt="Une femme devant un manège"
              className="img-fluid"
              style={{
                width: "100%",
                borderRadius: "4rem",
                boxShadow: "4px 4px 10px grey",
              }}
            />
          </Col>
          <Col xs={12} lg={6} className="mt-md-4">
            <h3>
              Votre CSE participe au remboursement de vos activités culturelles
            </h3>
            <div className="my-2 mt-3">
              La participation du CSE est plafonnée à 50 € par année civile par
              agent sur les activités culturelles diverses. <br />
              <br />
              Attention le remboursement n'est pas fractionnable, un seul
              remboursement par agent par année civile.
            </div>
            {content}
            <br />
            <div className="text-center text-md-start">
              <ButtonPrimary
                label="Faire une demande"
                type="button"
                disabled={isLoading || isError || total}
                onClick={() => setShowRefundModal(true)}
              />
            </div>
          </Col>
        </div>
        <Refunds type={RefundType.Cultural} />
        <RefundModal
          type={RefundType.Cultural}
          show={showRefundModal}
          handleClose={() => setShowRefundModal(false)}
        />
      </ContainerStyled>
    </div>
  )
}

export default Culture
