import { faBasketball, faMusic } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Row } from "react-bootstrap"
import styled from "styled-components"
import {
  getRefundStatusLabel,
  getStatusColor,
  Refund,
  RefundStatusType,
  RefundType,
} from "../../models/refund"
import { API } from "../../utils/API"
import colors from "../../utils/style/colors"
import { cutDocTitle, formatPrice, formatStrToDate } from "../../utils/utils"
import Toastify from "toastify-js"

const StyledSpan = styled.span<{ status: RefundStatusType }>`
  font-size: 0.8rem;
  padding: 0.2rem 0.5rem;
  border-radius: 2rem;
  align-self: center;
  text-align: center;
  background-color: ${(prop) =>
    prop.status ? getStatusColor(prop.status) : "white"};
  color: white;
  margin-left: 0rem;
  white-space: nowrap;

  @media all and (min-width: 400px) {
    margin-left: 0.5rem;
  }
`

const StyledButton = styled.button`
  background-color: transparent;
  border: none;
  font-size: 0.9rem;
  color: ${colors.violet};
  padding: 0;

  &:hover {
    color: ${colors.blue};
  }
`

const StyledRow = styled(Row)`
  background-color: white;
  font-size: 1rem;
  box-shadow: 3px 3px 10px lightgray;
  h3 {
    font-size: 1.1rem !important;
    color: ${colors.darkViolet};
    margin-bottom: 0 !important ;
  }
`

const Title = styled.span`
  font-weight: bold;
`
function CardRefund({ refund }: { refund: Refund }) {
  const handleDownload = (id: number, fileName: string) => {
    API.get(`documents/${id}/download`, {
      responseType: "blob",
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement("a")
        link.href = url
        link.setAttribute("download", fileName)
        document.body.appendChild(link)
        link.click()
      })
      .catch((error) => {
        Toastify({
          text: "Une erreur s'est produite lors du téléchargement",
          duration: 8000,
          newWindow: true,
          close: true,
          gravity: "top", // `top` or `bottom`
          position: "right", // `left`, `center` or `right`
          stopOnFocus: true, // Prevents dismissing of toast on hover
          style: {
            background: "linear-gradient(to right, #29aac4, #bd79e7)",
          },
          onClick: function () {}, // Callback after click
        }).showToast()
      })
  }
  return (
    <StyledRow className="px-2 py-3 m-1 my-3">
      <div className="d-flex flex-wrap gap-md-1 justify-content-between px-1 px-md-2 mb-3 mb-md-2">
        <div className="d-flex gap-2 align-items-center">
          <FontAwesomeIcon
            icon={refund.type === RefundType.Cultural ? faMusic : faBasketball}
            color={colors.violet}
          />
          <h3>Demande du {formatStrToDate(refund.requestDate)} : </h3>
        </div>
        <StyledSpan status={refund.status} className="d-none d-md-block">
          {getRefundStatusLabel(refund.status, true)}
        </StyledSpan>
      </div>
      <div className="mb-2">
        <div style={{ fontWeight: "bold" }}>Numéro : {refund.number}</div>
      </div>
      <div className="mb-2">
        <Title>Intitulé :</Title> {refund.title}
      </div>
      {refund.type === RefundType.Sport && (
        <div className="mb-2">
          <Title>Bénéficiaire :</Title>{" "}
          {refund.recipient
            ? `${refund.recipient.firstname} ${refund.recipient.lastname}`
            : "Moi-même"}
        </div>
      )}
      <div className="mb-2">
        <Title>Détail :</Title>
        <div
          style={{
            backgroundColor: "#e9e9e9",
            borderRadius: "5px",
            padding: "0.8rem",
            fontSize: "0.9rem",
            whiteSpace: "pre-line",
          }}
          className="mt-1"
        >
          {refund.employeeMsg}
        </div>
      </div>
      {refund?.documents && refund.documents.length > 0 && (
        <div className="d-flex flex-wrap gap-3 my-2">
          <Title>Pièce(s)-jointe(s):</Title>
          <div className="d-flex flex-wrap gap-3">
            {refund.documents.map((doc) => (
              <StyledButton
                onClick={() => handleDownload(doc.id, doc.path)}
                key={doc.id}
              >
                {cutDocTitle(doc.title)}
              </StyledButton>
            ))}
          </div>
        </div>
      )}
      <div className="mb-2">
        <Title>Montant total :</Title> {formatPrice(refund.price)}
      </div>
      <div className="d-flex flex-wrap justify-content-between mb-1">
        <div style={{ fontWeight: "bold" }}>
          À rembourser : {formatPrice(refund.repaymentAmount)}
        </div>
        <StyledSpan status={refund.status} className="d-md-none">
          {getRefundStatusLabel(refund.status, true)}
        </StyledSpan>
      </div>
    </StyledRow>
  )
}

export default CardRefund
