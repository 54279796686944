import { faFilm, faSwimmer } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { EscalatorWarning, Man } from "@mui/icons-material"
import { Fragment } from "react"
import { Row } from "react-bootstrap"
import styled from "styled-components"
import {
  CardOrderDto,
  getOrderStatusLabel,
  getStatusColor,
  OrderStatusType,
  OrderType,
} from "../../models/order"
import colors from "../../utils/style/colors"
import { formatPrice, formatStrToDate } from "../../utils/utils"

const StyledSpan = styled.span<{ status: OrderStatusType }>`
  font-size: 0.8rem;
  padding: 0.2rem 0.5rem;
  border-radius: 2rem;
  align-self: center;
  text-align: center;
  white-space: nowrap;
  background-color: ${(prop) =>
    prop.status ? getStatusColor(prop.status) : "white"};
  color: white;
  margin-left: 0.5rem;
`
const StyledRow = styled(Row)`
  background-color: white;
  font-size: 1rem;
  box-shadow: 3px 3px 10px lightgray;
  h3 {
    font-size: 1.1rem !important;
    color: ${colors.darkViolet};
    margin-bottom: 0 !important ;
  }
`
function CardOrder({ order }: { order: CardOrderDto }) {
  return (
    <StyledRow className="px-2 py-3 m-1 my-3">
      <div className="d-flex flex-wrap gap-md-1 justify-content-between px-1 px-md-2 mb-3 mb-md-2">
        <div className="d-flex gap-2 align-items-center">
          <FontAwesomeIcon
            icon={order.type === OrderType.Movie ? faFilm : faSwimmer}
            color={colors.violet}
          />
          <h3>Commande du {formatStrToDate(order.orderDate)} : </h3>
        </div>
        <StyledSpan status={order.status} className="d-none d-md-block">
          {getOrderStatusLabel(order.status, true)}
        </StyledSpan>
      </div>
      <div className="px-1 px-md-2 pb-1">
        <div style={{ fontWeight: "bold" }}>Numéro : {order.number}</div>
      </div>
      <div className="px-1 px-md-2">
        <Man sx={{ color: colors.orange }} /> Nombre de{" "}
        {order.type === OrderType.Movie ? "places adulte" : "cartes"} :{" "}
        {order.adultQuantity}
        <br />
        {order.type === OrderType.Movie && (
          <Fragment>
            <EscalatorWarning sx={{ color: colors.blue }} /> Nombre de{" "}
            {order.type === OrderType.Movie ? "places" : "cartes"} enfant :{" "}
            {order.childQuantity}
          </Fragment>
        )}
      </div>
      <div className="d-flex flex-wrap justify-content-between mt-2">
        <div style={{ fontWeight: "bold" }}>
          Total : {formatPrice(order.amount)}
        </div>
        <StyledSpan status={order.status} className="d-md-none">
          {getOrderStatusLabel(order.status, true)}
        </StyledSpan>
      </div>
    </StyledRow>
  )
}

export default CardOrder
