import { IconButton, Switch, Tooltip } from "@mui/material"
import { useState } from "react"
import { Container } from "react-bootstrap"
import DataTable from "react-data-table-component"
import styled from "styled-components"
import {
  useDeleteDocumentMutation,
  useGetDocumentsAdminWithPaginationQuery,
} from "../../../features/documentSlice"
import colors from "../../../utils/style/colors"
import { formatStrToDate } from "../../../utils/utils"
import DeleteIcon from "@mui/icons-material/Delete"
import ModeEditIcon from "@mui/icons-material/ModeEdit"
import DownloadIcon from "@mui/icons-material/Download"
import ButtonPrimary from "../../../components/ButtonPrimary"
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons"
import DocumentModal from "../../../components/DocumentModal"
import ValidateModal from "../../../components/ValidateModal"
import { API } from "../../../utils/API"
import Toastify from "toastify-js"
import { Loader } from "../../../utils/Atoms"

const StyledDiv = styled(Container)`
  padding: 2rem 0.5rem 2rem 2rem;

  h1 {
    font-size: 1.4rem;
    color: ${colors.violet};
    text-align: center;
    margin-bottom: 2rem;
  }

  @media all and (min-width: 575px) {
    padding: 2rem 0.5rem 2rem 2.5rem;
  }

  @media all and (min-width: 768px) {
    padding: 2rem 0.5rem 2rem 3rem;
    h1 {
      font-size: 1.7rem;
    }
  }
`

function DocumentsAdmin() {
  const paginationComponentOptions = {
    rowsPerPageText: "Documents par page",
    rangeSeparatorText: "de",
  }

  const columns = [
    {
      name: "Nom",
      selector: (row: any) => row.title,
      sortable: true,
      sortField: "title",
    },
    {
      name: "Créé le",
      selector: (row: any) => row.createdAt,
      sortable: true,
      sortField: "createdAt",
      format: (row: any) => formatStrToDate(row.createdAt, "dd/MM/yyyy HH:mm"),
    },
    {
      name: "Visible",
      selector: (row: any) => (
        <Switch color="secondary" checked={row.visible} />
      ),
      sortable: true,
      sortField: "visible",
    },
    {
      name: "Actions",
      cell: (row: any) => (
        <div>
          <Tooltip title="Télécharger">
            <IconButton
              onClick={() => handleDownload(row.id, row.path)}
              aria-label="télécharger"
            >
              <DownloadIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Modifier">
            <IconButton
              onClick={() => handleRowClicked(row.id)}
              aria-label="modifier"
            >
              <ModeEditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Supprimer">
            <IconButton
              onClick={() => handleRemove(row.id)}
              aria-label="supprimer"
              color="error"
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      wrap: true,
      minWidth: "8rem",
    },
  ]

  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(10)
  const [showDialog, setShowDialog] = useState(false)
  const [documentId, setDocumentId] = useState<number | undefined>()
  const [showConfirmDelete, setShowConfirmDelete] = useState(false)
  const [deleteDocument, { isLoading: isDeleteLoading }] =
    useDeleteDocumentMutation()
  const [order, setOrder] = useState({
    orderBy: "createdAt",
    direction: "DESC",
  })

  const {
    data: documentsPaginate = [],
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetDocumentsAdminWithPaginationQuery({
    limit: perPage,
    page: page,
    orderBy: order.orderBy,
    direction: order.direction,
  })

  const handlePageChange = (page: number) => {
    setPage(page)
  }

  const handlePerRowsChange = (newPerPage: number) => {
    setPerPage(newPerPage)
  }

  const handleSort = (selectedColumn: any, sortDirection: any) => {
    setOrder({
      orderBy: selectedColumn.sortField,
      direction: sortDirection,
    })
  }

  const handleShow = () => {
    setDocumentId(undefined)
    setShowDialog(true)
  }
  const handleClose = () => setShowDialog(false)
  const handleCloseConfirm = () => setShowConfirmDelete(false)
  const handleRowClicked = (id: number) => {
    setDocumentId(id)
    setShowDialog(true)
  }

  const handleRemove = (id: number) => {
    setDocumentId(id)
    setShowConfirmDelete(true)
  }

  const handleDownload = (id: number, fileName: string) => {
    API.get(`documents/${id}/download`, {
      responseType: "blob",
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement("a")
        link.href = url
        link.setAttribute("download", fileName)
        document.body.appendChild(link)
        link.click()
      })
      .catch((error) => {
        Toastify({
          text: "Une erreur s'est produite lors du téléchargement",
          duration: 8000,
          newWindow: true,
          close: true,
          gravity: "top", // `top` or `bottom`
          position: "right", // `left`, `center` or `right`
          stopOnFocus: true, // Prevents dismissing of toast on hover
          style: {
            background: "linear-gradient(to right, #29aac4, #bd79e7)",
          },
          onClick: function () {}, // Callback after click
        }).showToast()
      })
  }

  const handleDeleteDocument = () => {
    if (documentId) {
      deleteDocument(documentId)
        .unwrap()
        .then(() => {
          setShowConfirmDelete(false)
          Toastify({
            text: "Le document a bien été supprimé",
            duration: 8000,
            newWindow: true,
            close: true,
            gravity: "top", // `top` or `bottom`
            position: "right", // `left`, `center` or `right`
            stopOnFocus: true, // Prevents dismissing of toast on hover
            style: {
              background: "linear-gradient(to right, #29aac4, #bd79e7)",
            },
            onClick: function () {}, // Callback after click
          }).showToast()
        })
    }
  }

  return (
    <StyledDiv fluid>
      <h1>Gestion des documents</h1>
      <div>
        <ButtonPrimary
          label="Ajouter"
          icon={faPlusCircle}
          className="mb-3"
          onClick={handleShow}
        />
        <DataTable
          columns={columns}
          data={documentsPaginate.items}
          progressPending={isLoading}
          pagination
          paginationServer
          paginationTotalRows={documentsPaginate.totalItems}
          paginationPerPage={perPage}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          onRowClicked={(row) => handleRowClicked(row.id)}
          onSort={handleSort}
          sortServer
          defaultSortFieldId={2}
          defaultSortAsc={false}
          responsive
          highlightOnHover
          pointerOnHover
          noDataComponent="Aucun document"
          progressComponent={<Loader></Loader>}
          paginationComponentOptions={paginationComponentOptions}
        ></DataTable>
      </div>
      {showDialog && (
        <DocumentModal
          documentId={documentId}
          show={showDialog}
          handleClose={handleClose}
          isUpdate={documentId !== undefined}
        />
      )}
      {showConfirmDelete && (
        <ValidateModal
          show={showConfirmDelete}
          handleClose={handleCloseConfirm}
          modalMessage="La suppression est définitive, êtes-vous sûr de vouloir continuer ?"
          modalTitle="Suppression d'un document"
          handleConfirm={handleDeleteDocument}
          loading={isDeleteLoading}
        />
      )}
    </StyledDiv>
  )
}

export default DocumentsAdmin
