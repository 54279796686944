import { faChevronRight, faCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useMemo } from "react"
import { Link } from "react-router-dom"
import styled from "styled-components"
import { useGetArticlesQuery } from "../../features/articleSlice"
import Article from "../../models/article"
import { Loader } from "../../utils/Atoms"
import colors from "../../utils/style/colors"
import { cutText, formatStrToDate } from "../../utils/utils"
import InternError from "../InternError"

const StyledDiv = styled.header`
  background: #fcfcfc 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 35px #00000008;
  border-radius: 7px;
`

const StyledIcon = styled(FontAwesomeIcon)`
  font-size: 0.5rem;
  padding: 0.2rem;
`
const StyledLink = styled(Link)`
  color: rgba(0, 0, 0, 0.3);
  font-size: 1rem;
  text-decoration: none;
  &:hover,
  &.active {
    color: ${colors.blue}!important;
  }
`

const StyledDate = styled.div`
  font-size: 0.7rem;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.5);
`
const StyledTitle = styled.div`
  font-size: 1rem;
  font-weight: bold;
`

const StyledDesc = styled.div`
  font-size: 0.9rem;
`

const StyledImg = styled.img`
  max-height: 20rem;
  object-fit: cover;
  @media all and (min-width: 992px) {
    height: inherit;
  }
`
const NewsLink = styled(Link)`
  text-decoration: none;
  color: black;

  &:hover {
    color: ${colors.violet};
  }
`

function NewsBloc() {
  const {
    data: articles = [],
    isLoading,
    isSuccess,
    isError,
    error,
    refetch,
  } = useGetArticlesQuery()

  const sortedArticles = useMemo(() => {
    const sortedArticles = articles.slice()
    // Sort posts in descending chronological order
    sortedArticles.sort((a: Article, b: Article) =>
      b.createdAt.localeCompare(a.createdAt)
    )

    return sortedArticles.slice(0, 3)
  }, [articles])

  /*useEffect(() => {
    refetch()
  }, [])*/

  let content

  if (isLoading) {
    content = <Loader />
  } else if (isSuccess) {
    content = (
      <div className="d-flex flex-column p-2 p-md-4 pt-2">
        {sortedArticles &&
          sortedArticles.map((newsItem: Article, index: number) => (
            <NewsLink
              to={`news/${newsItem.id}`}
              key={newsItem.id}
              className="container-fluid"
            >
              <div className="row py-2">
                <StyledImg
                  className="rounded img-fluid col-lg-4 p-0"
                  src={`${process.env.REACT_APP_API_ARTICLE_URL}/${newsItem.thumbnail?.name}`}
                  alt={newsItem.thumbnail.alt}
                />
                <div className="d-flex flex-column px-3 py-2 col-lg-8">
                  <StyledDate>
                    {formatStrToDate(newsItem.createdAt, "PPP")}
                  </StyledDate>
                  <StyledTitle>{newsItem.title}</StyledTitle>
                  <StyledDesc>{cutText(newsItem.description)}</StyledDesc>
                </div>
              </div>
            </NewsLink>
          ))}
      </div>
    )
  } else if (isError) {
    content = <InternError />
  }

  return (
    <StyledDiv>
      <div className="d-flex justify-content-between px-4 pt-4 flex-wrap">
        <h3 style={{ fontSize: "1.4rem" }}>
          <StyledIcon icon={faCircle} color={colors.violet}></StyledIcon>{" "}
          Dernières actualités
        </h3>
        <StyledLink to="/news">
          Voir tout <FontAwesomeIcon icon={faChevronRight}></FontAwesomeIcon>
        </StyledLink>
      </div>
      {content}
    </StyledDiv>
  )
}

export default NewsBloc
