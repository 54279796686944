export enum RelationshipType {
  Child = "CHILD",
  Spouse = "SPOUSE",
}

export function getRelationshipLabel(relation: RelationshipType | string) {
  switch (relation) {
    case RelationshipType.Child:
      return "Enfant"
    case RelationshipType.Spouse:
      return "Conjoint(e)"
    default:
      return relation
  }
}
export default interface Member {
  id: number
  firstname: string
  lastname: string
  birthDate: string
  relationship: RelationshipType
  isEditable: boolean
}
