import { Form } from "react-bootstrap"
import styled from "styled-components"
import ButtonPrimary from "../../components/ButtonPrimary"
import bgImage from "../../assets/bus.jpg"
import { useState } from "react"
import { LoginRequest } from "../../features/api/apiSlice"
import { useAuth } from "../../utils/hooks/useAuth"
import { useToggle } from "../../utils/hooks/useToggle"
import { Link } from "react-router-dom"

const StyledDiv = styled.div`
  background-color: white;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 90vw;
  align-self: center;
  border-radius: 15px;
  box-shadow: grey 2px 2px 15px;
  @media (min-width: 576px) {
    padding: 2rem;
    width: 22rem;
  }
`

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.6),
      rgba(83, 44, 110, 0.5)
    ),
    url(${bgImage});
  background-size: cover;
  background-position: center;
  min-height: 100vh;
`

const StyledLink = styled(Link)`
  border: none;
  color: grey;
  font-style: italic;
  background-color: transparent;
`

function Login() {
  const [isPasswordVisible, togglePasswordVisibility] = useToggle(false)
  const [validated, setValidated] = useState(false)
  const [formState, setFormState] = useState<LoginRequest>({
    username: "",
    password: "",
  })

  const {
    tryLogin: { login, isLoading },
  } = useAuth()

  const canSubmit = formState.username && formState.password && !isLoading

  const handleChange = ({
    target: { name, value },
  }: React.ChangeEvent<HTMLInputElement>) =>
    setFormState((prev) => ({ ...prev, [name]: value }))

  const handleSubmit = (event: any) => {
    event.preventDefault()
    event.stopPropagation()
    const form = event.currentTarget
    if (form.checkValidity() === false) {
      return
    } else {
      setValidated(true)
      login(formState)
    }
  }

  return (
    <StyledContainer>
      <h1 className="text-center p-4 pb-5" style={{ color: "white" }}>
        CSE RDAM
      </h1>
      <StyledDiv>
        <h2 className="text-center pb-4 fs-2 violet">Connexion</h2>
        <Form onSubmit={(e) => handleSubmit(e)} validated={validated}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Adresse email</Form.Label>
            <Form.Control
              required
              type="email"
              placeholder="Entrez votre email"
              onChange={handleChange}
              name="username"
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Mot de passe</Form.Label>
            <Form.Control
              type={isPasswordVisible ? "text" : "password"}
              placeholder="Entrez votre mot de passe"
              required
              onChange={handleChange}
              name="password"
            />
            <Form.Text className="text-muted float-end pb-4">
              <StyledLink to="/reset-password">
                Mot de passe oublié ?
              </StyledLink>
            </Form.Text>
          </Form.Group>
          <div className="d-flex justify-content-center mt-4 w-100">
            <ButtonPrimary
              label="Se connecter"
              type="submit"
              loading={isLoading}
              disabled={!canSubmit}
            />
          </div>
        </Form>
      </StyledDiv>
    </StyledContainer>
  )
}

export default Login
