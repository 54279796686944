import { Link } from "react-router-dom"
import WaveHeader from "../../components/WaveHeader"
import bgImage from "../../assets/lost.jpg"
import { Container } from "react-bootstrap"

function NoMatch() {
  return (
    <div>
      <WaveHeader title="Page inexistante" image={bgImage} />
      <Container className="text-center">
        <h2>La page n'a pas été trouvée</h2>
        <p>
          <Link to="/">Retour à la page d'accueil</Link>
        </p>
      </Container>
    </div>
  )
}

export default NoMatch
