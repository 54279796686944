import {
  faAddressBook,
  faBoxOpen,
  faBuildingColumns,
  faCrown,
  faEuroSign,
  faFileArrowDown,
  faFileImport,
  faHandHoldingDollar,
  faNewspaper,
  faSignOut,
  faUserPlus,
  faWarehouse,
} from "@fortawesome/free-solid-svg-icons"
import {
  styled as muiStyled,
  useTheme,
  Theme,
  CSSObject,
} from "@mui/material/styles"
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import MuiDrawer from "@mui/material/Drawer"
import {
  Box,
  CssBaseline,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
} from "@mui/material"
import { Button } from "react-bootstrap"
import {
  Link,
  Outlet,
  useMatch,
  useNavigate,
  useResolvedPath,
} from "react-router-dom"
import styled from "styled-components"
import User from "../../models/user"
import { useAuth } from "../../utils/hooks/useAuth"
import colors from "../../utils/style/colors"
import ButtonPrimary from "../ButtonPrimary"
import { Fragment, useState } from "react"
import MenuIcon from "@mui/icons-material/Menu"

const StyledDiv = styled.div`
  font-size: 0.7rem;
  align-items: center;

  @media (max-width: 576px) {
    button {
      font-size: 0.7rem;
      padding: 0.2rem 0.3rem;
    }
  }

  @media (min-width: 768px) {
    font-size: 1rem;
  }
`

const drawerWidth = 240

interface AppBarProps extends MuiAppBarProps {
  open?: boolean
}

const AppBar = muiStyled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  backgroundColor: "#202020",
  color: "white",
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}))

const openedMixin = (theme: Theme): CSSObject => ({
  backgroundColor: colors.violet,
  color: "white",
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
})

const closedMixin = (theme: Theme): CSSObject => ({
  backgroundColor: colors.violet,
  color: "white",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
})

const Drawer = muiStyled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  zIndex: 200,
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}))

const DrawerHeader = muiStyled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}))

function CustomButton({ menu, open, handleMenuItemClick }: any) {
  let resolved = useResolvedPath(menu.path)
  let match = useMatch({ path: resolved.pathname, end: true })

  return (
    <ListItemButton
      sx={{
        minHeight: 48,
        justifyContent: open ? "initial" : "center",
        px: 2.5,
      }}
      onClick={handleMenuItemClick}
    >
      <ListItemIcon
        title={menu.text}
        sx={{
          minWidth: 0,
          mr: open ? 3 : "auto",
          justifyContent: "center",
        }}
      >
        <FontAwesomeIcon
          icon={menu.icon}
          style={{ color: match ? colors.blue : "white", width: "20px" }}
        ></FontAwesomeIcon>
      </ListItemIcon>
      <ListItemText
        primary={menu.text}
        sx={{ opacity: open ? 1 : 0 }}
        style={{ color: match ? colors.blue : "white" }}
      />
    </ListItemButton>
  )
}

function AdminMenu({ user }: { user: User }) {
  const [open, setOpen] = useState(false)
  const theme = useTheme()
  const { logout } = useAuth()

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  const menuItems = [
    {
      text: "Ajouter utilisateur",
      path: "/superadmin/register",
      icon: faUserPlus,
    },
    {
      text: "Import utilisateurs",
      path: "/superadmin/import-users",
      icon: faFileImport,
    },

    {
      text: "Import commandes piscine",
      path: "/superadmin/import-pool-orders",
      icon: faFileImport,
    },
  ]

  const navigate = useNavigate()

  const handleMenuItemClick = (menu: any) => {
    setOpen(false)
    navigate(menu.path)
  }

  return (
    <Fragment>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar sx={{ paddingRight: 1 }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 2,
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <StyledDiv className="d-flex w-100 justify-content-between gap-1">
            <div>
              <Link to="/">
                <ButtonPrimary size="small" label="Retour sur le site" />
              </Link>
            </div>
            <div className="d-flex align-items-center gap-1">
              <FontAwesomeIcon icon={faCrown} color="#e6c619"></FontAwesomeIcon>{" "}
              {user.email}
              <Button
                variant="link"
                style={{ color: "white" }}
                title="Déconnexion"
                onClick={logout}
              >
                <FontAwesomeIcon icon={faSignOut} />
              </Button>
            </div>
          </StyledDiv>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon style={{ color: "white" }} />
            ) : (
              <ChevronLeftIcon style={{ color: "white" }} />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {menuItems.map((menu, index) => (
            <ListItem key={menu.text} disablePadding sx={{ display: "block" }}>
              <CustomButton
                menu={menu}
                open={open}
                handleMenuItemClick={() => handleMenuItemClick(menu)}
              ></CustomButton>
            </ListItem>
          ))}
        </List>
      </Drawer>
    </Fragment>
  )
}

export default function SuperAdminLayout() {
  const {
    loggedUser: { user },
  } = useAuth()

  let content

  if (user?.roles.includes("ROLE_SUPER_ADMIN")) {
    content = (
      <div>
        {/* <AdminTopBar user={user} /> */}
        <AdminMenu user={user} />
        <Box
          component="main"
          sx={{ flexGrow: 1, paddingLeft: 4, paddingRight: 1, paddingTop: 7 }}
        >
          <Outlet />
        </Box>
      </div>
    )
  } else {
    content = <div>"Vous n'avez pas les droits pour accéder à cette page"</div>
  }
  return content
}
