import { Col, FloatingLabel, Form, Modal, Row } from "react-bootstrap"
import ButtonPrimary from "../ButtonPrimary"
import styled from "styled-components"
import colors from "../../utils/style/colors"
import ButtonSecondary from "../ButtonSecondary"
import { useEffect, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBox } from "@fortawesome/free-solid-svg-icons"
import { useAddOtherOrderMutation } from "../../features/orderSlice"
import File from "../../models/file"
import OtherOrder from "../../models/otherOrder"
import { ResponseApiType } from "../../models/responseApiType"
import { Button } from "@mui/material"

interface ModalProps {
  show: boolean
  handleClose: () => void
}

const StyledModal = styled(Modal)`
  font-size: 0.9rem;
  h2 {
    font-size: 1rem;
    font-weight: 300;
    padding-left: 0.5rem;

    @media (min-width: 768px) {
      font-size: 1.2rem;
    }
  }

  .prices span,
  .total span {
    font-weight: bold;
  }

  .modal-header {
    background-color: ${colors.violet};
    color: white;
  }
`

const StyledSpan = styled.span`
  color: grey;
  font-size: 0.8rem;
  font-style: italic;
`

function OtherOrderModal(modalProps: ModalProps) {
  const init = { amount: null, employeeMessage: "", title: "" } as OtherOrder
  const [validated, setValidated] = useState(false)
  const [otherOrder, setOtherOrder] = useState<OtherOrder>(init)
  const [errors, setErrors] = useState({} as any)
  const [msgError, setMsgError] = useState<string>()
  const [documentsErrorArray, setDocumentsErrorArray] = useState([] as any[])
  const [addOrder, { isLoading: isPostLoading }] = useAddOtherOrderMutation()
  const [postDocuments, setPostDocuments] = useState([] as File[])

  const handleCardOrderChange = ({
    target: { name, value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setOtherOrder((prev) => ({ ...prev, [name]: value }))
  }

  const handleAmountChange = ({
    target,
  }: React.ChangeEvent<HTMLInputElement>) => {
    if (target.validity.valid) {
      setOtherOrder({
        ...otherOrder,
        amount: parseFloat(target.value),
      })
    } else if (target.value == "") {
      //input is not numeric
      setOtherOrder({
        ...otherOrder,
        amount: undefined,
      })
    }
  }

  const handleAddOtherOrder = (event: any) => {
    const form = event.currentTarget
    event.preventDefault()
    event.stopPropagation()
    if (form.checkValidity() === false) {
      setValidated(true)
    } else {
      let newOrder = {
        amount: otherOrder.amount,
        employeeMessage: otherOrder.employeeMessage,
        documents: postDocuments,
        title: otherOrder.title,
      } as OtherOrder

      addOrder(newOrder)
        .unwrap()
        .then(() => modalProps.handleClose())
        .catch((error: any) => {
          if (error.data?.type === ResponseApiType.FormError) {
            setMsgError("Veuillez vérifier les données")
            setErrors(error.data?.detail)
            setValidated(true)
          } else {
            setMsgError("Une erreur interne s'est produite.")
          }

          console.log(errors)
        })
    }
  }

  const convertToBase64 = (file: any): Promise<string> => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader()
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result as string)
      }
      fileReader.onerror = (error) => {
        reject(error)
      }
    })
  }

  const handleDocUpload = (e: any) => {
    setPostDocuments([])
    const files = e.target.files
    Object.entries(files).forEach(
      async ([key, file]: [key: any, file: any]) => {
        await convertToBase64(file)
          .then((result) => {
            setPostDocuments((docArr) => [
              ...docArr,
              {
                data: result,
                filename: file.name,
                alt: "",
                fileBlob: file,
              } as File,
            ])
          })
          .catch((error) => console.error(error))
      }
    )
  }

  const removeDoc = (doc: File) => {
    //remove from postImages
    const newPostDocs = postDocuments.filter(
      (filePrev: File) => filePrev.fileBlob !== doc.fileBlob
    )

    setPostDocuments(newPostDocs)
  }

  useEffect(() => {
    if (modalProps.show) {
      setValidated(false)
      setPostDocuments([])
      setOtherOrder(init)
      setMsgError("")
      setErrors({})
      setDocumentsErrorArray([])
    }
  }, [modalProps.show])

  useEffect(() => {
    let imagesErrors: any[] = []
    postDocuments.forEach((postDoc, index) => {
      let msg = errors[`documents[${index}].file`]
      if (msg) {
        imagesErrors.push(`Fichier ${index + 1}: ${msg[0]}`)
      }
    })

    if (imagesErrors.length > 0) setDocumentsErrorArray(imagesErrors)
  }, [errors])

  return (
    <>
      <StyledModal
        show={modalProps.show}
        onHide={modalProps.handleClose}
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-width p-0"
        centered
      >
        <Modal.Header closeButton closeVariant="white">
          <Modal.Title className="d-flex gap-2 pt-1 align-items-center align-items-sm-start">
            <FontAwesomeIcon icon={faBox} color="white" />
            <h2>Commander divers</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-4">
          <Form noValidate validated={validated} onSubmit={handleAddOtherOrder}>
            <Row className="mb-3 ps-2">
              Veuillez renseigner les champs ci-dessous :
            </Row>
            <Form.Group className="mb-3" controlId="formTitle">
              <FloatingLabel controlId="title" label="Titre (ex: Parfums) *">
                <Form.Control
                  type="text"
                  placeholder="Titre"
                  autoFocus
                  required
                  maxLength={70}
                  value={otherOrder.title}
                  name="title"
                  onChange={handleCardOrderChange}
                  isInvalid={validated && errors.title}
                />
                <Form.Control.Feedback type="invalid">
                  Le titre est requis
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
            <Form.Group className="mb-4" controlId="formMessage">
              <FloatingLabel
                controlId="message"
                label="Détail de la commande *"
              >
                <Form.Control
                  placeholder="Détail de la commande"
                  aria-label="Détail de la commande"
                  name="employeeMessage"
                  as="textarea"
                  style={{ height: "15rem" }}
                  required
                  value={otherOrder.employeeMessage}
                  onChange={handleCardOrderChange}
                  isInvalid={validated && errors?.employeeMessage}
                />
                {errors && errors.employeeMessage ? (
                  <Form.Control.Feedback
                    type="invalid"
                    style={{ display: "block" }}
                  >
                    {errors &&
                      errors.employeeMessage?.map(
                        (error: any, index: number) => (
                          <div key={index}>{error}</div>
                        )
                      )}
                  </Form.Control.Feedback>
                ) : (
                  <Form.Control.Feedback type="invalid">
                    Détail requis
                  </Form.Control.Feedback>
                )}
              </FloatingLabel>
            </Form.Group>
            <Form.Group controlId="formDocuments" className="mb-4">
              <Form.Label>
                Pièce(s)-jointe(s){" "}
                <StyledSpan>(Taille max par document : 2M)</StyledSpan>
              </Form.Label>
              <Form.Control
                type="file"
                onChange={handleDocUpload}
                multiple
                isInvalid={validated && documentsErrorArray.length > 0}
              />
              <Form.Control.Feedback type="invalid">
                {documentsErrorArray &&
                  documentsErrorArray.map((txtError) => <div>{txtError}</div>)}
              </Form.Control.Feedback>
              <div className="d-flex flex-wrap gap-2 mt-1">
                {postDocuments &&
                  postDocuments.map((doc, index) => (
                    <div key={index}>
                      <span className="fst-italic">{doc.filename}</span>
                      <div>
                        <Button
                          onClick={() => removeDoc(doc)}
                          variant="text"
                          color="error"
                        >
                          Supprimer
                        </Button>
                      </div>
                    </div>
                  ))}
              </div>
            </Form.Group>
            <Form.Group className="mb-3 total" as={Col} controlId="formTotal">
              <FloatingLabel controlId="amount" label="Montant total (€) *">
                <Form.Control
                  placeholder="Montant total"
                  aria-label="Montant total"
                  name="amount"
                  required
                  type="number"
                  step={0.01}
                  min={0}
                  value={otherOrder?.amount ?? ""}
                  onChange={handleAmountChange}
                  isInvalid={validated && errors?.amount}
                />
                {errors && errors.amount ? (
                  <Form.Control.Feedback
                    type="invalid"
                    style={{ display: "block" }}
                  >
                    {errors &&
                      errors.amount?.map((error: any, index: number) => (
                        <div key={index}>{error}</div>
                      ))}
                  </Form.Control.Feedback>
                ) : (
                  <Form.Control.Feedback type="invalid">
                    Format incorrect
                  </Form.Control.Feedback>
                )}
              </FloatingLabel>
            </Form.Group>

            <Form.Group className="my-3 text-danger">{msgError}</Form.Group>
            <div className="d-flex justify-content-end gap-3 flex-wrap">
              <ButtonPrimary
                label="Valider"
                type="submit"
                loading={isPostLoading}
                disabled={!((otherOrder?.amount ?? 0) > 0)}
              />
              <ButtonSecondary
                onClick={modalProps.handleClose}
                label="Annuler"
              />
            </div>
          </Form>
        </Modal.Body>
      </StyledModal>
    </>
  )
}

export default OtherOrderModal
