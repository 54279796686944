import { XCircleFill } from "react-bootstrap-icons"

function InternError() {
  return (
    <div className="text-center">
      <XCircleFill color="#dc3545" size={60}></XCircleFill>
      <div className="p-5">Une erreur s'est produite, veuillez réessayer</div>
    </div>
  )
}

export default InternError
