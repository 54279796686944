import { Paiement } from "./paiement"

export enum OperationType {
  Debit = "Debit",
  Credit = "Credit",
}

export enum Operationfrom {
  OtherOrderAdvance = "OtherOrderAdvance",
  CardStockOrder = "CardStockOrder",
  Refund = "Refund",
  Manual = "Manual",
  BankDeposit = "BankDeposit",
}
export default interface Operation {
  id: number
  title: string
  detail: string
  amount: number
  operationDate: string
  type: OperationType
  operationFrom?: Operationfrom
  paymentDebit?: Paiement
  bankDeposit?: { id: number }
  createdAt: string
  updatedAt?: string
}

export function getOperationTypeLabel(op: OperationType | string) {
  switch (op) {
    case OperationType.Debit:
      return "Débit"
    case OperationType.Credit:
      return "Crédit"
    default:
      return op
  }
}
