import { IconButton, Switch, Tooltip } from "@mui/material"
import { useState } from "react"
import { Container } from "react-bootstrap"
import styled from "styled-components"
import colors from "../../../utils/style/colors"
import DeleteIcon from "@mui/icons-material/Delete"
import ModeEditIcon from "@mui/icons-material/ModeEdit"
import { cutText, formatStrToDate } from "../../../utils/utils"
import ButtonPrimary from "../../../components/ButtonPrimary"
import DataTable from "react-data-table-component"
import { faEye, faPlusCircle } from "@fortawesome/free-solid-svg-icons"
import {
  useDeleteArticleMutation,
  useGetArticlesAdminWithPaginationQuery,
} from "../../../features/articleSlice"
import { Link } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import ArticleModal from "../../../components/ArticleModal"
import ValidateModal from "../../../components/ValidateModal"
import Toastify from "toastify-js"
import { Loader } from "../../../utils/Atoms"

const StyledDiv = styled(Container)`
  padding: 2rem 0.5rem 2rem 2rem;

  h1 {
    font-size: 1.4rem;
    color: ${colors.violet};
    text-align: center;
    margin-bottom: 2rem;
  }

  @media all and (min-width: 575px) {
    padding: 2rem 0.5rem 2rem 2.5rem;
  }

  @media all and (min-width: 768px) {
    padding: 2rem 0.5rem 2rem 3rem;
    h1 {
      font-size: 1.7rem;
    }
  }
`

function NewsAdmin() {
  const handleRemove = (id: number) => {
    setArticleId(id)
    setShowConfirmDelete(true)
  }

  const paginationComponentOptions = {
    rowsPerPageText: "Actualités par page",
    rangeSeparatorText: "de",
  }

  const columns: any = [
    {
      name: "Titre",
      selector: (row: any) => row.title,
      sortable: true,
      sortField: "title",
      maxWidth: "22rem",
    },
    {
      name: "Description",
      selector: (row: any) => row.description,
      format: (row: any) => cutText(row.description, 100),
      sortable: true,
      sortField: "description",
      wrap: true,
    },
    {
      name: "Modifiée le",
      selector: (row: any) => row.updatedAt,
      sortable: true,
      sortField: "updatedAt",
      format: (row: any) => formatStrToDate(row.updatedAt, "dd/MM/yyyy HH:mm"),
    },
    {
      name: "Visible",
      selector: (row: any) => (
        <Switch color="secondary" checked={row.visible} />
      ),
      sortable: true,
      sortField: "visible",
    },
    {
      name: "Actions",
      cell: (row: any) => (
        <div>
          <Tooltip title="Voir">
            <Link
              to={`/news/${row.id}`}
              target="_blank"
              style={{ color: colors.blue }}
            >
              <FontAwesomeIcon icon={faEye} size="lg"></FontAwesomeIcon>
            </Link>
          </Tooltip>
          <Tooltip title="Modifier">
            <IconButton
              onClick={() => handleRowClicked(row.id)}
              aria-label="modifier"
            >
              <ModeEditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Supprimer">
            <IconButton
              onClick={() => handleRemove(row.id)}
              aria-label="supprimer"
              color="error"
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      wrap: true,
      minWidth: "8rem",
    },
  ]
  const [articleId, setArticleId] = useState<number | undefined>()
  const [showDialog, setShowDialog] = useState(false)
  const [showConfirmDelete, setShowConfirmDelete] = useState(false)
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(10)
  const [deleteArticle, { isLoading: isDeleteLoading }] =
    useDeleteArticleMutation()
  const [order, setOrder] = useState({
    orderBy: "updatedAt",
    direction: "DESC",
  })

  const {
    data: articlesPaginate = [],
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetArticlesAdminWithPaginationQuery({
    limit: perPage,
    page: page,
    orderBy: order.orderBy,
    direction: order.direction,
  })

  const handlePageChange = (page: number) => {
    setPage(page)
  }

  const handlePerRowsChange = (newPerPage: number) => {
    setPerPage(newPerPage)
  }

  const handleSort = (selectedColumn: any, sortDirection: any) => {
    setOrder({
      orderBy: selectedColumn.sortField,
      direction: sortDirection,
    })
  }

  const handleShow = () => {
    setArticleId(undefined)
    setShowDialog(true)
  }
  const handleClose = () => setShowDialog(false)
  const handleCloseConfirm = () => setShowConfirmDelete(false)

  const handleRowClicked = (id: number) => {
    setArticleId(id)
    setShowDialog(true)
  }

  const handleDeleteArticle = (articleId?: number) => {
    if (articleId) {
      deleteArticle(articleId)
        .unwrap()
        .then(() => {
          setShowConfirmDelete(false)
          Toastify({
            text: "L'actualité a bien été supprimée",
            duration: 8000,
            newWindow: true,
            close: true,
            gravity: "top", // `top` or `bottom`
            position: "right", // `left`, `center` or `right`
            stopOnFocus: true, // Prevents dismissing of toast on hover
            style: {
              background: "linear-gradient(to right, #29aac4, #bd79e7)",
            },
            onClick: function () {}, // Callback after click
          }).showToast()
        })
    }
  }

  return (
    <StyledDiv fluid>
      <h1>Gestion des actualités</h1>
      <div>
        <ButtonPrimary
          label="Ajouter"
          icon={faPlusCircle}
          className="mb-3"
          onClick={handleShow}
        />
        <DataTable
          columns={columns}
          data={articlesPaginate.items}
          progressPending={isLoading}
          pagination
          paginationServer
          paginationTotalRows={articlesPaginate.totalItems}
          paginationPerPage={perPage}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          onRowClicked={(row: any) => handleRowClicked(row.id)}
          onSort={handleSort}
          sortServer
          defaultSortFieldId={3}
          defaultSortAsc={false}
          responsive
          highlightOnHover
          pointerOnHover
          noDataComponent="Aucune actualité"
          progressComponent={<Loader></Loader>}
          paginationComponentOptions={paginationComponentOptions}
        ></DataTable>
      </div>
      {showDialog && (
        <ArticleModal
          articleId={articleId}
          show={showDialog}
          handleClose={handleClose}
          isUpdate={articleId !== undefined}
        />
      )}
      {showConfirmDelete && (
        <ValidateModal
          show={showConfirmDelete}
          handleClose={handleCloseConfirm}
          modalMessage="La suppression est définitive, êtes-vous sûr de vouloir continuer ?"
          modalTitle="Suppression d'une actualité"
          handleConfirm={() => handleDeleteArticle(articleId)}
        />
      )}
    </StyledDiv>
  )
}

export default NewsAdmin
