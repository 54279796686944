import WaveHeader from "../../components/WaveHeader"
import bgImage from "../../assets/document-bg.jpg"
import { Container, Row } from "react-bootstrap"
import styled from "styled-components"
import colors from "../../utils/style/colors"
import ButtonPrimary from "../../components/ButtonPrimary"
import { useMemo, useState } from "react"
import { Loader } from "../../utils/Atoms"
import { Document as DocumentItem } from "../../models/document"
import { faDownload } from "@fortawesome/free-solid-svg-icons"
import { formatStrToDate } from "../../utils/utils"
import ReactSelect, { ActionMeta, SingleValue } from "react-select"
import { format } from "date-fns"
import InternError from "../../components/InternError"
import Pagination from "@mui/material/Pagination"
import { useGetDocumentsWithPaginationQuery } from "../../features/documentSlice"
import { API } from "../../utils/API"
import Toastify from "toastify-js"

const StyledDiv = styled(Container)`
  padding: 1rem;
  @media (min-width: 768px) {
  }
`

const CardHeaderDiv = styled.div`
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 10px #0000000d;
  border-radius: 7px 7px 0px 0px;
  text-transform: uppercase;
  color: ${colors.violet};
  font-weight: bold;
  text-align: center;
`

const CardContentDiv = styled.div`
  font-weight: bold;
  text-align: center;
`

const CardFooterDiv = styled.div`
  color: #404040;
  font-size: 0.8rem;
`

const CardDiv = styled.div`
  background: #fcfcfc 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 35px #00000008;
  border-radius: 7px;
  max-width: 13rem;
`

function DocumentSection() {
  const currentYear: number = parseInt(format(new Date(), "yyyy"))
  const [year, setYear] = useState(currentYear)
  const [page, setPage] = useState(1)
  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value)
    document.getElementById("documents")?.scrollIntoView()
  }

  const handleFilterChange = (
    newValue: SingleValue<{ value: number; label: number }>,
    actionMeta: ActionMeta<{ value: number; label: number }>
  ) => {
    if (newValue?.value) setYear(newValue?.value)
  }

  const defaultSelectValue = { value: currentYear, label: currentYear }

  const options = [
    defaultSelectValue,
    { value: currentYear - 1, label: currentYear - 1 },
    { value: currentYear - 2, label: currentYear - 2 },
  ]

  const {
    data: documentsPaginate = [],
    isLoading,
    isSuccess,
    isError,
    error,
    refetch,
  } = useGetDocumentsWithPaginationQuery({ limit: 12, page: page, year: year })

  const sortedDocuments = useMemo(() => {
    const documents = documentsPaginate.items ?? []
    const sortedDocuments = documents.slice()
    // Sort posts in descending chronological order
    sortedDocuments.sort((a: DocumentItem, b: DocumentItem) =>
      b.createdAt.localeCompare(a.createdAt)
    )
    return sortedDocuments
  }, [documentsPaginate])

  const handleDownload = (id: number, fileName: string) => {
    API.get(`documents/${id}/download`, {
      responseType: "blob",
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement("a")
        link.href = url
        link.setAttribute("download", fileName)
        document.body.appendChild(link)
        link.click()
      })
      .catch((error) => {
        Toastify({
          text: "Une erreur s'est produite lors du téléchargement",
          duration: 8000,
          newWindow: true,
          close: true,
          gravity: "top", // `top` or `bottom`
          position: "right", // `left`, `center` or `right`
          stopOnFocus: true, // Prevents dismissing of toast on hover
          style: {
            background: "linear-gradient(to right, #29aac4, #bd79e7)",
          },
          onClick: function () {}, // Callback after click
        }).showToast()
      })
  }

  let content
  let paginate

  if (isLoading) {
    content = <Loader />
  } else if (isSuccess) {
    if (sortedDocuments.length > 0) {
      content =
        sortedDocuments &&
        sortedDocuments.map((document: DocumentItem, index: number) => (
          <div
            className="col-md-3 p-0 justify-content-center"
            key={document.id}
          >
            <CardDiv key={document.id} className="m-auto">
              <CardHeaderDiv className="p-2">
                <div className="py-3">Télécharger</div>
                <ButtonPrimary
                  icon={faDownload}
                  onClick={() => handleDownload(document.id, document.path)}
                ></ButtonPrimary>
              </CardHeaderDiv>
              <CardContentDiv className="p-3">{document.title}</CardContentDiv>
              <CardFooterDiv className="py-2 px-3">
                Mis en ligne le {formatStrToDate(document.createdAt)}
              </CardFooterDiv>
            </CardDiv>
          </div>
        ))

      if (documentsPaginate.totalPages > 1) {
        paginate = (
          <Row className="justify-content-center">
            <Pagination
              count={documentsPaginate.totalPages}
              color="secondary"
              page={page}
              onChange={handlePageChange}
            />
          </Row>
        )
      }
    } else {
      content = <div style={{ textAlign: "center" }}>Aucun document</div>
    }
  } else if (isError) {
    content = <InternError />
  }

  return (
    <div>
      <WaveHeader title="Documents" image={bgImage} />
      <StyledDiv id="documents">
        <Row style={{ gap: "1rem" }} className="justify-content-center">
          <div className="d-flex flex-wrap justify-content-center align-items-baseline mb-3">
            <label className="violet fs-5">Année :</label>
            <ReactSelect
              defaultValue={defaultSelectValue}
              options={options}
              placeholder="Sélectionnez une année"
              isSearchable={false}
              className="px-2"
              onChange={handleFilterChange}
            ></ReactSelect>
          </div>
          {content}
          {paginate}
        </Row>
      </StyledDiv>
    </div>
  )
}

export default DocumentSection
