import styled from "styled-components"
import InternError from "../../../components/InternError"
import Toastify from "toastify-js"
import {
  ResponseApiException,
  ResponseApiType,
} from "../../../models/responseApiType"
import { Loader } from "../../../utils/Atoms"
import colors from "../../../utils/style/colors"
import { formatPrice, formatStrToDate } from "../../../utils/utils"
import { Link, useNavigate, useParams } from "react-router-dom"
import { Col, Container, Row } from "react-bootstrap"
import { Breadcrumbs, Button, Typography } from "@mui/material"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEuroSign } from "@fortawesome/free-solid-svg-icons"
import Operation, {
  getOperationTypeLabel,
  Operationfrom,
} from "../../../models/operation"
import {
  useDeleteOperationMutation,
  useGetOperationQuery,
} from "../../../features/operationSlice"
import { getStockOrderTypeLabel } from "../../../models/cardStockOrder"
import { Refund } from "../../../models/refund"
import { OtherOrderAdmin } from "../../../models/orderAdmin"
import ValidateModal from "../../../components/ValidateModal"
import { useState } from "react"

const LinkStyled = styled(Link)`
  text-decoration: none;
  color: #929292;
  &:hover {
    text-decoration: underline;
    color: ${colors.violet};
  }
`

const StyledDiv = styled(Container)`
  padding: 2rem 0.5rem 2rem 2.6rem;
  h3 {
    font-size: 1rem;
    margin-bottom: 0rem;
    color: ${colors.violet};
  }

  @media all and (min-width: 768px) {
    padding: 2rem 0.5rem 2rem 3.5rem;
  }

  h1 {
    font-size: 1.6rem;
    color: ${colors.violet};
    text-align: center;
    margin-bottom: 2rem;
  }
`
const CardDiv = styled.div`
  @media all and (min-width: 992px) {
    padding: 0 5rem;
  }

  @media all and (min-width: 1200px) {
    padding: 0 6rem;
  }
`

const StyledCol = styled(Col)`
  font-weight: bold;
  font-size: 1rem;
  color: ${colors.darkViolet};
`

function OperationItem() {
  let { id } = useParams()
  const [showConfirm, setShowConfirm] = useState(false)
  let navigate = useNavigate()

  const {
    data: operation,
    isLoading,
    isSuccess,
    isError,
    error,
  }: {
    data: Operation
    isLoading: boolean
    isSuccess: boolean
    isError: boolean
    error: ResponseApiException
  } = useGetOperationQuery(id)

  const [deleteOperation, { isLoading: isDeleteLoading }] =
    useDeleteOperationMutation()

  const handleValidate = () => {
    //post paiement and ids => set to ordered status and add paiement to other orders
    deleteOperation(id)
      .unwrap()
      .then(() => {
        Toastify({
          text: "La transaction a bien été supprimée",
          duration: 8000,
          newWindow: true,
          close: true,
          gravity: "top", // `top` or `bottom`
          position: "right", // `left`, `center` or `right`
          stopOnFocus: true, // Prevents dismissing of toast on hover
          style: {
            background: "linear-gradient(to right, #29aac4, #bd79e7)",
          },
          onClick: function () {}, // Callback after click
        }).showToast()
        navigate("/admin/financial-summary")
      })
      .catch((error: ResponseApiException) => {
        console.error(error)
      })
      .finally(setShowConfirm(false))
  }

  let content
  if (isLoading) {
    content = <Loader />
  } else if (isError) {
    if (error.data?.type === ResponseApiType.CustomError) {
      content = error.data?.detail
    } else {
      content = <InternError />
    }
  } else if (isSuccess) {
    content = (
      <CardDiv className="gap-2 justify-content-center w-100">
        <Row className="justify-content-start my-2">
          <StyledCol>
            Date de l'opération : {formatStrToDate(operation.operationDate)}
          </StyledCol>
        </Row>
        <Row className="mb-2">
          <StyledCol> Type : {getOperationTypeLabel(operation.type)}</StyledCol>
        </Row>
        <Row className="mb-2">
          <StyledCol>Libellé : {operation.title}</StyledCol>
        </Row>
        <Row className="mb-2">
          <StyledCol> Détails : {operation.detail}</StyledCol>
        </Row>
        <Row className="mt-4 mb-2">
          <StyledCol>
            <FontAwesomeIcon icon={faEuroSign} color="#dcac01" size="lg" />
            &nbsp; Montant de l'opération : {formatPrice(operation.amount)}
          </StyledCol>
        </Row>
        <Row className="mb-2 mt-3">
          <StyledCol> Informations complémentaires :</StyledCol>
        </Row>
        {operation.operationFrom === Operationfrom.Manual && (
          <Row className="mb-2">
            <div>
              Cette transaction a été ajoutée manuellement via l'interface de
              synthèse financière
            </div>
          </Row>
        )}
        {operation.operationFrom === Operationfrom.BankDeposit && (
          <Row className="mb-2">
            <div>
              Cette transaction correspond à une remise en banque. Vous pouvez
              voir le détail de cette remise{" "}
              <Link
                to={`/admin/bank-deposit/${operation.bankDeposit?.id}`}
                target="_blank"
              >
                en cliquant ici
              </Link>
            </div>
          </Row>
        )}
        {operation.operationFrom === Operationfrom.CardStockOrder && (
          <Row className="mb-2">
            <div>
              Cette transaction correspond à une commande de cartes de{" "}
              {getStockOrderTypeLabel(
                operation.paymentDebit?.cardStockOrder?.type ?? ""
              )}{" "}
              le{" "}
              {formatStrToDate(
                operation.paymentDebit?.cardStockOrder?.orderedAt
              )}
              .
            </div>
          </Row>
        )}
        {operation.operationFrom === Operationfrom.Refund && (
          <Row className="mb-2">
            <div>
              Cette transaction correspond à un ou aux remboursements suivants :
              <br />
              <ul className="mt-2">
                {operation.paymentDebit?.refunds?.map((refund: Refund) => (
                  <li key={refund.id} className="pb-4">
                    <h3>Numéro : {refund.number}</h3>
                    <div>
                      Montant remboursé : {formatPrice(refund.repaymentAmount)}
                    </div>
                    <div>
                      <Link
                        to={`/admin/refunds/${refund.number}`}
                        target="_blank"
                      >
                        Voir les détails
                      </Link>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </Row>
        )}
        {operation.operationFrom === Operationfrom.OtherOrderAdvance && (
          <Row className="mb-2">
            <div>
              Cette transaction correspond à une commande groupée du CSE pour
              les commandes de type Autre :
              <br />
              <ol className="mt-2">
                {operation.paymentDebit?.otherOrders?.map(
                  (order: OtherOrderAdmin) => (
                    <li key={order.id} className="pb-4">
                      <h3>Numéro : {order.parentOrder?.number}</h3>
                      <div>
                        Montant : {formatPrice(order.parentOrder.amount)}
                      </div>
                      <div>
                        Commandée le : {formatStrToDate(order.orderedAt)}
                      </div>
                      {order.receivedAt && (
                        <div>
                          Réceptionnée le : {formatStrToDate(order.receivedAt)}
                        </div>
                      )}
                      <div>
                        <Link
                          to={`/admin/orders/${order.parentOrder?.number}`}
                          target="_blank"
                        >
                          Voir les détails
                        </Link>
                      </div>
                    </li>
                  )
                )}
              </ol>
            </div>
          </Row>
        )}
      </CardDiv>
    )
  }

  return (
    <StyledDiv fluid>
      <Breadcrumbs aria-label="breadcrumb" className="mb-4">
        <LinkStyled color="gray" to="/admin/financial-summary">
          Budget social
        </LinkStyled>
        <Typography color="text.primary">Détail opération</Typography>
      </Breadcrumbs>
      <h1>Détail d'une opération</h1>
      <div className="d-flex justify-content-center">{content}</div>
      {operation?.operationFrom === Operationfrom.Manual && (
        <div>
          <div className="mx-2 mt-3 d-flex justify-content-end">
            <Button
              color="error"
              variant="outlined"
              onClick={() => setShowConfirm(true)}
            >
              Supprimer
            </Button>
          </div>
          <ValidateModal
            show={showConfirm}
            handleClose={() => setShowConfirm(false)}
            modalMessage="Êtes-vous sûr de vouloir supprimer cette transaction ? Le budget sera mis à jour."
            modalTitle="Suppression définitive transaction"
            handleConfirm={handleValidate}
            loading={isDeleteLoading}
          />
        </div>
      )}
    </StyledDiv>
  )
}

export default OperationItem
