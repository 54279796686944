import {
  faArrowUpRightFromSquare,
  faCoins,
  faHandHoldingDollar,
  faPenAlt,
  faPencilAlt,
} from "@fortawesome/free-solid-svg-icons"
import frLocale from "date-fns/locale/fr"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  Breadcrumbs,
  Paper,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material"
import { Fragment, useEffect, useState } from "react"
import { Container, Form, Row } from "react-bootstrap"
import { Link, useParams } from "react-router-dom"
import styled from "styled-components"
import ButtonPrimary from "../../../components/ButtonPrimary"
import colors from "../../../utils/style/colors"
import {
  cutDocTitle,
  formatPrice,
  formatStrToDate,
  statusRefundIsEditable,
} from "../../../utils/utils"
import logo from "../../../assets/logo-tac-bus.svg"
import { Loader } from "../../../utils/Atoms"
import InternError from "../../../components/InternError"
import ButtonSecondary from "../../../components/ButtonSecondary"
import {
  getPaiementTypeLabel,
  Paiement,
  PaiementType,
} from "../../../models/paiement"
import Toastify from "toastify-js"
import ValidateModal from "../../../components/ValidateModal"
import { API } from "../../../utils/API"
import { Document } from "../../../models/document"
import {
  getRefundStatusLabel,
  getRefundTypeLabel,
  getStatusColor,
  Refund,
  RefundStatusType,
  RefundType,
} from "../../../models/refund"
import {
  useEditRefundMutation,
  useGetAdminRefundQuery,
} from "../../../features/refundSlice"
import { ResponseApiException } from "../../../models/responseApiType"
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { format } from "date-fns"

const StyledDiv = styled(Container)`
  padding: 2rem 1rem 2rem 3rem;
  background-image: linear-gradient(
      90deg,
      rgba(247, 247, 247, 0.8),
      rgba(247, 247, 247, 0.8)
    ),
    url(${logo});
  background-repeat: no-repeat;
  background-size: 100%, 5rem;
  min-height: 100vh;
  background-position: left, bottom 0 right 1rem;
  @media (min-width: 768px) {
    background-size: 100%, 18rem;
    background-position: left, bottom 3rem right 1rem;
  }

  textarea:focus-visible {
    outline: none !important;
  }

  h1 {
    font-size: 1.5rem;
    color: ${colors.violet};
    text-align: center;
    margin-bottom: 2rem;
  }
`

const StyledButton = styled.button`
  background-color: transparent;
  border: none;
  font-size: 0.9rem;
  color: ${colors.violet};
  padding: 0;
  text-align: left;

  &:hover {
    color: ${colors.blue};
  }
`

const LinkStyled = styled(Link)`
  text-decoration: none;
  color: #929292;
  &:hover {
    text-decoration: underline;
    color: ${colors.violet};
  }
`
const StyledSpan = styled.span`
  font-weight: bold;
  color: ${colors.darkViolet};
`

const Span = styled.span`
  font-weight: 500;
  color: ${colors.darkViolet};
`

const Status = styled.span<{ status: RefundStatusType }>`
  font-size: 0.8rem;
  padding: 0.2rem 0.5rem;
  border-radius: 2rem;
  align-self: center;
  text-align: center;
  white-space: nowrap;
  background-color: ${(prop) =>
    prop.status ? getStatusColor(prop.status) : "white"};
  color: white;
  margin-left: 0.5rem;
`
function RefundItem() {
  let { refundNumber } = useParams()
  const [isUpdate, setIsUpdate] = useState(false)
  const [statusIsEditable, setStatusIsEditable] = useState(false)
  const [isPayment, setIsPayment] = useState(false)
  const [isChequePaiement, setIsChequePaiement] = useState(false)
  const [showConfirm, setShowConfirm] = useState(false)
  const [status, setStatus] = useState<RefundStatusType | string>("")
  const [note, setNote] = useState<string | undefined>()
  const [title, setTitle] = useState<string | undefined>()
  const [chequeNumber, setChequeNumber] = useState<string>("")
  const [paiementType, setPaiementType] = useState<string | null>(
    PaiementType.Cash
  )
  const [repayedAt, setRepayedAt] = useState<Date | null>(new Date())

  const handlePaiement = (
    event: React.MouseEvent<HTMLElement>,
    newPaiement: string | null
  ) => {
    setPaiementType(newPaiement)
    if (newPaiement === PaiementType.Cheque) {
      setIsChequePaiement(true)
    } else {
      setIsChequePaiement(false)
    }
  }

  const {
    data: refund,
    isLoading,
    isSuccess,
    isError,
  }: {
    data: Refund
    isLoading: boolean
    isSuccess: boolean
    isError: boolean
  } = useGetAdminRefundQuery(refundNumber)

  const [editRefund, { isLoading: isEditLoading }] = useEditRefundMutation()

  const handleDownload = (id: number, fileName: string) => {
    API.get(`documents/${id}/download`, {
      responseType: "blob",
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement("a")
        link.href = url
        link.setAttribute("download", fileName)
        document.body.appendChild(link)
        link.click()
      })
      .catch((error) => {
        Toastify({
          text: "Une erreur s'est produite lors du téléchargement",
          duration: 8000,
          newWindow: true,
          close: true,
          gravity: "top", // `top` or `bottom`
          position: "right", // `left`, `center` or `right`
          stopOnFocus: true, // Prevents dismissing of toast on hover
          style: {
            background: "linear-gradient(to right, #29aac4, #bd79e7)",
          },
          onClick: function () {}, // Callback after click
        }).showToast()
      })
  }

  const handleValidate = () => {
    let refundDto = {
      id: refund.id,
      note: note,
      status: status,
      title: title,
      repayedAt: repayedAt ? format(repayedAt, "yyyy-MM-dd HH:mm:ss") : null,
      payment: paiementType
        ? ({
            type: paiementType,
            chequeNumber: chequeNumber,
          } as Paiement)
        : null,
    } as Refund

    editRefund({ id: refund.id, body: refundDto })
      .unwrap()
      .then(() => {
        setIsUpdate(false)
        Toastify({
          text: "Les modifications ont bien été prises en compte",
          duration: 8000,
          newWindow: true,
          close: true,
          gravity: "top", // `top` or `bottom`
          position: "right", // `left`, `center` or `right`
          stopOnFocus: true, // Prevents dismissing of toast on hover
          style: {
            background: "linear-gradient(to right, #29aac4, #bd79e7)",
          },
          onClick: function () {}, // Callback after click
        }).showToast()
      })
      .catch((error: ResponseApiException) => {
        console.error(error.data)
      })
      .finally(setShowConfirm(false))
  }

  useEffect(() => {
    if (isSuccess) {
      let isEditable = statusRefundIsEditable(refund?.status)
      setStatusIsEditable(isEditable)
      setStatus(refund?.status)
      setNote(refund.note)
      setPaiementType(refund?.payment?.type ?? "")
      setChequeNumber(refund?.payment?.chequeNumber ?? "")
      setIsPayment(RefundStatusType.Repayed === refund?.status)
      setIsChequePaiement(refund?.payment?.type === PaiementType.Cheque)
      setTitle(refund.title)
      setRepayedAt(refund?.repayedAt ? new Date(refund?.repayedAt) : new Date())
    }
  }, [refund, isSuccess])

  useEffect(() => {
    if (status === RefundStatusType.Repayed) {
      setIsPayment(true)
    } else {
      setIsPayment(false)
    }
  }, [status])

  let content

  if (isLoading) {
    content = <Loader />
  } else if (isSuccess) {
    content = (
      <Paper
        elevation={2}
        className="col-lg-6 col-md-8 p-2 p-md-4 d-flex flex-column gap-1"
      >
        <div>
          <StyledSpan>Type de demande :</StyledSpan>{" "}
          {getRefundTypeLabel(refund?.type)}
        </div>
        {refund?.type === RefundType.Sport && (
          <div>
            <StyledSpan>Bénéficiaire :</StyledSpan>{" "}
            {refund?.recipient
              ? `${refund?.recipient?.firstname} ${refund?.recipient?.lastname}`
              : `${refund?.owner?.firstName} ${refund?.owner?.lastname} - ${refund?.owner?.email}`}
          </div>
        )}
        {(!isUpdate || !statusIsEditable) && (
          <div>
            <StyledSpan>Intitulé :</StyledSpan> {refund?.title}
          </div>
        )}
        {isUpdate && statusIsEditable && (
          <Form.Group className="mb-3" controlId="title">
            <Form.Label>
              <StyledSpan>
                Intitulé *
                <span>
                  &nbsp;&nbsp;
                  <FontAwesomeIcon
                    icon={faPencilAlt}
                    size="sm"
                    color="#fb5a5a"
                  />
                </span>
              </StyledSpan>
            </Form.Label>
            <Form.Control
              className="mt-1"
              placeholder="Intitulé"
              required
              defaultValue={refund?.title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </Form.Group>
        )}
        <div>
          <StyledSpan>Date de la demande :</StyledSpan>{" "}
          {formatStrToDate(refund.requestDate)}
        </div>
        <div>
          <StyledSpan>Salarié :</StyledSpan> {refund.owner?.firstName}{" "}
          {refund.owner?.lastname}
          <span className="ms-2">
            <Link
              to={`/admin/users/${refund.owner?.id}`}
              target="_blank"
              style={{ color: colors.blue, fontSize: "0.9rem" }}
            >
              Voir le profil&nbsp;&nbsp;
              <FontAwesomeIcon size="sm" icon={faArrowUpRightFromSquare} />
            </Link>
          </span>
        </div>

        <div className="my-1">
          <StyledSpan>Détails de la demande :</StyledSpan>
          <div className="ms-2 mt-1" style={{ whiteSpace: "pre-line" }}>
            {refund.employeeMsg ?? "Aucun détail fourni"}
          </div>
        </div>
        <div className="my-1 mt-2">
          <StyledSpan>Pièce(s)-jointe(s) :</StyledSpan>
          <div className="d-flex flex-column gap-1 mt-1">
            {refund.documents && refund.documents.length > 0
              ? refund.documents.map((doc: Document) => (
                  <StyledButton
                    onClick={() => handleDownload(doc.id, doc.path)}
                    key={doc.id}
                  >
                    {cutDocTitle(doc.title)}
                  </StyledButton>
                ))
              : "Aucune pièce-jointe fournie"}
          </div>
        </div>
        <div className="mt-2">
          <StyledSpan>
            Statut
            {isUpdate && statusIsEditable && (
              <span>
                &nbsp;&nbsp;
                <FontAwesomeIcon icon={faPencilAlt} size="sm" color="#fb5a5a" />
              </span>
            )}{" "}
            :
          </StyledSpan>
          {(!isUpdate || !statusIsEditable) && (
            <Status status={refund.status}>
              {getRefundStatusLabel(refund.status)}
            </Status>
          )}
          {isUpdate && statusIsEditable && (
            <Form.Select
              size="sm"
              onChange={(e) => setStatus(e.target.value)}
              defaultValue={refund.status}
            >
              {[RefundStatusType.ToHandle].includes(refund.status) && (
                <option value={RefundStatusType.ToHandle}>
                  {getRefundStatusLabel(RefundStatusType.ToHandle)}
                </option>
              )}
              {[RefundStatusType.ToHandle, RefundStatusType.Pending].includes(
                refund.status
              ) && (
                <option value={RefundStatusType.Pending}>
                  {getRefundStatusLabel(RefundStatusType.Pending)}
                </option>
              )}
              {[
                RefundStatusType.Repayed,
                RefundStatusType.Pending,
                RefundStatusType.ToHandle,
              ].includes(refund.status) && (
                <option value={RefundStatusType.Repayed}>
                  {getRefundStatusLabel(RefundStatusType.Repayed)}
                </option>
              )}
              {[
                RefundStatusType.Canceled,
                RefundStatusType.Pending,
                RefundStatusType.ToHandle,
              ].includes(refund.status) && (
                <option value={RefundStatusType.Canceled}>
                  {getRefundStatusLabel(RefundStatusType.Canceled)}
                </option>
              )}
            </Form.Select>
          )}
        </div>
        {refund.canceledAt && (
          <div className="mt-2">
            <StyledSpan>Annulée le :</StyledSpan>{" "}
            {formatStrToDate(refund.canceledAt)}
          </div>
        )}
        {refund.pendingAt && (
          <div className="mt-2">
            <StyledSpan>Mise en attente le :</StyledSpan>{" "}
            {formatStrToDate(refund.pendingAt)}
          </div>
        )}
        {refund.repayedAt && (
          <div className="mt-2">
            <StyledSpan>Remboursée le :</StyledSpan>{" "}
            {formatStrToDate(refund.repayedAt)}
          </div>
        )}
        {isPayment &&
          (!isUpdate ||
            (refund.type === RefundType.Sport &&
              refund.status === RefundStatusType.Repayed)) && (
            <div className="mt-2 d-flex flex-column">
              <StyledSpan>Remboursement du CSE :</StyledSpan>
              <ul>
                <li>
                  <div>
                    <Span>Type de paiement :</Span>&nbsp;&nbsp;
                    {getPaiementTypeLabel(paiementType ?? "")}
                  </div>
                </li>
                {isChequePaiement && (
                  <li>
                    <div className="mt-1">
                      <Span>Numéro de chèque :</Span>&nbsp;&nbsp;
                      {chequeNumber}
                    </div>
                  </li>
                )}
                <li>
                  <div className="mt-1">
                    <Span>Total remboursement CSE:</Span>&nbsp;&nbsp;
                    {formatPrice(refund?.payment?.amount ?? 0)}
                  </div>
                </li>
              </ul>
            </div>
          )}
        {isPayment &&
          isUpdate &&
          !(
            refund.type === RefundType.Sport &&
            refund.status === RefundStatusType.Repayed
          ) && (
            <div className="mt-2 d-flex flex-column">
              <StyledSpan>
                Type de paiement :
                <span>
                  &nbsp;&nbsp;
                  <FontAwesomeIcon
                    icon={faPencilAlt}
                    size="sm"
                    color="#fb5a5a"
                  />
                </span>
              </StyledSpan>
              <ToggleButtonGroup
                value={paiementType}
                exclusive
                aria-required
                onChange={handlePaiement}
                aria-label="type de paiement"
                aria-readonly={true}
              >
                <ToggleButton value={PaiementType.Cash} aria-label="en espèces">
                  {getPaiementTypeLabel(PaiementType.Cash)}
                </ToggleButton>
                <ToggleButton
                  value={PaiementType.Cheque}
                  aria-label="par chèque"
                >
                  {getPaiementTypeLabel(PaiementType.Cheque)}
                </ToggleButton>
              </ToggleButtonGroup>
              {isChequePaiement && (
                <Form.Control
                  className="mt-1"
                  placeholder="Numéro de chèque"
                  defaultValue={chequeNumber}
                  onChange={(e) => setChequeNumber(e.target.value)}
                />
              )}
              <div className="mt-3">
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  adapterLocale={frLocale}
                >
                  <DatePicker
                    label="Date du remboursement"
                    value={repayedAt}
                    onChange={(newValue) => {
                      setRepayedAt(newValue)
                    }}
                    className="datePicker"
                    maxDate={new Date()}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: "jj/mm/aaaa",
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </div>
            </div>
          )}
        <div className="my-1">
          <StyledSpan>
            Remarque ADMIN
            {isUpdate && (
              <span>
                &nbsp;&nbsp;
                <FontAwesomeIcon icon={faPencilAlt} size="sm" color="#fb5a5a" />
                &nbsp;
              </span>
            )}
            :
          </StyledSpan>
          <Form.Control
            className="mt-1"
            plaintext={!isUpdate}
            readOnly={!isUpdate}
            as="textarea"
            placeholder="Aucune remarque"
            defaultValue={refund.note}
            onChange={(e) => setNote(e.target.value)}
          />
        </div>
        <div className="mb-2">
          <FontAwesomeIcon icon={faCoins} color={colors.violet} />
          &nbsp;&nbsp;
          <StyledSpan>Prix total : {formatPrice(refund.price)}</StyledSpan>
        </div>

        <div>
          <FontAwesomeIcon icon={faHandHoldingDollar} color={colors.violet} />
          &nbsp;&nbsp;
          <StyledSpan>
            Montant remboursé : {formatPrice(refund.repaymentAmount)}
          </StyledSpan>
        </div>

        <div className="d-flex flew-wrap justify-content-end gap-1 mt-3">
          {!isUpdate && (
            <ButtonPrimary
              label="Modifier"
              icon={faPenAlt}
              onClick={() => setIsUpdate(true)}
            />
          )}
          {isUpdate && (
            <Fragment>
              <ButtonPrimary
                label="Valider"
                onClick={() => setShowConfirm(true)}
              />
              <ButtonSecondary
                label="Annuler"
                onClick={() => setIsUpdate(false)}
              />
            </Fragment>
          )}
        </div>
      </Paper>
    )
  } else if (isError) {
    content = <InternError />
  }

  return (
    <StyledDiv fluid>
      <Breadcrumbs aria-label="breadcrumb" className="mb-4">
        <LinkStyled color="gray" to="/admin/refunds">
          Remboursements
        </LinkStyled>
        <Typography color="text.primary">N° {refundNumber}</Typography>
      </Breadcrumbs>
      <h1>Remboursement {refundNumber}</h1>
      <Row className="justify-content-center">{content}</Row>
      <ValidateModal
        show={showConfirm}
        handleClose={() => setShowConfirm(false)}
        modalMessage="Êtes-vous sûr de vouloir modifier cette demande ?"
        modalTitle="Modification d'une demande"
        handleConfirm={handleValidate}
        loading={isEditLoading}
      />
    </StyledDiv>
  )
}

export default RefundItem
