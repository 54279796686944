import Member from "../models/member"
import PersonalInfos, { AdminUser } from "../models/personalInfos"
import { RefundType } from "../models/refund"
import UsersPagination from "../models/usersPagination"
import { apiSlice } from "./api/apiSlice"

export const extendedApiSlice: any = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPersonalInfos: builder.query<PersonalInfos, void>({
      query: () => ({ url: "personal-infos" }),
      providesTags: (result) => [
        { type: "PersonalInfos", id: result?.id },
        { type: "PersonalInfos", id: "LIST" },
      ],
    }),
    postPersonalInfos: builder.mutation<number, PersonalInfos>({
      query: (body) => ({
        url: `personal-infos`,
        method: "POST",
        body,
      }),
      invalidatesTags: [{ type: "PersonalInfos", id: "LIST" }],
    }),
    postFamilyMember: builder.mutation<number, Member>({
      query: (body) => ({
        url: `personal-infos/family-members`,
        method: "POST",
        body,
      }),
      invalidatesTags: [{ type: "PersonalInfos", id: "LIST" }],
    }),
    postPersonalDocument: builder.mutation<number, Partial<Document>>({
      query: (body) => ({
        url: `personal-infos/documents`,
        method: "POST",
        body,
      }),
      invalidatesTags: [{ type: "PersonalInfos", id: "LIST" }],
    }),
    postResetPassword: builder.mutation<any, { email: string }>({
      query: (body) => ({
        url: `reset-password`,
        method: "POST",
        body,
      }),
      invalidatesTags: [{ type: "PersonalInfos", id: "LIST" }],
    }),
    getUsersToRepayed: builder.query<PersonalInfos, { type: RefundType }>({
      query: ({ type }) => ({ url: `admin/users/to-repayed/${type}` }),
      providesTags: (result) => [
        { type: "UserAdmin", id: result?.id },
        { type: "UserAdmin", id: "LIST" },
      ],
    }),
    getDirectoryWithPagination: builder.query<
      UsersPagination,
      {
        from: string
        to: string
        limit: number
        page: number
        orderBy: string
        direction: string
        search: string
      }
    >({
      query: (param) => {
        return { url: "admin/users", params: param }
      },
      providesTags: (result) =>
        result && result.items
          ? [
              ...result.items.map(({ id }) => ({
                type: "UserAdmin" as const,
                id,
              })),
              { type: "UserAdmin", id: "LIST" },
            ]
          : [{ type: "UserAdmin", id: "LIST" }],
    }),
    getUserProfile: builder.query<AdminUser, number>({
      query: (id) => `admin/users/${id}`,
      providesTags: (result, error, id) => [{ type: "UserAdmin", id }],
    }),
    getUsers: builder.query<PersonalInfos[], void>({
      query: () => ({ url: "admin/users" }),
      providesTags: (result) => [{ type: "UserAdmin", id: "LIST" }],
    }),
  }),
})

export const {
  useGetPersonalInfosQuery,
  usePostPersonalInfosMutation,
  usePostFamilyMemberMutation,
  usePostPersonalDocumentMutation,
  useGetUsersToRepayedQuery,
  useGetDirectoryWithPaginationQuery,
  useGetUserProfileQuery,
  useGetUsersQuery,
  usePostResetPasswordMutation,
} = extendedApiSlice
