import WaveHeader from "../../../components/WaveHeader"
import bgImage from "../../../assets/personal-data.jpg"
import { Col, FloatingLabel, Form, Modal, Row } from "react-bootstrap"
import styled from "styled-components"
import ButtonPrimary from "../../../components/ButtonPrimary"
import {
  faCakeCandles,
  faCircleInfo,
  faDownload,
  faPencilAlt,
} from "@fortawesome/free-solid-svg-icons"
import ContactSupportIcon from "@mui/icons-material/ContactSupport"
import colors from "../../../utils/style/colors"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button, Divider, Tooltip } from "@mui/material"
import {
  useGetPersonalInfosQuery,
  usePostFamilyMemberMutation,
  usePostPersonalInfosMutation,
} from "../../../features/userSlice"
import PersonalInfos from "../../../models/personalInfos"
import InternError from "../../../components/InternError"
import { Loader } from "../../../utils/Atoms"
import React, { Fragment, useEffect, useState } from "react"
import { API } from "../../../utils/API"
import Toastify from "toastify-js"
import ButtonSecondary from "../../../components/ButtonSecondary"
import ValidateModal from "../../../components/ValidateModal"
import { ResponseApiType } from "../../../models/responseApiType"
import { formatStrToDate } from "../../../utils/utils"
import Member, {
  getRelationshipLabel,
  RelationshipType,
} from "../../../models/member"
import PersonalDocumentModal from "../../../components/PersonalDocumentModal"

const StyledDiv = styled(Row)`
  background-color: white;
  font-size: 1rem;
  box-shadow: 3px 3px 10px lightgray;
  width: 100%;
  margin: 2rem 0rem;
  padding: 1rem 0rem;

  @media all and (min-width: 768px) {
    margin: 2rem 0.5rem;
    max-width: 40rem;
    padding: 1rem 1rem 2rem 1rem;
  }
  @media all and (min-width: 1200px) {
    max-width: 52rem;
  }
`

const NoneSpan = styled.span`
  color: grey;
  font-style: italic;
`

const StyledCol = styled(Col)`
  font-weight: bold;
  font-size: 1.1rem;
  color: ${colors.darkViolet};
`

const StyledTitle = styled.h2`
  background: transparent
    linear-gradient(180deg, #58b4e5 0%, #5687ce 23%, #9060b1 64%, #ab75d0 100%)
    0% 0% no-repeat padding-box;
  border-radius: 3px;
  color: white;
  padding: 0.5rem 1rem;
  text-align: center;
  position: relative;
  top: -2.3rem;
  font-size: 1.3rem;
  width: fit-content;
`

const StyledSelect = styled.div`
  font-size: 0.8rem;
  input,
  select {
    font-size: 0.9rem;
  }
`

const StyledModal = styled(Modal)`
  font-size: 0.9rem;
  .modal-title {
    font-size: 1rem !important;
    font-weight: 300 !important;
    padding-left: 0.5rem;

    @media (min-width: 768px) {
      font-size: 1.2rem !important;
    }
  }

  .modal-header {
    background-color: ${colors.violet};
    color: white;
  }
`
interface ModalProps {
  show: boolean
  handleClose: () => void
}

function AddMemberModal(modalProps: ModalProps) {
  const [validated, setValidated] = useState(false)
  const [msgError, setMsgError] = useState<string>()
  const [infos, setInfos] = useState<Member>()
  const [errors, setErrors] = useState({} as any)

  const [postFamilyMember, { isLoading: isUpdateLoading }] =
    usePostFamilyMemberMutation()

  const handleMemberChange = ({ target: { name, value } }: any) => {
    if (name === "birthDate") {
      let dateArray = value.trim().split("/")
      value = `${dateArray[2]}/${dateArray[1]}/${dateArray[0]}`
    }
    setInfos((prev: any) => ({ ...prev, [name]: value.trim() }))
  }

  const handleSubmit = (event: any) => {
    const form = event.currentTarget
    event.preventDefault()
    event.stopPropagation()
    if (form.checkValidity() === false) {
      setValidated(true)
    } else {
      postFamilyMember(infos)
        .unwrap()
        .then(() => {
          setValidated(false)
          Toastify({
            text: "Membre de la famille ajouté",
            duration: 8000,
            newWindow: true,
            close: true,
            gravity: "top", // `top` or `bottom`
            position: "right", // `left`, `center` or `right`
            stopOnFocus: true, // Prevents dismissing of toast on hover
            style: {
              background: "linear-gradient(to right, #29aac4, #bd79e7)",
            },
            onClick: function () {}, // Callback after click
          }).showToast()
          modalProps.handleClose()
        })
        .catch((error: any) => {
          if (error.data?.type === ResponseApiType.CustomError) {
            setMsgError(error.data?.detail)
            setErrors(error.data?.detail)
            setValidated(true)
          } else if (error.data?.type === ResponseApiType.FormError) {
            setMsgError("Veuillez vérifier les données")
            setErrors(error.data?.detail)
            setValidated(true)
          } else {
            setMsgError("Une erreur interne s'est produite.")
          }
        })
    }
  }

  useEffect(() => {
    if (!modalProps.show) {
      setMsgError("")
      setErrors(null)
      setValidated(false)
      setInfos(undefined)
      setInfos((prev: any) => ({
        ...prev,
        relationship: RelationshipType.Child,
      }))
    }
  }, [modalProps.show])

  return (
    <StyledModal
      show={modalProps.show}
      onHide={modalProps.handleClose}
      backdrop="static"
      keyboard={false}
      dialogClassName="modal-width"
      centered
    >
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title>Ajouter un membre de la famille</Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-4">
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <StyledSelect className="d-flex flex-column mb-2 gap-2">
            <FloatingLabel label="Prénom">
              <Form.Control
                className="mb-2"
                name="firstname"
                required
                placeholder="Prénom"
                onChange={handleMemberChange}
                isInvalid={validated && errors && errors.firstname}
              />
              <Form.Control.Feedback type="invalid">
                {errors &&
                  errors.firstname &&
                  errors.firstname.map(
                    (txtError: string, indexError: number) => (
                      <div key={indexError}>{txtError}</div>
                    )
                  )}
              </Form.Control.Feedback>
            </FloatingLabel>
            <FloatingLabel label="Nom">
              <Form.Control
                className="mb-2"
                name="lastname"
                placeholder="Nom"
                required
                onChange={handleMemberChange}
                isInvalid={validated && errors && errors.lastname}
              />
              <Form.Control.Feedback type="invalid">
                {errors &&
                  errors.lastname &&
                  errors.lastname.map(
                    (txtError: string, indexError: number) => (
                      <div key={indexError}>{txtError}</div>
                    )
                  )}
              </Form.Control.Feedback>
            </FloatingLabel>
            <FloatingLabel label="Né(e) le" placeholder="jj/mm/aaaa">
              <Form.Control
                name="birthDate"
                required
                placeholder="jj/mm/aaaa"
                pattern="(0[1-9]|[12][0-9]|3[01])[/](0[1-9]|1[012])[/](19|20)\d\d"
                onChange={handleMemberChange}
                isInvalid={validated && errors && errors.birthDate}
              />
              <Form.Text className="text-muted ps-2 mb-2">
                Format: jj/mm/aaaa
              </Form.Text>
              <Form.Control.Feedback type="invalid">
                {errors && errors.birthDate
                  ? errors.birthDate.map(
                      (txtError: string, indexError: number) => (
                        <div key={indexError}>{txtError}</div>
                      )
                    )
                  : "Format incorrect"}
              </Form.Control.Feedback>
            </FloatingLabel>
            <FloatingLabel label="Lien de parenté">
              <Form.Select
                aria-label="Lien de parenté"
                style={{ minWidth: "10rem" }}
                name="relationship"
                onChange={handleMemberChange}
              >
                <option value={RelationshipType.Child}>
                  {getRelationshipLabel(RelationshipType.Child)}
                </option>
                <option value={RelationshipType.Spouse}>
                  {getRelationshipLabel(RelationshipType.Spouse)}
                </option>
              </Form.Select>
            </FloatingLabel>
          </StyledSelect>
          <Form.Group className="my-3 text-danger">{msgError}</Form.Group>
          <div className="d-flex justify-content-end gap-3 flex-wrap">
            <ButtonPrimary
              label="ajouter"
              type="submit"
              loading={isUpdateLoading}
            />
            <ButtonSecondary onClick={modalProps.handleClose} label="Annuler" />
          </div>
        </Form>
      </Modal.Body>
    </StyledModal>
  )
}

function PersonalInfosUser() {
  const [isUpdate, setIsUpdate] = useState(false)
  const [infos, setInfos] = useState<PersonalInfos>()
  const [showConfirm, setShowConfirm] = useState(false)
  const [validated, setValidated] = useState(false)
  const [msgError, setMsgError] = useState<string>()
  const [errors, setErrors] = useState({} as any)
  const [showMemberModal, setShowMemberModal] = useState(false)
  const [showDocumentModal, setShowDocumentModal] = useState(false)

  const {
    data: personalInfos,
    isLoading,
    isSuccess,
    isError,
  }: {
    data: PersonalInfos
    isLoading: boolean
    isSuccess: boolean
    isError: boolean
  } = useGetPersonalInfosQuery()

  const [postPersonalInfos, { isLoading: isUpdateLoading }] =
    usePostPersonalInfosMutation()

  const handleDownload = (id: number, fileName: string) => {
    API.get(`documents/${id}/download`, {
      responseType: "blob",
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement("a")
        link.href = url
        link.setAttribute("download", fileName)
        document.body.appendChild(link)
        link.click()
      })
      .catch((error) => {
        Toastify({
          text: "Une erreur s'est produite lors du téléchargement",
          duration: 8000,
          newWindow: true,
          close: true,
          gravity: "top", // `top` or `bottom`
          position: "right", // `left`, `center` or `right`
          stopOnFocus: true, // Prevents dismissing of toast on hover
          style: {
            background: "linear-gradient(to right, #29aac4, #bd79e7)",
          },
          onClick: function () {}, // Callback after click
        }).showToast()
      })
  }

  const handleInfosChange = ({
    target: { name, value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setInfos((prev: any) => ({ ...prev, [name]: value.trim() }))
  }

  const handleAddressChange = ({
    target: { name, value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setInfos((prev: any) => ({
      ...prev,
      address: { ...prev.address, [name]: value.trim() },
    }))
  }

  const handleMemberChange = (
    id: number,
    { target: { name, value } }: React.ChangeEvent<HTMLInputElement>
  ) => {
    let member = infos?.familyMember?.find((memb: Member) => memb.id === id)
    if (member) {
      if (name === "birthDate") {
        let dateArray = value.trim().split("/")
        value = `${dateArray[2]}/${dateArray[1]}/${dateArray[0]}`
      }
      member = { ...member, [name]: value.trim() }
      let removeMember = infos?.familyMember?.filter(
        (memb: Member) => memb.id !== id
      )

      removeMember?.push(member)
      setInfos((prev: any) => ({ ...prev, familyMember: removeMember }))
      /*setInfos((prev: any) => ({
        ...prev,
        familyMember: [...prev.familyMember, member],
      }))*/
    }
  }

  const handleValidate = () => {
    setShowConfirm(false)
    postPersonalInfos(infos)
      .unwrap()
      .then(() => {
        setIsUpdate(false)
        setValidated(false)
        Toastify({
          text: "Modifications enregistrées",
          duration: 8000,
          newWindow: true,
          close: true,
          gravity: "top", // `top` or `bottom`
          position: "right", // `left`, `center` or `right`
          stopOnFocus: true, // Prevents dismissing of toast on hover
          style: {
            background: "linear-gradient(to right, #29aac4, #bd79e7)",
          },
          onClick: function () {}, // Callback after click
        }).showToast()
      })
      .catch((error: any) => {
        if (error.data?.type === ResponseApiType.CustomError) {
          setMsgError(error.data?.detail)
          setErrors(error.data?.detail)
          setValidated(true)
        } else if (error.data?.type === ResponseApiType.FormError) {
          setMsgError("Veuillez vérifier les données")
          setErrors(error.data?.detail)
          setValidated(true)
        } else {
          setMsgError("Une erreur interne s'est produite.")
        }
      })
  }

  const handleSubmit = (event: any) => {
    const form = event.currentTarget
    event.preventDefault()
    event.stopPropagation()
    if (form.checkValidity() === false) {
      setValidated(true)
      setMsgError("Veuillez vérifier les données")
    } else {
      setMsgError("")
      setShowConfirm(true)
    }
  }

  useEffect(() => {
    setInfos(personalInfos)
  }, [personalInfos])

  useEffect(() => {
    if (!isUpdate) {
      setMsgError("")
      setErrors(null)
      setValidated(false)
      setInfos(personalInfos)
    }
  }, [isUpdate])

  const removeFamilyMember = (id: number) => {
    let removeMember = infos?.familyMember?.filter(
      (memb: Member) => memb.id !== id
    )

    setInfos((prev: any) => ({ ...prev, familyMember: removeMember }))
  }

  let content

  if (isError) {
    content = <InternError />
  } else if (isSuccess) {
    //  setInfos(personalInfos)
    content = (
      <Form onSubmit={handleSubmit} noValidate validated={validated}>
        <StyledDiv className="gap-2 justify-content-center">
          <div className="row justify-content-center">
            <StyledTitle>Vos informations</StyledTitle>
          </div>

          <Row className="justify-content-center">
            <StyledCol sm={6} md={5}>
              Nom :
            </StyledCol>
            <Col sm={6} md={7}>
              {!isUpdate ? (
                personalInfos?.lastname
              ) : (
                <Fragment>
                  <Form.Control
                    required
                    name="lastname"
                    placeholder="Votre nom"
                    defaultValue={personalInfos?.lastname}
                    onChange={handleInfosChange}
                    isInvalid={errors && errors.lastname}
                  />
                  <Form.Control.Feedback type="invalid">
                    Ce champ est requis
                  </Form.Control.Feedback>
                </Fragment>
              )}
            </Col>
          </Row>
          <Divider />
          <Row className="justify-content-center">
            <StyledCol sm={6} md={5}>
              Prénom :
            </StyledCol>
            <Col sm={6} md={7}>
              {!isUpdate ? (
                personalInfos?.firstName
              ) : (
                <Fragment>
                  <Form.Control
                    name="firstName"
                    required
                    placeholder="Votre prénom"
                    defaultValue={personalInfos?.firstName}
                    onChange={handleInfosChange}
                    isInvalid={errors && errors.firstName}
                  />
                  <Form.Control.Feedback type="invalid">
                    Ce champ est requis
                  </Form.Control.Feedback>
                </Fragment>
              )}
            </Col>
          </Row>
          <Divider />

          <Row className="justify-content-center">
            <StyledCol sm={6} md={5}>
              Adresse :
            </StyledCol>
            <Col sm={6} md={7}>
              {!isUpdate ? (
                personalInfos.address ? (
                  <Fragment>
                    {personalInfos.address.number}{" "}
                    {personalInfos.address.street}
                    <br />
                    {personalInfos.address.additionalAddress && (
                      <Fragment>
                        <span>{personalInfos.address.additionalAddress}</span>
                        <br />
                      </Fragment>
                    )}
                    {personalInfos.address.postalCode}{" "}
                    {personalInfos.address.city}
                  </Fragment>
                ) : (
                  <NoneSpan>Aucune adresse enregistrée</NoneSpan>
                )
              ) : (
                <Fragment>
                  <Form.Control
                    className="mb-2"
                    name="number"
                    placeholder="Numéro"
                    defaultValue={personalInfos?.address?.number}
                    onChange={handleAddressChange}
                  />
                  <Form.Control
                    className="mb-2"
                    name="street"
                    placeholder="Voie"
                    defaultValue={personalInfos?.address?.street}
                    onChange={handleAddressChange}
                  />
                  <Form.Control
                    className="mb-2"
                    name="additionalAddress"
                    placeholder="Complément"
                    defaultValue={personalInfos?.address?.additionalAddress}
                    onChange={handleAddressChange}
                  />
                  <div className="d-flex flex-wrap flex-md-nowrap gap-1">
                    <Form.Control
                      className="mb-2"
                      name="postalCode"
                      placeholder="Code postal"
                      defaultValue={personalInfos?.address?.postalCode}
                      onChange={handleAddressChange}
                    />
                    <Form.Control
                      className="mb-2"
                      name="city"
                      placeholder="Ville"
                      defaultValue={personalInfos?.address?.city}
                      onChange={handleAddressChange}
                    />
                  </div>
                </Fragment>
              )}
            </Col>
          </Row>
          <Divider />
          <Row className="justify-content-start">
            <StyledCol
              xs={12}
              className="mb-1 d-flex flex-wrap justify-content-between"
            >
              <span>
                Membres de la famille :{" "}
                <Tooltip
                  enterTouchDelay={0}
                  leaveTouchDelay={4000}
                  title="Ici vous pouvez ajouter vos enfants pour lesquels vous voulez faire une demande de remboursement"
                  placement="bottom"
                >
                  <ContactSupportIcon color="secondary" fontSize="small" />
                </Tooltip>
              </span>
              {!isUpdate && (
                <div>
                  <Button
                    color="secondary"
                    onClick={() => setShowMemberModal(true)}
                  >
                    Ajouter un membre
                  </Button>
                </div>
              )}
            </StyledCol>
            <Col xs={12}>
              <div className="mb-3 mt-1 fst-italic">
                <FontAwesomeIcon icon={faCircleInfo} color="#72a4ff" />
                &nbsp; Lorsque vous ajoutez un membre de votre famille, veuillez
                ajouter également le document justificatif le concernant
                (extrait d'acte de naissance, livret de famille...)
              </div>
            </Col>
            {!isUpdate ? (
              <Col xs={12} /*sm={6} md={7}*/>
                {personalInfos.familyMember &&
                personalInfos.familyMember.length > 0 ? (
                  <ul>
                    {personalInfos.familyMember.map((member) => (
                      <li key={member.id} className="mb-2">
                        {member.firstname} {member.lastname} (
                        {getRelationshipLabel(member.relationship)})
                        <span className="d-flex align-items-baseline gap-2">
                          <FontAwesomeIcon
                            icon={faCakeCandles}
                            color="sandybrown"
                          />
                          {formatStrToDate(member.birthDate)}
                        </span>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <NoneSpan>
                    Vous n'avez enregistré aucun membre de votre famille
                  </NoneSpan>
                )}
              </Col>
            ) : (
              <Col xs={12}>
                <div className="my-3 mt-2 fst-italic">
                  <FontAwesomeIcon icon={faCircleInfo} color="#72a4ff" />
                  &nbsp; Les membres pour lesquels vous avez effectuez une
                  demande de remboursement ne sont pas modifiables.
                </div>
                <div>
                  {infos?.familyMember &&
                    infos?.familyMember.length > 0 &&
                    infos?.familyMember.map((member, index) => (
                      <Fragment key={member.id}>
                        <div style={{ fontWeight: "bold" }} className="mb-1">
                          <span>Membre {index + 1} :</span>
                          <span>
                            <Button
                              variant="text"
                              color="error"
                              disabled={!member.isEditable}
                              onClick={() => removeFamilyMember(member.id)}
                            >
                              Supprimer
                            </Button>
                          </span>
                        </div>
                        <StyledSelect className="d-flex flex-wrap mb-2">
                          <FloatingLabel label="Prénom">
                            <Form.Control
                              className="mb-2"
                              name="firstname"
                              required
                              placeholder="Prénom"
                              defaultValue={member.firstname}
                              onChange={(e: any) =>
                                handleMemberChange(member.id, e)
                              }
                              disabled={!member.isEditable}
                              isInvalid={
                                validated &&
                                errors &&
                                errors[`familyMember[${index + 1}]`] &&
                                errors[
                                  `familyMember[${index + 1}].firstname`
                                ] &&
                                errors[`familyMember[${index + 1}].firstname`]
                                  .length > 0
                              }
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors &&
                                errors[`familyMember[${index + 1}]`] &&
                                errors[
                                  `familyMember[${index + 1}].firstname`
                                ] &&
                                errors[
                                  `familyMember[${index + 1}].firstname`
                                ].map(
                                  (txtError: string, indexError: number) => (
                                    <div key={indexError}>{txtError}</div>
                                  )
                                )}
                            </Form.Control.Feedback>
                          </FloatingLabel>
                          <FloatingLabel label="Nom">
                            <Form.Control
                              className="mb-2"
                              name="lastname"
                              placeholder="Nom"
                              required
                              defaultValue={member.lastname}
                              disabled={!member.isEditable}
                              onChange={(e: any) =>
                                handleMemberChange(member.id, e)
                              }
                              isInvalid={
                                validated &&
                                errors &&
                                errors[`familyMember[${index + 1}]`] &&
                                errors[`familyMember[${index + 1}].lastname`] &&
                                errors[`familyMember[${index + 1}].lastname`]
                                  .length > 0
                              }
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors &&
                                errors[`familyMember[${index + 1}]`] &&
                                errors[`familyMember[${index + 1}].lastname`] &&
                                errors[
                                  `familyMember[${index + 1}].lastname`
                                ].map(
                                  (txtError: string, indexError: number) => (
                                    <div key={indexError}>{txtError}</div>
                                  )
                                )}
                            </Form.Control.Feedback>
                          </FloatingLabel>
                          <FloatingLabel
                            label="Né(e) le"
                            placeholder="jj/mm/aaaa"
                          >
                            <Form.Control
                              name="birthDate"
                              required
                              placeholder="jj/mm/aaaa"
                              pattern="(0[1-9]|[12][0-9]|3[01])[/](0[1-9]|1[012])[/](19|20)\d\d"
                              defaultValue={
                                formatStrToDate(member.birthDate) ?? ""
                              }
                              disabled={!member.isEditable}
                              onChange={(e: any) =>
                                handleMemberChange(member.id, e)
                              }
                              isInvalid={
                                validated &&
                                errors &&
                                errors[`familyMember[${index + 1}]`] &&
                                errors[
                                  `familyMember[${index + 1}].birthDate`
                                ] &&
                                errors[`familyMember[${index + 1}].birthDate`]
                                  .length > 0
                              }
                            />
                            <Form.Text className="text-muted ps-2 mb-2">
                              jj/mm/aaaa
                            </Form.Text>
                            <Form.Control.Feedback type="invalid">
                              {errors &&
                              errors[`familyMember[${index + 1}]`] &&
                              errors[`familyMember[${index + 1}].birthDate`]
                                ? errors[
                                    `familyMember[${index + 1}].birthDate`
                                  ].map(
                                    (txtError: string, indexError: number) => (
                                      <div key={indexError}>{txtError}</div>
                                    )
                                  )
                                : "Format incorrect"}
                            </Form.Control.Feedback>
                          </FloatingLabel>
                          <FloatingLabel label="Lien de parenté">
                            <Form.Select
                              aria-label="Lien de parenté"
                              style={{ minWidth: "10rem" }}
                              disabled={true}
                              name="relationship"
                              defaultValue={member?.relationship}
                            >
                              <option value={RelationshipType.Child}>
                                {getRelationshipLabel(RelationshipType.Child)}
                              </option>
                              <option value={RelationshipType.Spouse}>
                                {getRelationshipLabel(RelationshipType.Spouse)}
                              </option>
                            </Form.Select>
                          </FloatingLabel>
                        </StyledSelect>
                      </Fragment>
                    ))}
                </div>
              </Col>
            )}
          </Row>
          <Divider />
          <Row className="justify-content-center">
            <StyledCol
              xs={12}
              className="mb-1 d-flex flex-wrap justify-content-between"
            >
              <span>
                Vos documents :{" "}
                <Tooltip
                  enterTouchDelay={0}
                  leaveTouchDelay={4000}
                  title="Ici vous pouvez ajouter vos documents justificatifs tels que votre livret de famille ou les extraits d'acte de naissance de vos enfants"
                  placement="bottom"
                >
                  <ContactSupportIcon color="secondary" fontSize="small" />
                </Tooltip>
              </span>
              {!isUpdate && (
                <div>
                  <Button
                    color="secondary"
                    onClick={() => setShowDocumentModal(true)}
                  >
                    Ajouter un document
                  </Button>
                </div>
              )}
            </StyledCol>
            <Col xs={12}>
              <div className="mb-3 mt-1 fst-italic">
                <FontAwesomeIcon icon={faCircleInfo} color="#72a4ff" />
                &nbsp; Si vous souhaitez supprimer un document personnel, merci
                de nous contacter.
              </div>
              {!isUpdate &&
                (personalInfos.personalDocuments &&
                personalInfos.personalDocuments.length > 0 ? (
                  <div className="d-flex flex-wrap gap-4 mt-1 justify-content-start">
                    {personalInfos.personalDocuments.map((doc) => (
                      <div
                        className="d-flex flex-column align-items-start align-items-sm-center"
                        key={doc.id}
                      >
                        <span
                          style={{ color: colors.violet, fontSize: "0.9rem" }}
                          className="mb-1"
                        >
                          {doc.title}
                        </span>
                        <ButtonPrimary
                          size="small"
                          icon={faDownload}
                          label="Télécharger"
                          onClick={() => handleDownload(doc.id, doc.path)}
                          type="button"
                        />
                      </div>
                    ))}
                  </div>
                ) : (
                  <NoneSpan>Aucun document enregistré</NoneSpan>
                ))}
            </Col>
          </Row>
          <div className="my-2 mt-4 fst-italic">
            <FontAwesomeIcon icon={faCircleInfo} color="#72a4ff" />
            &nbsp; Ces données sont consultables par les administrateurs de
            l'application si besoin de vérifier vos justificatifs ou de vous
            contacter.
          </div>
          <Form.Group className="mt-2 text-danger">{msgError}</Form.Group>
          <div className="d-flex flew-wrap justify-content-end gap-1 mt-3">
            {!isUpdate && (
              <ButtonPrimary
                type="button"
                label="Modifier"
                icon={faPencilAlt}
                onClick={() => setIsUpdate(true)}
                loading={isUpdateLoading}
              />
            )}
            {isUpdate && (
              <Fragment>
                <ButtonPrimary
                  label="Valider"
                  type="submit"
                  loading={isUpdateLoading}
                />
                <ButtonSecondary
                  label="Annuler"
                  type="button"
                  onClick={() => setIsUpdate(false)}
                />
              </Fragment>
            )}
          </div>
        </StyledDiv>
      </Form>
    )
  } else if (isLoading) {
    content = <Loader />
  }

  return (
    <div>
      <WaveHeader title="Infos. personnelles" image={bgImage} />
      <div className="d-flex justify-content-center">{content}</div>
      <ValidateModal
        show={showConfirm}
        handleClose={() => setShowConfirm(false)}
        modalMessage="Êtes-vous sûr de vouloir modifier vos informations ?"
        modalTitle="Modification des infos. personnelles"
        handleConfirm={handleValidate}
        loading={isUpdateLoading}
      />
      <AddMemberModal
        show={showMemberModal}
        handleClose={() => {
          setShowMemberModal(false)
        }}
      />
      <PersonalDocumentModal
        show={showDocumentModal}
        handleClose={() => {
          setShowDocumentModal(false)
        }}
      />
    </div>
  )
}

export default PersonalInfosUser
