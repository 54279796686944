import { useState } from "react"
import { FloatingLabel, Form } from "react-bootstrap"
import styled from "styled-components"
import ButtonPrimary from "../../../components/ButtonPrimary"
import { API } from "../../../utils/API"
import Toastify from "toastify-js"

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`

const StyledDiv = styled.div`
  margin-top: 3rem;
  background-color: white;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 90vw;
  align-self: center;
  border-radius: 15px;
  box-shadow: grey 2px 2px 15px;
  @media (min-width: 576px) {
    padding: 2rem;
    width: 30rem;
  }
`

function Register() {
  const [email, setEmail] = useState<string>()
  const [name, setName] = useState<string>()
  const [firstname, setFirstname] = useState<string>()
  const [role, setRole] = useState<string>("ROLE_USER")
  const [validated, setValidated] = useState(false)
  const [msg, setMsg] = useState<string>("")
  const [isLoading, setIsLoading] = useState(false)

  const canSubmit = email && name && firstname && role && !isLoading

  const handleSubmit = (event: any) => {
    event.preventDefault()
    event.stopPropagation()
    let data = {
      email: email?.trim(),
      firstname: firstname?.trim(),
      lastname: name?.trim(),
      role: role,
    }
    setIsLoading(true)
    //API call
    API.post(`superadmin/register`, data)
      .then((response: any) => {
        Toastify({
          text: "L'utilisateur a bien été ajouté",
          duration: 8000,
          newWindow: true,
          close: true,
          gravity: "top", // `top` or `bottom`
          position: "right", // `left`, `center` or `right`
          stopOnFocus: true, // Prevents dismissing of toast on hover
          style: {
            background: "linear-gradient(to right, #29aac4, #bd79e7)",
          },
          onClick: function () {}, // Callback after click
        }).showToast()
        setMsg("")
      })
      .catch((error: any) => {
        setMsg(error?.response?.data?.detail)
        Toastify({
          text: "Une erreur s'est produite lors de l'ajout du compte",
          duration: 8000,
          newWindow: true,
          close: true,
          gravity: "top", // `top` or `bottom`
          position: "right", // `left`, `center` or `right`
          stopOnFocus: true, // Prevents dismissing of toast on hover
          style: {
            background: "linear-gradient(to right, #29aac4, #bd79e7)",
          },
          onClick: function () {}, // Callback after click
        }).showToast()
      })
      .finally(() => setIsLoading(false))
  }

  return (
    <StyledContainer>
      <StyledDiv>
        <h2 className="text-center pb-4 fs-4 violet">Ajout d'un utilisateur</h2>
        <Form onSubmit={(e) => handleSubmit(e)} validated={validated}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <FloatingLabel className="mb-3" label="Email">
              <Form.Control
                required
                type="email"
                placeholder="Entrez l'email"
                onChange={(e) => {
                  setEmail(e.target.value)
                }}
                name="email"
              />
            </FloatingLabel>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formName">
            <FloatingLabel className="mb-3" label="Nom">
              <Form.Control
                required
                type="text"
                placeholder="Entrez le nom"
                onChange={(e) => {
                  setName(e.target.value)
                }}
                name="name"
              />
            </FloatingLabel>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formFirstname">
            <FloatingLabel className="mb-3" label="Prénom">
              <Form.Control
                required
                type="text"
                placeholder="Entrez le prénom"
                onChange={(e) => {
                  setFirstname(e.target.value)
                }}
                name="firstname"
              />
            </FloatingLabel>
          </Form.Group>
          <Form.Group>
            <FloatingLabel className="mb-3" label="Rôle">
              <Form.Select
                aria-label="Role"
                style={{ minWidth: "10rem" }}
                name="role"
                value={role}
                onChange={(e) => {
                  setRole(e.target.value)
                }}
              >
                <option value="ROLE_USER">Utilisateur classique</option>
                <option value="ROLE_ADMIN">Admin</option>
                <option value="ROLE_SUPER_ADMIN">Super Admin</option>
              </Form.Select>
            </FloatingLabel>
          </Form.Group>
          <div className="text-danger d-flex text-break">{msg}</div>
          <div className="d-flex justify-content-center mt-4 w-100">
            <ButtonPrimary
              label="Valider"
              type="submit"
              loading={isLoading}
              disabled={!canSubmit}
            />
          </div>
        </Form>
      </StyledDiv>
    </StyledContainer>
  )
}

export default Register
