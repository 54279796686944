import { useEffect, useState } from "react"
import { Form, Modal } from "react-bootstrap"
import ButtonPrimary from "../ButtonPrimary"
import styled from "styled-components"
import colors from "../../utils/style/colors"
import ButtonSecondary from "../ButtonSecondary"
import { ResponseApiType } from "../../models/responseApiType"
import {
  useAddDocumentMutation,
  useEditDocumentMutation,
  useGetDocumentQuery,
} from "../../features/documentSlice"
import { Document, DocumentAdmin } from "../../models/document"
import { API } from "../../utils/API"
import { faDownload } from "@fortawesome/free-solid-svg-icons"
import Toastify from "toastify-js"

interface ModalProps {
  show: boolean
  handleClose: () => void
  documentId?: number
  isUpdate?: boolean
}

const StyledModal = styled(Modal)`
  font-size: 0.9rem;
  .modal-title {
    font-size: 1rem !important;
    font-weight: 300 !important;
    padding-left: 0.5rem;

    @media (min-width: 768px) {
      font-size: 1.2rem !important;
    }
  }

  .modal-header {
    background-color: ${colors.violet};
    color: white;
  }
`

const StyledCheck = styled(Form.Check)`
  input:checked {
    background-color: ${colors.violet}!important;
    border-color: ${colors.violet};
  }
`

const StyledSpan = styled.span`
  color: grey;
  font-size: 0.8rem;
  font-style: italic;
`

function DocumentModal(modalProps: ModalProps) {
  const initialDocumentValue: DocumentAdmin = {
    visible: true,
    title: "",
    path: "",
    id: 0,
    data: "",
    createdAt: "",
    updatedAt: "",
  }

  const [addDocument, { isLoading: isCreateLoading }] = useAddDocumentMutation()
  const [editDocument, { isLoading: isUpdateLoading }] =
    useEditDocumentMutation()
  const [postDocument, setPostDocument] = useState(initialDocumentValue)
  const [validated, setValidated] = useState(false)
  const [errors, setErrors] = useState({} as any)
  const [skip, setSkip] = useState(true)
  const [msgError, setMsgError] = useState<string>()
  const {
    data: documentdata,
    isSuccess,
  }: { data: Document; isSuccess: boolean } = useGetDocumentQuery(
    modalProps.documentId,
    { skip }
  )

  //Init Document if Edit
  useEffect(() => {
    if (modalProps.isUpdate) {
      setSkip(false)
      if (isSuccess) {
        setPostDocument((prev) => ({
          ...prev,
          visible: documentdata?.visible ?? false,
          title: documentdata?.title,
          id: documentdata?.id,
          data: "",
          path: documentdata?.path,
        }))
      }
    }
  }, [modalProps.documentId, documentdata, modalProps.isUpdate, isSuccess])

  const handleAddOrEditDocument = (event: any) => {
    const form = event.currentTarget
    event.preventDefault()
    event.stopPropagation()
    if (form.checkValidity() === false) {
      setValidated(true)
    } else {
      if (modalProps.isUpdate) {
        editDocument({
          id: modalProps.documentId,
          body: postDocument,
        })
          .unwrap()
          .then(() => modalProps.handleClose())
          .catch((error: any) => {
            console.error(error.data)
            if (error.data?.type === ResponseApiType.FormError) {
              setMsgError("Veuillez vérifier les données")
              setErrors(error.data?.detail)
              setValidated(true)
            } else {
              setMsgError("Une erreur interne s'est produite.")
            }
          })
      } else {
        addDocument(postDocument)
          .unwrap()
          .then(() => modalProps.handleClose())
          .catch((error: any) => {
            if (error.data?.type === ResponseApiType.FormError) {
              setMsgError("Veuillez vérifier les données")
              setErrors(error.data?.detail)
              setValidated(true)
            } else {
              setMsgError("Une erreur interne s'est produite.")
            }
          })
      }
    }
  }

  const convertToBase64 = (file: any): Promise<string> => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader()
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result as string)
      }
      fileReader.onerror = (error) => {
        reject(error)
      }
    })
  }
  const handleFileUpload = async (e: any) => {
    const file = e.target.files[0]
    await convertToBase64(file)
      .then((result) => {
        setPostDocument((prev) => ({
          ...prev,
          data: result,
        }))
      })
      .catch((error) => console.error(error))
  }

  const toggleVisible = (e: any) => {
    setPostDocument((prev) => ({
      ...prev,
      visible: !prev.visible,
    }))
  }

  const handleDocumentChange = ({
    target: { name, value },
  }: React.ChangeEvent<HTMLInputElement>) =>
    setPostDocument((prev) => ({ ...prev, [name]: value }))

  const handleDownload = (id: number, fileName: string) => {
    API.get(`documents/${id}/download`, {
      responseType: "blob",
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement("a")
        link.href = url
        link.setAttribute("download", fileName)
        document.body.appendChild(link)
        link.click()
      })
      .catch((error) => {
        Toastify({
          text: "Une erreur s'est produite lors du téléchargement",
          duration: 8000,
          newWindow: true,
          close: true,
          gravity: "top", // `top` or `bottom`
          position: "right", // `left`, `center` or `right`
          stopOnFocus: true, // Prevents dismissing of toast on hover
          style: {
            background: "linear-gradient(to right, #29aac4, #bd79e7)",
          },
          onClick: function () {}, // Callback after click
        }).showToast()
      })
  }

  return (
    <>
      <StyledModal
        show={modalProps.show}
        onHide={modalProps.handleClose}
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-width"
      >
        <Modal.Header closeButton closeVariant="white">
          <Modal.Title>
            {modalProps.isUpdate
              ? "Modification d'un document"
              : "Ajout d'un document"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            noValidate
            validated={validated}
            onSubmit={handleAddOrEditDocument}
          >
            <Form.Group className="mb-3" controlId="formTitle">
              <Form.Label>Titre *</Form.Label>
              <Form.Control
                type="text"
                autoFocus
                required
                value={postDocument.title}
                name="title"
                onChange={handleDocumentChange}
                isInvalid={validated && errors.title}
              />
              <Form.Control.Feedback type="invalid">
                Le titre est requis
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="formThumb" className="mb-3">
              <Form.Label>
                Fichier attaché *<StyledSpan>(Taille max : 10M)</StyledSpan>
              </Form.Label>
              <Form.Control
                type="file"
                onChange={handleFileUpload}
                required={!modalProps.isUpdate}
                isInvalid={validated && errors.file}
              />
              <Form.Control.Feedback type="invalid">
                {errors.file}
              </Form.Control.Feedback>
              <br />
              <div>
                {postDocument.path && (
                  <ButtonPrimary
                    icon={faDownload}
                    label="Télécharger"
                    onClick={() =>
                      handleDownload(postDocument.id, postDocument.path)
                    }
                    type="button"
                  />
                )}
              </div>
            </Form.Group>
            <Form.Group className="mb-3 " controlId="formVisible">
              <Form.Label className="me-2">Visible</Form.Label>
              <StyledCheck
                inline
                type="switch"
                checked={postDocument.visible}
                onChange={toggleVisible}
              />
            </Form.Group>
            <Form.Group className="mb-3 text-danger">{msgError}</Form.Group>
            <div className="d-flex justify-content-end gap-3 flex-wrap">
              <ButtonPrimary
                label="sauvegarder"
                type="submit"
                loading={isUpdateLoading || isCreateLoading}
              />
              <ButtonSecondary
                onClick={modalProps.handleClose}
                label="Annuler"
                disabled={isUpdateLoading || isCreateLoading}
              />
            </div>
          </Form>
        </Modal.Body>
      </StyledModal>
    </>
  )
}

export default DocumentModal
