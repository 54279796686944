import BankDeposit from "../models/bankDeposit"
import BankDepositPagination from "../models/bankDepositPagination"
import { apiSlice } from "./api/apiSlice"

export const extendedApiSlice: any = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getBankDepositWithPagination: builder.query<
      BankDepositPagination,
      {
        from: string
        to: string
        limit: number
        page: number
        orderBy: string
        direction: string
      }
    >({
      query: (param) => {
        return { url: "admin/bank-deposits", params: param }
      },
      providesTags: (result) =>
        result && result.items
          ? [
              ...result.items.map(({ id }) => ({
                type: "BankDeposit" as const,
                id,
              })),
              { type: "BankDeposit", id: "LIST" },
            ]
          : [{ type: "BankDeposit", id: "LIST" }],
    }),
    postBankDeposit: builder.mutation<number, any>({
      query: (body) => ({
        url: `admin/bank-deposits`,
        method: "POST",
        body,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "BankDeposit", id: "LIST" },
        { type: "OtherOrder", id: "LIST" },
        { type: "AdminOtherOrder", id: "LIST" },
        { type: "CardOrder", id: "LIST" },
        { type: "AdminCardOrder", id: "LIST" },
        { type: "AdminOrder", id: "LIST" },
        { type: "Operation", id: "LIST" },
        { type: "BankAccount", id: "LIST" },
      ],
    }),
    getBankDeposit: builder.query<BankDeposit, number>({
      query: (id) => `admin/bank-deposits/${id}`,
      providesTags: (result, error, id) => [{ type: "BankDeposit", id }],
    }),
    deleteBankDeposit: builder.mutation<boolean, number>({
      query: (id) => ({
        url: `admin/bank-deposits/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "BankDeposit", id: "LIST" },
        { type: "OtherOrder", id: "LIST" },
        { type: "AdminOtherOrder", id: "LIST" },
        { type: "CardOrder", id: "LIST" },
        { type: "AdminCardOrder", id: "LIST" },
        { type: "AdminOrder", id: "LIST" },
        { type: "Operation", id: "LIST" },
        { type: "BankAccount", id: "LIST" },
      ],
    }),
  }),
})

export const {
  useGetBankDepositWithPaginationQuery,
  usePostBankDepositMutation,
  useGetBankDepositQuery,
  useDeleteBankDepositMutation,
} = extendedApiSlice
