import { Row } from "react-bootstrap"
import styled from "styled-components"
import ButtonPrimary from "../ButtonPrimary"
interface ImageProps {
  path: string
  alt: string
  name: string
}

interface CardProps {
  key: number
  title: string
  content: string
  image: ImageProps
  labelChips: string
  labelButton: string
  className: string
  buttonOnClick?: () => void
}

const StyledCard = styled.div`
  box-shadow: 0px 5px 35px #00000008;
  border-radius: 7px;
  background-color: white;
  margin-bottom: 2rem;
`

const StyledChips = styled.div`
  position: relative;
`

const StyledSpan = styled.span`
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 14px;
  opacity: 1;
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
  color: #6c6c6c;
  font-size: 0.8rem;
  position: absolute;
  right: 0rem;
  top: 0.5rem;
  padding: 0.2rem 0.8rem;
`

function Card(cardProps: CardProps) {
  return (
    <StyledCard className={cardProps.className}>
      <StyledChips>
        <StyledSpan>{cardProps.labelChips}</StyledSpan>
      </StyledChips>
      <Row>
        <img
          src={cardProps.image?.path}
          alt={cardProps.image?.alt}
          className="img-fluid"
          style={{ padding: 0, height: "14rem", objectFit: "cover" }}
        />
      </Row>
      <Row style={{ padding: "1rem", fontWeight: "bold", fontSize: "1.1rem" }}>
        <span>{cardProps.title}</span>
      </Row>
      <Row style={{ padding: "0 1rem 1rem 1rem", fontWeight: 100 }}>
        <span>{cardProps.content}</span>
      </Row>
      <Row className="justify-content-end" style={{ padding: "1rem" }}>
        <ButtonPrimary
          label={cardProps.labelButton}
          onClick={cardProps.buttonOnClick}
        />
      </Row>
    </StyledCard>
  )
}

export default Card
