import Home from "./Home"
import News from "./News"
import Presentation from "./Presentation"
import { Route, Routes, Outlet, Navigate, Link } from "react-router-dom"
import Header from "../components/Header"
import Footer from "../components/Footer"
import NoMatch from "./NoMatch"
import Contact from "./Contact"
import Documents from "./Documents"
import NewsItem from "./NewsItem"
import Login from "./Login"
import { PrivateRoute } from "../utils/PrivateRoute"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCrown, faSignOut } from "@fortawesome/free-solid-svg-icons"
import styled from "styled-components"
import ButtonPrimary from "../components/ButtonPrimary"
import { Button } from "react-bootstrap"
import AdminLayout from "../components/AdminLayout"
import DocumentsAdmin from "./Admin/Documents"
import NewsAdmin from "./Admin/News"
import User from "../models/user"
import { useAuth } from "../utils/hooks/useAuth"
import "../styles/global.scss"
import Orders from "./Profile/Order"
import Pool from "./Profile/Pool"
import Movie from "./Profile/Movie"
import Other from "./Profile/Other"
import Stock from "./Admin/Storage"
import OrdersAdmin from "./Admin/Orders"
import OrderItem from "./Admin/OrderItem"
import PersonalInfos from "./Profile/PersonalInfos"
import Culture from "./Profile/Culture"
import Refund from "./Profile/Refund"
import Sport from "./Profile/Sport"
import RefundsAdmin from "./Admin/Refunds"
import RefundItem from "./Admin/RefundItem"
import Directory from "./Admin/Directory"
import UserProfile from "./Admin/UserProfile"
import BankDeposit from "./Admin/BankDeposit"
import BankDepositItem from "./Admin/BankDepositItem"
import FinancialSummary from "./Admin/FinancialSummary"
import ResetPassword from "./ResetPassword"
import ResetPasswordToken from "./ResetPasswordToken"
import Parameters from "./Profile/Parameters"
import MentionsLegales from "./MentionsLegales"
import ConfidentialPolitic from "./ConfidentialPolitic"
import OperationItem from "./Admin/OperationItem"
import SuperAdminLayout from "../components/SuperAdminLayout"
import Register from "./SuperAdmin/Register"
import ImportUsers from "./SuperAdmin/Import/Users"
import PoolOrder from "./SuperAdmin/Import/PoolOrder"

export default function App() {
  return (
    <main className="App">
      <Content />
    </main>
  )
}

const StyledAdminDiv = styled.div`
  background-color: #202020;
  color: white;
  padding: 0.5rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 600 !important;
  font-size: 0.8rem;

  @media (max-width: 576px) {
    button {
      font-size: 0.8rem;
      padding: 0.2rem 0.3rem;
    }
  }

  @media (min-width: 768px) {
    font-size: 1rem;
  }
`

function AdminBar({ user }: { user: User }) {
  const { logout } = useAuth()

  return (
    <StyledAdminDiv>
      <div>
        <Link to="/admin">
          <ButtonPrimary label="Interface ADMIN" size="small" />
        </Link>
      </div>
      <div className="">
        <FontAwesomeIcon icon={faCrown}></FontAwesomeIcon> {user.email}
        &nbsp;
        <Button
          variant="link"
          style={{ color: "white" }}
          title="Déconnexion"
          onClick={logout}
        >
          <FontAwesomeIcon icon={faSignOut} />
        </Button>
      </div>
    </StyledAdminDiv>
  )
}

const MainDiv = styled.div`
  padding-bottom: 52rem;

  @media all and (min-width: 768px) {
    padding-bottom: 28rem;
  }
`

function Layout() {
  const {
    loggedUser: { user },
  } = useAuth()

  return (
    <div>
      {(user?.roles.includes("ROLE_ADMIN") ||
        user?.roles.includes("ROLE_SUPER_ADMIN")) && <AdminBar user={user} />}
      <Header />
      <MainDiv>
        <Outlet />
      </MainDiv>
      <Footer />
    </div>
  )
}

function Content() {
  return (
    <div style={{ position: "relative", minHeight: "100vh" }}>
      <Routes>
        <Route path="/login" element={<Login />}></Route>
        <Route path="/reset-password" element={<ResetPassword />}></Route>
        <Route
          path="/reset-password/:token"
          element={<ResetPasswordToken />}
        ></Route>
        <Route
          path="/"
          element={
            <PrivateRoute>
              <Layout />
            </PrivateRoute>
          }
        >
          <Route index element={<Home />}></Route>
          <Route path="mentions-legales" element={<MentionsLegales />}></Route>
          <Route
            path="politique-confidentialite"
            element={<ConfidentialPolitic />}
          ></Route>
          <Route path="news" element={<News />}></Route>
          <Route path="news/:newsId" element={<NewsItem />}></Route>
          <Route path="presentation" element={<Presentation />}></Route>
          <Route path="documents" element={<Documents />}></Route>
          <Route path="contact" element={<Contact />}></Route>
          <Route path="commandes" element={<Orders />}></Route>
          <Route path="commandes/piscine" element={<Pool />}></Route>
          <Route path="commandes/cinema" element={<Movie />}></Route>
          <Route path="commandes/autre" element={<Other />}></Route>
          <Route path="remboursements" element={<Refund />}></Route>
          <Route path="remboursements/sport" element={<Sport />}></Route>
          <Route path="remboursements/culture" element={<Culture />}></Route>
          <Route path="infos-personnelles" element={<PersonalInfos />}></Route>
          <Route path="parametres" element={<Parameters />}></Route>
          <Route path="*" element={<NoMatch />} />
        </Route>
        <Route
          path="admin"
          element={
            <PrivateRoute>
              <AdminLayout />
            </PrivateRoute>
          }
        >
          <Route path="documents" element={<DocumentsAdmin />}></Route>
          <Route path="news" element={<NewsAdmin />}></Route>
          <Route path="stock" element={<Stock />}></Route>
          <Route path="directory" element={<Directory />}></Route>
          <Route path="users/:userId" element={<UserProfile />}></Route>
          <Route path="orders" element={<OrdersAdmin />}></Route>
          <Route path="orders/:orderNumber" element={<OrderItem />}></Route>
          <Route path="refunds" element={<RefundsAdmin />}></Route>
          <Route path="refunds/:refundNumber" element={<RefundItem />}></Route>
          <Route path="bank-deposit" element={<BankDeposit />}></Route>
          <Route path="bank-deposit/:id" element={<BankDepositItem />}></Route>
          <Route path="operation/:id" element={<OperationItem />}></Route>
          <Route
            path="financial-summary"
            element={<FinancialSummary />}
          ></Route>
          <Route index element={<Navigate to="documents" replace />} />
        </Route>
        <Route
          path="superadmin"
          element={
            <PrivateRoute>
              <SuperAdminLayout />
            </PrivateRoute>
          }
        >
          <Route path="register" element={<Register />}></Route>
          <Route path="import-users" element={<ImportUsers />}></Route>
          <Route path="import-pool-orders" element={<PoolOrder />}></Route>
          <Route index element={<Navigate to="register" replace />} />
        </Route>
      </Routes>
    </div>
  )
}
