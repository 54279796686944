import { Paiement } from "../models/paiement"
import { Refund, RefundType } from "../models/refund"
import RefundPagination from "../models/refundPagination"
import { apiSlice } from "./api/apiSlice"

export const extendedApiSlice: any = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getRefunds: builder.query<Refund[], string>({
      query: (type) => `refunds/${type}`,
      providesTags: (result) => [{ type: "Refund" as const, id: "LIST" }],
    }),
    addRefund: builder.mutation<Partial<Refund>, any>({
      query: (body) => ({
        url: `refunds`,
        method: "POST",
        body,
      }),
      invalidatesTags: [
        { type: "Refund", id: "LIST" },
        { type: "AdminRefund", id: "LIST" },
      ],
    }),
    getRefundsWithPagination: builder.query<
      RefundPagination,
      {
        from: string
        to: string
        limit: number
        page: number
        orderBy: string
        direction: string
        type: string
        status: string
        search: string
      }
    >({
      query: (param) => {
        return { url: "admin/refunds", params: param }
      },
      providesTags: (result) =>
        result && result.items
          ? [
              ...result.items.map(({ id }) => ({
                type: "AdminRefund" as const,
                id,
              })),
              { type: "AdminRefund", id: "LIST" },
            ]
          : [{ type: "AdminRefund", id: "LIST" }],
    }),
    getAdminRefund: builder.query<Refund, string>({
      query: (number) => `admin/refunds/${number}`,
      providesTags: (result, error, id) => [
        { type: "AdminRefund", id: result?.id },
      ],
    }),
    editRefund: builder.mutation<any, { id: number; body: Refund }>({
      query: ({ id, body }) => ({
        url: `admin/refunds/${id}`,
        method: "POST",
        body,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "AdminRefund", id: "LIST" },
        { type: "Refund", id: "LIST" },
        { type: "AdminRefund", id: arg.id },
      ],
    }),
    getRefundsToRepayed: builder.query<
      Refund[],
      { id: number; type: RefundType }
    >({
      query: ({ id, type }) => `admin/refunds/to-repayed/${id}/${type}`,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: "AdminRefund" as const,
                id,
              })),
              { type: "AdminRefund", id: "LIST" },
            ]
          : [{ type: "AdminRefund", id: "LIST" }],
    }),
    getRefundsTotal: builder.mutation<any, { body: any }>({
      query: ({ body }) => ({
        url: `admin/refunds/total`,
        method: "POST",
        body,
      }),
    }),
    postAdminRefund: builder.mutation<
      any,
      {
        body: {
          ids: []
          adminPayment: Paiement
          detail: string
          repayedAt: string
        }
      }
    >({
      query: ({ body }) => ({
        url: `admin/refunds/to-repayed`,
        method: "POST",
        body,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "AdminRefund", id: "LIST" },
      ],
    }),
  }),
})

export const {
  useGetRefundsQuery,
  useAddRefundMutation,
  useGetRefundsWithPaginationQuery,
  useGetAdminRefundQuery,
  useEditRefundMutation,
  useGetRefundsToRepayedQuery,
  useGetRefundsTotalMutation,
  usePostAdminRefundMutation,
} = extendedApiSlice
