import WaveHeader from "../../components/WaveHeader"
import bgImage from "../../assets/contact-bg.jpg"
import { FloatingLabel, Form, Row } from "react-bootstrap"
import styled from "styled-components"
import colors from "../../utils/style/colors"
import contactIcons from "../../assets/contact-icons.svg"
import logoBus from "../../assets/logo-tac-bus.svg"
import ButtonPrimary from "../../components/ButtonPrimary"
import { useState } from "react"
import { useAuth } from "../../utils/hooks/useAuth"
import { API } from "../../utils/API"
import { ResponseApiType } from "../../models/responseApiType"
import Toastify from "toastify-js"

const StyledDiv = styled.div`
  padding: 1rem;
  background-image: linear-gradient(
      90deg,
      rgba(247, 247, 247, 0.8),
      rgba(247, 247, 247, 0.8)
    ),
    url(${contactIcons}), url(${logoBus});
  background-repeat: no-repeat;
  background-size: 100%, 20rem, 5rem;
  background-position: left, top 7rem left 0, bottom 0 right 1rem;
  @media (min-width: 768px) {
    background-size: 100%, 40rem, 18rem;
    background-position: left, top 3rem left 1rem, bottom 0 right 1rem;
  }
`
const StyledSpan = styled.span`
  color: ${colors.violet};
`

const StyledForm = styled(Form)`
  background: #ffffff;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 5px;
  padding: 1rem;
`

const StyledFormTitle = styled.h2`
  background: transparent
    linear-gradient(180deg, #58b4e5 0%, #5687ce 23%, #9060b1 64%, #ab75d0 100%)
    0% 0% no-repeat padding-box;
  border-radius: 3px;
  color: white;
  padding: 0.5rem 1rem;
  text-align: center;
  position: relative;
  top: -2.3rem;
  font-size: 1.4rem;
  width: fit-content;
`

function ContactSection() {
  const [validated, setValidated] = useState(false)
  const [message, setMessage] = useState("")
  const [errors, setErrors] = useState({} as any)
  const [msgError, setMsgError] = useState<string>()
  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = (event: any) => {
    const form = event.currentTarget
    event.preventDefault()
    event.stopPropagation()
    if (form.checkValidity() === false) {
      setValidated(true)
    } else {
      setValidated(false)
      setIsLoading(true)
      API.post("send-message", message)
        .then((data: any) => {
          setMsgError("")
          Toastify({
            text: "Votre message a bien été envoyé",
            duration: 8000,
            newWindow: true,
            close: true,
            gravity: "top", // `top` or `bottom`
            position: "right", // `left`, `center` or `right`
            stopOnFocus: true, // Prevents dismissing of toast on hover
            style: {
              background: "linear-gradient(to right, #29aac4, #bd79e7)",
            },
            onClick: function () {}, // Callback after click
          }).showToast()
        })
        .catch((result: any) => {
          let error = result.response
          console.error(error)
          setValidated(true)
          if (error.data?.type === ResponseApiType.CustomError) {
            setMsgError(error.data?.detail)
          } else {
            setMsgError("Une erreur interne s'est produite.")
          }
        })
        .finally(() => setIsLoading(false))
    }
  }

  const {
    loggedUser: { user },
  } = useAuth()
  return (
    <div>
      <WaveHeader title="Contact" image={bgImage} />
      <StyledDiv className="container-fluid">
        <Row className="justify-content-center violet fs-6 fs-md-5 text-center pb-5">
          <p className="col-md-8 col-xl-6">
            Si vous avez la moindre question ou si vous avez besoin d’un
            renseignement, n’hésitez pas à nous contacter via le formulaire
            ci-dessous. Nous vous répondrons dès que possible.
          </p>
        </Row>
        <Row className="justify-content-center pb-5 pb-md-2">
          <StyledForm
            className="col-md-6 col-xl-4"
            onSubmit={handleSubmit}
            noValidate
            validated={validated}
          >
            <div className="row justify-content-center">
              <StyledFormTitle>Formulaire de contact</StyledFormTitle>
            </div>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>
                Email : <StyledSpan>{user?.email}</StyledSpan>
              </Form.Label>
            </Form.Group>
            <FloatingLabel
              className="mb-3"
              controlId="message"
              label="Votre message"
            >
              <Form.Control
                as="textarea"
                placeholder="Votre message"
                style={{ height: "10rem" }}
                required
                onChange={(e: any) => setMessage(e.target.value)}
              />
              <Form.Control.Feedback type="invalid">
                Ce champ est requis.
              </Form.Control.Feedback>
            </FloatingLabel>
            {errors && <div className="text-danger">{msgError}</div>}
            <div className="text-end pt-3">
              <ButtonPrimary
                label="Envoyer"
                type="submit"
                loading={isLoading}
              />
            </div>
          </StyledForm>
        </Row>
      </StyledDiv>
    </div>
  )
}

export default ContactSection
