import { Document, DocumentAdmin } from "../models/document"
import documentPagination from "../models/documentPagination"
import { apiSlice } from "./api/apiSlice"

export const extendedApiSlice: any = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getDocumentsWithPagination: builder.query<
      documentPagination,
      { limit: number; page: number; year: number }
    >({
      query: (param) => {
        return { url: "/documents/visible", params: param }
      },
      providesTags: (result) =>
        result
          ? [
              ...result.items.map(({ id }) => ({
                type: "Document" as const,
                id,
              })),
              { type: "Document", id: "LIST" },
            ]
          : [{ type: "Document", id: "LIST" }],
    }),
    getDocumentsAdminWithPagination: builder.query<
      documentPagination,
      { limit: number; page: number; orderBy: string; direction: string }
    >({
      query: (param) => {
        return { url: "/admin/documents/public", params: param }
      },
      providesTags: (result) =>
        result
          ? [
              ...result.items.map(({ id }) => ({
                type: "DocumentAdmin" as const,
                id,
              })),
              { type: "DocumentAdmin", id: "LIST" },
            ]
          : [{ type: "DocumentAdmin", id: "LIST" }],
    }),
    getDocuments: builder.query<Document[], void>({
      query: () => ({ url: "/documents/visible" }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: "Document" as const, id })),
              { type: "Document", id: "LIST" },
            ]
          : [{ type: "Document", id: "LIST" }],
    }),
    addDocument: builder.mutation<DocumentAdmin, Partial<Document>>({
      query: (body) => ({
        url: `admin/documents`,
        method: "POST",
        body,
      }),
      invalidatesTags: [
        { type: "Document", id: "LIST" },
        { type: "DocumentAdmin", id: "LIST" },
      ],
    }),
    getDocument: builder.query<Document, number>({
      query: (id) => `admin/documents/${id}`,
      providesTags: (result, error, id) => [{ type: "DocumentAdmin", id }],
    }),
    editDocument: builder.mutation<
      Document,
      { id: number; body: DocumentAdmin }
    >({
      query: ({ id, body }) => ({
        url: `admin/documents/${id}`,
        method: "POST",
        body,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "DocumentAdmin", id: "LIST" },
        { type: "Document", id: "LIST" },
        { type: "DocumentAdmin", id: arg.id },
      ],
    }),
    deleteDocument: builder.mutation<boolean, number>({
      query: (id) => ({
        url: `admin/documents/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "DocumentAdmin", id: "LIST" },
        { type: "Document", id: "LIST" },
      ],
    }),
  }),
})

export const {
  useGetDocumentsQuery,
  useGetDocumentsWithPaginationQuery,
  useGetDocumentsAdminWithPaginationQuery,
  useGetDocumentQuery,
  useAddDocumentMutation,
  useEditDocumentMutation,
  useDeleteDocumentMutation,
} = extendedApiSlice
