import { differenceInMinutes, differenceInYears, format } from "date-fns"
import { fr } from "date-fns/locale"
import CardOrder from "../models/cardOrder"
import { StockOrderType } from "../models/cardStockOrder"
import { OrderStatusType } from "../models/order"
import { RefundStatusType, RefundType } from "../models/refund"
import {
  ResponseApiException,
  ResponseApiType,
} from "../models/responseApiType"

export function strToDate(dateStr: string) {
  return Date.parse(dateStr) ?? null
}

export function formatStrToDate(
  dateStr?: string,
  formatStr: string = "dd/MM/yyyy"
) {
  if (!dateStr) return ""
  let date = strToDate(dateStr)

  return date ? format(date, formatStr, { locale: fr }) : null
}

export function cutText(text: string, length: number = 100): string {
  return text.length >= length ? `${text.substring(0, length)}[...]` : text
}

export function cutDocTitle(text: string, length: number = 20) {
  let result
  if (text.length >= length) {
    let extension = text.split(".").pop()
    result = `${text.substring(0, length)}[...].${extension}`
  } else result = text
  return result
}

export function getMessageFromType(resp: ResponseApiException | any) {
  if (resp.data) {
    switch (resp.data.type) {
      case ResponseApiType.BadCredentials:
        return "Identifiants invalides"
      case ResponseApiType.TokenExpired:
        return "Votre session a expiré"
      case ResponseApiType.InternalError:
        return "Une erreur interne s'est produite"
      case ResponseApiType.CustomError:
        return resp.data?.detail
      default:
        return null
    }
  }

  return "Une erreur s'est produite"
}

export function statusOrderIsEditable(
  status: OrderStatusType,
  isCard: boolean = true
) {
  if (status) {
    return isCard
      ? [OrderStatusType.ToHandle].includes(status)
      : [OrderStatusType.Ordered, OrderStatusType.PendingPayment].includes(
          status
        )
  }
  return false
}

export function statusRefundIsEditable(status: RefundStatusType) {
  if (status) {
    return [RefundStatusType.ToHandle, RefundStatusType.Pending].includes(
      status
    )
  }
  return false
}

export function variableIsDefined(variable: any) {
  if (typeof variable === "object") {
    return Object.keys(variable).length > 0
  } else {
    return variable && typeof variable !== "undefined" && variable != null
  }
}

export function formatDecimal(num: number, nbDecimal: number = 2) {
  return (Math.round(num * 100) / 100).toFixed(nbDecimal)
}

export function formatPrice(num: number | undefined, space = false) {
  if (num === undefined) return ""
  let nb = formatDecimal(num)
  if (!nb || nb == "NaN") {
    return ""
  }

  let str = nb.toString()

  let numbers = str?.split(".")

  if (!numbers || numbers.length === 0) {
    return ""
  }
  if (space)
    return numbers.length > 1
      ? `${parseInt(numbers[0]).toLocaleString()}€${numbers[1]}`
      : numbers[0]
  else return numbers.length > 1 ? `${numbers[0]}€${numbers[1]}` : numbers[0]
}

export function getFirstDayOfYear(year: number) {
  return new Date(year, 0, 1)
}

export function getFirstDayOfCurrentYear() {
  const currentYear = new Date().getFullYear()
  return getFirstDayOfYear(currentYear)
}

export function getLastDayOfYear(year: number) {
  return new Date(year, 11, 31)
}

export function getLastDayOfCurrentYear() {
  const currentYear = new Date().getFullYear()
  return getLastDayOfYear(currentYear)
}
export function calculateRefund(price: number, type: RefundType) {
  if (!price) return 0
  if (type === RefundType.Cultural) {
    let priceReduce = price * 0.25
    return priceReduce <= 50 ? priceReduce : 50
  } else if (type === RefundType.Sport) {
    return price <= 40 ? price : 40
  }

  return null
}

export function calculateSportRefund(
  price: number,
  amountAlreadyRepayed: number
) {
  if (!price) return 0
  let amountToRepayed = 40 - amountAlreadyRepayed
  return price <= amountToRepayed ? price : amountToRepayed
}

export function calculateOrderAmount(
  cardOrder: Partial<CardOrder>,
  priceInfos: any,
  type: StockOrderType
): number {
  let amount = 0
  /*let quota = priceInfos.movieQuota
  if (type === StockOrderType.Movie) {
    let nbMovieCardsOrdered = priceInfos.nbOrders

    let nbCardOrdered =
      (cardOrder.adultQuantity ?? 0) + (cardOrder.childQuantity ?? 0)

    //if quota exceeded cost price applied
    if (nbMovieCardsOrdered > quota) {
      let adultCost = priceInfos.adultCostPrice * (cardOrder.adultQuantity ?? 0)
      let childCost = priceInfos.childCostPrice * (cardOrder.childQuantity ?? 0)
      amount = adultCost + childCost
    } else {
      let nbRemainingCard = quota - nbMovieCardsOrdered

      if (nbRemainingCard >= nbCardOrdered) {
        let adultCost = priceInfos.adultPrice * (cardOrder.adultQuantity ?? 0)
        let childCost = priceInfos.childPrice * (cardOrder.childQuantity ?? 0)
        amount = adultCost + childCost
      } else {
        let nbAdultReduc = 0
        let nbChildReduc = 0
        let nbAdultFull = 0
        let nbChildFull = 0

        if ((cardOrder.adultQuantity ?? 0) <= nbRemainingCard) {
          nbAdultReduc += cardOrder.adultQuantity ?? 0
          nbRemainingCard -= cardOrder.adultQuantity ?? 0
        } else {
          nbAdultReduc += nbRemainingCard
          nbAdultFull += (cardOrder.adultQuantity ?? 0) - nbAdultReduc
          nbRemainingCard = 0
        }
        if ((cardOrder.childQuantity ?? 0) > nbRemainingCard) {
          nbChildReduc += nbRemainingCard
          nbChildFull += (cardOrder.childQuantity ?? 0) - nbChildReduc
        } else {
          nbChildReduc += cardOrder.childQuantity ?? 0
        }

        let adultCost =
          priceInfos.adultPrice * nbAdultReduc +
          priceInfos.adultCostPrice * nbAdultFull
        let childCost =
          priceInfos.childPrice * nbChildReduc +
          priceInfos.childCostPrice * nbChildFull
        amount = adultCost + childCost
      }
    }
  } else if (type === StockOrderType.Pool) {
    let adultCost = priceInfos.adultPrice * (cardOrder.adultQuantity ?? 0)
    let childCost = priceInfos.childPrice * (cardOrder.childQuantity ?? 0)
    amount = adultCost + childCost
  }*/

  let adultCost = priceInfos.adultPrice * (cardOrder.adultQuantity ?? 0)
  let childCost = priceInfos.childPrice * (cardOrder.childQuantity ?? 0)
  amount = adultCost + childCost
  return amount
}

export function getAge(dateStr: string) {
  let birthDate = strToDate(dateStr)
  const age = differenceInYears(new Date(), birthDate)
  return age
}

export function getDifferenceInMinutes(dateStr: string) {
  let date = strToDate(dateStr)
  const minutes = differenceInMinutes(date, new Date())
  return minutes
}
