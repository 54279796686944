import { Form, Modal, Row } from "react-bootstrap"
import ButtonPrimary from "../ButtonPrimary"
import styled from "styled-components"
import colors from "../../utils/style/colors"
import ButtonSecondary from "../ButtonSecondary"
import { useEffect, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Toastify from "toastify-js"
import { faBox, faInfoCircle } from "@fortawesome/free-solid-svg-icons"
import frLocale from "date-fns/locale/fr"
import {
  useGetOtherOrdersByStatusQuery,
  usePostReceiptAdminOtherOrderMutation,
} from "../../features/orderSlice"
import DataTable from "react-data-table-component"
import { Loader } from "../../utils/Atoms"
import { formatPrice, formatStrToDate } from "../../utils/utils"
import { OtherOrderAdmin } from "../../models/orderAdmin"
import {
  getOrderStatusLabel,
  getStatusColor,
  OrderStatusType,
} from "../../models/order"
import {
  ResponseApiException,
  ResponseApiType,
} from "../../models/responseApiType"
import ValidateModal from "../ValidateModal"
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { TextField } from "@mui/material"
import { format } from "date-fns"

interface ModalProps {
  show: boolean
  handleClose: () => void
}

const StyledModal = styled(Modal)`
  font-size: 0.9rem;
  h2 {
    font-size: 1rem;
    font-weight: 300;
    padding-left: 0.5rem;

    @media (min-width: 768px) {
      font-size: 1.2rem;
    }
  }

  .prices span,
  .total span {
    font-weight: bold;
  }

  .modal-header {
    background-color: ${colors.violet};
    color: white;
  }
`

const StyledSpan = styled.span<{ status: OrderStatusType }>`
  font-size: 0.8rem;
  padding: 0.2rem 0.5rem;
  border-radius: 2rem;
  align-self: center;
  text-align: center;
  white-space: nowrap;
  background-color: ${(prop) =>
    prop.status ? getStatusColor(prop.status) : "white"};
  color: white;
  margin-left: 0.5rem;
`

const Span = styled.span`
  font-weight: bold;
  color: ${colors.darkViolet};
`

function OrdersReceivedModal(modalProps: ModalProps) {
  const [validated, setValidated] = useState(false)
  const [errors, setErrors] = useState({} as any)
  const [msgError, setMsgError] = useState<string>()
  const [skip, setSkip] = useState(true)
  const [perPage, setPerPage] = useState(20)
  const [selectedOrders, setSelectedOrders] = useState([])
  const [showConfirm, setShowConfirm] = useState(false)
  const [receivedAt, setReceivedAt] = useState<Date | null>(new Date())

  const handlePerRowsChange = (newPerPage: number) => {
    setPerPage(newPerPage)
  }

  useEffect(() => {
    if (modalProps.show) {
      setSkip(false)
      setValidated(false)
      setMsgError("")
      setErrors({})
    }
  }, [modalProps.show])

  const paginationComponentOptions = {
    rowsPerPageText: "Commandes par page",
    rangeSeparatorText: "de",
  }

  const columns = [
    {
      name: "Date commande",
      selector: (row: OtherOrderAdmin) => row.parentOrder?.orderDate,
      sortable: true,
      format: (row: OtherOrderAdmin) =>
        formatStrToDate(row.parentOrder?.orderDate, "dd/MM/yyyy HH:mm"),
    },
    {
      name: "Numéro",
      selector: (row: OtherOrderAdmin) => row.parentOrder?.number,
    },
    {
      name: "Salarié",
      sortable: true,
      selector: (row: OtherOrderAdmin) => row.parentOrder?.owner,
      format: (row: OtherOrderAdmin) => {
        if (
          !row.parentOrder?.owner.firstName &&
          !row.parentOrder?.owner.lastname
        )
          return row.parentOrder?.owner.email
        else
          return `${row.parentOrder?.owner.firstName} ${row.parentOrder?.owner.lastname}`
      },
    },
    {
      name: "Libellé",
      sortable: true,
      selector: (row: OtherOrderAdmin) => row.parentOrder?.title,
    },
    {
      name: "Montant",
      selector: (row: OtherOrderAdmin) => row.parentOrder?.amount,
      format: (row: OtherOrderAdmin) => formatPrice(row.parentOrder?.amount),
    },
    {
      name: "Statut",
      center: true,
      grow: 2,
      selector: (row: OtherOrderAdmin) => row.parentOrder?.status,
      cell: (row: OtherOrderAdmin) => (
        <StyledSpan status={row.parentOrder?.status}>
          {getOrderStatusLabel(row.parentOrder?.status)}
        </StyledSpan>
      ),
    },
  ] as any

  const {
    data: orders = [],
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetOtherOrdersByStatusQuery(OrderStatusType.Ordered, { skip: skip })

  const [postReceiptAdminOtherOrder, { isLoading: isPostLoading }] =
    usePostReceiptAdminOtherOrderMutation()

  const handleRowSelected = ({ selectedRows }: any) => {
    console.log(selectedRows)
    setSelectedOrders(selectedRows)
  }

  const handleValidate = () => {
    let postAdmin = {
      ids: selectedOrders.map((item: OtherOrderAdmin) => item.id),
      receivedAt: receivedAt ? format(receivedAt, "yyyy-MM-dd HH:mm:ss") : null,
    }

    //post paiement and ids => set to ordered status and add paiement to other orders
    postReceiptAdminOtherOrder({
      body: postAdmin,
    })
      .unwrap()
      .then(() => {
        Toastify({
          text: "Les commandes ont bien été modifiées",
          duration: 8000,
          newWindow: true,
          close: true,
          gravity: "top", // `top` or `bottom`
          position: "right", // `left`, `center` or `right`
          stopOnFocus: true, // Prevents dismissing of toast on hover
          style: {
            background: "linear-gradient(to right, #29aac4, #bd79e7)",
          },
          onClick: function () {}, // Callback after click
        }).showToast()
        modalProps.handleClose()
      })
      .catch((error: ResponseApiException) => {
        console.error(error)
        if (error.data?.type === ResponseApiType.FormError) {
          setMsgError("Veuillez vérifier les données")
          setErrors(error.data?.detail)
          setValidated(true)
        } else if (error.data?.type === ResponseApiType.CustomError) {
          setMsgError(error.data?.detail)
        } else {
          setMsgError("Une erreur interne s'est produite.")
        }
      })
      .finally(setShowConfirm(false))
  }

  return (
    <>
      <StyledModal
        show={modalProps.show}
        onHide={modalProps.handleClose}
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-width p-0"
        centered
      >
        <Modal.Header closeButton closeVariant="white">
          <Modal.Title className="d-flex gap-2 pt-1 align-items-center align-items-sm-start">
            <FontAwesomeIcon icon={faBox} color="white" />
            <h2>Commande passée par le CSE</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-4">
          <Form
            noValidate
            validated={validated}
            onSubmit={(e) => {
              e.preventDefault()
              setShowConfirm(true)
            }}
          >
            <Row className="mb-3 ps-2">
              Veuillez sélectionner les commandes des employés que vous avez
              reçues :
            </Row>
            <div className="mb-3 ">
              <FontAwesomeIcon icon={faInfoCircle} color={colors.violet} />{" "}
              <span className="fst-italic" style={{ color: "grey" }}>
                Une fois que vous aurez validé, toutes les commandes
                sélectionnées passeront automatiquement au statut en attente de
                paiement
              </span>
            </div>
            <Row>
              {isSuccess && (
                <DataTable
                  columns={columns}
                  data={orders}
                  striped
                  dense
                  progressPending={isLoading}
                  pagination
                  onChangeRowsPerPage={handlePerRowsChange}
                  paginationPerPage={perPage}
                  defaultSortFieldId={1}
                  defaultSortAsc={false}
                  responsive
                  selectableRows
                  onSelectedRowsChange={handleRowSelected}
                  highlightOnHover
                  noDataComponent="Aucune commande commandée"
                  progressComponent={<Loader></Loader>}
                  paginationComponentOptions={paginationComponentOptions}
                ></DataTable>
              )}
            </Row>

            <Row className="mb-3 px-2">
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={frLocale}
              >
                <DatePicker
                  label="Date de réception de la commande"
                  value={receivedAt}
                  onChange={(newValue) => {
                    setReceivedAt(newValue)
                  }}
                  className="datePicker"
                  maxDate={new Date()}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: "jj/mm/aaaa",
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
              {errors && errors.receivedAt && (
                <Form.Control.Feedback
                  type="invalid"
                  style={{ display: "block" }}
                >
                  {errors &&
                    errors.receivedAt?.map((error: any, index: number) => (
                      <div key={index}>{error}</div>
                    ))}
                </Form.Control.Feedback>
              )}
            </Row>
            <Form.Group className="my-3 text-danger">{msgError}</Form.Group>
            <div className="d-flex justify-content-end gap-3 flex-wrap">
              <ButtonPrimary
                label="Valider"
                type="submit"
                disabled={!selectedOrders || selectedOrders.length === 0}
                loading={isPostLoading}
              />
              <ButtonSecondary
                onClick={modalProps.handleClose}
                label="Annuler"
                type="button"
              />
            </div>
            <ValidateModal
              show={showConfirm}
              handleClose={() => setShowConfirm(false)}
              modalMessage="Êtes-vous sûr de vouloir modifier ces commandes ?"
              modalTitle="Modification des commandes"
              handleConfirm={handleValidate}
              loading={isPostLoading}
            />
          </Form>
        </Modal.Body>
      </StyledModal>
    </>
  )
}

export default OrdersReceivedModal
