import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { FloatingLabel, Form, Modal } from "react-bootstrap"
import styled from "styled-components"
import Operation, { OperationType } from "../../models/operation"
import colors from "../../utils/style/colors"
import frLocale from "date-fns/locale/fr"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEuroSign } from "@fortawesome/free-solid-svg-icons"
import { format } from "date-fns"
import ValidateModal from "../ValidateModal"
import ButtonSecondary from "../ButtonSecondary"
import ButtonPrimary from "../ButtonPrimary"
import Toastify from "toastify-js"
import {
  ResponseApiException,
  ResponseApiType,
} from "../../models/responseApiType"
import { usePostOperationMutation } from "../../features/operationSlice"
import { SelectChangeEvent, TextField } from "@mui/material"
import { useEffect, useState } from "react"

interface ModalProps {
  show: boolean
  handleClose: () => void
}

const StyledModal = styled(Modal)`
  font-size: 0.9rem;
  h2 {
    font-size: 1rem;
    font-weight: 300;
    padding-left: 0.5rem;

    @media (min-width: 768px) {
      font-size: 1.2rem;
    }
  }

  .prices span,
  .total span {
    font-weight: bold;
  }

  .modal-header {
    background-color: ${colors.violet};
    color: white;
  }
`

function AdminFinancialModal(modalProps: ModalProps) {
  const [validated, setValidated] = useState(false)
  const [errors, setErrors] = useState({} as any)
  const [msgError, setMsgError] = useState<string>()
  const [operation, setOperation] = useState<Partial<Operation>>()
  const [showConfirm, setShowConfirm] = useState(false)
  const [to, setTo] = useState<Date | null>(new Date())

  const init = {
    operationDate: format(new Date(), "yyyy-MM-dd HH:mm:ss"),
    type: OperationType.Credit,
    title: "",
  } as Partial<Operation>

  const [postOperation, { isLoading: isPostLoading }] =
    usePostOperationMutation()

  const handleSubmit = (event: any) => {
    event.preventDefault()
    const form = event.currentTarget
    if (form.checkValidity() === false) {
      setValidated(true)
    } else {
      setShowConfirm(true)
    }
  }

  const handleValidate = () => {
    //post paiement and ids => set to ordered status and add paiement to other orders
    postOperation(operation)
      .unwrap()
      .then(() => {
        Toastify({
          text: "L'opération' a bien été créée",
          duration: 8000,
          newWindow: true,
          close: true,
          gravity: "top", // `top` or `bottom`
          position: "right", // `left`, `center` or `right`
          stopOnFocus: true, // Prevents dismissing of toast on hover
          style: {
            background: "linear-gradient(to right, #29aac4, #bd79e7)",
          },
          onClick: function () {}, // Callback after click
        }).showToast()
        modalProps.handleClose()
      })
      .catch((error: ResponseApiException) => {
        console.error(error)
        if (error.data?.type === ResponseApiType.FormError) {
          setMsgError("Veuillez vérifier les données")
          setErrors(error.data?.detail)
          setValidated(true)
        } else if (error.data?.type === ResponseApiType.CustomError) {
          setMsgError(error.data?.detail)
        } else {
          setMsgError("Une erreur interne s'est produite.")
        }
      })
      .finally(setShowConfirm(false))
  }

  useEffect(() => {
    if (modalProps.show) {
      setValidated(false)
      setMsgError("")
      setErrors({})
      setOperation(init)
      setTo(new Date())
    }
  }, [modalProps.show])

  const handleOperationChange = ({
    target: { name, value },
  }: React.ChangeEvent<HTMLInputElement> | SelectChangeEvent | any) => {
    if (name === "amount") value = parseFloat(value)
    setOperation((prev) => ({ ...prev, [name]: value }))
  }

  useEffect(() => {
    let newDate: any
    try {
      newDate = to ? format(to, "yyyy-MM-dd HH:mm:ss") : null
    } catch (error: any) {
      newDate = null
    }
    setOperation((prev: any) => ({
      ...prev,
      operationDate: newDate,
    }))
  }, [to])

  return (
    <StyledModal
      show={modalProps.show}
      onHide={modalProps.handleClose}
      backdrop="static"
      keyboard={false}
      dialogClassName="modal-width-refund p-0"
      centered
    >
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={frLocale}
      >
        <Modal.Header closeButton closeVariant="white">
          <Modal.Title className="d-flex gap-2 pt-1 align-items-center align-items-sm-start">
            <FontAwesomeIcon icon={faEuroSign} color="white" />
            <h2>Ajout d'une opération sur le compte</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-4">
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Form.Group className="mb-3 ps-2" controlId="formTitle">
              <FloatingLabel
                controlId="floatingSelect"
                label="Choisissez un type"
              >
                <Form.Select
                  name="type"
                  aria-label="Type d'opération"
                  onChange={handleOperationChange}
                  value={operation?.type}
                  required
                  isInvalid={validated && (errors.type || !operation?.type)}
                >
                  <option value={OperationType.Credit}>Crédit</option>
                  <option value={OperationType.Debit}>Débit</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  Le type est requis
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
            <Form.Group className="mb-3 ps-2" controlId="formTitle">
              <FloatingLabel controlId="amount" label="Libellé *">
                <Form.Control
                  type="text"
                  required
                  value={operation?.title ?? ""}
                  name="title"
                  onChange={handleOperationChange}
                  isInvalid={validated && errors.title}
                />
                <Form.Control.Feedback type="invalid">
                  Le libellé est requis
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
            <Form.Group className="mb-3 ps-2" controlId="formDetail">
              <FloatingLabel controlId="amount" label="Détail">
                <Form.Control
                  type="text"
                  value={operation?.detail ?? ""}
                  name="detail"
                  onChange={handleOperationChange}
                />
              </FloatingLabel>
            </Form.Group>
            <Form.Group className="mb-4 px-2" controlId="formAmount">
              <FloatingLabel controlId="amount" label="Montant (€) *">
                <Form.Control
                  type="number"
                  step={0.01}
                  min={0}
                  name="amount"
                  onChange={handleOperationChange}
                  isInvalid={validated && !operation?.amount}
                  style={{ maxWidth: "10rem" }}
                />
                <Form.Control.Feedback type="invalid">
                  Le montant est obligatoire et doit être composé de 2 décimales
                  maximum.
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
            <Form.Group className="mb-4 px-2">
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={frLocale}
              >
                <DatePicker
                  label="Date de l'opération"
                  value={to}
                  onChange={(newValue) => {
                    setTo(newValue)
                  }}
                  className="datePicker"
                  maxDate={new Date()}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: "jj/mm/aaaa",
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
            </Form.Group>
            <Form.Group className="my-3 text-danger">{msgError}</Form.Group>
            <div className="d-flex justify-content-end gap-3 flex-wrap">
              <ButtonPrimary
                label="Valider"
                type="submit"
                loading={isPostLoading}
              />
              <ButtonSecondary
                onClick={modalProps.handleClose}
                label="Annuler"
                type="button"
              />
            </div>
            <ValidateModal
              show={showConfirm}
              handleClose={() => setShowConfirm(false)}
              modalMessage="Merci de bien vérifier les données avant de valider, celles-ci ne seront pas modifiables"
              modalTitle="Ajout opération compte CSE"
              handleConfirm={handleValidate}
              loading={isPostLoading}
            />
          </Form>
        </Modal.Body>
      </LocalizationProvider>
    </StyledModal>
  )
}

export default AdminFinancialModal
