import WaveHeader from "../../components/WaveHeader"
import bgImage from "../../assets/presentation-bg.jpg"
import { Col, Row } from "react-bootstrap"
import membersImg from "../../assets/members.png"
import styled from "styled-components"
import waveImg from "../../assets/vagues-bg-under.svg"
import waveSymImg from "../../assets/vagues-bg.svg"
import colors from "../../utils/style/colors"
import tasksImg from "../../assets/tasks.png"
import conditionsImg from "../../assets/conditions.png"
import activitiesImg from "../../assets/activities.png"
import contactIcons from "../../assets/contact-icons.svg"
import { CheckLg } from "react-bootstrap-icons"
import ButtonPrimary from "../../components/ButtonPrimary"
import { faEnvelope, faUser } from "@fortawesome/free-solid-svg-icons"
import { useNavigate } from "react-router-dom"

const StyledDiv = styled.div`
  h2 {
    color: ${colors.violet};
    font-size: 1.8rem;
    padding-bottom: 1rem;
  }
`
const StyledCol = styled.div`
  padding: 2rem 1rem;
  @media (min-width: 768px) {
    padding: 3rem;
  }
`

const StyledWaveUnderRow = styled(Row)`
  margin-top: 0;
  position: relative;
  align-items: center;
  &::after {
    background-image: url(${waveImg});
    background-size: 100% 100%;
    background-position: center;
    bottom: -6rem;
    content: "";
    height: 5rem;
    left: 0;
    position: absolute;
    width: 100%;
  }
`

const StyledWaveUnderRow2 = styled(Row)`
  margin-top: 5rem;
  position: relative;
  align-items: center;
  &::after {
    background-image: url(${waveSymImg});
    background-size: 100% 100%;
    background-position: center;
    bottom: -3.5rem;
    content: "";
    height: 5rem;
    left: 0;
    position: absolute;
    width: 100%;
  }
`

function PresentationSection() {
  const navigate = useNavigate()
  return (
    <div>
      <WaveHeader title="Présentation" image={bgImage} />
      <StyledDiv className="container-fluid">
        <StyledWaveUnderRow className="justify-content-center">
          <StyledCol className="col-lg-7 order-last order-lg-first">
            <h2>Qui sont les membres du CSE ?</h2>
            <div>
              <p>
                Le CSE ou Comité Social et Économique comprend l’employeur et
                une délégation du personnel. Les membres de la délégation du
                personnel sont des salariés de l’entreprise qui ont été élus
                pour représenter l’ensemble de leurs collègues.
              </p>
              <p>
                Les membres du bureau du CSE sont Mme DE BONI Hanane (Secrétaire
                et Trésorière adjointe), Mr BLANC Dominique (Trésorier et
                Secrétaire adjoint), Mr SAYAH Salah Eddine, Mr LEBRUN Alan, Mme
                VALLEE Elen, Mr JALOUX Fabien, élus depuis le 1er Décembre 2023
                pour une durée de 4 ans.
              </p>
            </div>
          </StyledCol>
          <Col lg={4} className="order-first order-lg-last">
            <img src={membersImg} alt="" className="img-fluid" />
          </Col>
        </StyledWaveUnderRow>
        <StyledWaveUnderRow2>
          <Col lg={4}>
            <img src={tasksImg} alt="" className="img-fluid" />
          </Col>
          <StyledCol className="col-lg-7">
            <h2 style={{ color: colors.blue }}>
              Quelles sont les missions du CSE ?
            </h2>
            <div>
              <p>
                Le CSE veille à donner l’avis de l’ensemble des salariés sur les
                décisions économiques et sociales prises par l’employeur. Il
                doit être informé et consulté sur les données économiques de
                l’entreprise et sur les décisions qui ont un impact sur les
                conditions de travail.
              </p>
              <p>
                Sa mission est également de gérer les activités sociales et
                culturelles au bénéfice des salariés et de leur famille. Le CSE
                propose ainsi, grâce à un budget dédié, d’accéder à de nombreux
                avantages qui ont pour but d’améliorer la vie personnelle et
                professionnelle des collaborateurs.
              </p>
            </div>
          </StyledCol>
        </StyledWaveUnderRow2>
        <StyledWaveUnderRow className="justify-content-center pt-4">
          <StyledCol className="col-lg-7 order-last order-lg-first">
            <h2 style={{ color: colors.green }}>
              Quelles sont les conditions d’accès au CSE ?
            </h2>
            <div>
              <p>
                Le CSE réserve les activités sociales et culturelles aux
                salariés et à leur famille. Tous les salariés de l’entreprise
                sont des ayants-droits de ces avantages CSE sans distinction des
                contrats de travail. L'accès aux chèques vacances et cartes
                cadoc nécessite d'avoir au moins 6 mois d'ancienneté ou un
                contrat de plus de 6 mois.
              </p>
            </div>
          </StyledCol>
          <Col lg={4} className="order-first order-lg-last">
            <img src={conditionsImg} alt="" className="img-fluid" />
          </Col>
        </StyledWaveUnderRow>
        <StyledWaveUnderRow2 className="justify-content-center">
          <Col lg={4}>
            <img src={activitiesImg} alt="" className="img-fluid" />
          </Col>
          <StyledCol className="col-lg-7">
            <h2 style={{ color: colors.orange }}>
              Quels types d’activités sont concernées ?
            </h2>
            <div>
              <p>
                Le CSE vous permet d’avoir de nombreux avantages notamment :
              </p>
              <ul style={{ listStyleType: "none" }}>
                <li>
                  <CheckLg color={colors.orange}></CheckLg>&nbsp;Places de
                  cinéma à prix réduit
                </li>
                <li>
                  {" "}
                  <CheckLg color={colors.orange}></CheckLg>&nbsp;Cartes de
                  piscine à prix réduit
                </li>
                <li>
                  {" "}
                  <CheckLg color={colors.orange}></CheckLg>&nbsp;Participation
                  financière aux activités sportives et culturelles
                </li>
                <li>
                  {" "}
                  <CheckLg color={colors.orange}></CheckLg>&nbsp;Tarifs
                  préférentiels sur un catalogue de parfums
                </li>
              </ul>
              <p>
                Pour toutes commandes et demandes de remboursement rendez-vous
                sur votre profil !
              </p>
              <div className="text-center text-md-end py-2">
                <ButtonPrimary
                  label="Mon profil"
                  icon={faUser}
                  type="button"
                  onClick={() => navigate("/commandes")}
                ></ButtonPrimary>
              </div>
            </div>
          </StyledCol>
        </StyledWaveUnderRow2>
        <Row
          style={{ paddingTop: "5rem" }}
          className="justify-content-center align-items-center"
        >
          <Col md={8} lg={5} className="p-3">
            <h2>Votre CSE est à l’écoute</h2>
            <p>
              Un problème ? Une question ? Nous sommes là pour vous répondre.
            </p>
            <div className="text-center text-md-start pt-3">
              <ButtonPrimary
                label="Contactez-nous"
                icon={faEnvelope}
                type="button"
                onClick={() => navigate("/contact")}
              ></ButtonPrimary>
            </div>
          </Col>
          <Col lg={5} className="text-center text-md-start">
            <img src={contactIcons} alt="" className="img-fluid" />
          </Col>
        </Row>
      </StyledDiv>
    </div>
  )
}

export default PresentationSection
