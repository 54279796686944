import { Box, Tab, Tabs } from "@mui/material"
import { useEffect, useState } from "react"
import { Container } from "react-bootstrap"
import styled from "styled-components"
import colors from "../../../utils/style/colors"
import { AltRoute, Pool } from "@mui/icons-material"
import AdminCardOrder from "../../../components/AdminCardOrder"
import AdminOtherOrder from "../../../components/AdminOtherOrder"

const StyledDiv = styled(Container)`
  padding: 2rem 0.5rem 2rem 2rem;

  h1 {
    font-size: 1.4rem;
    color: ${colors.violet};
    text-align: center;
    margin-bottom: 2rem;
  }

  @media all and (min-width: 575px) {
    padding: 2rem 0.5rem 2rem 2.5rem;
  }

  @media all and (min-width: 768px) {
    padding: 2rem 0.5rem 2rem 3.2rem;
    h1 {
      font-size: 1.7rem;
    }
  }
`

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  }
}

function OrdersAdmin() {
  const [value, setValue] = useState(0)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
    localStorage.setItem("orderTab", newValue.toString())
  }

  useEffect(() => {
    let favorite = Number(localStorage.getItem("orderTab")) || 0
    setValue(favorite)
  }, [])

  return (
    <StyledDiv fluid>
      <h1>Gestion des commandes</h1>
      <Tabs
        value={value}
        onChange={handleChange}
        variant="fullWidth"
        aria-label="Navigation"
        textColor="secondary"
        indicatorColor="secondary"
      >
        <Tab icon={<Pool />} label="Piscine / Cinéma" {...a11yProps(0)} />
        <Tab icon={<AltRoute />} label="Autre (Parfums)" {...a11yProps(1)} />
      </Tabs>

      <div>
        <TabPanel value={value} index={0}>
          <AdminCardOrder />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <AdminOtherOrder />
        </TabPanel>
      </div>
    </StyledDiv>
  )
}

export default OrdersAdmin
