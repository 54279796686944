import BankAccount from "../models/bankAccount"
import Operation from "../models/operation"
import OperationPagination from "../models/operationPagination"
import { apiSlice } from "./api/apiSlice"

export const extendedApiSlice: any = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getBankOperationWithPagination: builder.query<
      OperationPagination,
      {
        from: string
        to: string
        limit: number
        page: number
        orderBy: string
        direction: string
        operationFrom: string
      }
    >({
      query: (param) => {
        return { url: "admin/operations", params: param }
      },
      providesTags: (result) =>
        result && result.items
          ? [
              ...result.items.map(({ id }) => ({
                type: "Operation" as const,
                id,
              })),
              { type: "Operation", id: "LIST" },
            ]
          : [{ type: "Operation", id: "LIST" }],
    }),
    getBankAccount: builder.query<BankAccount, void>({
      query: () => `admin/bank-account`,
      providesTags: (result) => [{ type: "BankAccount", id: result?.id }],
    }),
    postOperation: builder.mutation<number, Partial<Operation>>({
      query: (body) => ({
        url: `admin/operation`,
        method: "POST",
        body,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Operation", id: "LIST" },
        { type: "BankAccount", id: "LIST" },
      ],
    }),
    getOperation: builder.query<Operation, number>({
      query: (id) => `admin/operations/${id}`,
      providesTags: (result, error, id) => [{ type: "Operation", id }],
    }),
    deleteOperation: builder.mutation<boolean, number>({
      query: (id) => ({
        url: `admin/operations/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Operation", id: "LIST" },
        { type: "BankAccount", id: "LIST" },
      ],
    }),
  }),
})

export const {
  useGetBankOperationWithPaginationQuery,
  useGetBankAccountQuery,
  usePostOperationMutation,
  useGetOperationQuery,
  useDeleteOperationMutation,
} = extendedApiSlice
