import * as React from "react"
import { createRoot } from "react-dom/client"

import App from "./pages/App"
import "./styles/global.scss"
import { BrowserRouter as Router } from "react-router-dom"
import { Provider } from "react-redux"
import { store } from "./utils/store"
import "bootstrap/dist/css/bootstrap.min.css"
import ScrollToTop from "./components/ScrollToTop"
import "@fontsource/roboto/300.css"
import "@fontsource/roboto/400.css"
import "@fontsource/roboto/500.css"
import "@fontsource/roboto/700.css"
import GlobalStyle from "./utils/style/GlobalStyle"
import { SnackbarProvider } from "notistack"
import "toastify-js/src/toastify.css"

const container = document.getElementById("root") as HTMLElement
const root = createRoot(container)

root.render(
  <Provider store={store}>
    <React.StrictMode>
      <Router>
        <ScrollToTop />
        <GlobalStyle />
        <SnackbarProvider maxSnack={3}>
          <App />
        </SnackbarProvider>
      </Router>
    </React.StrictMode>
  </Provider>
)
