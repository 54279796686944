import Article from "../models/article"
import ArticleAdmin from "../models/articleAdmin"
import ArticlePagination from "../models/articlePagination"
import { apiSlice } from "./api/apiSlice"

export const extendedApiSlice: any = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getArticlesWithPagination: builder.query<
      ArticlePagination,
      { limit: number; page: number }
    >({
      query: (param) => {
        const { limit, page } = param
        return { url: "articles/visible", params: { limit, page } }
      },
      providesTags: (result) =>
        result && result.items
          ? [
              ...result.items.map(({ id }) => ({
                type: "Article" as const,
                id,
              })),
              { type: "Article", id: "LIST" },
            ]
          : [{ type: "Article", id: "LIST" }],
    }),
    getArticlesAdminWithPagination: builder.query<
      ArticlePagination,
      { limit: number; page: number; orderBy: string; direction: string }
    >({
      query: (param) => {
        return { url: "admin/articles", params: param }
      },
      providesTags: (result) =>
        result
          ? [
              ...result.items.map(({ id }) => ({
                type: "ArticleAdmin" as const,
                id,
              })),
              { type: "ArticleAdmin", id: "LIST" },
            ]
          : [{ type: "ArticleAdmin", id: "LIST" }],
    }),
    getArticles: builder.query<Article[], void>({
      query: () => ({ url: "articles/visible" }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: "Article" as const, id })),
              { type: "Article", id: "LIST" },
            ]
          : [{ type: "Article", id: "LIST" }],
    }),
    addArticle: builder.mutation<Article, ArticleAdmin>({
      query: (body) => ({
        url: `admin/articles`,
        method: "POST",
        body,
      }),
      invalidatesTags: [
        { type: "ArticleAdmin", id: "LIST" },
        { type: "Article", id: "LIST" },
      ],
    }),
    editArticle: builder.mutation<Article, any>({
      query: ({ id, body }) => ({
        url: `admin/articles/${id}`,
        method: "POST",
        body,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "ArticleAdmin", id: "LIST" },
        { type: "Article", id: "LIST" },
        { type: "Article", id: arg.id },
      ],
    }),
    getArticle: builder.query<Article, string>({
      query: (id) => `articles/${id}`,
      providesTags: (result, error, id) => [{ type: "Article", id }],
    }),
    deleteArticle: builder.mutation<boolean, number>({
      query: (id) => ({
        url: `admin/articles/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "ArticleAdmin", id: "LIST" },
        { type: "Article", id: "LIST" },
      ],
    }),
  }),
})

export const {
  useGetArticlesQuery,
  useGetArticlesWithPaginationQuery,
  useGetArticleQuery,
  useGetArticlesAdminWithPaginationQuery,
  useAddArticleMutation,
  useEditArticleMutation,
  useDeleteArticleMutation,
} = extendedApiSlice
