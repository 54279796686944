import { FloatingLabel, Form, Modal, Row } from "react-bootstrap"
import ButtonPrimary from "../ButtonPrimary"
import styled from "styled-components"
import colors from "../../utils/style/colors"
import ButtonSecondary from "../ButtonSecondary"
import { Fragment, useEffect, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Toastify from "toastify-js"
import frLocale from "date-fns/locale/fr"
import {
  faBox,
  faInfoCircle,
  faQuestionCircle,
  faWarning,
} from "@fortawesome/free-solid-svg-icons"
import DataTable from "react-data-table-component"
import { Loader } from "../../utils/Atoms"
import { formatPrice, formatStrToDate } from "../../utils/utils"
import { TextField, ToggleButton, ToggleButtonGroup } from "@mui/material"
import {
  getPaiementTypeLabel,
  Paiement,
  PaiementType,
} from "../../models/paiement"
import {
  ResponseApiException,
  ResponseApiType,
} from "../../models/responseApiType"
import ValidateModal from "../ValidateModal"
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { format } from "date-fns"
import {
  getRefundStatusLabel,
  getStatusColor,
  Refund,
  RefundStatusType,
  RefundType,
} from "../../models/refund"
import { getRelationshipLabel } from "../../models/member"
import {
  useGetRefundsToRepayedQuery,
  useGetRefundsTotalMutation,
  usePostAdminRefundMutation,
} from "../../features/refundSlice"
import { useGetUsersToRepayedQuery } from "../../features/userSlice"
import InternError from "../InternError"
import User from "../../models/user"

interface ModalProps {
  show: boolean
  handleClose: () => void
}

const StyledModal = styled(Modal)`
  font-size: 0.9rem;
  h2 {
    font-size: 1rem;
    font-weight: 300;
    padding-left: 0.5rem;

    @media (min-width: 768px) {
      font-size: 1.2rem;
    }
  }

  .prices span,
  .total span {
    font-weight: bold;
  }

  .modal-header {
    background-color: ${colors.violet};
    color: white;
  }
`

const StyledSpan = styled.span<{ status: RefundStatusType }>`
  font-size: 0.8rem;
  padding: 0.2rem 0.5rem;
  border-radius: 2rem;
  align-self: center;
  text-align: center;
  white-space: nowrap;
  background-color: ${(prop) =>
    prop.status ? getStatusColor(prop.status) : "white"};
  color: white;
  margin-left: 0.5rem;
`

const Span = styled.span`
  font-weight: bold;
  color: ${colors.darkViolet};
`

function RepayedModal(modalProps: ModalProps) {
  const [validated, setValidated] = useState(false)
  const [errors, setErrors] = useState({} as any)
  const [msgError, setMsgError] = useState<string>()
  const [perPage, setPerPage] = useState(20)
  const [selectedRefunds, setSelectedRefunds] = useState([])
  const [total, setTotal] = useState<number>(0)
  const [isAdminChequePaiement, setIsAdminChequePaiement] = useState(true)
  const [adminChequeNumber, setAdminChequeNumber] = useState<string>("")
  const [detail, setDetail] = useState<string>("")
  const [adminPaiementType, setAdminPaiementType] = useState<string | null>(
    PaiementType.Cheque
  )

  const [employeeId, setEmployeeId] = useState<number>(0)
  const [repayedAt, setRepayedAt] = useState<Date | null>(new Date())
  const [showConfirm, setShowConfirm] = useState(false)

  const handlePerRowsChange = (newPerPage: number) => {
    setPerPage(newPerPage)
  }

  useEffect(() => {
    setShowConfirm(false)
    if (modalProps.show) {
      setValidated(false)
      setMsgError("")
      setErrors({})
      setEmployeeId(0)
    }
  }, [modalProps.show])

  const paginationComponentOptions = {
    rowsPerPageText: "Demandes par page",
    rangeSeparatorText: "de",
  }

  const columns = [
    {
      name: "Date demande",
      selector: (row: Refund) => row.requestDate,
      sortable: true,
      sortField: "requestDate",
      format: (row: Refund) =>
        formatStrToDate(row.requestDate, "dd/MM/yyyy HH:mm"),
    },
    {
      name: "Numéro",
      selector: (row: Refund) => row.number,
    },
    {
      name: "Bénéficiaire",
      selector: (row: Refund) => row.recipient,
      cell: (row: Refund) => (
        <div className="text-cente">
          {row.recipient
            ? `${row.recipient?.firstname} ${
                row.recipient?.lastname
              } (${getRelationshipLabel(row.recipient?.relationship)})`
            : !row.owner?.firstName && !row.owner?.lastname
            ? row.owner?.email
            : `${row.owner?.firstName} ${row.owner?.lastname}`}
        </div>
      ),
    },
    {
      name: "Intitulé",
      selector: (row: Refund) => row.title,
      sortable: true,
      sortField: "title",
    },
    {
      name: "Prix",
      selector: (row: Refund) => row.price,
      format: (row: Refund) => formatPrice(row.price),
    },
    {
      name: "À rembourser",
      selector: (row: Refund) => row.repaymentAmount,
      format: (row: Refund) => formatPrice(row.repaymentAmount),
    },
    {
      name: "Statut",
      selector: (row: Refund) => row.status,
      cell: (row: Refund) => (
        <StyledSpan status={row.status}>
          {getRefundStatusLabel(row.status)}
        </StyledSpan>
      ),
    },
  ] as any

  const {
    data: refunds = [],
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetRefundsToRepayedQuery(
    { id: employeeId, type: RefundType.Sport },
    { skip: !employeeId }
  )

  const {
    data: users = [],
    isLoading: isUsersLoading,
    isSuccess: isUsersSuccess,
    isError: isUsersError,
  } = useGetUsersToRepayedQuery({ type: RefundType.Sport })

  const [getTotal, { isLoading: isTotalLoading }] = useGetRefundsTotalMutation()

  const [postAdminRefund, { isLoading: isPostLoading }] =
    usePostAdminRefundMutation()

  const handleRowSelected = ({ selectedRows }: any) => {
    setSelectedRefunds(selectedRows)
  }

  const handleAdminPaiement = (
    event: React.MouseEvent<HTMLElement>,
    newPaiement: string | null
  ) => {
    setAdminPaiementType(newPaiement)
    if (newPaiement === PaiementType.Cheque) {
      setIsAdminChequePaiement(true)
    } else {
      setIsAdminChequePaiement(false)
    }
  }

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      getTotal({
        body: selectedRefunds.map((item: Refund) => item.id),
      })
        .unwrap()
        .then((resp: any) => setTotal(parseFloat(resp)))
    }, 500)

    return () => clearTimeout(delayDebounceFn)
  }, [selectedRefunds])

  const handleValidate = () => {
    let postAdmin = {
      ids: selectedRefunds.map((item: Refund) => item.id),
      adminPayment: {
        type: adminPaiementType,
        chequeNumber: adminChequeNumber,
      } as Paiement,
      detail: detail,
      repayedAt: repayedAt ? format(repayedAt, "yyyy-MM-dd HH:mm:ss") : null,
    }

    //post paiement and ids => set to ordered status and add paiement to other refunds
    postAdminRefund({
      body: postAdmin,
    })
      .unwrap()
      .then(() => {
        Toastify({
          text: "Les demandes ont bien été modifiées",
          duration: 8000,
          newWindow: true,
          close: true,
          gravity: "top", // `top` or `bottom`
          position: "right", // `left`, `center` or `right`
          stopOnFocus: true, // Prevents dismissing of toast on hover
          style: {
            background: "linear-gradient(to right, #29aac4, #bd79e7)",
          },
          onClick: function () {}, // Callback after click
        }).showToast()
        modalProps.handleClose()
      })
      .catch((error: ResponseApiException) => {
        console.error(error)
        if (error.data?.type === ResponseApiType.FormError) {
          setMsgError("Veuillez vérifier les données")
          setErrors(error.data?.detail)
          setValidated(true)
        } else if (error.data?.type === ResponseApiType.CustomError) {
          setMsgError(error.data?.detail)
        } else {
          setMsgError("Une erreur interne s'est produite.")
        }
      })
      .finally(() => {
        setShowConfirm(false)
      })
  }
  let content

  if (isUsersSuccess) {
    content = (
      <Form
        noValidate
        validated={validated}
        onSubmit={(e) => {
          e.preventDefault()
          setShowConfirm(true)
        }}
      >
        {isUsersSuccess && (
          <FloatingLabel className="mb-3" label="Employé">
            <Form.Select
              aria-label="Employé"
              style={{ minWidth: "10rem" }}
              name="employeeId"
              onChange={(e) => {
                setSelectedRefunds([])
                setEmployeeId(parseInt(e.target.value))
              }}
            >
              <option value="">Sélectionnez un employé</option>
              {users?.map((user: any) => (
                <option value={user.id} key={user.id}>
                  {user.firstName} {user.lastname}
                </option>
              ))}
            </Form.Select>
          </FloatingLabel>
        )}
        <Row className="mb-3 ps-2">
          Veuillez sélectionner les demandes que vous avez remboursées :
        </Row>
        <div className="mb-3 ">
          <FontAwesomeIcon icon={faInfoCircle} color={colors.violet} />{" "}
          <span className="fst-italic" style={{ color: "grey" }}>
            Une fois que vous aurez validé, toutes les demandes sélectionnées
            passeront automatiquement au statut Remboursée
          </span>
        </div>
        {isSuccess && (
          <Fragment>
            <Row>
              {isSuccess && (
                <DataTable
                  columns={columns}
                  data={refunds}
                  progressPending={isLoading}
                  pagination
                  striped
                  dense
                  onChangeRowsPerPage={handlePerRowsChange}
                  paginationPerPage={perPage}
                  defaultSortFieldId={1}
                  defaultSortAsc={false}
                  responsive
                  selectableRows
                  onSelectedRowsChange={handleRowSelected}
                  highlightOnHover
                  noDataComponent="Aucune demande à rembourser"
                  progressComponent={<Loader></Loader>}
                  paginationComponentOptions={paginationComponentOptions}
                ></DataTable>
              )}
            </Row>
            <Row>
              <div
                className="mt-2 mb-1 ps-2 d-flex flex-wrap gap-1 gap-md-2"
                style={{ fontWeight: "bold" }}
              >
                Montant Total :{" "}
                {isTotalLoading ? (
                  <Loader />
                ) : total ? (
                  formatPrice(total)
                ) : (
                  <FontAwesomeIcon
                    icon={faQuestionCircle}
                    color={colors.violet}
                  />
                )}
              </div>
              <div className="mb-3 ps-2">
                <FontAwesomeIcon icon={faWarning} color="darkred" />
                &nbsp; Veillez bien à vérifier que le montant correspond au
                remboursement que vous avez réalisé
              </div>
            </Row>
            <Row>
              <div className="ps-2 mb-3 d-flex flex-column gap-1">
                <Span>
                  Type de paiement : *{" "}
                  {errors && errors.adminPayment && "Ce champ est requis"}
                </Span>
                <ToggleButtonGroup
                  value={adminPaiementType}
                  exclusive
                  aria-required
                  onChange={handleAdminPaiement}
                  aria-label="type de paiement"
                >
                  <ToggleButton
                    value={PaiementType.Cash}
                    aria-label="en espèces"
                  >
                    {getPaiementTypeLabel(PaiementType.Cash)}
                  </ToggleButton>
                  <ToggleButton
                    value={PaiementType.Cheque}
                    aria-label="par chèque"
                  >
                    {getPaiementTypeLabel(PaiementType.Cheque)}
                  </ToggleButton>
                </ToggleButtonGroup>
                {isAdminChequePaiement && (
                  <Form.Control
                    className="mt-1"
                    placeholder="Numéro de chèque"
                    defaultValue={adminChequeNumber}
                    onChange={(e) => setAdminChequeNumber(e.target.value)}
                  />
                )}
              </div>
            </Row>
            <Form.Group className="mb-4" controlId="formDetail">
              <Form.Label>
                <Span>Intitulé : * </Span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Indiquez un intitulé"
                value={detail}
                isInvalid={errors && errors.detail}
                onChange={(e) => setDetail(e.target.value)}
              />
              <Form.Text className="text-muted">
                L'intitulé correspond à celui qui apparaîtra dans la synthèse du
                budget social CSE
              </Form.Text>
              <Form.Control.Feedback type="invalid">
                {errors.detail}
              </Form.Control.Feedback>
            </Form.Group>
            <Row className="mb-3 px-2">
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={frLocale}
              >
                <DatePicker
                  label="Date du remboursement"
                  value={repayedAt}
                  onChange={(newValue) => {
                    setRepayedAt(newValue)
                  }}
                  className="datePicker"
                  maxDate={new Date()}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: "jj/mm/aaaa",
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
              {errors && errors.repayedAt && (
                <Form.Control.Feedback
                  type="invalid"
                  style={{ display: "block" }}
                >
                  {errors &&
                    errors.repayedAt?.map((error: any, index: number) => (
                      <div key={index}>{error}</div>
                    ))}
                </Form.Control.Feedback>
              )}
            </Row>
          </Fragment>
        )}

        {isLoading && <Loader />}
        {isError && <InternError />}

        <Form.Group className="my-3 text-danger">{msgError}</Form.Group>
        <div className="d-flex justify-content-end gap-3 flex-wrap">
          <ButtonPrimary
            label="Valider"
            type="submit"
            disabled={!total || total <= 0}
            // loading={isPostLoading}
          />
          <ButtonSecondary
            onClick={modalProps.handleClose}
            label="Annuler"
            type="button"
          />
        </div>
        <ValidateModal
          show={showConfirm}
          handleClose={() => setShowConfirm(false)}
          modalMessage="Êtes-vous sûr de vouloir ajouter ce remboursement ?"
          modalTitle="Ajout d'un remboursement"
          handleConfirm={handleValidate}
          loading={isPostLoading}
        />
      </Form>
    )
  } else if (isUsersLoading) {
    content = <Loader />
  } else if (isUsersError) {
    content = <InternError />
  }

  return (
    <>
      <StyledModal
        show={modalProps.show}
        onHide={modalProps.handleClose}
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-width-refund p-0"
        centered
      >
        <Modal.Header closeButton closeVariant="white">
          <Modal.Title className="d-flex gap-2 pt-1 align-items-center align-items-sm-start">
            <FontAwesomeIcon icon={faBox} color="white" />
            <h2>Remboursement</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-4">{content}</Modal.Body>
      </StyledModal>
    </>
  )
}

export default RepayedModal
