import { Document } from "./document"
import Member from "./member"
import { Paiement } from "./paiement"

export enum RefundStatusType {
  ToHandle = "ToHandle",
  Pending = "Pending",
  Repayed = "Repayed",
  Canceled = "Canceled",
}

export function getRefundStatus() {
  return [
    RefundStatusType.ToHandle,
    RefundStatusType.Pending,
    RefundStatusType.Repayed,
    RefundStatusType.Canceled,
  ]
}

export function getRefundStatusLabel(
  status: RefundStatusType | string,
  isEmployee: boolean = false
) {
  switch (status) {
    case RefundStatusType.ToHandle:
      return "À traiter"
    case RefundStatusType.Repayed:
      return "Remboursée"
    case RefundStatusType.Pending:
      return "En attente"
    case RefundStatusType.Canceled:
      return "Annulée"
    default:
      return status
  }
}

export function getStatusColor(
  status: RefundStatusType,
  isEmployee: boolean = false
) {
  switch (status) {
    case RefundStatusType.ToHandle:
      return "grey"
    case RefundStatusType.Pending:
      return "orange"
    case RefundStatusType.Repayed:
      return "green"
    case RefundStatusType.Canceled:
      return "darkred"
    default:
      return "white"
  }
}

export enum RefundType {
  Sport = "SPORT",
  Cultural = "CULTURAL",
}

export function getRefundTypeLabel(type: RefundType | string) {
  switch (type) {
    case RefundType.Sport:
      return "Activités sportives et scolaires"
    case RefundType.Cultural:
      return "Activités culturelles"
    default:
      return type
  }
}

export interface Refund {
  id: number
  requestDate: string
  number: string
  status: RefundStatusType
  note?: string
  createdAt: string
  updatedAt?: string
  price: number
  type: RefundType
  repayedAt?: string
  pendingAt?: string
  canceledAt?: string
  title: string
  employeeMsg?: string
  repaymentAmount: number
  documents?: Document[]
  recipient?: Member
  recipientId?: number
  owner?: { firstName: string; lastname: string; id: number; email: string }
  payment?: Paiement
}
