import { TextField } from "@mui/material"
import { useEffect, useState } from "react"
import { Container, Row } from "react-bootstrap"
import DataTable from "react-data-table-component"
import styled from "styled-components"
import colors from "../../../utils/style/colors"
import Toastify from "toastify-js"
import {
  formatPrice,
  formatStrToDate,
  getFirstDayOfCurrentYear,
  getLastDayOfCurrentYear,
} from "../../../utils/utils"
import frLocale from "date-fns/locale/fr"

import { faPlusCircle } from "@fortawesome/free-solid-svg-icons"
import { Loader } from "../../../utils/Atoms"
import InternError from "../../../components/InternError"
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { format } from "date-fns"
import ButtonPrimary from "../../../components/ButtonPrimary"
import { useNavigate } from "react-router-dom"
import {
  useGetBankAccountQuery,
  useGetBankOperationWithPaginationQuery,
} from "../../../features/operationSlice"
import Operation, { OperationType } from "../../../models/operation"
import BankAccount from "../../../models/bankAccount"
import AdminFinancialModal from "../../../components/AdminFinancialModal"
import { API } from "../../../utils/API"

const StyledDiv = styled(Container)`
  padding: 2rem 0.5rem 2rem 2rem;

  h1 {
    font-size: 1.4rem;
    color: ${colors.violet};
    text-align: center;
    margin-bottom: 2rem;
  }

  @media all and (min-width: 575px) {
    padding: 2rem 0.5rem 2rem 2.5rem;
  }

  @media all and (min-width: 768px) {
    padding: 2rem 0.5rem 2rem 3rem;
    h1 {
      font-size: 1.7rem;
    }
  }
`

const StyledRow = styled(Row)`
  font-size: 1rem;
  h3 {
    font-size: 1.1rem !important;
    color: ${colors.darkViolet};
    margin-bottom: 0 !important ;
  }
`

function FinancialSummary() {
  const navigate = useNavigate()
  const paginationComponentOptions = {
    rowsPerPageText: "Éléments par page",
    rangeSeparatorText: "de",
  }

  const columns: any = [
    {
      name: "Date",
      selector: (row: Operation) => row.operationDate,
      sortable: true,
      sortField: "operationDate",
      format: (row: Operation) =>
        formatStrToDate(row.operationDate ?? "", "dd/MM/yyyy"),
    },
    {
      name: "Libellé",
      selector: (row: Operation) => row.title,
      wrap: true,
    },
    {
      name: "Débit (€)",
      selector: (row: Operation) => row.amount,
      cell: (row: Operation) =>
        row.type === OperationType.Debit && formatPrice(row.amount),
    },
    {
      name: "Crédit (€)",
      selector: (row: Operation) => row.amount,
      cell: (row: Operation) =>
        row.type === OperationType.Credit && formatPrice(row.amount),
    },
    {
      name: "Détails",
      selector: (row: Operation) => row.detail,
      wrap: true,
    },
  ]

  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(10)
  const [from, setFrom] = useState<Date | null>(getFirstDayOfCurrentYear())
  const [to, setTo] = useState<Date | null>(getLastDayOfCurrentYear())
  const [fromDate, setFromDate] = useState<string | null>()
  const [toDate, setToDate] = useState<string | null>()
  const [showModal, setShowModal] = useState(false)

  const [order, setOrder] = useState({
    orderBy: "operationDate",
    direction: "DESC",
  })

  const {
    data: bankPaginate = [],
    isLoading,
    isSuccess,
    isError,
  } = useGetBankOperationWithPaginationQuery(
    {
      limit: perPage,
      page: page,
      orderBy: order.orderBy,
      direction: order.direction,
      from: fromDate,
      to: toDate,
    },
    { skip: !fromDate || !toDate }
  )
  const {
    data: bankAccount,
    isLoading: isBankLoading,
    isSuccess: isBankSuccess,
    isError: isBankError,
    refetch,
  }: {
    data: BankAccount
    isLoading: boolean
    isSuccess: boolean
    isError: boolean
    refetch: () => void
  } = useGetBankAccountQuery()

  const handleExport = () => {
    if (from && to) {
      API.get(`admin/bank-account/export`, {
        responseType: "blob",
        params: {
          from: fromDate,
          to: toDate,
        },
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement("a")
          link.href = url
          link.setAttribute(
            "download",
            `budget-social-CSE_${format(from, "dd-MM-yyyy")}_${format(
              to,
              "dd-MM-yyyy"
            )}.xlsx`
          )
          document.body.appendChild(link)
          link.click()
        })
        .catch((error) => {
          Toastify({
            text: "Une erreur s'est produite lors du téléchargement",
            duration: 8000,
            newWindow: true,
            close: true,
            gravity: "top", // `top` or `bottom`
            position: "right", // `left`, `center` or `right`
            stopOnFocus: true, // Prevents dismissing of toast on hover
            style: {
              background: "linear-gradient(to right, #29aac4, #bd79e7)",
            },
            onClick: function () {}, // Callback after click
          }).showToast()
        })
    }
  }

  const handlePageChange = (page: number) => {
    setPage(page)
  }

  const handlePerRowsChange = (newPerPage: number) => {
    console.log(newPerPage)
    setPerPage(newPerPage)
  }

  const handleSort = (selectedColumn: any, sortDirection: any) => {
    setOrder({
      orderBy: selectedColumn.sortField,
      direction: sortDirection,
    })
  }

  const handleRowClicked = (id: number) => {
    navigate(`/admin/operation/${id}`)
  }
  useEffect(() => {
    try {
      let date1 = from ? format(from, "yyyy-MM-dd HH:mm:ss") : null
      let date2 = to ? format(to, "yyyy-MM-dd HH:mm:ss") : null
      setFromDate(date1)
      setToDate(date2)
    } catch (error) {
      console.error(error)
    }
  }, [from, to])

  let content

  if (isLoading) {
    content = <Loader />
  } else if (isError) {
    content = <InternError />
  } else if (isSuccess) {
    content = (
      <div className="w-100">
        <DataTable
          columns={columns}
          data={bankPaginate.items}
          progressPending={isLoading}
          pagination
          striped
          paginationServer
          paginationTotalRows={bankPaginate.totalItems}
          paginationPerPage={perPage}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          onRowClicked={(row: Operation) => handleRowClicked(row?.id)}
          onSort={handleSort}
          sortServer
          defaultSortFieldId={1}
          defaultSortAsc={false}
          responsive
          highlightOnHover
          pointerOnHover
          noDataComponent="Aucune opération"
          progressComponent={<Loader></Loader>}
          paginationComponentOptions={paginationComponentOptions}
        ></DataTable>
      </div>
    )
  }

  useEffect(() => {
    refetch()
  }, [])

  let contentAccount

  if (isBankLoading) {
    contentAccount = <Loader />
  } else if (isBankError) {
    contentAccount = <InternError />
  } else if (isBankSuccess) {
    contentAccount = formatPrice(bankAccount.balance, true)
  }
  return (
    <StyledDiv fluid>
      <h1>Budget social</h1>
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={frLocale}
      >
        <StyledRow className="px-lg-2 py-3 m-lg-1 my-3">
          <div className="d-flex flex-wrap gap-5 justify-content-center">
            <DatePicker
              label="Du"
              value={from}
              onChange={(newValue) => {
                setFrom(newValue)
              }}
              className="datePicker"
              renderInput={(params) => (
                <TextField
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                    placeholder: "jj/mm/aaaa",
                  }}
                />
              )}
            />
            <DatePicker
              label="au"
              value={to}
              onChange={(newValue) => {
                setTo(newValue)
              }}
              className="datePicker"
              minDate={from}
              renderInput={(params) => (
                <TextField
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                    placeholder: "jj/mm/aaaa",
                  }}
                />
              )}
            />
          </div>
        </StyledRow>
      </LocalizationProvider>
      <div className="d-flex flex-wrap gap-2 justify-content-start px-lg-5">
        <ButtonPrimary
          label="Ajouter"
          icon={faPlusCircle}
          onClick={() => setShowModal(true)}
        />
        <ButtonPrimary
          style={{
            padding: "0.5rem 0.8rem",
            borderRadius: "8px",
            alignSelf: "center",
            background: colors.violet,
          }}
          label="Exporter"
          onClick={() => handleExport()}
        />
      </div>
      <div
        className="d-flex justify-content-start px-lg-5 my-3 fw-bold"
        style={{ fontSize: "1.2rem" }}
      >
        Solde actuel : {contentAccount}
      </div>
      <div className="d-flex justify-content-center px-lg-5">{content}</div>
      <AdminFinancialModal
        show={showModal}
        handleClose={() => setShowModal(false)}
      />
    </StyledDiv>
  )
}

export default FinancialSummary
