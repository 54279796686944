import busImg from "../../assets/bus.jpg"
import homeWave from "../../assets/home-wave2.svg"
import questionSvg from "../../assets/questions.svg"
import contactIcons from "../../assets/contact-icons.svg"
import moneySvg from "../../assets/money.svg"
import shapeVioletSvg from "../../assets/shape-violet.png"
import styled from "styled-components"
import ButtonPrimary from "../../components/ButtonPrimary"
import { faEye, faUser, faEnvelope } from "@fortawesome/free-solid-svg-icons"
import { Col, Row } from "react-bootstrap"
import waveSymImg from "../../assets/vagues-bg.svg"
import NewsBloc from "../../components/NewsBloc"
import DocumentsBloc from "../../components/DocumentsBloc"
import shapesImg from "../../assets/shapes.png"
import { Link, useNavigate } from "react-router-dom"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material"
import colors from "../../utils/style/colors"
import { fontWeight } from "@mui/system"

const StyledDiv = styled.header`
  background-image: linear-gradient(
      90deg,
      rgba(83, 44, 110, 0.7),
      rgba(83, 44, 110, 0.7)
    ),
    url(${busImg});
  background-size: cover;
  background-position: center, center center;
  height: 36rem;
  color: white;
  position: relative;
  text-align: center;

  &::after {
    background-image: url(${homeWave});
    background-size: 100% 100%;
    background-position: center;
    bottom: -1px;
    content: "";
    height: 4rem;
    left: 0;
    position: absolute;
    width: 100%;
  }

  @media all and (min-width: 992px) {
    height: 45rem;

    &::after {
      height: 20rem;
    }
  }

  h1 {
    text-transform: uppercase;
    font-size: 1.7rem;
  }

  h2 {
    font-size: 1.3rem;
    font-weight: 100;
  }
`
const StyledTitle = styled.div`
  position: absolute;
  top: 20rem;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 18rem;

  @media all and (min-width: 992px) {
    width: auto;
  }
`

const StyledRow = styled(Row)`
  padding-top: 1rem;
  @media all and (min-width: 768px) {
    padding-top: 5rem;
  }
`

const StyledShapes = styled.div`
  position: absolute;
  z-index: 1;
  bottom: -5rem;
  left: 2rem;
  transform: rotate(-23deg);

  img {
    object-fit: contain;
    width: 7rem;
  }

  @media all and (min-width: 992px) {
    bottom: 1rem;
    left: 3rem;
    transform: rotate(0deg);
    img {
      width: 10rem;
    }
  }
`

const StyledWaveUnderRow2 = styled(Row)`
  position: relative;
  align-items: center;
  padding-bottom: 8rem;

  @media all and (min-width: 768px) {
    padding-top: 5rem;
  }
  &::after {
    background-image: url(${waveSymImg});
    background-size: 100% 100%;
    background-position: center;
    bottom: -1rem;
    content: "";
    height: 5rem;
    left: 0;
    position: absolute;
    width: 100%;
  }
`

const StyledBloc = styled(Row)`
  background-image: url(${shapeVioletSvg});
  background-size: contain;
  background-position: top 0 right 0;
  background-repeat: no-repeat;
`

const StyledImg = styled.img`
  width: 27rem;
  @media all and (min-width: 992px) {
    width: auto;
  }
`
function Home() {
  let navigate = useNavigate()
  const routeChange = (path: string) => {
    navigate(path)
  }

  return (
    <div>
      <StyledDiv>
        <StyledTitle>
          <h1>CSE RDAM</h1>
          <h2>
            Retrouvez toutes nos actualités, documents et faites vos demandes de
            remboursement et commandes en ligne
          </h2>
        </StyledTitle>
        <StyledShapes>
          <img src={shapesImg} alt="" />
        </StyledShapes>
      </StyledDiv>
      <section className="container-fluid my-5" style={{ paddingTop: "5rem" }}>
        <StyledBloc className="justify-content-center">
          <Col md={6} lg={5} className="pb-2">
            <NewsBloc />
          </Col>
          <Col md={5} lg={5}>
            <DocumentsBloc />
          </Col>
        </StyledBloc>
      </section>
      <section className="container-fluid my-5">
        <Row className="justify-content-center align-items-center">
          <Col md={5} className="order-1 order-md-0">
            <h3 className="violet mb-3">
              Le Comité Social et Économique (CSE)
            </h3>
            <p>
              Le CSE remplace les anciennes instances représentatives du
              personnel depuis 2018. <br />
              Son rôle principal est de représenter les employés d’une société
              collectivement et individuellement.
            </p>
            <p style={{ fontWeight: "bold" }} className="mt-5">
              Retrouvez plus d’informations sur votre CSE et leurs membres sur
              la page dédiée.
            </p>
            <div className="text-center text-md-start">
              <ButtonPrimary
                label="Découvrez la présentation"
                icon={faEye}
                className="mt-2"
                onClick={() => routeChange("/presentation")}
              />
            </div>
          </Col>
          <Col md={5} className="text-center order-0 order-md-1 mb-5 mb-md-0">
            <StyledImg src={questionSvg} alt="" className="img-fluid" />
          </Col>
        </Row>
      </section>
      <section className="container-fluid my-2 my-md-5">
        <StyledRow className="justify-content-center align-items-center">
          <Col md={5} className="text-center">
            <StyledImg src={moneySvg} alt="" className="img-fluid" />
          </Col>
          <Col md={5}>
            <h3 className="violet mb-3 mt-5 mt-md-0">Les oeuvres sociales</h3>
            <p>
              Le CSE vous permet d’avoir accès à des tarifs réduits ou des
              remboursements partiels (cinéma, piscine, parfums, activités
              culturelles et sportives).
            </p>
            <p style={{ fontWeight: "bold" }} className="mt-5">
              Besoin de faire une commande ? Une demande de remboursement ?
              Rendez-vous sur votre profil.
            </p>
            <div className="text-center text-md-start">
              <ButtonPrimary
                label="Mon profil"
                icon={faUser}
                className="mt-2"
                type="button"
                onClick={() => navigate("/commandes")}
              />
            </div>
          </Col>
        </StyledRow>
      </section>
      <section className="container-fluid my-5">
        <StyledWaveUnderRow2 className="justify-content-center align-items-cente gap-2">
          <Col md={6} lg={5} className="p-3">
            <h3 className="violet">Votre CSE est à l’écoute</h3>
            <p>
              Un problème ? Une question ? Nous sommes là pour vous répondre.
            </p>
            <div className="text-center text-md-start pt-3">
              <ButtonPrimary
                label="Contactez-nous"
                icon={faEnvelope}
                onClick={() => routeChange("/contact")}
              ></ButtonPrimary>
            </div>
          </Col>
          <Col
            md={5}
            lg={5}
            className="text-center text-md-start justify-content-center"
          >
            <StyledImg src={contactIcons} alt="" className="img-fluid" />
          </Col>
        </StyledWaveUnderRow2>
      </section>
      <section className="container-fluid my-5 text-center">
        <h3 id="faq" className="violet pb-4">
          Foire aux questions
        </h3>
        <Row className="justify-content-center text-start">
          <Col md={10} lg={9} xl={8} xxl={7}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>
                  À partir de quand puis-je avoir accès aux avantages du CSE ?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Le CSE réserve les activités sociales et culturelles aux
                  salariés et à leur famille. Tous les salariés de l’entreprise
                  sont des ayants-droits de ces avantages CSE sans distinction
                  des contrats de travail. L'accès aux chèques vacances et
                  cartes cadoc nécessite d'avoir au moins 6 mois d'ancienneté ou
                  un contrat de plus de 6 mois.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography>
                  Comment puis-je faire une commande de parfum ?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Vous devez tout d’abord accéder au site de notre partenaire
                  laparfumerie afin de prendre connaissance des offres en-cours.
                  Vous pouvez y accéder en{" "}
                  <a
                    href="https://www.laparfumerie.eu"
                    target="_blank"
                    rel="noreferrer"
                    style={{
                      textDecoration: "none",
                      fontWeight: "bold",
                      color: colors.violet,
                    }}
                  >
                    cliquant ici
                  </a>{" "}
                  avec le login{" "}
                  <span style={{ fontWeight: "bold", color: colors.violet }}>
                    laparfumerie
                  </span>{" "}
                  et le mot de passe{" "}
                  <span style={{ fontWeight: "bold", color: colors.violet }}>
                    123456
                  </span>
                  . Ensuite directement depuis votre profil, vous pouvez nous{" "}
                  <Link to="/commandes/autre" style={{ color: colors.violet }}>
                    passer votre commande
                  </Link>
                  . N’oubliez pas de renseigner la référence de votre produit.
                  Le paiement se fera au moment de la réception de votre colis
                  auprès de l’un des membres de votre CSE.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography>
                  Quelle est la réduction associée aux cartes de piscines et aux
                  places de cinéma ?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Le CSE vous permet d’avoir accès à des tarifs préférentiels
                  pour des places de cinéma et des cartes d’accès à la piscine
                  municipale de Sedan et au centre aquatique Bernard Albin de
                  Charleville-Mézières. Retrouvez les tarifs dans la section{" "}
                  <Link to="/commandes" style={{ color: colors.violet }}>
                    Mes commandes
                  </Link>
                  .
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography>
                  Quelle part des activités sportives et culturelles est pris en
                  charge par le CSE ?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Concernant les activités sportives, le CSE s’engage à prendre
                  à hauteur de 40€ par enfant de moins de 16 ans par année
                  civile. Concernant les activités culturelles, le CSE peut
                  participer à hauteur de 50€ maximum une fois par année civile.
                  Faites vos{" "}
                  <Link to="/remboursements" style={{ color: colors.violet }}>
                    demandes de remboursement
                  </Link>{" "}
                  directement sur votre profil.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography>
                  Comment faire parvenir les pièces justificatives pour mon
                  remboursement ?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Il est possible lorsque vous remplissez votre demande de
                  remboursement d’y ajouter une pièce jointe avec votre
                  justificatif (facture).
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography>
                  Comment puis-je avoir accès à mon compte chèque cadoc ?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Rendez-vous directement sur le site{" "}
                  <a
                    href="https://www.illicado.com"
                    target="_blank"
                    rel="noreferrer"
                    style={{ color: colors.violet }}
                  >
                    illicado.com
                  </a>{" "}
                  et entrer vos identifiants afin de consulter votre solde.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Col>
        </Row>
      </section>
    </div>
  )
}

export default Home
