import { faChevronRight, faCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useMemo } from "react"
import { Link } from "react-router-dom"
import styled from "styled-components"
import { useGetDocumentsQuery } from "../../features/documentSlice"
import { Document } from "../../models/document"
import { API } from "../../utils/API"
import { Loader } from "../../utils/Atoms"
import colors from "../../utils/style/colors"
import { formatStrToDate } from "../../utils/utils"
import InternError from "../InternError"
import Toastify from "toastify-js"

const StyledDiv = styled.header`
  background: #fcfcfc 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 35px #00000008;
  border-radius: 7px;
`

const StyledIcon = styled(FontAwesomeIcon)`
  font-size: 0.5rem;
  padding: 0.2rem;
`
const StyledLink = styled(Link)`
  color: rgba(0, 0, 0, 0.3);
  font-size: 1rem;
  text-decoration: none;
  &:hover,
  &.active {
    color: ${colors.blue}!important;
  }
`

const StyledDate = styled.div`
  font-size: 0.7rem;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.5);
`
const StyledTitle = styled.div`
  font-size: 1rem;
  font-weight: bold;
`

const FakeLink = styled.div`
  &:hover {
    cursor: pointer;
    color: ${colors.violet};
  }
`

const StyledDesc = styled.div`
  font-size: 0.9rem;
`

function DocumentsBloc() {
  const {
    data: documents = [],
    isLoading,
    isSuccess,
    isError,
    error,
    refetch,
  } = useGetDocumentsQuery()

  const sortedDocuments = useMemo(() => {
    const sortedDocuments = documents.slice()
    // Sort posts in descending chronological order
    sortedDocuments.sort((a: Document, b: Document) =>
      b.createdAt.localeCompare(a.createdAt)
    )
    return sortedDocuments.slice(0, 4)
  }, [documents])

  const handleDownload = (id: number, fileName: string) => {
    API.get(`documents/${id}/download`, {
      responseType: "blob",
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement("a")
        link.href = url
        link.setAttribute("download", fileName)
        document.body.appendChild(link)
        link.click()
      })
      .catch((error) => {
        Toastify({
          text: "Une erreur s'est produite lors du téléchargement",
          duration: 8000,
          newWindow: true,
          close: true,
          gravity: "top", // `top` or `bottom`
          position: "right", // `left`, `center` or `right`
          stopOnFocus: true, // Prevents dismissing of toast on hover
          style: {
            background: "linear-gradient(to right, #29aac4, #bd79e7)",
          },
          onClick: function () {}, // Callback after click
        }).showToast()
      })
  }

  /*useEffect(() => {
    refetch()
  }, [])*/

  let content

  if (isLoading) {
    content = <Loader />
  } else if (isSuccess) {
    content = (
      <div className="d-flex flex-column p-2 p-md-4 pt-2">
        {sortedDocuments &&
          sortedDocuments.map((document: Document, index: number) => (
            <div className="d-flex py-2" key={document.id}>
              <FakeLink
                className="d-flex flex-column px-3 py-1"
                onClick={() => handleDownload(document.id, document.path)}
              >
                <StyledDate>Document</StyledDate>
                <StyledTitle>{document.title}</StyledTitle>
                <StyledDesc>
                  Mis en ligne le {formatStrToDate(document.createdAt)}
                </StyledDesc>
              </FakeLink>
            </div>
          ))}
      </div>
    )
  } else if (isError) {
    content = <InternError />
  }

  return (
    <StyledDiv>
      <div className="d-flex justify-content-between px-4 pt-4 flex-wrap">
        <h3 style={{ fontSize: "1.4rem" }}>
          <StyledIcon icon={faCircle} color={colors.violet}></StyledIcon>{" "}
          Derniers documents
        </h3>
        <StyledLink to="/documents">
          Voir tout <FontAwesomeIcon icon={faChevronRight}></FontAwesomeIcon>
        </StyledLink>
      </div>
      {content}
    </StyledDiv>
  )
}

export default DocumentsBloc
