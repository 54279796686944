import WaveHeader from "../../../components/WaveHeader"
import bgImage from "../../../assets/parameters.jpg"
import { Col, Form, Modal, Row } from "react-bootstrap"
import styled from "styled-components"
import ButtonPrimary from "../../../components/ButtonPrimary"
import { faAt, faLock, faXmark } from "@fortawesome/free-solid-svg-icons"
import colors from "../../../utils/style/colors"
import { Divider } from "@mui/material"
import React, { useEffect, useState } from "react"
import Toastify from "toastify-js"
import ButtonSecondary from "../../../components/ButtonSecondary"
import { useAuth } from "../../../utils/hooks/useAuth"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { API } from "../../../utils/API"

const StyledDiv = styled(Row)`
  background-color: white;
  font-size: 1rem;
  box-shadow: 3px 3px 10px lightgray;
  width: 100%;
  margin: 2rem 0.5rem;
  padding: 1rem 0.3rem;

  @media all and (min-width: 768px) {
    max-width: 40rem;
    padding: 1rem 1rem 2rem 1rem;
  }
  @media all and (min-width: 1200px) {
    max-width: 40rem;
  }
`
const StyledCol = styled(Col)`
  font-weight: bold;
  font-size: 1.1rem;
  color: ${colors.darkViolet};
`

const StyledTitle = styled.h2`
  background: transparent
    linear-gradient(180deg, #58b4e5 0%, #5687ce 23%, #9060b1 64%, #ab75d0 100%)
    0% 0% no-repeat padding-box;
  border-radius: 3px;
  color: white;
  padding: 0.5rem 1rem;
  text-align: center;
  position: relative;
  top: -2.3rem;
  font-size: 1.3rem;
  width: fit-content;
`

const StyledModal = styled(Modal)`
  font-size: 0.9rem;
  .modal-title {
    font-size: 1rem !important;
    font-weight: 300 !important;
    padding-left: 0.5rem;

    @media (min-width: 768px) {
      font-size: 1.2rem !important;
    }
  }

  .modal-header {
    background-color: ${colors.violet};
    color: white;
  }
`
interface ModalProps {
  show: boolean
  handleClose: () => void
}

function ChangeMailModal(modalProps: ModalProps) {
  const [validated, setValidated] = useState(false)
  const [msgError, setMsgError] = useState<string>()
  const [isLoading, setIsLoading] = useState(false)
  const [errors, setErrors] = useState({} as any)
  const [currentPassword, setCurrentPassword] = useState<string>("")
  const [email, setEmail] = useState<string>("")
  const { logout } = useAuth()

  useEffect(() => {
    if (!modalProps.show) {
      setMsgError("")
      setErrors(null)
      setValidated(false)
      setEmail("")
      setCurrentPassword("")
    }
  }, [modalProps.show])

  const handleSubmit = (event: any) => {
    event.preventDefault()
    event.stopPropagation()
    const form = event.currentTarget
    if (form.checkValidity() === false) {
      setValidated(true)
      return
    } else {
      setIsLoading(true)
      //reset
      API.post(`change-email`, {
        email: email,
        currentPassword: currentPassword,
      })
        .then((response: any) => {
          Toastify({
            text: "L'adresse email a bien été changée",
            duration: 8000,
            newWindow: true,
            close: true,
            gravity: "top", // `top` or `bottom`
            position: "right", // `left`, `center` or `right`
            stopOnFocus: true, // Prevents dismissing of toast on hover
            style: {
              background: "linear-gradient(to right, #29aac4, #bd79e7)",
            },
            onClick: function () {}, // Callback after click
          }).showToast()
          logout()
          modalProps.handleClose()
        })
        .catch((error: any) => {
          console.error(error.response)
          Toastify({
            text: "Une erreur s'est produite lors de la modification de l'adresse mail",
            duration: 8000,
            newWindow: true,
            close: true,
            gravity: "top", // `top` or `bottom`
            position: "right", // `left`, `center` or `right`
            stopOnFocus: true, // Prevents dismissing of toast on hover
            style: {
              background: "linear-gradient(to right, #29aac4, #bd79e7)",
            },
            onClick: function () {}, // Callback after click
          }).showToast()
          if (error.response.status === 400) {
            setMsgError(error.response.data)
          }
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }

  return (
    <StyledModal
      show={modalProps.show}
      onHide={modalProps.handleClose}
      backdrop="static"
      keyboard={false}
      dialogClassName="modal-width"
      centered
    >
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title>Modifier l'adresse email</Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-4">
        <Form onSubmit={handleSubmit} validated={validated} noValidate>
          <div className="mb-3">
            Veuillez indiquer votre mot de passe actuel
          </div>

          <Form.Group className="mb-3" controlId="formBasicCurrentPassword">
            <Form.Control
              required
              type="password"
              placeholder="Mot de passe actuel"
              onChange={(e) => {
                setCurrentPassword(e.target.value)
              }}
              name="currentPassword"
            />
            <Form.Control.Feedback type="invalid">
              Le mot de passe actuel est requis
            </Form.Control.Feedback>
          </Form.Group>
          <div className="mb-3">
            Veuillez indiquer votre nouvelle adresse mail
          </div>

          <Form.Group className="mb-3" controlId="formMail">
            <Form.Control
              required
              type="email"
              placeholder="Adresse mail"
              onChange={(e) => {
                setEmail(e.target.value)
              }}
              name="email"
            />
            <Form.Control.Feedback type="invalid">
              L'adresse email est requise
            </Form.Control.Feedback>
          </Form.Group>
          {msgError && (
            <div className="mb-3 fst-italic text-center">
              <FontAwesomeIcon icon={faXmark} color="darkred" /> {msgError}
            </div>
          )}
          <div className="d-flex flex-wrap gap-1 justify-content-center mt-4 w-100">
            <ButtonPrimary
              label="Valider"
              type="submit"
              loading={isLoading}
              disabled={isLoading}
            />
            <ButtonSecondary onClick={modalProps.handleClose} label="Annuler" />
          </div>
        </Form>
      </Modal.Body>
    </StyledModal>
  )
}

function ChangePasswordModal(modalProps: ModalProps) {
  const [validated, setValidated] = useState(false)
  const [msgError, setMsgError] = useState<string>()
  const [isLoading, setIsLoading] = useState(false)
  const [match, setMatch] = useState(false)
  const [errors, setErrors] = useState({} as any)
  const [currentPassword, setCurrentPassword] = useState<string>("")

  const [formState, setFormState] = useState<any>({
    plainPassword: {
      first: "",
      second: "",
      currentPassword: "",
    },
  })

  const handleChange = ({
    target: { name, value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setFormState((prev: any) => ({
      ...prev,
      plainPassword: { ...prev.plainPassword, [name]: value },
    }))
  }
  useEffect(() => {
    setMatch(formState.plainPassword?.first === formState.plainPassword?.second)
  }, [formState])

  useEffect(() => {
    if (!modalProps.show) {
      setMsgError("")
      setErrors(null)
      setValidated(false)
      setFormState({
        plainPassword: {
          first: "",
          second: "",
        },
      })
      setCurrentPassword("")
    }
  }, [modalProps.show])

  const handleSubmit = (event: any) => {
    event.preventDefault()
    event.stopPropagation()
    const form = event.currentTarget
    if (form.checkValidity() === false) {
      setValidated(true)
      return
    } else {
      setIsLoading(true)
      //reset
      console.log(formState)
      API.post(`change-password`, {
        form: formState,
        currentPassword: currentPassword,
      })
        .then((response: any) => {
          Toastify({
            text: "Le mot de passe a bien été changé",
            duration: 8000,
            newWindow: true,
            close: true,
            gravity: "top", // `top` or `bottom`
            position: "right", // `left`, `center` or `right`
            stopOnFocus: true, // Prevents dismissing of toast on hover
            style: {
              background: "linear-gradient(to right, #29aac4, #bd79e7)",
            },
            onClick: function () {}, // Callback after click
          }).showToast()
          modalProps.handleClose()
        })
        .catch((error: any) => {
          console.log("ici", error.response)
          Toastify({
            text: "Une erreur s'est produite lors de la modification du mot de passe",
            duration: 8000,
            newWindow: true,
            close: true,
            gravity: "top", // `top` or `bottom`
            position: "right", // `left`, `center` or `right`
            stopOnFocus: true, // Prevents dismissing of toast on hover
            style: {
              background: "linear-gradient(to right, #29aac4, #bd79e7)",
            },
            onClick: function () {}, // Callback after click
          }).showToast()
          if (error.response.status === 401) {
            setMsgError(error.response.data)
          }
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }

  return (
    <StyledModal
      show={modalProps.show}
      onHide={modalProps.handleClose}
      backdrop="static"
      keyboard={false}
      dialogClassName="modal-width"
      centered
    >
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title>Modifier le mot de passe</Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-4">
        <Form onSubmit={handleSubmit} validated={validated} noValidate>
          <div className="mb-3">
            Veuillez indiquer votre mot de passe actuel
          </div>

          <Form.Group className="mb-3" controlId="formBasicCurrentPassword">
            <Form.Control
              required
              type="password"
              placeholder="Mot de passe actuel"
              onChange={(e) => {
                setCurrentPassword(e.target.value)
              }}
              name="currentPassword"
            />
            <Form.Control.Feedback type="invalid">
              Le mot de passe actuel est requis
            </Form.Control.Feedback>
          </Form.Group>
          <div className="mb-3">
            Veuillez indiquer votre nouveau mot de passe (min. 8 caractères)
          </div>

          <Form.Group className="mb-3" controlId="formBasicPlainPassword">
            <Form.Control
              required
              minLength={8}
              type="password"
              placeholder="Nouveau mot de passe"
              onChange={handleChange}
              name="first"
              isInvalid={
                validated && formState.plainPassword?.first?.length < 8
              }
            />
            <Form.Control.Feedback type="invalid">
              Le mot de passe doit faire au moins 8 caractères
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicPlainPassword2">
            <Form.Control
              required
              minLength={8}
              type="password"
              placeholder="Répétez votre mot de passe"
              onChange={handleChange}
              name="second"
              isInvalid={validated && !match}
            />
            <Form.Control.Feedback type="invalid">
              Les mots de passe ne correspondent pas
            </Form.Control.Feedback>
          </Form.Group>
          {msgError && (
            <div className="mb-3 fst-italic text-center">
              <FontAwesomeIcon icon={faXmark} color="darkred" /> {msgError}
            </div>
          )}
          <div className="d-flex flex-wrap gap-1 justify-content-center mt-4 w-100">
            <ButtonPrimary
              label="Valider"
              type="submit"
              loading={isLoading}
              disabled={isLoading}
            />
            <ButtonSecondary onClick={modalProps.handleClose} label="Annuler" />
          </div>
        </Form>
      </Modal.Body>
    </StyledModal>
  )
}

function Parameters() {
  const [showPasswordModal, setShowPasswordModal] = useState(false)
  const [showMailModal, setShowMailModal] = useState(false)
  const {
    loggedUser: { user },
  } = useAuth()
  let content = (
    <StyledDiv className="gap-3 justify-content-center">
      <div className="row justify-content-center">
        <StyledTitle>Vos paramètres</StyledTitle>
      </div>

      <Row className="justify-content-center">
        <StyledCol sm={6} md={5}>
          Email :
        </StyledCol>
        <Col sm={6} md={7}>
          {user?.email}
        </Col>
      </Row>
      <Divider />
      <Row className="justify-content-center">
        <StyledCol sm={6} md={5}>
          Mot de passe :
        </StyledCol>
        <Col sm={6} md={7}>
          ********
        </Col>
      </Row>

      <div className="d-flex flew-wrap justify-content-end gap-1 mt-4">
        <ButtonPrimary
          type="button"
          label="Modifier le mot de passe"
          icon={faLock}
          onClick={() => setShowPasswordModal(true)}
        />
        <ButtonPrimary
          type="button"
          label="Modifier l'adresse mail"
          icon={faAt}
          onClick={() => setShowMailModal(true)}
        />
      </div>
    </StyledDiv>
  )

  return (
    <div>
      <WaveHeader title="Paramètres" image={bgImage} />
      <div className="d-flex justify-content-center">{content}</div>
      <ChangePasswordModal
        show={showPasswordModal}
        handleClose={() => {
          setShowPasswordModal(false)
        }}
      />
      <ChangeMailModal
        show={showMailModal}
        handleClose={() => {
          setShowMailModal(false)
        }}
      />
    </div>
  )
}

export default Parameters
