import { Col, FloatingLabel, Form, Modal, Row } from "react-bootstrap"
import ButtonPrimary from "../ButtonPrimary"
import styled from "styled-components"
import colors from "../../utils/style/colors"
import ButtonSecondary from "../ButtonSecondary"
import { useEffect, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faExclamationTriangle,
  faFilm,
  faPersonSwimming,
} from "@fortawesome/free-solid-svg-icons"
import { StockOrderType } from "../../models/cardStockOrder"
import CardOrder from "../../models/cardOrder"
import { API } from "../../utils/API"
import { Loader } from "../../utils/Atoms"
import InternError from "../InternError"
import { calculateOrderAmount, formatPrice } from "../../utils/utils"
import { useAddCardOrderMutation } from "../../features/orderSlice"

interface ModalProps {
  show: boolean
  handleClose: () => void
  type: StockOrderType
}

const StyledModal = styled(Modal)`
  font-size: 0.9rem;
  h2 {
    font-size: 1rem;
    font-weight: 300;
    padding-left: 0.5rem;

    @media (min-width: 768px) {
      font-size: 1.2rem;
    }
  }

  .prices span,
  .total span {
    font-weight: bold;
  }

  .modal-header {
    background-color: ${colors.violet};
    color: white;
  }
`

function CardModal(modalProps: ModalProps) {
  const [validated, setValidated] = useState(false)
  const [orderPriceInfoLoading, setOrderPriceInfoLoading] = useState(false)
  const [orderPriceInfoSuccess, setOrderPriceInfoSuccess] = useState(false)
  const [orderPriceInfoError, setOrderPriceInfoError] = useState(null)
  const [cardOrder, setCardOrder] = useState<Partial<CardOrder>>()
  const [priceInfos, setPriceInfos] = useState<any>()
  const [total, setTotal] = useState(0)
  const [errors, setErrors] = useState({} as any)
  const [msgError, setMsgError] = useState<string>()
  const [addCardOrder, { isLoading: isPostLoading }] = useAddCardOrderMutation()

  const handleCardOrderChange = ({
    target: { name, value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setCardOrder((prev) => ({ ...prev, [name]: value }))
  }

  const handleAddCardOrder = (event: any) => {
    const form = event.currentTarget
    event.preventDefault()
    event.stopPropagation()
    if (form.checkValidity() === false) {
      setValidated(true)
    } else {
      let newCardOrder = {
        adultQuantity: +(cardOrder?.adultQuantity ?? 0),
        childQuantity: +(cardOrder?.childQuantity ?? 0),
        type: modalProps.type,
      } as Partial<CardOrder>

      addCardOrder(newCardOrder)
        .unwrap()
        .then(() => modalProps.handleClose())
        .catch((error: any) => {
          setMsgError("Veuillez vérifier les données")
          setValidated(true)
          setErrors(error.data?.detail)

          console.error(errors)
        })
    }
  }

  useEffect(() => {
    if (cardOrder && priceInfos) {
      let newAmount = calculateOrderAmount(
        cardOrder,
        priceInfos,
        modalProps.type
      )
      setTotal(newAmount)
    }
  }, [cardOrder])

  useEffect(() => {
    setValidated(false)
    if (modalProps.show) {
      setOrderPriceInfoLoading(true)
      API.get(`card-order/${modalProps.type}/infos`)
        .then((response) => {
          setPriceInfos(response.data)
          setOrderPriceInfoSuccess(true)
        })
        .catch((error) => {
          setOrderPriceInfoError(error)
          setOrderPriceInfoSuccess(false)
        })
        .finally(() => setOrderPriceInfoLoading(false))
    }
  }, [modalProps.show])

  return (
    <>
      <StyledModal
        show={modalProps.show}
        onHide={modalProps.handleClose}
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-width p-0"
        centered
      >
        <Modal.Header closeButton closeVariant="white">
          {modalProps.type === StockOrderType.Movie ? (
            <Modal.Title className="d-flex gap-2 pt-1 align-items-center align-items-sm-start">
              <FontAwesomeIcon icon={faFilm} color="white" />
              <h2>Commander des places de cinéma</h2>
            </Modal.Title>
          ) : (
            <Modal.Title className="d-flex gap-2">
              <FontAwesomeIcon icon={faPersonSwimming} color="white" />
              <h2>Commander des cartes de piscine</h2>
            </Modal.Title>
          )}
        </Modal.Header>
        <Modal.Body className="px-4">
          {orderPriceInfoLoading ? (
            <Loader />
          ) : orderPriceInfoError ? (
            <InternError />
          ) : (
            <Form
              noValidate
              validated={validated}
              onSubmit={handleAddCardOrder}
            >
              <Form.Group className="mb-4">
                {modalProps.type === StockOrderType.Movie ? (
                  <div className="d-flex gap-4 flex-wrap prices">
                    <div className="mb-1">
                      <span>Tarif adulte :</span>{" "}
                      {formatPrice(priceInfos?.adultPrice)}
                    </div>
                    <div className="mb-3">
                      <span>Tarif enfant :</span>{" "}
                      {formatPrice(priceInfos?.childPrice)}
                    </div>
                  </div>
                ) : (
                  <div className="d-flex gap-4 flex-wrap prices">
                    <div className="mb-1">
                      <span>Prix d'une carte :</span>{" "}
                      {formatPrice(priceInfos?.adultPrice)}
                    </div>
                    {/* <div className="mb-3">
                      <span>Tarif enfant :</span>{" "}
                      {formatPrice(priceInfos?.childPrice)}
                    </div> */}
                  </div>
                )}
                {/* {modalProps.type === StockOrderType.Movie &&
                  orderPriceInfoSuccess &&
                  (priceInfos.nbOrders > priceInfos.movieQuota ||
                    priceInfos.movieQuota - priceInfos.nbOrders <
                      +(cardOrder?.adultQuantity ?? 0) +
                        +(cardOrder?.childQuantity ?? 0)) && (
                    <div className="mb-2">
                      <FontAwesomeIcon
                        icon={faExclamationTriangle}
                        color="orange"
                      />
                      &nbsp; Vous avez atteint le quota de places annuelles, les
                      prix coûtants sont donc appliqués
                      {priceInfos.movieQuota - priceInfos.nbOrders > 0
                        ? ` au delà de ${
                            priceInfos.movieQuota - priceInfos.nbOrders
                          } places`
                        : ""}
                      &nbsp;(Adulte : {formatPrice(priceInfos.adultCostPrice)} -
                      Enfant : {formatPrice(priceInfos.childCostPrice)})
                    </div>
                  )} */}
                <Row className="mb-3 ps-2">
                  Veuillez renseigner les champs ci-dessous :
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col} controlId="formAdultQte">
                    <FloatingLabel
                      controlId="adultQuantity"
                      label={`Nombre de ${
                        modalProps.type === StockOrderType.Movie
                          ? "places adultes"
                          : "cartes"
                      }`}
                    >
                      <Form.Control
                        placeholder="Nombre de places adultes"
                        aria-label="Nombre de places adultes"
                        name="adultQuantity"
                        required
                        type="number"
                        step={1}
                        min={0}
                        onChange={handleCardOrderChange}
                        isInvalid={validated && errors?.adultQuantity}
                      />
                      {errors && errors.adultQuantity ? (
                        <Form.Control.Feedback
                          type="invalid"
                          style={{ display: "block" }}
                        >
                          {errors &&
                            errors.adultQuantity?.map(
                              (error: any, index: number) => (
                                <div key={index}>{error}</div>
                              )
                            )}
                        </Form.Control.Feedback>
                      ) : (
                        <Form.Control.Feedback type="invalid">
                          Veuillez entrer des chiffres entiers
                        </Form.Control.Feedback>
                      )}
                    </FloatingLabel>
                  </Form.Group>
                </Row>
                {modalProps.type === StockOrderType.Movie && (
                  <Row className="mb-3">
                    <Form.Group as={Col} controlId="formChildQte">
                      <FloatingLabel
                        controlId="childQuantity"
                        label={`Nombre de ${
                          modalProps.type === StockOrderType.Movie
                            ? "places"
                            : "cartes"
                        } enfants`}
                      >
                        <Form.Control
                          placeholder="Nombre de places enfants"
                          aria-label="Nombre de places enfants"
                          name="childQuantity"
                          required
                          type="number"
                          step={1}
                          min={0}
                          onChange={handleCardOrderChange}
                          isInvalid={validated && errors?.childQuantity}
                        />
                        {errors && errors.childQuantity ? (
                          <Form.Control.Feedback
                            type="invalid"
                            style={{ display: "block" }}
                          >
                            {errors &&
                              errors.childQuantity?.map(
                                (error: any, index: number) => (
                                  <div key={index}>{error}</div>
                                )
                              )}
                          </Form.Control.Feedback>
                        ) : (
                          <Form.Control.Feedback type="invalid">
                            Veuillez entrer des chiffres entiers
                          </Form.Control.Feedback>
                        )}
                      </FloatingLabel>
                    </Form.Group>
                  </Row>
                )}
              </Form.Group>
              <Row className="mb-3 total">
                <Form.Group as={Col}>
                  <span>Montant total :</span> {formatPrice(total)}
                </Form.Group>
              </Row>

              <Form.Group className="my-3 text-danger">{msgError}</Form.Group>
              <div className="d-flex justify-content-end gap-3 flex-wrap">
                <ButtonPrimary
                  label="Valider"
                  type="submit"
                  loading={isPostLoading}
                  disabled={!(total > 0)}
                />
                <ButtonSecondary
                  onClick={modalProps.handleClose}
                  label="Annuler"
                />
              </div>
            </Form>
          )}
        </Modal.Body>
      </StyledModal>
    </>
  )
}

export default CardModal
