import { TextField } from "@mui/material"
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { Row } from "react-bootstrap"
import styled from "styled-components"
import colors from "../../utils/style/colors"
import frLocale from "date-fns/locale/fr"
import { useEffect, useState } from "react"
import DataTable from "react-data-table-component"
import { Loader } from "../../utils/Atoms"
import Toastify from "toastify-js"

import {
  formatPrice,
  formatStrToDate,
  getFirstDayOfCurrentYear,
  getLastDayOfCurrentYear,
} from "../../utils/utils"
import { getStatusColor, OrderStatusType } from "../../models/order"
import { format } from "date-fns"
import InternError from "../InternError"
import { useNavigate } from "react-router-dom"
import ButtonPrimary from "../ButtonPrimary"
import { API } from "../../utils/API"
import Operation, { Operationfrom } from "../../models/operation"
import { useGetBankOperationWithPaginationQuery } from "../../features/operationSlice"

const StyledRow = styled(Row)`
  font-size: 1rem;
  h3 {
    font-size: 1.1rem !important;
    color: ${colors.darkViolet};
    margin-bottom: 0 !important ;
  }
`

const StyledSpan = styled.span<{ status: OrderStatusType }>`
  font-size: 0.8rem;
  padding: 0.2rem 0.5rem;
  border-radius: 2rem;
  align-self: center;
  text-align: center;
  white-space: nowrap;
  background-color: ${(prop) =>
    prop.status ? getStatusColor(prop.status) : "white"};
  color: white;
  /*margin-left: 0.5rem;*/
`

function AdminCSEOtherOrder() {
  const [from, setFrom] = useState<Date | null>(getFirstDayOfCurrentYear())
  const [to, setTo] = useState<Date | null>(getLastDayOfCurrentYear())
  const [fromDate, setFromDate] = useState<string | null>()
  const [toDate, setToDate] = useState<string | null>()
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(10)
  const [order, setOrder] = useState({
    orderBy: "operationDate",
    direction: "DESC",
  })

  const navigate = useNavigate()

  const paginationComponentOptions = {
    rowsPerPageText: "Commandes par page",
    rangeSeparatorText: "de",
  }

  const columns = [
    {
      name: "Date commande",
      selector: (row: Operation) => row.operationDate,
      sortable: true,
      sortField: "operationDate",
      format: (row: Operation) =>
        formatStrToDate(row.operationDate, "dd/MM/yyyy HH:mm"),
    },
    {
      name: "Libellé",
      selector: (row: Operation) => row.title,
    },
    {
      name: "Détails",
      selector: (row: Operation) => row.detail,
    },
    {
      name: "Montant",
      selector: (row: Operation) => row.amount,
      format: (row: Operation) => formatPrice(row.amount),
    },

    {
      name: "Nb commandes salariés",
      selector: (row: Operation) => row.paymentDebit?.otherOrders,
      format: (row: Operation) => row.paymentDebit?.otherOrders?.length,
    },
  ] as any

  const {
    data: operationPaginate = [],
    isLoading,
    isSuccess,
    isError,
  } = useGetBankOperationWithPaginationQuery(
    {
      limit: perPage,
      page: page,
      orderBy: order.orderBy,
      direction: order.direction,
      operationFrom: Operationfrom.OtherOrderAdvance,
      from: fromDate,
      to: toDate,
    },
    { skip: !fromDate || !toDate }
  )

  const handlePageChange = (page: number) => {
    setPage(page)
  }

  const handlePerRowsChange = (newPerPage: number) => {
    setPerPage(newPerPage)
  }

  const handleSort = (selectedColumn: any, sortDirection: any) => {
    setOrder({
      orderBy: selectedColumn.sortField,
      direction: sortDirection,
    })
  }

  const handleRowClicked = (id: number) => {
    //Go to operation detail page
    navigate(`/admin/operation/${id}`)
  }

  useEffect(() => {
    try {
      let date1 = from ? format(from, "yyyy-MM-dd HH:mm:ss") : null
      let date2 = to ? format(to, "yyyy-MM-dd HH:mm:ss") : null
      setFromDate(date1)
      setToDate(date2)
    } catch (error) {
      console.error(error)
    }
  }, [from, to])

  const handleExport = () => {
    if (from && to) {
      API.get(`admin/other-order/export`, {
        responseType: "blob",
        params: {
          from: fromDate,
          to: toDate,
        },
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement("a")
          link.href = url
          link.setAttribute(
            "download",
            `commandes-autre_${format(from, "dd-MM-yyyy")}_${format(
              to,
              "dd-MM-yyyy"
            )}.xlsx`
          )
          document.body.appendChild(link)
          link.click()
        })
        .catch((error) => {
          Toastify({
            text: "Une erreur s'est produite lors du téléchargement",
            duration: 8000,
            newWindow: true,
            close: true,
            gravity: "top", // `top` or `bottom`
            position: "right", // `left`, `center` or `right`
            stopOnFocus: true, // Prevents dismissing of toast on hover
            style: {
              background: "linear-gradient(to right, #29aac4, #bd79e7)",
            },
            onClick: function () {}, // Callback after click
          }).showToast()
        })
    }
  }

  return (
    <div className="my-3">
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={frLocale}
      >
        <h2
          style={{
            fontSize: "1.7rem",
            color: colors.violet,
            textAlign: "center",
          }}
        >
          Commandes passées par le CSE
        </h2>
        <StyledRow className="py-3 px-0 mx-0 my-3">
          <div className="d-flex flex-wrap gap-5 justify-content-center">
            <DatePicker
              label="Du"
              value={from}
              onChange={(newValue) => {
                setFrom(newValue)
              }}
              className="datePicker"
              renderInput={(params) => (
                <TextField
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                    placeholder: "jj/mm/aaaa",
                  }}
                />
              )}
            />
            <DatePicker
              label="au"
              value={to}
              onChange={(newValue) => {
                setTo(newValue)
              }}
              className="datePicker"
              minDate={from}
              renderInput={(params) => (
                <TextField
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                    placeholder: "jj/mm/aaaa",
                  }}
                />
              )}
            />
          </div>
          <div className="mt-4 mb-3 d-flex flex-wrap gap-1 ">
            <ButtonPrimary
              style={{
                padding: "0.5rem 0.8rem",
                borderRadius: "8px",
                alignSelf: "center",
                background: colors.violet,
              }}
              label="Exporter"
              onClick={() => handleExport()}
            />
          </div>
          {isSuccess && (
            <DataTable
              columns={columns}
              data={operationPaginate.items}
              progressPending={isLoading}
              pagination
              striped
              paginationServer
              paginationTotalRows={operationPaginate.totalItems}
              onChangeRowsPerPage={handlePerRowsChange}
              paginationPerPage={perPage}
              onChangePage={handlePageChange}
              onRowClicked={(row: Operation) => handleRowClicked(row.id)}
              onSort={handleSort}
              sortServer
              defaultSortFieldId={1}
              defaultSortAsc={false}
              responsive
              highlightOnHover
              pointerOnHover
              noDataComponent="Aucune commande"
              progressComponent={<Loader></Loader>}
              paginationComponentOptions={paginationComponentOptions}
            ></DataTable>
          )}
          {isError && <InternError />}
        </StyledRow>
      </LocalizationProvider>
    </div>
  )
}

export default AdminCSEOtherOrder
